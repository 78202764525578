import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import validator from 'validator';
import ImgLogo from '../assets/images/logo.png';
import ImgBackground from '../assets/images/login-background.png';
import { Button, Input, LoginForm, Message } from '../components';
import { forgotPassword } from '../auth0';
import { TEXT_MISS_EMAIL, TEXT_WRONG_FORMAT_EMAIL } from '../constants/message';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmitForgot = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validator.isEmpty(email)) {
      setEmailError(TEXT_MISS_EMAIL);
      setLoading(false);
    } else if (!validator.isEmail(email)) {
      setEmailError(TEXT_WRONG_FORMAT_EMAIL);
      setLoading(false);
    } else {
      forgotPassword(e, email, (err, rspn) => {
        setResponse(err || rspn);
        setLoading(false);
      });
    }
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  return (
    <LoginForm>
      <img className="login-background" alt="backgound" src={ImgBackground} />
      <div className="login-overlay" />
      <form id="forgotForm" onSubmit={handleSubmitForgot}>
        <img alt="logo" src={ImgLogo} />
        <div className="form">
          <Row>
            <h2>FORGOT PASSWORD?</h2>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <p className="forgot-password-text">
              Let us know your email address so that we can send you an email to
              reset your password.
            </p>
          </Row>
          <Row>
            <p>Email Address</p>
          </Row>
          <Row className={emailError.error_description ? 'input--error' : ''}>
            <Input
              type="text"
              size="large"
              onChange={(e) => onChangeEmail(e)}
            />
          </Row>
          <Message type="error">{emailError}</Message>
          <Button
            size="large"
            loading={loading}
            type="primary"
            form="forgotForm"
            key="submit"
            htmlType="submit"
            block
          >
            RESET PASSWORD
          </Button>
          {response ? <Message type="response">{response}</Message> : ''}
          <Row>
            <span
              className="under-line back-to-login"
              aria-hidden
              onClick={() => history.push('/login')}
            >
              Back to Login
            </span>
          </Row>
        </div>
      </form>
    </LoginForm>
  );
};

export default ForgetPassword;
