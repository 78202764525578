import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from './Button';
import Modal from './Modal';
import COUNTRY_LIST from '../constants/countries';
import { usePrevious } from '../helpers/hooks';

import { FormInput, FormSearchInput, FormLocationSearchInput } from './Forms';

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 410px auto;
`;
const FormRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.error ? '0' : '15px')};
`;
const FormColumn = styled.div`
  width: ${(props) => `${props.width}px` || 'auto'};
`;
const FormItem = styled.div`
  .ant-select {
    min-width: 200px;
  }
`;

const StyledAddressInput = styled.div`
  .ant-modal-footer {
    padding: 25px 0 !important;
    button {
      min-width: 120px;
    }
  }
`;

const FormEffect = ({ data, values, setFieldValue, children }) => {
  const prevCountry = usePrevious(values.country);
  useEffect(() => {
    if (
      prevCountry &&
      prevCountry !== values.country &&
      data?.country !== values.country
    ) {
      setFieldValue('city', '');
      setFieldValue('state', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevCountry, setFieldValue, values.country]);
  return <>{children}</>;
};

const AddressInput = ({
  title,
  children,
  submitText,
  onSubmit = async () => {},
  data = null
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateDirtyCountry, setValidateDirtyCountry] = useState(true);
  const [validateState, setValidateState] = useState(false);
  const countryField = useRef();
  const [initialValues, setInitialValues] = useState({
    locationName: '',
    city: '',
    state: '',
    postalCode: '',
    address: '',
    pocName: '',
    pocPhoneNumber: '',
    pocEmail: ''
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        locationId: data.location_id,
        locationName: data.location_name,
        country: data.country,
        city: data.city,
        state: data.state,
        postalCode: data.postal_code,
        address: data.street_address,
        pocName: data.poc_name,
        pocPhoneNumber: data.poc_number,
        pocEmail: data.poc_email
      });
    }
  }, [data]);

  const addressInputFormSchema = Yup.object().shape({
    locationName: Yup.mixed()
      .nullable()
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          return (value?.length || 0) <= 50;
        }
      ),
    country: Yup.string()
      .nullable()
      .required('Please enter Country'),
    city: Yup.string()
      .nullable()
      .required('Please enter City'),
    state: Yup.string()
      .nullable()
      .required('Please enter State'),
    postalCode: Yup.mixed()
      .nullable()
      .test('postalCode', 'Please enter Postal Code', function(value) {
        const { parent } = this;
        if (!value && (!parent.country || parent.country !== 'Hong Kong'))
          return value;
        return true;
      })
      .test('postalCode', 'Please enter valid characters', function(value) {
        if (value) return /^[0-9A-Z\- ]+$/.test(value);
        return true;
      }),
    address: Yup.string()
      .nullable()
      .required('Please enter Address')
      .test(
        'max',
        'You have exceeded the maximum 250 characters length limit',
        (value) => {
          return (value?.length || 0) <= 250;
        }
      ),
    pocName: Yup.mixed()
      .nullable()
      .required("Please enter PoC's Name")
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          return (value?.length || 0) <= 50;
        }
      ),
    pocPhoneNumber: Yup.mixed()
      .nullable()
      .required("Please enter PoC's Number")
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          return (value?.length || 0) <= 50;
        }
      )
      .test('phoneNumber', 'Please enter valid characters', (value) => {
        return /^\+?[0-9\- ]+$/.test(value);
      }),
    pocEmail: Yup.mixed()
      .nullable()
      .test('email', 'Please enter a valid email address', (value) => {
        if (value) {
          return /^[^@]+@[^.]+\..+$/.test(value);
        }
        return true;
      })
  });

  const showModal = () => {
    setValidateState(false);
    setValidateDirtyCountry(true);
    setVisible(true);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    setValidateDirtyCountry(false);
    setValidateState(false);
    resetForm({});
    setVisible(false);
  };

  const handleCancel = (resetForm) => () => {
    setValidateDirtyCountry(false);
    setValidateState(false);
    resetForm({});
    setVisible(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addressInputFormSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: formSubmit, resetForm, values, setFieldValue }) => (
        <StyledAddressInput>
          {children && children({ showModal, setVisible })}
          <Modal
            title={title || 'Add Address'}
            visible={visible}
            onCancel={handleCancel(resetForm)}
            closable={false}
            footer={null}
            width={720}
            maskClosable={!loading}
          >
            <FormEffect
              data={data}
              values={values}
              setFieldValue={setFieldValue}
            >
              <FormContainer>
                <FormColumn>
                  <h4>Location Information</h4>
                  <FormRow>
                    <FormItem style={{ width: '100%' }}>
                      <p>Location name (Optional)</p>
                      <FormInput
                        placeholder="e.g. My Warehouse"
                        name="locationName"
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      style={{ maxWidth: '205px', paddingRight: '5px' }}
                    >
                      <p>Country/Region</p>
                      <FormSearchInput
                        name="country"
                        items={COUNTRY_LIST}
                        placeholder="Select"
                        setRef={countryField}
                        validateDirty={validateDirtyCountry}
                      />
                    </FormItem>
                    <FormItem style={{ maxWidth: '205px', paddingLeft: '5px' }}>
                      <p>State/Province/Region</p>
                      <FormLocationSearchInput
                        name="state"
                        country={values.country}
                        types={['(regions)']}
                        placeholder="Type here"
                        onClick={() => {
                          setValidateDirtyCountry(false);
                          if (!values.country) {
                            countryField.current.focus();
                            countryField.current.blur();
                            setValidateState(true);
                          } else {
                            setValidateState(true);
                          }
                        }}
                        validateState={validateState}
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      style={{ maxWidth: '205px', paddingRight: '5px' }}
                    >
                      <p>City</p>
                      <FormLocationSearchInput
                        name="city"
                        country={values.country}
                        types={['(cities)']}
                        placeholder="Type here"
                      />
                    </FormItem>
                    <FormItem style={{ maxWidth: '205px', paddingLeft: '5px' }}>
                      <p>
                        Postal Code{' '}
                        {values.country && values.country === 'Hong Kong' && (
                          <span>(Optional)</span>
                        )}
                      </p>
                      <FormInput placeholder="Type here" name="postalCode" />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem style={{ width: '100%' }}>
                      <p>Address</p>
                      <FormLocationSearchInput
                        name="address"
                        country={values.country}
                        types={['address']}
                        placeholder="Type here"
                        freeText
                      />
                    </FormItem>
                  </FormRow>
                </FormColumn>
                <FormColumn
                  style={{
                    marginLeft: '25px',
                    paddingLeft: '25px',
                    borderLeft: '1px solid #ccc'
                  }}
                >
                  <h4>Point of contact (PoC)</h4>
                  <FormRow>
                    <FormItem>
                      <p>PoC&#8217;s Name</p>
                      <FormInput placeholder="Type here" name="pocName" />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem>
                      <p>PoC&#8217;s Phone Number</p>
                      <FormInput
                        placeholder="Type here"
                        name="pocPhoneNumber"
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem>
                      <p>PoC&#8217;s Email (Optional)</p>
                      <FormInput placeholder="Type here" name="pocEmail" />
                    </FormItem>
                  </FormRow>
                </FormColumn>
              </FormContainer>
            </FormEffect>
            <div className="ant-modal-footer">
              <Button
                key="back"
                type="secondary"
                onClick={handleCancel(resetForm)}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                loading={loading}
                onClick={() => {
                  setValidateDirtyCountry(false);
                  formSubmit();
                }}
              >
                {submitText || 'Add'}
              </Button>
            </div>
          </Modal>
        </StyledAddressInput>
      )}
    </Formik>
  );
};

export default AddressInput;
