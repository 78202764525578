export const QUOTE_REQUESTED = 'QUOTE_REQUESTED';
export const QUOTE_REREQUESTED_DRAFT = 'QUOTE_REREQUESTED_DRAFT';
export const QUOTE_DRAFT_CREATED = 'QUOTE_DRAFT_CREATED';
export const QUOTE_CREATED = 'QUOTE_CREATED';
export const QUOTE_ACCEPTED = 'QUOTE_ACCEPTED';
export const QUOTE_REQUEST_UNSUCCESSFUL = 'QUOTE_REQUEST_UNSUCCESSFUL';
export const DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED';

export const SHIPMENT_CONFIRMED = 'SHIPMENT_CONFIRMED';
export const SCHEDULED_FOR_PICKUP = 'SCHEDULED_FOR_PICKUP';
export const SCHEDULED_FOR_DROPOFF = 'SCHEDULED_FOR_DROPOFF';
export const RESCHEDULED_FOR_PICKUP = 'RESCHEDULED_FOR_PICKUP';
export const RESCHEDULED_FOR_DROPOFF = 'RESCHEDULED_FOR_DROPOFF';
export const SHIPMENT_PICKEDUP = 'SHIPMENT_PICKEDUP';
export const REACHED_ORIGIN_WAREHOUSE = 'REACHED_ORIGIN_WAREHOUSE';
export const CARGO_DELIVERED_TO_AIRLINES_TERMINAL =
  'CARGO_DELIVERED_TO_AIRLINES_TERMINAL';
export const RECEIVED_BY_AIRLINES = 'RECEIVED_BY_AIRLINES';
export const IN_TRANSHIPMENT_COUNTRY = 'IN_TRANSHIPMENT_COUNTRY';
export const PENDING_CUSTOM_CLEARANCE = 'PENDING_CUSTOM_CLEARANCE';
export const CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY =
  'CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY';
export const OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
export const DELIVERED = 'DELIVERED';
export const RESCHEDULED_FOR_DELIVERY = 'RESCHEDULED_FOR_DELIVERY';
export const DELIVERY_FAILED = 'DELIVERY_FAILED';
export const DELIVERED_WITHOUT_POD = 'DELIVERED_WITHOUT_POD';
export const CANCELLED = 'CANCELLED';
export const QUOTE_EXPIRED = 'QUOTE_EXPIRED';
export const BILLED = 'BILLED';
export const CONTAINER_LOADING = 'CONTAINER_LOADING';
export const CARGO_ONBOARD_VESSEL = 'CARGO_ONBOARD_VESSEL';
export const ON_SAIL = 'ON_SAIL';
export const PARTIAL_UPLIFT_TO_DESTINATION = 'PARTIAL_UPLIFT_TO_DESTINATION';
export const FREIGHT_UPLIFT_COMPLETED = 'FREIGHT_UPLIFT_COMPLETED';
export const OFFLOADED_BY_AIRLINES = 'OFFLOADED_BY_AIRLINES';
export const FLIGHT_RESCHEDULED = 'FLIGHT_RESCHEDULED';
export const QUOTE_REQUEST = 'QUOTE_REQUEST';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const WIN = 'WIN';
export const LOSS = 'LOSS';
export const PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED';
export const VESSEL_DELAYED = 'VESSEL_DELAYED';
export const SPACE_BOOKING_IN_PROGRESS = 'SPACE_BOOKING_IN_PROGRESS';

export const QUOTE_STATUS_MAPPING = {
  QUOTE_REQUEST: 'Quote Requested',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  WIN: 'Win',
  LOSS: 'Loss'
};

export const isShipmentStatus = (status) => {
  return [
    SHIPMENT_CONFIRMED,
    SCHEDULED_FOR_PICKUP,
    SCHEDULED_FOR_DROPOFF,
    RESCHEDULED_FOR_PICKUP,
    RESCHEDULED_FOR_DROPOFF,
    REACHED_ORIGIN_WAREHOUSE,
    SHIPMENT_PICKEDUP,
    CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
    FLIGHT_RESCHEDULED,
    CONTAINER_LOADING,
    RECEIVED_BY_AIRLINES,
    CARGO_ONBOARD_VESSEL,
    ON_SAIL,
    PARTIAL_UPLIFT_TO_DESTINATION,
    FREIGHT_UPLIFT_COMPLETED,
    OFFLOADED_BY_AIRLINES,
    IN_TRANSHIPMENT_COUNTRY,
    PENDING_CUSTOM_CLEARANCE,
    CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
    OUT_FOR_DELIVERY,
    DELIVERED,
    RESCHEDULED_FOR_DELIVERY,
    DELIVERY_FAILED,
    DELIVERED_WITHOUT_POD,
    BILLED,
    CANCELLED,
    PARTIALLY_DELIVERED,
    VESSEL_DELAYED,
    SPACE_BOOKING_IN_PROGRESS
  ].includes(status);
};

export const isQuoteFinal = (status) => {
  return [
    QUOTE_ACCEPTED,
    QUOTE_REQUEST_UNSUCCESSFUL,
    QUOTE_EXPIRED,
    DOCUMENT_UPLOADED,
    SHIPMENT_CONFIRMED,
    SCHEDULED_FOR_PICKUP,
    SCHEDULED_FOR_DROPOFF,
    RESCHEDULED_FOR_PICKUP,
    RESCHEDULED_FOR_DROPOFF,
    REACHED_ORIGIN_WAREHOUSE,
    SHIPMENT_PICKEDUP,
    CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
    FLIGHT_RESCHEDULED,
    CONTAINER_LOADING,
    RECEIVED_BY_AIRLINES,
    CARGO_ONBOARD_VESSEL,
    ON_SAIL,
    PARTIAL_UPLIFT_TO_DESTINATION,
    FREIGHT_UPLIFT_COMPLETED,
    OFFLOADED_BY_AIRLINES,
    IN_TRANSHIPMENT_COUNTRY,
    PENDING_CUSTOM_CLEARANCE,
    CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
    OUT_FOR_DELIVERY,
    DELIVERED,
    RESCHEDULED_FOR_DELIVERY,
    DELIVERY_FAILED,
    DELIVERED_WITHOUT_POD,
    BILLED,
    CANCELLED,
    PARTIALLY_DELIVERED,
    VESSEL_DELAYED,
    SPACE_BOOKING_IN_PROGRESS
  ].includes(status);
};
