import React from 'react';
import styled from '@emotion/styled';
import Message from '../Message';
import Modal from '../Modal';
import Button from '../Button';
import TextArea from '../TextArea';

const TitleReject = styled.span`
  color: #d5152f;
`;
const StyledModalContent = styled.div`
  padding: 0 0 15px 15px;
`;
const CancellationModal = ({
  isCancelModalVisible,
  setIsCancelModalVisible,
  setCancelReasonError,
  handleCancelShipment,
  handleSetCancelReason,
  cancelReason,
  cancelReasonError
}) => {
  const updateCancelReason = (event) => {
    if (event.target.value.length > 250) {
      setCancelReasonError(
        'You have exceeded the maximum 250 characters length limit'
      );
    } else {
      setCancelReasonError('');
    }
    handleSetCancelReason(event);
  };
  return (
    <Modal
      title={<TitleReject>Cancel Shipment</TitleReject>}
      visible={isCancelModalVisible}
      closable={false}
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => {
            setIsCancelModalVisible(false);
            setCancelReasonError('');
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={cancelReasonError !== '' || cancelReason.length === 0}
          onClick={handleCancelShipment}
        >
          Confirm
        </Button>
      ]}
    >
      <StyledModalContent>
        <p>Reason for cancellation</p>
        <TextArea placeholder="Type Here" onChange={updateCancelReason} />
        <Message type="error">{cancelReasonError}</Message>
      </StyledModalContent>
    </Modal>
  );
};

export default CancellationModal;
