// TODO: should add these feenames to database, ops keep requesting new ones
const feeName = [
  'All in Charges',
  'Admin Charge',
  'First mile',
  'Last mile',
  'Mid mile',
  'Custom Clearance',
  'Terminal Handling Charge',
  'Port Charges',
  'DO Exchange',
  'Storage',
  'License',
  'License Application',
  'Delivery Order Fees',
  'Agency Fees',
  'Equipment Transfer Charges',
  'Container Monitoring System (CMS)',
  'Haulage - Normal Chassis',
  'Haulage - Tri-Axle',
  'Haulage Fuel Surcharge',
  'PSA Lolo',
  'Depot Handling Charge ',
  'Portnet',
  'Customs Declaration Fee',
  'Handling Charges',
  'Container Demurrage/Detention',
  'Chassis Demurrage',
  'Stuffing/Unstuffing Charges - Loose Carton',
  'Stuffing/Unstuffing Charges - Palletize',
  'Overtime Charges',
  'Labour/Manpower Charges',
  'BL Fee',
  'BL Surrender Fee',
  'Handling - In',
  'Handling - Out',
  'PSA Wrong Weight',
  'Container Washing',
  'Container Shifting Fee',
  'Standby Charges',
  'Clearance & Delivery',
  'Delivery',
  'Pick-up',
  'Terminal clearance',
  'IOR',
  'GST Outlay (Tax)',
  'Duty Tax',
  'Forklift Charges',
  'AWB Fee',
  'Permit Fee',
  'Permit Fee (Additional HS-CODE)',
  'Fuel Surcharge (FSC)',
  'Security Surcharge (SSC)',
  'Others (For miscellaneous cost or billings)'
];

export default feeName;
