import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import { adminService } from '../../services';

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;
`;
const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const CreateMerchant = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const createNewMerchant = async () => {
    try {
      setLoading(true);
      await adminService.createNewMerchant({
        name
      });
      message.success('Merchant created successfully');
      setName('');
    } catch (err) {
      const errMessage =
        err?.response?.data?.data?.message ||
        'Error Occured while creating merchant';
      message.error(errMessage);
    }
    setLoading(false);
  };

  return (
    <StyledContainer>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col span={12}>
          <p>Merchant Name</p>
          <Input
            placeholder="Type Merchant Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Col>
      </StyledRow>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col>
          <Button
            onClick={createNewMerchant}
            disabled={!name}
            loading={loading}
          >
            Submit
          </Button>
        </Col>
      </StyledRow>
    </StyledContainer>
  );
};

export default CreateMerchant;
