import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';

const StyledBillingInfoMessage = styled.div`
  background-color: #1fceca33;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-family: AvenirLTStdBook;
  color: #000;
  margin-bottom: 15px;
  svg {
    margin-right: 10px;
    color: #000;
  }
  a {
    color: #050593;
  }
`;

const BillingInfoMessage = ({ children }) => {
  return (
    <StyledBillingInfoMessage>
      <Icon type="info-circle" theme="outlined" />
      {children}
    </StyledBillingInfoMessage>
  );
};

const InfoMessage = (props) => {
  const { quote_status, blank, readyToBill, isCostLocked, onEdit } = props.data;
  if (quote_status === 'BILLED') {
    return null;
  }
  if (blank) {
    return (
      <BillingInfoMessage>
        Billing information is currently blank.&nbsp;
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          Edit
        </a>
        &nbsp;and then submit for billing.
      </BillingInfoMessage>
    );
  }
  if (!readyToBill && !isCostLocked) {
    return (
      <BillingInfoMessage>
        Cost & billing info is currently saved as draft. &nbsp;
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          Edit, lock cost or ready to bill.
        </a>
      </BillingInfoMessage>
    );
  }
  if (readyToBill && isCostLocked) {
    return (
      <BillingInfoMessage>
        Cost information is locked and billing information is ready to bill.
      </BillingInfoMessage>
    );
  }
  if (readyToBill) {
    return (
      <BillingInfoMessage>
        Billing information is ready to bill.&nbsp;
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          Edit or lock cost.
        </a>
      </BillingInfoMessage>
    );
  }
  if (isCostLocked) {
    return (
      <BillingInfoMessage>
        Cost information is locked.&nbsp;
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            onEdit();
          }}
        >
          Edit or ready to bill.
        </a>
      </BillingInfoMessage>
    );
  }
  return null;
};

export default InfoMessage;
