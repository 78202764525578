import { createAction } from '@reduxjs/toolkit';
import { quoteService } from '../services/quotes';
import {
  QUOTE_ACCEPTED,
  // DOCUMENT_UPLOADED,
  SHIPMENT_CONFIRMED
} from '../constants/status';

export const fetchQuoteDetail = createAction('quote/FETCH_DETAIL');
export const fetchRequestQuotesDetail = createAction(
  'quote/FETCH_DETAIL_QUOTE_REQUEST'
);
export const replaceDocument = createAction('quote/REPLACE_DOCUMENT');
export const updateQuoteAddressView = createAction('quote/UPDATE_ADDRESS_VIEW');
export const fetchQuoteShipmentLog = createAction('quote/FETCH_SHIPMENT_LOG');
export const fetchQuoteCharges = createAction('quote/FETCH_CHARGES');
export const changeQuoteStatus = createAction('quote/CHANGE_STATUS');
export const updateDocument = createAction('quote/UPDATE_DOCUMENT');
export const unloadQuoteDetails = createAction('quote/UNLOAD_DATA');
export const unloadRequestQuoteDetails = createAction(
  'quote/UNLOAD_DATA_REQUEST_QUOTE'
);
export const changeDocumentStatus = createAction('quote/DOC_STATUS_CHANGE');
export const removeDocumentFromList = createAction('quote/REMOVE_DOCUMENT');
export const updateDocumentVisibility = createAction(
  'quote/UPDATE_DOCUMENT_VISIBILITY'
);
export const fetchQuoteLookups = createAction('quote/FETCH_LOOKUPS');
export const addLineItems = createAction('quote/ADD_LINE_ITEMS');
export const removeLineItem = createAction('quote/REMOVE_LINE_ITEM');
export const updateLineItem = createAction('quote/UPDATE_LINE_ITEM');
export const saveLineItems = createAction('quote/SAVE_LINE_ITEM');

export const getQuoteShipmentLog = (id) => async (dispatch) => {
  const res = await quoteService.getQuoteShipmentLog(id);
  dispatch(fetchQuoteShipmentLog(res));
};

export const getQuoteDetail = (id) => async (dispatch) => {
  const res = await quoteService.getQuoteDetail(id);
  dispatch(fetchQuoteDetail(res));
};
export const getRequestQuotesDetail = (id) => async (dispatch) => {
  const res = await quoteService.getRequestQuotesDetail(id);
  dispatch(fetchRequestQuotesDetail(res));
};

export const getQuoteCharges = (id) => async (dispatch) => {
  const res = await quoteService.getQuoteCharges(id);
  dispatch(fetchQuoteCharges(res));
};

export const getQuoteLookups = () => async (dispatch) => {
  const res = await quoteService.getQuoteLookups().exec();
  dispatch(fetchQuoteLookups(res?.data?.data));
};

export const acceptDocument = (id) => async (dispatch) => {
  const res = await quoteService.acceptDocument(id);
  dispatch(changeQuoteStatus({ status: QUOTE_ACCEPTED }));
  dispatch(getQuoteShipmentLog(id));
  return res;
};

export const updateQuoteStatus = ({
  id,
  status,
  reason = '',
  updateDate = null,
  userTimeZoneOffset = 0,
  otherInformation = {}
}) => async (dispatch) => {
  const res = await quoteService
    .updateQuoteStatus({
      id,
      status,
      reason,
      updateDate,
      userTimeZoneOffset,
      otherInformation
    })
    .exec();
  dispatch(changeQuoteStatus({ status, updateDate }));
  dispatch(getQuoteShipmentLog(id));
  return res;
};
export const changeStatus = ({ action, notes, id }) => async (dispatch) => {
  const log = '';
  const res = await quoteService.changeQuoteStatus(id, { action, notes, log });
  dispatch(changeQuoteStatus({ status: SHIPMENT_CONFIRMED }));
  return res;
};

export const confirmShipmentStatus = ({
  action,
  notes,
  id,
  pickupDate,
  deliveryDate,
  operator
}) => async (dispatch) => {
  const log = '';
  const res = await quoteService.changeQuoteConfirmStatus(id, {
    action,
    notes,
    log,
    pickupDate,
    deliveryDate,
    operator
  });
  dispatch(changeQuoteStatus({ status: SHIPMENT_CONFIRMED }));
  return res;
};
export const uploadDocument = ({
  data,
  documentStatus,
  id,
  importAwb = null
}) => async (dispatch) => {
  const res = await quoteService.documentUpload({
    data,
    documentStatus,
    id,
    importAwb
  });
  if (id.startsWith('Q')) {
    dispatch(replaceDocument(res?.data?.data));
  } else {
    dispatch(updateDocument(res.data.data));
  }
  dispatch(getQuoteShipmentLog(id));
  return res;
};

export const approveDocument = (id, quoteId) => async (dispatch) => {
  const res = await quoteService.approveDocument(id);
  dispatch(changeDocumentStatus(id));
  dispatch(getQuoteShipmentLog(quoteId));
  return res;
};

export const removeDocument = (id) => async (dispatch) => {
  const res = await quoteService.removeDocument(id);
  dispatch(removeDocumentFromList(id));
  return res;
};

export const updateQuoteWinLossStatus = ({ id, data }) => async () => {
  const res = await quoteService.changeQuoteRequestStatus(id, data);
  return res;
};
