import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { setColumns } from '../../actions/costBillingDetail';

const FilterContainer = styled.div`
  user-select: none;
  width: 560px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
`;

const ColumnDropdown = ({ label }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  const cost = useSelector((state) => state.costBillingDetails.columns.cost);
  const billing = useSelector(
    (state) => state.costBillingDetails.columns.billing
  );
  const financeTeam = useSelector(
    (state) => state.costBillingDetails.columns.financeTeam
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (!node.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
    };
  }, [dispatch]);

  return (
    <div ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Hide/Show Columns'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row>
            <Col span={8}>
              <header>Cost</header>
              <Checkbox.Group
                options={[
                  { label: 'N/P name', value: 'network_partner' },
                  { label: 'Cost description', value: 'cost_description' },
                  { label: 'MAWB/HAWB', value: 'cost_awb' },
                  { label: 'N/P invoice no.', value: 'np_invoice_number' },
                  { label: 'Cost fee type', value: 'cost_fee_type' },
                  { label: 'No. of units', value: 'cost_units' },
                  { label: 'Cost currency', value: 'cost_currency' },
                  { label: 'Cost unit price', value: 'cost_unit_price' }
                ]}
                defaultValue={cost}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'cost', columns: values }));
                }}
              />
            </Col>

            <Col span={9}>
              <header>Billing</header>
              <Checkbox.Group
                options={[
                  { label: 'Janio billing entity', value: 'billing_country' },
                  { label: 'Bill to', value: 'xero_client_id' },
                  { label: 'Bill comment', value: 'comment' },
                  { label: 'Billing description', value: 'description' },
                  { label: 'MAWB/HAWB', value: 'billing_awb' },
                  { label: 'No. of units', value: 'weight' },
                  { label: 'Bill currency', value: 'billing_currency' },
                  { label: 'Bill unit price', value: 'billing_unit_price' }
                ]}
                defaultValue={billing}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'billing', columns: values }));
                }}
              />
            </Col>

            <Col span={7}>
              <header>Finance Team</header>
              <Checkbox.Group
                options={[
                  { label: 'Invoice no.', value: 'invoice_number' },
                  { label: 'Invoice link', value: 'invoice_link' },
                  { label: 'Credit note no.', value: 'credit_note_no' },
                  { label: 'Credit note link', value: 'credit_note_link' }
                ]}
                defaultValue={financeTeam}
                onChange={(values) => {
                  dispatch(
                    setColumns({ type: 'financeTeam', columns: values })
                  );
                }}
              />
            </Col>
          </Row>
        </FilterContainer>
      </Dropdown>
    </div>
  );
};

export default ColumnDropdown;
