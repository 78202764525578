import React from 'react';
import styled from '@emotion/styled';
import { logout } from '../auth0';
import HeaderDropdown from './HeaderDropdown';

const StyledHeaderDropdown = styled(HeaderDropdown)`
  .link {
    cursor: pointer;
  }
`;

const ProfileDropdown = ({ user }) => {
  return (
    <StyledHeaderDropdown>
      <li className="name bold">{user?.name}</li>
      <li className="email spacer-25">{user?.email}</li>
      {/* <li><Link to="/">Account</Link></li>
      <li><Link to="/">Manage Address</Link></li> */}
      <li className="link" aria-hidden onClick={(e) => logout(e)}>
        <span>Logout</span>
      </li>
    </StyledHeaderDropdown>
  );
};

export default ProfileDropdown;
