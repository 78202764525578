import React from 'react';
import { Radio as AntRadio } from 'antd';
import styled from '@emotion/styled';

const StyledRadio = styled(AntRadio)`
  .ant-radio-wrapper {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    min-width: 105px;
  }
  &:checked: {
    background-color: red;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: #fff;
    border-color: #050593;
  }
  .ant-radio-inner::after {
    background-color: #050593;
  }
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #050593;
    background-color: #fff;
  }

  .ant-radio-disabled :not(.ant-radio-checked) .ant-radio-inner {
    background-color: #fff;
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: #8282c9;
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
    border-color: #fff;
  }
`;
const StyledRadioGroup = styled(AntRadio.Group)`
  .ant-radio-wrapper {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    min-width: 105px;
  }

  .ant-radio-checked .ant-radio-inner {
    background: #fff;
    border-color: #050593;
  }

  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #050593;
  }

  .ant-radio-disabled :not(.ant-radio-checked) .ant-radio-inner {
    background-color: #fff;
  }
  .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: #fff;
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
    border-color: #fff;
  }
`;

const Radio = (props) => {
  const { children, ...rest } = props;
  return <StyledRadio {...rest}>{children}</StyledRadio>;
};

const RadioGroup = ({ children, ...rest }) => {
  return <StyledRadioGroup {...rest}>{children}</StyledRadioGroup>;
};

Radio.Group = RadioGroup;

export default Radio;
