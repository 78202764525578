import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { set } from 'lodash';
import DimensionsInput from '../DimensionsInput';
import SearchInput from '../SearchInput';
import NumberInput from '../NumberInput';
import WeightInput from '../WeightInput';
import Message from '../Message';
import FloatInput from '../FloatInput';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #cccccc;
  .ant-input-number {
    width: 100%;
  }
  .delete-dimension {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    transition: none;
  }
  .delete-dimension:hover,
  .delete-dimension:focus {
    border-color: #050593;
    color: #050593;
  }
  .ant-row {
    margin-bottom: 10px;
    .ant-col {
      margin-bottom: 10px;
    }
  }
  &:first-of-type {
    border-top: solid 1px #cccccc;
    margin-top: 20px;
  }
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const VolumeWeightInput = ({
  id,
  value,
  label,
  showDelete = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {},
  packageTypes = [],
  loading = false,
  isQuote = false
}) => {
  const [packageState, setPackageState] = useState(value);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required('Please enter Package Type'),
    amount: Yup.string()
      .nullable()
      .required('Please enter unit'),
    chargeableWeight: Yup.string()
      .nullable()
      .required('Please enter Chargeable Weight'),
    weight: Yup.object().shape({
      amount: Yup.string()
        .nullable()
        .required(
          packageState?.weightType
            ? 'Please enter Weight for one unit'
            : 'Please enter Total Weight'
        )
    })
  });

  const validate = () => {
    validationSchema
      .validate(packageState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          set(ers, er.path, er.message);
          return ers;
        }, {});

        if (Object.keys(errs).length > 0) {
          setErrors(errs);
        } else {
          setErrors(null);
        }
      });
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }
    onChange(packageState, errors);
  }, [packageState, errors]);

  const buildDimensionsError = (error) => {
    const fields = Object.keys(error);
    return error[fields[0]];
  };

  const handleChange = (name) => (e) => {
    dirty.current = true;
    switch (name) {
      case 'type':
        setPackageState({ ...packageState, type: e });
        break;
      case 'amount':
        setPackageState({ ...packageState, amount: e });
        break;
      case 'chargeableWeight':
        setPackageState({ ...packageState, chargeableWeight: e.target.value });
        break;
      case 'weight':
        setPackageState({
          ...packageState,
          weight: {
            isKg: e.unit.toLowerCase() === 'kg',
            amount: e.weight
          },
          weightType: e.type
        });
        break;
      case 'dimensions':
        setPackageState({
          ...packageState,
          dimensions: {
            length: e.length,
            width: e.width,
            height: e.height,
            amount: e.volume,
            isCm: e.unit.toLowerCase() === 'cm'
          },
          volumeType: e.type
        });
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur(packageState, errors);
  };

  return (
    <Container id={id}>
      <h5>
        {label}
        {showDelete && (
          <Button
            className="delete-dimension"
            icon="delete"
            onClick={onDelete}
          />
        )}
      </h5>
      <Row gutter={20}>
        <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
          <p>Type</p>
          <SearchInput
            placeholder="Select"
            items={packageTypes}
            value={packageState?.type}
            loading={loading}
            onChange={handleChange('type')}
            onBlur={handleBlur}
          />
          {errors?.type && dirty.current && (
            <Message type="error">{errors?.type}</Message>
          )}
        </Col>
        <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
          <p>No. of Units</p>
          <NumberInput
            placeholder="How much"
            value={packageState?.amount}
            onChange={handleChange('amount')}
            onBlur={handleBlur}
          />
          {errors?.amount && dirty.current && (
            <Message type="error">{errors?.amount}</Message>
          )}
        </Col>
        <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
          <p>Chargeable Weight (KG)</p>
          <FloatInput
            placeholder="How much..."
            value={packageState?.chargeableWeight}
            onChange={handleChange('chargeableWeight')}
            onBlur={handleBlur}
          />
          {errors?.chargeableWeight && dirty.current && (
            <Message type="error">{errors?.chargeableWeight}</Message>
          )}
        </Col>
        <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
          <p>Weight</p>
          <WeightInput
            placeholder="How much..."
            value={packageState?.weight?.amount}
            type={packageState?.weightType}
            unit={packageState?.weight?.isKg ? 'KG' : 'LB'}
            onChange={handleChange('weight')}
            onBlur={handleBlur}
          />
          {errors?.weight?.amount && dirty.current && (
            <Message type="error">{errors?.weight?.amount}</Message>
          )}
        </Col>
        <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
          <p>
            {packageState?.volumeType
              ? 'Volume (L x W x H) (Optional)'
              : 'Volume (CBM) (Optional)'}
          </p>
          <DimensionsInput
            value={{
              length: packageState?.dimensions?.length,
              width: packageState?.dimensions?.width,
              height: packageState?.dimensions?.height,
              volume: packageState?.dimensions?.amount
            }}
            type={packageState?.volumeType}
            unit={packageState?.dimensions?.isCm ? 'CM' : 'IN'}
            onChange={handleChange('dimensions')}
            onBlur={handleBlur}
          />
          {errors?.dimensions && dirty.current && (
            <Message type="error">
              {buildDimensionsError(errors?.dimensions)}
            </Message>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default VolumeWeightInput;
