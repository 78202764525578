import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import Input from '../Input';

const FullLoadSeal = ({
  value,
  type = '',
  order,
  onChange = () => {},
  onBlur = () => {},
  loadType,
  isQuote = false
}) => {
  const [sealState, setSealState] = useState(value);

  useEffect(() => {
    onChange(sealState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sealState]);

  const handleChange = (name) => (e) => {
    const number = e.target.value;
    switch (name) {
      case 'container':
        setSealState({ ...sealState, containerNo: number });
        break;
      case 'seal':
        setSealState({ ...sealState, sealNo: number });
        break;
      default:
    }
  };

  const handleBlur = () => {
    onBlur(sealState);
  };

  return (
    <>
      <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
        <p>
          {loadType === 'Full' ? `#${order} ` : null}
          {`${type[0].toUpperCase()}${type.slice(1)} No. (Optional)`}
        </p>
        <Input
          value={sealState?.containerNo}
          onChange={handleChange('container')}
          onBlur={handleBlur}
        />
      </Col>
      <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
        <p>{loadType === 'Full' ? `#${order}` : null} Seal No. (Optional)</p>
        <Input
          value={sealState?.sealNo}
          onChange={handleChange('seal')}
          onBlur={handleBlur}
        />
      </Col>
    </>
  );
};

const Seals = styled.div`
  .ant-row {
    margin-top: 15px;
    .ant-col {
      margin-bottom: 15px;
    }
  }
`;

const FullLoadSeals = ({
  type,
  seals,
  amount,
  onChange = () => {},
  onBlur = () => {},
  loadType = 'Full',
  isQuote = false
}) => {
  const buildSeals = (size = 0) => {
    const bSeals = [];
    for (let i = 0; i < size; i += 1) {
      bSeals.push({
        id: randomstring.generate(),
        order: i,
        containerNo: null,
        sealNo: null
      });
    }
    return bSeals;
  };

  const [loadSeals, setLoadSeals] = useState(seals || buildSeals(amount));

  useEffect(() => {
    if (seals?.length !== amount) {
      setLoadSeals(buildSeals(amount));
    }
  }, [amount, seals]);

  const handleChange = (id) => (e) => {
    setLoadSeals(
      loadSeals.map((loadSeal) => {
        if (loadSeal.id === id) {
          return {
            ...loadSeal,
            ...e
          };
        }
        return loadSeal;
      })
    );
  };

  useEffect(() => {
    if (parseInt(amount, 10) > 0) {
      onChange(loadSeals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSeals]);

  const handleBlur = () => {
    onBlur(loadSeals);
  };

  return (
    <Seals>
      <Row gutter={20}>
        {loadSeals?.map((seal) => (
          <FullLoadSeal
            key={seal.id}
            order={seal.order + 1}
            type={type}
            value={seal}
            onChange={handleChange(seal.id)}
            onBlur={handleBlur}
            loadType={loadType}
            isQuote={isQuote}
          />
        ))}
      </Row>
    </Seals>
  );
};

export default FullLoadSeals;
