import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledActionLink = styled(Link)`
  color: #050593;
  &:hover {
    color: #050593;
  }
`;

const ActionLink = ({ status, children, ...props }) => {
  return <StyledActionLink {...props}>{children}</StyledActionLink>;
};

export default ActionLink;
