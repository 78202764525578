import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Row, message } from 'antd';
import moment from 'moment';
import Table from '../Table';
import ShipmentQuoteActions from './ShipmentQuoteActions';
import { getQuoteCharges, updateQuoteStatus } from '../../actions/quoteDetail';
import { TEXT_QUOTE_STATUS_ERROR } from '../../constants/message';
import {
  QUOTE_CREATED,
  QUOTE_REQUEST_UNSUCCESSFUL,
  QUOTE_REREQUESTED_DRAFT,
  QUOTE_DRAFT_CREATED
} from '../../constants/status';

const StyledShipmentQuote = styled.div`
  font-family: AvenirLTStdBook;
`;
const StyledTableContainer = styled.div`
  border: 1px dashed #050593;
  padding: 10px;
  margin-bottom: 15px;
`;
const QuoteValidity = styled.span`
  font-size: 16px;
  font-family: AvenirLTStdBookOblique;
  padding-left: 12px;
  color: #050593;
  width: 370px;
`;
const QuoteTotal = styled.span`
  font-size: 19px;
  color: #000000;
  font-family: AvenirLTStdHeavy;
  padding-right: 12px;
`;

const columns = [
  {
    title: 'Fee name',
    dataIndex: 'fee_name',
    key: 'fee_name'
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'No. of units',
    dataIndex: 'weight',
    key: 'weight'
  },
  {
    title: 'Unit Price (USD)',
    dataIndex: 'unit_price',
    key: 'unit_price'
  },
  {
    title: 'Total (USD)',
    dataIndex: 'total_amount',
    key: 'total_amount'
  }
];

const ButtonsSchema = {
  SEND_QUOTE_BUTTON: [QUOTE_DRAFT_CREATED, QUOTE_REREQUESTED_DRAFT],
  EDIT_QUOTE_BUTTON: [
    QUOTE_DRAFT_CREATED,
    QUOTE_CREATED,
    QUOTE_REREQUESTED_DRAFT
  ],
  REJECT_QUOTE_BUTTON: [
    QUOTE_DRAFT_CREATED,
    QUOTE_CREATED,
    QUOTE_REREQUESTED_DRAFT
  ]
};

// TODO: implement loading state and fetch data
const ShipmentQuoteDetailsView = ({ quoteId, onEdit }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuoteCharges(quoteId));
  }, [dispatch, quoteId]);
  const invoiceAPI = useSelector(
    (state) => state?.quoteDetail?.charges?.line_items || []
  );
  const quoteStatus = useSelector(
    (state) => state?.quoteDetail?.quote?.quote_status
  );
  const dataSource = invoiceAPI.map((item, index) => ({
    key: index,
    fee_name: item.fee_name,
    comment: item.comment,
    weight: item.weight,
    unit_price: `${item.unit_price}`,
    total_amount: `${item.total_amount}`
  }));
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const dataShippingLog = useSelector((state) => ({
    expiringAt: state?.quoteDetail?.charges?.expiring_at,
    totalInvoiceAmount: state?.quoteDetail?.charges?.total_invoice_amount
  }));
  const handleQuoteStatusChange = async (status, reason) => {
    try {
      await dispatch(updateQuoteStatus({ id: quoteId, status, reason }));
    } catch (error) {
      const result = error?.response?.data?.result;
      message.error(result || TEXT_QUOTE_STATUS_ERROR);
      return null;
    }
    return null;
  };
  return (
    <StyledShipmentQuote id="shipment-quote">
      <h4>
        {`${detailAPI.origin && detailAPI.origin.country} to ${
          detailAPI.destination.country
        }`}
      </h4>
      <StyledTableContainer>
        <Table
          totalCount={dataSource.length}
          type="tertiary"
          showScroll={false}
          showPagination={false}
          dataSource={dataSource}
          columns={columns}
        />
        <Row type="flex" justify="space-between">
          <QuoteValidity>
            This quote is valid until{' '}
            {moment(dataShippingLog && dataShippingLog.expiringAt).format(
              'DD MMM YYYY'
            )}{' '}
            and does not include taxes and duties
          </QuoteValidity>
          <QuoteTotal>
            Total USD{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            })
              .format(dataShippingLog && dataShippingLog.totalInvoiceAmount)
              .slice(1)}
          </QuoteTotal>
        </Row>
      </StyledTableContainer>

      <ShipmentQuoteActions
        quoteStatus={quoteStatus}
        buttonSchema={ButtonsSchema}
        handleOnSendClick={() => handleQuoteStatusChange(QUOTE_CREATED)}
        handleOnRejectClick={(reason) =>
          handleQuoteStatusChange(QUOTE_REQUEST_UNSUCCESSFUL, reason?.trim())
        }
        handleOnEditClick={onEdit}
      />
    </StyledShipmentQuote>
  );
};

export default ShipmentQuoteDetailsView;
