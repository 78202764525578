import { createReducer } from '@reduxjs/toolkit';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchQuotes,
  fetchQuotesLoading,
  fetchChoices,
  fetchChoicesLoading,
  setPageFilter,
  setPageSizeFilter,
  setQuoteFilters,
  setQuoteRangeFilters,
  resetFilters,
  cancelFilters,
  applyFilters
} from '../actions/quoteRequestsList';

const initialState = {
  quotes: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filtered: false,
  filters: {
    quoteId: null,
    createdAt: [],
    approveRejectedStatus: 'All',
    merchantName: null,
    origin: 'All',
    destination: 'All',
    shipmentMode: 'All',
    createdBy: 'All',
    creatorEmail: 'All',
    quoteStatus: 'All',
    winLossReason: null,
    shipmentQuoteId: null,
    networkPartnerCost: { min: '', max: '' },
    totalQuotePrice: { min: '', max: '' }
  },
  tempFilters: {
    quoteId: null,
    createdAt: [],
    approveRejectedStatus: 'All',
    merchantName: null,
    origin: 'All',
    destination: 'All',
    shipmentMode: 'All',
    createdBy: 'All',
    creatorEmail: 'All',
    quoteStatus: 'All',
    winLossReason: null,
    shipmentQuoteId: null,
    networkPartnerCost: { min: '', max: '' },
    totalQuotePrice: { min: '', max: '' }
  },
  choices: {
    loading: false,
    user_names: [],
    user_emails: []
  }
};

const quoteRequestsList = createReducer(initialState, {
  [fetchQuotes](state, action) {
    const { quotes, total } = action.payload;
    state.quotes = {
      data: quotes,
      total,
      loading: false
    };
  },
  [fetchChoices](state, action) {
    const { users } = action.payload;
    state.choices = {
      loading: false,
      user_names: [...new Set(users?.map((user) => user.name))].sort(),
      user_emails: [...new Set(users?.map((user) => user.email))].sort()
    };
  },
  [fetchChoicesLoading](state) {
    state.choices.loading = true;
  },
  [fetchQuotesLoading](state) {
    state.quotes.loading = true;
  },
  [setPageFilter](state, action) {
    state.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.page = 1;
    state.records = action.payload;
  },
  [setQuoteFilters](state, action) {
    state.tempFilters = {
      ...state.tempFilters,
      ...action.payload
    };
  },
  [setQuoteRangeFilters](state, action) {
    state.tempFilters[action.payload.field][action.payload.key] =
      action.payload.value;
  },
  [resetFilters](state, action) {
    if (action.payload) {
      state.filters = {
        ...state.tempFilters,
        // ...initialState.filters,
        [action.payload.key]: initialState.filters[action.payload.key],
        records: state.filters.records
      };
    } else {
      state.filters = {
        ...state.tempFilters,
        ...initialState.filters,
        records: state.filters.records
      };
    }
    state.filtered = false;
  },
  [cancelFilters](state) {
    state.tempFilters = state.filters;
  },
  [applyFilters](state) {
    state.filters = state.tempFilters;
    state.filtered = !(
      !state.filters.quoteId &&
      !state.filters.createdAt.length &&
      state.filters.approveRejectedStatus === 'All' &&
      state.filters.merchantName === 'All' &&
      state.filters.origin === 'All' &&
      state.filters.destination === 'All' &&
      state.filters.shipmentMode === 'All' &&
      state.filters.createdBy === 'All' &&
      state.filters.creatorEmail === 'All' &&
      state.filters.quoteStatus === 'All' &&
      !state.filters.shipmentQuoteId &&
      !state.filters.networkPartnerCost &&
      !state.filters.totalQuotePrice
    );
  }
});

const transformFilters = createTransform(
  (inboundState) => {
    // persist
    return { records: inboundState.records };
  },
  (outboundState) => {
    // hydrate
    return { ...initialState.filters, records: outboundState.records };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['filters'] }
);

export default persistReducer(
  {
    key: 'quoteRequestList',
    storage,
    whitelist: ['filters'],
    transforms: [transformFilters]
  },
  quoteRequestsList
);
