import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Popover, Icon, Input as AntInput } from 'antd';
import moment from 'moment';
import Input from './Input';
import DatePicker from './DatePicker';
import Button from './Button';
import SearchInput from './SearchInput';
import Select from './Select';
import { COUNTRIES, BILLING_COUNTRIES } from '../constants/countries';
import {
  setShipmentFilters,
  setShipmentRangeFilters,
  resetFilters,
  applyFilters
} from '../actions/shipmentList';
// import { AIRPORTS, SEAPORTS } from '../constants/ports';
import { toCamelCase } from '../utils/formatString';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  top: 225px;
  left: 184px;
  width: 280px;
  height: 138px;
  z-index: 999;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 20px #00000029;
  opacity: 1;
  &.range-filter {
    width: 330px !important;
  }
  &.filter-container {
    background: #fff;
    padding: 15px;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      text-align: left;
      .anticon {
        cursor: pointer;
      }
    }
    .ant-select-dropdown-menu {
      text-align: left;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 60px;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      color: #bbbbbb;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 0 12px 0 !important;
  }
`;
// const portsList = () => {
//   let portArray = [...AIRPORTS.map((port) => port.airportName), ...SEAPORTS.map((port) => port.name)]
//   let ports = [...new Set(portArray)].sort();
//   let portsList = ['All',...ports].map((name) => ({
//     label: name,
//     value: name
//   }));
//   portsList = portsList.filter(
//     (port) => port.label !== '' || port.value !== ''
//   );
//   return portsList || [];
// }

const ShipmentFilter = ({
  onFilter = () => {},
  filterName,
  isMinMaxFilter = false,
  confirm = () => {}
}) => {
  const dispatch = useDispatch();
  const {
    merchants,
    user_names,
    user_emails,
    statuses,
    loading,
    operators,
    operatorBranchCodes,
    originPorts,
    destinationPorts,
    transhipmentPorts
  } = useSelector((state) => state.shipmentList.choices);

  const status = useSelector((state) => state.shipmentList.tempFilters.status);
  const readyToBill = useSelector(
    (state) => state.shipmentList.tempFilters.readyToBill
  );
  const trackingNumber = useSelector(
    (state) => state.shipmentList.tempFilters.trackingNumber
  );
  const merchantName = useSelector(
    (state) => state.shipmentList.tempFilters.merchantName
  );
  const quoteRequestID = useSelector(
    (state) => state.shipmentList.tempFilters.quoteRequestID
  );
  const shipmentMode = useSelector(
    (state) => state.shipmentList.tempFilters.shipmentMode
  );
  const operator = useSelector(
    (state) => state.shipmentList.tempFilters.operator
  );
  const operatorBranchCode = useSelector(
    (state) => state.shipmentList.tempFilters.operatorBranchCode
  );
  const confirmedShipmentDate = useSelector((state) =>
    state.shipmentList.tempFilters.confirmedShipmentDate
      ? state.shipmentList.tempFilters.confirmedShipmentDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const estimatedDeliveryDate = useSelector((state) =>
    state.shipmentList.tempFilters.estimatedDeliveryDate
      ? state.shipmentList.tempFilters.estimatedDeliveryDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const actualDeliveryDate = useSelector((state) =>
    state.shipmentList.tempFilters.actualDeliveryDate
      ? state.shipmentList.tempFilters.actualDeliveryDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const createdAt = useSelector((state) =>
    state.shipmentList.tempFilters.createdAt
      ? state.shipmentList.tempFilters.createdAt?.map((d) => moment(d)) || []
      : []
  );
  const lastStatusUpdateDate = useSelector((state) =>
    state.shipmentList.tempFilters.lastStatusUpdateDate
      ? state.shipmentList.tempFilters.lastStatusUpdateDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const readyToBillDate = useSelector((state) =>
    state.shipmentList.tempFilters.readyToBillDate
      ? state.shipmentList.tempFilters.readyToBillDate?.map((d) => moment(d)) ||
        []
      : []
  );
  const origin = useSelector((state) => state.shipmentList.tempFilters.origin);
  const originPort = useSelector(
    (state) => state.shipmentList.tempFilters.originPort
  );
  const destinationPort = useSelector(
    (state) => state.shipmentList.tempFilters.destinationPort
  );
  const transhipmentPort = useSelector(
    (state) => state.shipmentList.tempFilters.transhipmentPort
  );
  const billingEntity = useSelector(
    (state) => state.shipmentList.tempFilters.billingEntity
  );
  const importAwb = useSelector(
    (state) => state.shipmentList.tempFilters.importAwb
  );
  const billingInvoiceNumber = useSelector(
    (state) => state.shipmentList.tempFilters.billingInvoiceNumber
  );
  const billingCreditNoteNumber = useSelector(
    (state) => state.shipmentList.tempFilters.billingCreditNoteNumber
  );
  const costBillingLock = useSelector(
    (state) => state.shipmentList.tempFilters.costBillingLock
  );
  const exportAwb = useSelector(
    (state) => state.shipmentList.tempFilters.exportAwb
  );
  const createdBy = useSelector(
    (state) => state.shipmentList.tempFilters.createdBy
  );
  const creatorEmail = useSelector(
    (state) => state.shipmentList.tempFilters.creatorEmail
  );
  const actualWeight = useSelector(
    (state) => state.shipmentList.tempFilters.actualWeight
  );
  const volumetricWeight = useSelector(
    (state) => state.shipmentList.tempFilters.volumetricWeight
  );
  const chargeableWeight = useSelector(
    (state) => state.shipmentList.tempFilters.chargeableWeight
  );
  const reconciliatedWeight = useSelector(
    (state) => state.shipmentList.tempFilters.reconciliatedWeight
  );
  const costAmount = useSelector(
    (state) => state.shipmentList.tempFilters.costAmount
  );
  const destination = useSelector(
    (state) => state.shipmentList.tempFilters.destination
  );
  const transhipmentCountry = useSelector(
    (state) => state.shipmentList.tempFilters.transhipmentCountry
  );

  const handleReset = () => {
    dispatch(resetFilters({ key: filterName }));
    const filters = {
      status: status === 'All' ? null : status,
      readyToBill: readyToBill === 'All' ? null : readyToBill,
      trackingNumber,
      quoteRequestID,
      merchantName: merchantName === 'All' ? null : merchantName,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      estimatedDeliveryDate,
      confirmedShipmentDate,
      actualDeliveryDate,
      createdAt,
      readyToBillDate,
      lastStatusUpdateDate,
      actualWeight:
        actualWeight && (actualWeight.min || actualWeight.max)
          ? actualWeight
          : null,
      volumetricWeight:
        volumetricWeight && (volumetricWeight.min || volumetricWeight.max)
          ? volumetricWeight
          : null,
      chargeableWeight:
        chargeableWeight &&
        (chargeableWeight.min ||
          chargeableWeight.max ||
          chargeableWeight.unit !== 'All')
          ? chargeableWeight
          : null,
      reconciliatedWeight:
        reconciliatedWeight &&
        (reconciliatedWeight.min ||
          reconciliatedWeight.max ||
          reconciliatedWeight.unit !== 'All')
          ? reconciliatedWeight
          : null,
      costAmount:
        costAmount && (costAmount.min || costAmount.max) ? costAmount : null,
      origin: origin === 'All' ? null : origin,
      originPort: originPort === 'All' ? null : originPort,
      destinationPort: destinationPort === 'All' ? null : destinationPort,
      transhipmentPort: transhipmentPort === 'All' ? null : transhipmentPort,
      billingEntity: billingEntity === 'All' ? null : billingEntity,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      importAwb,
      billingInvoiceNumber,
      billingCreditNoteNumber,
      costBillingLock,
      exportAwb,
      destination: destination === 'All' ? null : destination,
      transhipmentCountry:
        transhipmentCountry === 'All' ? null : transhipmentCountry,
      operator: operator === 'All' ? null : operator,
      operatorBranchCode:
        operatorBranchCode === 'All' ? null : operatorBranchCode,
      [filterName]:
        [
          'trackingNumber',
          'quoteRequestID',
          'estimatedDeliveryDate',
          'confirmedShipmentDate',
          'actualDeliveryDate',
          'createdAt',
          'readyToBillDate',
          'lastStatusUpdateDate'
        ].indexOf(filterName) === -1
          ? ''
          : null
    };
    onFilter(filters);
    confirm();
  };

  const handleFilter = () => {
    onFilter({
      status: status === 'All' ? null : status,
      readyToBill: readyToBill === 'All' ? null : readyToBill,
      trackingNumber,
      quoteRequestID,
      merchantName: merchantName === 'All' ? null : merchantName,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      estimatedDeliveryDate,
      confirmedShipmentDate,
      actualDeliveryDate,
      createdAt,
      readyToBillDate,
      lastStatusUpdateDate,
      actualWeight:
        actualWeight && (actualWeight.min || actualWeight.max)
          ? actualWeight
          : null,
      volumetricWeight:
        volumetricWeight && (volumetricWeight.min || volumetricWeight.max)
          ? volumetricWeight
          : null,
      chargeableWeight:
        chargeableWeight &&
        (chargeableWeight.min ||
          chargeableWeight.max ||
          chargeableWeight.unit !== 'All')
          ? chargeableWeight
          : null,
      reconciliatedWeight:
        reconciliatedWeight &&
        (reconciliatedWeight.min ||
          reconciliatedWeight.max ||
          reconciliatedWeight.unit !== 'All')
          ? reconciliatedWeight
          : null,
      costAmount:
        costAmount && (costAmount.min || costAmount.max) ? costAmount : null,
      origin: origin === 'All' ? null : origin,
      originPort: originPort === 'All' ? null : originPort,
      destinationPort: destinationPort === 'All' ? null : destinationPort,
      transhipmentPort: transhipmentPort === 'All' ? null : transhipmentPort,
      billingEntity: billingEntity === 'All' ? null : billingEntity,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      importAwb,
      billingInvoiceNumber,
      billingCreditNoteNumber,
      costBillingLock,
      exportAwb,
      destination: destination === 'All' ? null : destination,
      transhipmentCountry:
        transhipmentCountry === 'All' ? null : transhipmentCountry,
      operator: operator === 'All' ? null : operator,
      operatorBranchCode:
        operatorBranchCode === 'All' ? null : operatorBranchCode
    });
    dispatch(applyFilters());
    confirm();
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setShipmentFilters({ [field]: option?.props?.value }));
  };

  const handleUnitChange = (field, key) => (value, option) => {
    dispatch(
      setShipmentRangeFilters({ field, key, value: option?.props?.value })
    );
  };

  const handleDatePickerChange = (field) => (value) => {
    dispatch(
      setShipmentFilters({
        [field]: [
          value[0]?.startOf('day').toISOString(),
          value[1]?.endOf('day').toISOString()
        ]
      })
    );
  };

  const handleTrackerChange = (field) => (e) => {
    dispatch(setShipmentFilters({ [field]: e.target.value }));
  };

  const handleRangeChange = (field, key) => (e) => {
    dispatch(setShipmentRangeFilters({ field, key, value: e.target.value }));
  };

  return (
    <FilterContainer
      className={
        isMinMaxFilter ? 'filter-container range-filter' : 'filter-container'
      }
    >
      {filterName === 'trackingNumber' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="tracking-number">
              Filter tracking number{' '}
              <Popover
                placement="topLeft"
                arrowPointAtCenter
                overlayClassName="tooltip"
                content={
                  <div className="tooltip-content">
                    The tracking number is a unique ID referring to a quote or
                    shipment
                  </div>
                }
                title="Tracking Number"
              >
                <Icon type="question-circle" />
              </Popover>
            </label>
            <Input
              id="tracking-number"
              placeholder="Type..."
              value={trackingNumber}
              onChange={handleTrackerChange('trackingNumber')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'quoteRequestID' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-request-id">
              Filter Quote Request ID{' '}
              <Popover
                placement="topLeft"
                arrowPointAtCenter
                overlayClassName="tooltip"
                content={
                  <div className="tooltip-content">
                    The quote ID is a unique ID referring to a quote request
                  </div>
                }
                title="Quote ID"
              >
                <Icon type="question-circle" />
              </Popover>
            </label>
            <Input
              id="quote-id"
              placeholder="Type..."
              value={quoteRequestID}
              onChange={handleTrackerChange('quoteRequestID')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'merchantName' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="merchant-name">Filter merchant name</label>
            <SearchInput
              placeholder="Type or select..."
              id="merchant-name"
              items={merchants}
              loading={loading}
              value={merchantName}
              onChange={handleSearchInputChange('merchantName')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'status' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-public-status">Filter tracking status</label>
            <SearchInput
              id="quote-public-status"
              items={statuses}
              loading={loading}
              value={status}
              onChange={handleSearchInputChange('status')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'readyToBill' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="ready-to-bill">Filter ready to bill</label>
            <SearchInput
              id="ready-to-bill"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]}
              value={readyToBill}
              onChange={handleSearchInputChange('readyToBill')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'costBillingLock' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="ready-to-bill">Filter Cost & billing lock</label>
            <SearchInput
              id="costBillingLock"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Cost Locked', value: '1' },
                { label: 'Billing Locked', value: '2' },
                { label: 'Cost & Billing Locked', value: '3' },
                { label: 'Not Locked', value: '4' }
              ]}
              value={costBillingLock}
              onChange={handleSearchInputChange('costBillingLock')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'origin' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="origin-country">Filter origin country</label>
            <SearchInput
              id="origin-country"
              items={COUNTRIES}
              value={origin}
              valueKey="value"
              onChange={handleSearchInputChange('origin')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'originPort' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="originPort">Filter origin airport/port</label>
            <SearchInput
              id="originPort"
              items={originPorts}
              value={originPort}
              valueKey="value"
              onChange={handleSearchInputChange('originPort')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'destinationPort' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="destinationPort">
              Filter destination airport/port
            </label>
            <SearchInput
              id="destinationPort"
              items={destinationPorts}
              value={destinationPort}
              valueKey="value"
              onChange={handleSearchInputChange('destinationPort')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'transhipmentPort' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="transhipmentPort">
              Filter transhipment airport/port
            </label>
            <SearchInput
              id="transhipmentPort"
              items={transhipmentPorts}
              value={transhipmentPort}
              valueKey="value"
              onChange={handleSearchInputChange('transhipmentPort')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'billingEntity' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="billingEntity">Filter Janio Billing Branch</label>
            <SearchInput
              id="billingEntity"
              items={['All', ...BILLING_COUNTRIES].map((name) => ({
                label: toCamelCase(name),
                value: name
              }))}
              value={billingEntity}
              valueKey="value"
              onChange={handleSearchInputChange('billingEntity')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'createdBy' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdBy">Filter created by</label>
            <SearchInput
              id="createdBy"
              items={['All', ...user_names].map((name) => ({
                label: name,
                value: name
              }))}
              value={createdBy}
              valueKey="value"
              onChange={handleSearchInputChange('createdBy')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'creatorEmail' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="creatorEmail">Filter creator email</label>
            <SearchInput
              id="creatorEmail"
              items={['All', ...user_emails].map((email) => ({
                label: email,
                value: email
              }))}
              value={creatorEmail}
              valueKey="value"
              onChange={handleSearchInputChange('creatorEmail')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'importAwb' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="importAwb">Filter MAWB, OOBL, D/O Number</label>
            <Input
              id="importAwb"
              placeholder="Type..."
              value={importAwb}
              onChange={handleTrackerChange('importAwb')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'billingInvoiceNumber' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="billingInvoiceNumber">
              Filter billing invoice no.
            </label>
            <Input
              id="billingInvoiceNumber"
              placeholder="Type..."
              value={billingInvoiceNumber}
              onChange={handleTrackerChange('billingInvoiceNumber')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'billingCreditNoteNumber' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="billingCreditNoteNumber">
              Filter credit note no.
            </label>
            <Input
              id="billingCreditNoteNumber"
              placeholder="Type..."
              value={billingCreditNoteNumber}
              onChange={handleTrackerChange('billingCreditNoteNumber')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'transhipmentCountry' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="transhipment-country">
              Filter transhipment country
            </label>
            <SearchInput
              id="transhipment-country"
              items={COUNTRIES}
              value={transhipmentCountry}
              valueKey="value"
              onChange={handleSearchInputChange('transhipmentCountry')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'destination' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="destination-country">
              Filter destination country
            </label>
            <SearchInput
              id="destination-country"
              items={COUNTRIES}
              value={destination}
              valueKey="value"
              onChange={handleSearchInputChange('destination')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'shipmentMode' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-shipment-mode">Filter shipment mode</label>
            <SearchInput
              id="quote-shipment-mode"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Air', value: 'Air' },
                { label: 'Sea', value: 'Sea' },
                { label: 'Land', value: 'Land' }
              ]}
              loading={loading}
              value={shipmentMode}
              onChange={handleSearchInputChange('shipmentMode')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'confirmedShipmentDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="shipment-confirmed-date">
              Filter shipment confirmed date
            </label>
            <RangePicker
              id="shipment-confirmed-date"
              value={confirmedShipmentDate}
              onChange={handleDatePickerChange('confirmedShipmentDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'createdAt' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdAt">Filter shipment creation date</label>
            <RangePicker
              id="createdAt"
              value={createdAt}
              onChange={handleDatePickerChange('createdAt')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'lastStatusUpdateDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="lastStatusUpdateDate">
              Filter shipment creation date
            </label>
            <RangePicker
              id="lastStatusUpdateDate"
              value={lastStatusUpdateDate}
              onChange={handleDatePickerChange('lastStatusUpdateDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'readyToBillDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="readyToBillDate">Filter ready to bill date</label>
            <RangePicker
              id="readyToBillDate"
              value={readyToBillDate}
              onChange={handleDatePickerChange('readyToBillDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'actualWeight' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="actualWeight">Filter total gross weight</label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: 136,
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={actualWeight.min}
                onChange={handleRangeChange('actualWeight', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 136,
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={actualWeight.max}
                onChange={handleRangeChange('actualWeight', 'max')}
              />
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'volumetricWeight' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="volumetricWeight">
              Filter total volumetric weight
            </label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: 136,
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={volumetricWeight.min}
                onChange={handleRangeChange('volumetricWeight', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 136,
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={volumetricWeight.max}
                onChange={handleRangeChange('volumetricWeight', 'max')}
              />
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'costAmount' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="costAmount">Filter total cost</label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: 136,
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={costAmount.min}
                onChange={handleRangeChange('costAmount', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 136,
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={costAmount.max}
                onChange={handleRangeChange('costAmount', 'max')}
              />
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'chargeableWeight' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="chargeableWeight">
              Filter merchant chargeable weight
            </label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: '33%',
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={chargeableWeight.min}
                onChange={handleRangeChange('chargeableWeight', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: '10%',
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: '33%',
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={chargeableWeight.max}
                onChange={handleRangeChange('chargeableWeight', 'max')}
              />
              <Select
                style={{
                  width: '24%',
                  display: 'inline-block'
                }}
                value={chargeableWeight.unit}
                onChange={handleUnitChange('chargeableWeight', 'unit')}
              >
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="KG">KG</Select.Option>
                <Select.Option value="CBM">CBM</Select.Option>
              </Select>
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'estimatedDeliveryDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="estimated-delivery-date">
              Filter estimated delivery date
            </label>
            <RangePicker
              id="estimated-delivery-date"
              value={estimatedDeliveryDate}
              onChange={handleDatePickerChange('estimatedDeliveryDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'actualDeliveryDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="actual-delivery-date">
              Filter actual delivery date
            </label>
            <RangePicker
              id="actual-delivery-date"
              value={actualDeliveryDate}
              onChange={handleDatePickerChange('actualDeliveryDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'operator' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="operator-in-charge">
              Filter operator-in-charge
            </label>
            <SearchInput
              id="operator-in-charge"
              items={operators}
              value={operator}
              onChange={handleSearchInputChange('operator')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'operatorBranchCode' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="operatorBranchCode">
              Filter operator branch code
            </label>
            <SearchInput
              id="operatorBranchCode"
              items={operatorBranchCodes}
              value={operatorBranchCode}
              onChange={handleSearchInputChange('operatorBranchCode')}
            />
          </Col>
        </Row>
      )}
      <div className="actions">
        <Button
          type="link"
          size="small"
          className="reset-filter"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="secondary" onClick={handleFilter}>
          Filter
        </Button>
      </div>
    </FilterContainer>
  );
};

export default ShipmentFilter;
