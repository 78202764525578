import request, { axiosRequest } from '../utils/request';
import { queryString } from '../utils/formatString';

const getBillingDetails = async (id) => {
  const url = `/billing/${id}`;
  const response = await request().get(url);
  return response.data.data;
};
const saveBillingInfo = ({ data, id }) => {
  const url = `/billing/${id}`;
  return axiosRequest.post(url, data);
};

const getExchangeRates = async () => {
  if (localStorage.getItem('rates')) {
    return JSON.parse(localStorage.getItem('rates'));
  }
  const url = '/billing/rates';
  const response = await request().get(url);
  localStorage.setItem('rates', JSON.stringify(response.data));
  return response.data;
};

const getClients = async ({ billingEntity, query }) => {
  const queryParams = queryString({ billingEntity, query });
  const url = `/billing/clients?${queryParams}`;
  return request().get(url);
};

const unlockCostBilling = async (data) => {
  const url = `/billing/unlock-cost-billing`;
  return request().post(url, data);
};

export const billingService = {
  getBillingDetails,
  saveBillingInfo,
  getExchangeRates,
  getClients,
  unlockCostBilling
};
