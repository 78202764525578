import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Skeleton, Row, Col, message } from 'antd';
import { billingService } from '../../services/billing';
import Table from '../Table';
import Button from '../Button';
import ExchangeRates from '../ExchangeRates';
import { getQuoteShipmentLog } from '../../actions/quoteDetail';
import { calculateTotalFromRates } from '../../utils/calculateRates';
import InfoMessage from './InfoMessage';
import TotalBillingCost from './TotalBillingCost';
import { toCamelCase } from '../../utils/formatString';
import { getProfileSync } from '../../auth0';
import { updateCostBillingDetails } from '../../actions/costBillingDetail';
import {
  TEXT_COST_UNLOCK_SUCCESS,
  TEXT_COST_UNLOCK_ERROR,
  TEXT_BILLING_UNLOCK_SUCCESS,
  TEXT_BILLING_UNLOCK_ERROR
} from '../../constants/message';
import ColumnDropdown from './ColumnDropdown';

const StyledShipmentQuote = styled.div`
  font-family: AvenirLTStdBook;
`;
const StyledButton = styled(Button)`
  width: 150px;
`;
const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubTitle = styled.p`
  font-size: 16px;
  font-family: AvenirLTStdHeavy;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const InfoTitle = styled.p`
  font-family: AvenirLTStdHeavy;
  font-size: 14px;
  color: #707070;
`;
const BillingInfoValue = styled.p`
  color: #000000;
  font-size: 16px;
`;
const Section = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: ${(props) => (props.last ? '20px' : '0px')};
  .ant-table-body {
    margin: 0 !important;
  }
  .ant-table-placeholder {
    border-bottom: 1px solid #8084c1 !important;
    border-top: 1px solid #8084c1;
    background-color: #f2f2f9;
    font-size: 16px;
    padding: 7px 16px;
    color: #000;
    border-radius: 0;
  }
  td.ant-table-row-cell-break-word {
    word-wrap: normal;
    word-break: keep-all;
    white-space: nowrap;
  }
  /* Chrome Fixes */
  .ant-table-thead tr {
    th:nth-child(1) {
      min-width: 160px;
    }
    th:nth-child(2) {
      min-width: 140px;
    }
    th:nth-child(3) {
      min-width: 75px;
    }
    th:nth-child(4) {
      min-width: 140px;
    }
    th:nth-child(5) {
      min-width: 75px;
    }
    th:nth-child(6) {
      min-width: 125px;
    }
    th:nth-child(7) {
      min-width: 41px;
    }
  }
`;

const CostBillingDetailsView = ({ quoteId, onEdit }) => {
  const dispatch = useDispatch();
  const showUnlockButton = () => {
    const profile = getProfileSync();
    const roles = profile['https://b2b.janio.asia/roles'] || [];
    return roles.includes('ops/finance');
  };
  const [submitBtn, setSubmitBtn] = useState();
  const [saving, setSaving] = useState();
  const [rates, setRates] = useState(null);
  const [totalRates, setTotalRates] = useState({
    totalCost: 0,
    totalCostTax: 0,
    totalBilling: 0,
    totalBillingTax: 0,
    exchangeRates: []
  });
  useEffect(() => {
    const getRates = async () => {
      const rateList = await billingService.getExchangeRates();
      setRates(rateList.data);
    };
    getRates();
    dispatch(getQuoteShipmentLog(quoteId));
  }, [dispatch, quoteId]);
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const costBillingData = useSelector(
    (state) => state?.costBillingDetails?.costBillingData
  );
  const handleUnockCostBilling = async (type) => {
    try {
      setSaving(true);
      await billingService.unlockCostBilling({ type, quote_id: quoteId });
      dispatch(
        updateCostBillingDetails({ type: `is_${type}_locked`, value: false })
      );
      setSaving(false);
      message.success(
        type === 'cost' ? TEXT_COST_UNLOCK_SUCCESS : TEXT_BILLING_UNLOCK_SUCCESS
      );
      dispatch(getQuoteShipmentLog(quoteId));
    } catch (error) {
      const result = error?.response?.data?.result;
      setSaving(false);
      message.error(
        result ||
          (type === 'cost' ? TEXT_COST_UNLOCK_ERROR : TEXT_BILLING_UNLOCK_ERROR)
      );
    }
  };
  const loading = useSelector((state) => state?.costBillingDetails?.loading);
  const tableColumns = useSelector((state) =>
    Object.keys(state.costBillingDetails.columns).reduce((cols, curr) => {
      return [
        'fee_name',
        'cost_total_amount',
        'billing_total_amount',
        ...cols,
        ...state.costBillingDetails.columns[curr]
      ];
    }, [])
  );
  const columns = [
    {
      title: 'Fee name',
      dataIndex: 'fee_name',
      key: 'fee_name',
      width: 160,
      fixed: 'left',
      className: 'tableSeparator'
    },
    {
      title: 'N/P name',
      dataIndex: 'network_partner',
      key: 'network_partner',
      width: 160,
      className: 'column-spacing'
    },
    {
      title: 'Cost Description',
      dataIndex: 'cost_description',
      key: 'cost_description',
      width: 180,
      className: 'column-spacing'
    },
    {
      title: 'HAWB/HAWB',
      dataIndex: 'cost_awb',
      key: 'cost_awb',
      width: 180,
      className: 'column-spacing'
    },
    {
      title: 'N/P invoice no.',
      dataIndex: 'np_invoice_number',
      key: 'np_invoice_number',
      width: 180
    },
    {
      title: 'Cost fee type',
      dataIndex: 'cost_fee_type',
      key: 'cost_fee_type',
      width: 140
    },
    {
      title: 'No. of units',
      dataIndex: 'cost_units',
      key: 'cost_units',
      width: 140
    },
    {
      title: 'Cost currency',
      dataIndex: 'cost_currency',
      key: 'cost_currency',
      width: 140
    },
    {
      title: 'Cost unit price',
      dataIndex: 'cost_unit_price',
      key: 'cost_unit_price',
      width: 140
    },
    {
      title: 'Cost total',
      dataIndex: 'cost_total_amount',
      key: 'cost_total_amount',
      width: 150,
      className: 'tableSeparator'
    },
    {
      title: 'Janio billing entity',
      dataIndex: 'billing_country',
      key: 'billing_country',
      width: 180,
      className: 'column-spacing'
    },
    {
      title: 'Bill to',
      dataIndex: 'xero_client_id',
      key: 'xero_client_id',
      width: 100
    },
    {
      title: 'Bill comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 140
    },
    {
      title: 'Billing description',
      dataIndex: 'description',
      key: 'description',
      width: 200
    },
    {
      title: 'HAWB/HAWB',
      dataIndex: 'billing_awb',
      key: 'billing_awb',
      width: 180,
      className: 'column-spacing'
    },
    {
      title: 'No. of units',
      dataIndex: 'weight',
      key: 'weight',
      width: 120
    },
    {
      title: 'Bill currency',
      dataIndex: 'billing_currency',
      key: 'billing_currency',
      width: 110
    },
    {
      title: 'Bill unit price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      width: 120
    },
    {
      title: 'Bill total',
      dataIndex: 'billing_total_amount',
      key: 'billing_total_amount',
      width: 150,
      className: 'tableSeparator'
    },
    {
      title: 'Invoice no.',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: 120,
      className: 'column-spacing'
    },
    {
      title: 'Invoice link',
      dataIndex: 'invoice_link',
      key: 'invoice_link',
      width: 100
    },
    {
      title: 'Credit note no.',
      dataIndex: 'credit_note_no',
      key: 'credit_note_no',
      width: 140
    },
    {
      title: 'Credit note link',
      dataIndex: 'credit_note_link',
      key: 'credit_note_link',
      width: 140
    }
  ];
  const dataSource = costBillingData?.quote_line_items.map((item, index) => ({
    key: index,
    fee_name: item.fee_name || '-',
    network_partner: item?.cost?.network_partner || '-',
    np_invoice_number: item?.cost?.invoice_number || '-',
    cost_fee_type: item?.cost?.fee_type || '-',
    cost_currency: item?.cost?.currency || '-',
    cost_awb: item?.cost?.cost_awb || '-',
    cost_units: item?.cost?.units,
    cost_description: item?.cost?.cost_description || '-',
    cost_unit_price: item?.cost?.unit_price,
    cost_total_amount: item?.cost?.total_amount
      ? `${item?.cost?.currency || ''} ${item?.cost?.total_amount}`
      : '-',
    xero_client_id: item?.billing?.xero_client_name || '-',
    billing_country: item?.billing?.billing_country
      ? toCamelCase(item?.billing?.billing_country)
      : '-',
    comment: item?.billing?.comment || '-',
    description: item?.billing?.description || '-',
    billing_awb: item?.billing?.billing_awb || '-',
    weight: item?.billing?.weight,
    billing_unit_price: item?.billing?.unit_price,
    billing_total_amount: item?.billing?.total_amount
      ? `${item?.billing?.currency || ''} ${item?.billing?.total_amount}`
      : '-',
    billing_currency: item?.billing?.currency || '-',
    invoice_number: item?.billing?.bill_invoice_number || '-',
    invoice_link: item?.billing?.invoice_link || '-',
    credit_note_no: item?.billing?.credit_note_no || '-',
    credit_note_link: item?.billing?.credit_note_link || '-'
  }));
  const blank = useSelector(() => {
    return (
      (costBillingData?.quote_line_items || []).length < 1 &&
      !costBillingData?.operator_remark &&
      !costBillingData?.finance_remark
    );
  });
  useDeepCompareEffect(() => {
    if (detailAPI.quote_status === 'BILLED') {
      if (costBillingData?.quote_line_items?.length) {
        let exchange_rates = {};
        if (costBillingData?.billing_exchange_rates) {
          exchange_rates = {
            ...exchange_rates,
            ...costBillingData?.billing_exchange_rates
          };
        }
        if (costBillingData?.cost_exchange_rates) {
          exchange_rates = {
            ...exchange_rates,
            ...costBillingData?.cost_exchange_rates
          };
        }
        // to support previous billing line items
        if (
          costBillingData?.invoice_currency !== 'USD' ||
          !costBillingData?.billing_exchange_rates
        ) {
          setTotalRates({
            totalCost: costBillingData?.cost_total_amount,
            totalCostTax: 0,
            totalBilling: costBillingData?.billing_exchange_rates
              ? costBillingData?.billing_total_amount
              : costBillingData?.quote_line_items.reduce((curr, val) => {
                  return curr + parseFloat(val.billing.total_amount || 0);
                }, 0),
            totalBillingTax: 0,
            grossProfit: 0,
            grossProfitMargin: 0,
            exchangeRates: []
          });
        } else {
          // calculate total with existing rates
          setTotalRates(
            calculateTotalFromRates({
              target: costBillingData?.invoice_currency,
              lineTotals: costBillingData?.quote_line_items,
              rates: null,
              existingRates: exchange_rates
                ? new Map(
                    Object.keys(exchange_rates).reduce((val, curr) => {
                      return [...val, [curr, exchange_rates[curr]]];
                    }, [])
                  )
                : null
            })
          );
        }
      }
    } else {
      setTotalRates(
        calculateTotalFromRates({
          target: 'USD',
          lineTotals: costBillingData?.quote_line_items,
          rates
        })
      );
    }
  }, [costBillingData?.quote_line_items, rates]);

  return (
    <>
      {loading && <Skeleton title active paragraph={{ rows: 5 }} />}
      {!loading && (
        <StyledShipmentQuote>
          <InfoMessage
            data={{
              quote_status: detailAPI.quote_status,
              blank,
              readyToBill: costBillingData?.is_billing_locked,
              isCostLocked: costBillingData?.is_cost_locked,
              onEdit
            }}
          />
          <h4>
            {`${detailAPI.origin && detailAPI.origin.country} to ${
              detailAPI.destination.country
            }`}
          </h4>
          <Section>
            <TableTitle>
              <SubTitle>Cost & Billing Details</SubTitle>
              <ColumnDropdown />
            </TableTitle>
            <Table
              totalCount={dataSource.length}
              type="primary"
              showScroll={false}
              showPagination={false}
              dataSource={dataSource}
              columns={columns.filter(
                (column) =>
                  tableColumns.includes(column.key) || column.key === 'action'
              )}
              empty={<span>None</span>}
              renderEmptyTable
            />
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '20px' }}
            >
              {totalRates.exchangeRates && (
                <Col span={10} xxl={{ span: 6 }}>
                  {(costBillingData?.invoice_currency === 'USD' ||
                    detailAPI.quote_status !== 'BILLED') && (
                    <ExchangeRates
                      target="USD"
                      rates={totalRates.exchangeRates}
                    />
                  )}
                  {costBillingData?.invoice_currency !== 'USD' &&
                    detailAPI.quote_status === 'BILLED' && (
                      <>
                        <ExchangeRates
                          target="USD"
                          rates={
                            costBillingData?.cost_exchange_rates
                              ? new Map(
                                  Object.keys(
                                    costBillingData?.cost_exchange_rates
                                  ).reduce((val, curr) => {
                                    return [
                                      ...val,
                                      [
                                        curr,
                                        costBillingData?.cost_exchange_rates[
                                          curr
                                        ]
                                      ]
                                    ];
                                  }, [])
                                )
                              : []
                          }
                        />
                        <ExchangeRates
                          showLabel={false}
                          target={costBillingData?.invoice_currency}
                          rates={
                            costBillingData?.billing_exchange_rates
                              ? new Map(
                                  Object.keys(
                                    costBillingData?.billing_exchange_rates
                                  ).reduce((val, curr) => {
                                    return [
                                      ...val,
                                      [
                                        curr,
                                        costBillingData?.billing_exchange_rates[
                                          curr
                                        ]
                                      ]
                                    ];
                                  }, [])
                                )
                              : []
                          }
                        />
                      </>
                    )}
                </Col>
              )}
              <Col span={13} xxl={{ span: 18 }} style={{ textAlign: 'right' }}>
                <TotalBillingCost
                  costBillingLocked={
                    costBillingData?.is_cost_locked &&
                    costBillingData?.is_billing_locked
                  }
                  totalRates={totalRates}
                  invoiceCurrency={
                    detailAPI.quote_status === 'BILLED'
                      ? costBillingData?.invoice_currency
                      : 'USD'
                  }
                />
              </Col>
            </Row>
          </Section>
          <Section last>
            <SubTitle>Notes</SubTitle>
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <InfoTitle>Operator Remarks</InfoTitle>
                <BillingInfoValue>
                  {costBillingData?.operator_remark
                    ? costBillingData?.operator_remark
                        .split('\n')
                        .map((str) => <p>{str}</p>)
                    : '-'}
                </BillingInfoValue>
              </Col>
              <Col span={12}>
                <InfoTitle>Finance Remarks</InfoTitle>
                <BillingInfoValue>
                  {costBillingData?.finance_remark
                    ? costBillingData?.finance_remark
                        .split('\n')
                        .map((str) => <p>{str}</p>)
                    : '-'}
                </BillingInfoValue>
              </Col>
            </Row>
          </Section>
          {detailAPI.quote_status !== 'BILLED' && (
            <Row type="flex" justify="space-between">
              <StyledButton type="secondary" onClick={onEdit}>
                Edit
              </StyledButton>
              {showUnlockButton() && (
                <div>
                  {costBillingData?.is_cost_locked && (
                    <StyledButton
                      style={{ marginLeft: '8px' }}
                      type="secondary"
                      loading={submitBtn === 'cost' && saving}
                      onClick={() => {
                        setSubmitBtn('cost');
                        handleUnockCostBilling('cost');
                      }}
                    >
                      Unlock Cost
                    </StyledButton>
                  )}
                  {costBillingData?.is_billing_locked && (
                    <StyledButton
                      style={{ marginLeft: '8px' }}
                      type="secondary"
                      loading={submitBtn === 'billing' && saving}
                      onClick={() => {
                        setSubmitBtn('billing');
                        handleUnockCostBilling('billing');
                      }}
                    >
                      Unlock Billing
                    </StyledButton>
                  )}
                </div>
              )}
            </Row>
          )}
        </StyledShipmentQuote>
      )}
    </>
  );
};

export default CostBillingDetailsView;
