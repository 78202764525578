import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Timeline, Skeleton } from 'antd';
import moment from 'moment';
import Card from './Card';
import { getQuoteShipmentLog } from '../actions/quoteDetail';
import ShipmentLog from './ShipmentLog';

const StyledShipmentHistory = styled(Card)`
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  .ant-timeline {
    position: relative;
    left: 72px;
    .ant-timeline-item-content {
      position: relative;
      top: -6px;
      // width: 530px;
      margin: 0px 40px 0 25px;
      word-break: break-word;
      .ant-row {
        padding-bottom: 8px;
      }
    }
    .wrapper-item {
      padding-top: 30px;
      .ant-timeline-item {
        margin-top: -23px;
        margin-left: 20px;
        transform: translateY(8px);
        padding: 0;
      }
      .book-oblique {
        width: 90px;
        display: inline-block;
        position: relative;
        right: 74px;
        top: 2px;
        font-family: AvenirLTStdBookOblique;
        color: #000;
        font-size: 14px;
      }
      .ant-timeline-item-tail {
        border-left: 2px solid #050593;
        left: 3.5px;
        height: calc(100% + 28px);
      }
      .ant-timeline-item-head-blue {
        color: #1fceca;
        border: solid 2px #050593;
        background-color: #ffffff;
      }
      &:last-child .ant-timeline-item-tail {
        display: none;
      }
    }
  }
`;

// TODO: implement loading state and fetch data
const ShipmentHistory = ({ quoteId, heading }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      await dispatch(getQuoteShipmentLog(quoteId));
      setLoading(false);
    };
    fetchLogs();
  }, [dispatch, quoteId]);

  // TODO: find out why the following line wasn't validated by the eslint
  const shippingLog = useSelector((state) => {
    const groupedLog = {};
    // eslint-disable-next-line no-unused-expressions
    state?.quoteDetail?.shipping_log?.forEach((item) => {
      if (groupedLog[moment(item.created_at).format('YYYY-MM-DD')]) {
        groupedLog[moment(item.created_at).format('YYYY-MM-DD')].push(item);
      } else {
        groupedLog[moment(item.created_at).format('YYYY-MM-DD')] = [item];
      }
    });
    return groupedLog || [];
  });

  return (
    <StyledShipmentHistory>
      {!loading ? (
        <>
          <h4>Your {heading} History</h4>
          <Timeline>
            {Object.keys(shippingLog).map((element) => (
              <div key={element} className="wrapper-item">
                <span className="book-oblique">
                  {moment(element).format('DD MMM YYYY')}
                </span>
                <Timeline.Item className="shipment-log">
                  {shippingLog[element].map((log) => (
                    <ShipmentLog key={`logs${log}`} log={log} />
                  ))}
                </Timeline.Item>
              </div>
            ))}
          </Timeline>
        </>
      ) : (
        <Skeleton title active paragraph={{ rows: 5 }} />
      )}
    </StyledShipmentHistory>
  );
};

export default ShipmentHistory;
