import { createAction } from '@reduxjs/toolkit';
import { costService } from '../services/cost';
import { billingService } from '../services/billing';

export const fetchCostBillingDetails = createAction(
  'costbilling/COST_BILLING_DETAILS'
);
export const updateCostBillingDetails = createAction(
  'costbilling/UPDATE_COST_BILLING_DETAILS'
);
export const setColumns = createAction('costbilling/SET_COLUMNS');
export const addLineItems = createAction('costbilling/ADD_LINE_ITEMS');
export const removeLineItem = createAction('costbilling/REMOVE_LINE_ITEM');
export const updateLineItem = createAction('costbilling/UPDATE_LINE_ITEM');
export const cancelLineItems = createAction('costbilling/CANCEL_LINE_ITEMS');
export const saveLineItems = createAction('costbilling/SAVE_LINE_ITEMS');
export const loadCostBilling = createAction('costbilling/LOADING');
export const updateInvoiceDetails = createAction(
  'costbilling/UPDATE_INVOICE_DETAILS'
);

export const getCostBillingDetails = (id) => async (dispatch) => {
  dispatch(loadCostBilling());
  const costResponse = await costService.getCostDetails(id);
  const billingResponse = await billingService.getBillingDetails(id);
  dispatch(
    fetchCostBillingDetails({ cost: costResponse, billing: billingResponse })
  );
};
