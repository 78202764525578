import { axiosRequest } from '../utils/request';
import {
  removeEmpty,
  queryString,
  formatToISOString
} from '../utils/formatString';

const getShipments = async ({
  status = '',
  readyToBill = null,
  page = 1,
  pageSize = 10,
  trackingNumber = '',
  quoteRequestID = '',
  merchantName = '',
  shipmentMode = '',
  origin = '',
  originPort = '',
  destinationPort = '',
  transhipmentPort = '',
  billingEntity = '',
  importAwb = '',
  createdBy = '',
  creatorEmail = '',
  exportAwb = '',
  billingInvoiceNumber = '',
  billingCreditNoteNumber = '',
  costBillingLock = '',
  destination = '',
  transhipmentCountry = '',
  operator = '',
  operatorBranchCode = '',
  confirmedShipmentDate = [],
  estimatedDeliveryDate = [],
  actualDeliveryDate = [],
  readyToBillDate = [],
  createdAt = [],
  lastStatusUpdateDate = [],
  actualWeight = '',
  volumetricWeight = '',
  costAmount = '',
  chargeableWeight = '',
  reconciliatedWeight = ''
}) => {
  const params = removeEmpty({
    status,
    // TODO: temporary fix, search input does not properly return boolean values , so have to use strings
    readyToBill: readyToBill ? readyToBill === 'Yes' : readyToBill,
    page,
    records: pageSize,
    trackingNumber,
    quoteRequestID,
    merchantName,
    shipmentMode,
    origin,
    originPort,
    destinationPort,
    transhipmentPort,
    billingEntity,
    createdBy,
    creatorEmail,
    importAwb,
    billingInvoiceNumber,
    billingCreditNoteNumber,
    costBillingLock,
    exportAwb,
    transhipmentCountry,
    destination,
    operator,
    operatorBranchCode,
    actualWeight,
    volumetricWeight,
    chargeableWeight,
    reconciliatedWeight,
    costAmount,
    confirmedShipmentDate: confirmedShipmentDate?.map(formatToISOString) || [],
    estimatedDeliveryDate: estimatedDeliveryDate?.map(formatToISOString) || [],
    createdAt: createdAt?.map(formatToISOString) || [],
    lastStatusUpdateDate: lastStatusUpdateDate?.map(formatToISOString) || [],
    readyToBillDate: readyToBillDate?.map(formatToISOString) || [],
    actualDeliveryDate: actualDeliveryDate?.map(formatToISOString) || []
  });

  params.actualWeight = JSON.stringify(params.actualWeight);
  params.volumetricWeight = JSON.stringify(params.volumetricWeight);
  params.costAmount = JSON.stringify(params.costAmount);
  params.chargeableWeight = JSON.stringify(params.chargeableWeight);
  params.reconciliatedWeight = JSON.stringify(params.reconciliatedWeight);
  const queryParams = queryString(params);
  const url = `/shipments?${queryParams}`;
  const response = await axiosRequest.get(url, params).exec();
  const { shipments, total } = response.data.data;
  return {
    shipments: shipments.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const getShipmentFilters = async () => {
  const url = '/shipments/filters';
  const response = await axiosRequest.get(url).exec();
  return response.data.data;
};

const exportShipmentsCSV = async (data) => {
  const url = '/shipments/export';
  const response = await axiosRequest.post(url, data).exec();
  return response.data;
};

const getOperators = async () => {
  const url = '/shipments/operators';
  const response = await axiosRequest.get(url).exec();
  return response.data;
};

const assignOperator = async (operator, trackingNumber) => {
  const url = `/shipments/${trackingNumber}/assign`;
  const response = await axiosRequest
    .post(url, { operatorId: operator })
    .exec();
  return response.data;
};

const createShipment = (shipment) => {
  const url = `/shipments/create`;
  return axiosRequest.post(url, shipment);
};

export const deleteDraftShipment = (id) => {
  const url = `shipments/delete/${id}`;
  return axiosRequest.delete(url);
};

export const shipmentService = {
  getShipments,
  getShipmentFilters,
  exportShipmentsCSV,
  getOperators,
  assignOperator,
  createShipment,
  deleteDraftShipment
};
