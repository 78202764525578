import { axiosRequest } from '../utils/request';
import { queryString } from '../utils/formatString';

const fetchMerchantData = async (token) => {
  const queryParams = queryString({ token });
  const url = `/admin/merchants/?${queryParams}`;
  const response = await axiosRequest.get(url).exec();
  return response.data.data;
};
const mapMerchantAgentID = async (merchantID, { agentID, agentName }) => {
  const url = `/admin/merchant-id/`;
  const body = { merchantID, agentID, agentName };
  const response = await axiosRequest.post(url, body).exec();
  return response.data;
};
const blockUnblockUser = async (email, status) => {
  const url = `/admin/user`;
  const body = { email, status };
  const response = await axiosRequest.patch(url, body).exec();
  return response.data;
};
const createNewUser = async (data) => {
  const url = `/admin/user`;
  const response = await axiosRequest.post(url, data).exec();
  return response.data;
};
const createNewMerchant = async (data) => {
  const url = `/admin/merchant`;
  const response = await axiosRequest.post(url, data).exec();
  return response.data;
};
export const adminService = {
  fetchMerchantData,
  mapMerchantAgentID,
  blockUnblockUser,
  createNewUser,
  createNewMerchant
};
