import { createAction } from '@reduxjs/toolkit';
import { shipmentService } from '../services/shipments';

export const fetchShipments = createAction('shipments/FETCH_DATA');
export const fetchShipmentsLoading = createAction(
  'shipments/FETCH_DATA_LOADING'
);

export const fetchChoices = createAction('shipmentChoices/FETCH_DATA');
export const fetchChoicesLoading = createAction(
  'shipmentChoices/FETCH_DATA_LOADING'
);

export const setColumns = createAction('quotesFilter/SET_COLUMNS');
export const setPageFilter = createAction('shipmentFilter/SET_PAGE');
export const setPageSizeFilter = createAction('shipmentFilter/SET_PAGE_SIZE');
export const setShipmentFilters = createAction('shipmentFilter/SET_FILTERS');
export const setShipmentRangeFilters = createAction('shipmentFilter/SET_RANGE_FILTERS');
export const applyFilters = createAction('shipmentFilter/APPLY_FILTERS');
export const cancelFilters = createAction('shipmentFilter/CANCEL_FILTERS');
export const resetFilters = createAction('shipmentFilter/RESET_FILTERS');

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading());
  const choicesData = await shipmentService.getShipmentFilters();
  const operators = await shipmentService.getOperators();
  dispatch(fetchChoices({ ...choicesData, operators: operators.data }));
};

export const getShipments = (params = {}) => async (dispatch) => {
  dispatch(fetchShipmentsLoading());
  shipmentService.getShipments(params).then((data) => {
    dispatch(fetchShipments(data));
  });
};
