import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
// import { Affix, Button } from 'antd';
import { withRouter } from 'react-router-dom';
// import Chatbox from '../components/Chatbox';
// import UnreadIcon from '../assets/icons/unreadIcon.svg';

import {
  Card,
  ShipmentHistory,
  QuoteHeaderTitle,
  QuoteDetailsTabs
} from '../components';
import { unloadQuoteDetails, getQuoteDetail } from '../actions/quoteDetail';
import { getCostBillingDetails } from '../actions/costBillingDetail';

const StyledQuotesDetail = styled.div`
  // max-width: 1200px;
  margin: 0 40px;
  overflow-x: hidden;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;

// const StyledChatBox = styled.div`
//   .chat-button {
//     width: 200px;
//     height: 30px;

//     background-color: #d2f5f4;
//     box-shadow: 3px 6px 3px rgba(0, 0, 0, 0.161);
//     border: 0;
//     border-radius: 4px 4px 0px 0px;

//     color: #050593;
//     font-size: 16px;
//     font-family: AvenirLTStdBook;

//     img {
//       width: 20px;
//       height: 20px;
//       margin-left: 10px;
//     }
//   }
// `;

const ShipmentDetail = (props) => {
  const { match = {} } = props;
  const [loading, setLoading] = useState(true);
  // const [show, setShow] = useState(false);
  // const [badge, setBadge] = useState(false);
  const dispatch = useDispatch();
  const quoteId = match?.params?.quoteId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getQuoteDetail(quoteId));
    dispatch(getCostBillingDetails(quoteId));
    return () => {
      dispatch(unloadQuoteDetails());
    };
  }, [dispatch, quoteId]);

  const acceptQuote = async () => {};

  const quoteStatus = useSelector(
    (state) => state?.quoteDetail?.quote?.quote_status
  );

  // const handleBadge = (value) => setBadge(value);
  // const handleChatBox = () => setShow((prevState) => !prevState);

  return (
    <StyledQuotesDetail>
      <StyledCard>
        {!loading && (
          <QuoteHeaderTitle
            acceptQuote={acceptQuote}
            quoteStatus={quoteStatus}
          />
        )}
        <QuoteDetailsTabs
          quoteId={quoteId}
          quoteStatus={quoteStatus}
          onLoading={(isLoading) => {
            setLoading(isLoading);
          }}
        />
      </StyledCard>

      <ShipmentHistory quoteId={quoteId} heading="Shipment" />

      {/* <StyledChatBox>
        <Affix
          style={{
            position: 'fixed',
            bottom: 0,
            right: 100,
            minWidth: '30%',
            display: show ? 'block' : 'none'
          }}
        >
          <Chatbox onMinimize={handleChatBox} onBadge={handleBadge} />
        </Affix>

        <Affix
          style={{
            position: 'fixed',
            bottom: 0,
            right: 100,
            display: !show ? 'block' : 'none'
          }}
        >
          <Button className="chat-button" onClick={handleChatBox}>
            + Shipment Chat {badge && <img alt="I" src={UnreadIcon} />}
          </Button>
        </Affix>
      </StyledChatBox> */}
    </StyledQuotesDetail>
  );
};
export default withRouter(ShipmentDetail);
