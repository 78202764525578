import React, { useState } from 'react';
import { Tabs, Card, Row, Col, Upload, Divider, Badge } from 'antd';
import styled from '@emotion/styled';
import Papa from 'papaparse';
import { Button, Input } from '../components';
import {
  CartonLoading,
  CartonOperation,
  TrackingReceive
} from '../components/ShopeeIntergration';

const { TabPane } = Tabs;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 0 10px;
  color: #000000;
  font-family: AvenirLTStdBook;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 15px;
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
  }
`;

const ShopeeIntegration = () => {
  const [mawbNo, setMawbNo] = useState('');
  const [filename, setFilename] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [activeKey, setActiveKey] = useState('1');

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleFile = (newFile) => {
    setFilename(newFile.name);

    Papa.parse(newFile, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        setParsedData(results.data);
      }
    });

    return false;
  };

  return (
    <StyledCard>
      <Row gutter={[24, 16]}>
        <Col span={12}>
          <p>
            <Badge status="error" />
            Master Air WayBill No. (MAWB)
          </p>
          <Input
            placeholder="Type ..."
            value={mawbNo}
            disabled={activeKey !== '2' || false}
            maxLength={64}
            onChange={(e) => {
              setMawbNo(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <p>Attachment</p>
          <Upload
            style={{ width: 'auto' }}
            accept=".csv"
            showUploadList={false}
            beforeUpload={handleFile}
          >
            <Button
              type="secondary"
              style={{ width: 'auto' }}
              icon={filename ? 'close' : 'upload'}
            >
              {filename || 'Upload'}
            </Button>
          </Upload>
        </Col>
      </Row>

      <Divider style={{ marginTop: '35px' }} />

      <StyledTabs onChange={handleTabChange} activeKey={activeKey}>
        <TabPane tab="Carton Operation" key="1">
          <CartonOperation data={parsedData} />
        </TabPane>
        <TabPane tab="Carton Loading" key="2">
          <CartonLoading mawbNo={mawbNo} data={parsedData} />
        </TabPane>
        <TabPane tab="Receive Tracking" key="3">
          <TrackingReceive data={parsedData} />
        </TabPane>
      </StyledTabs>
    </StyledCard>
  );
};

export default ShopeeIntegration;
