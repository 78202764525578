import React from 'react';
import styled from '@emotion/styled';

const Rates = styled.div`
  p {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #000;
      list-style-type: none;
    }
  }
`;

const ExchangeRates = ({ target, rates = new Map(), showLabel = true }) => {
  const formatMoney = (rate) => {
    const option = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 4
    };

    if (rate < 0.0001) {
      option.minimumFractionDigits = 6;
    } else if (rate < 0.001) {
      option.minimumFractionDigits = 5;
    }

    return new Intl.NumberFormat('en-US', option).format(rate).slice(1);
  };
  return (
    <Rates>
      {!!showLabel && <p>Exchange Rates</p>}
      <ul>
        {[...rates].map(([currency, rate]) => (
          <li>
            {currency} 1 = {target} {formatMoney(rate)}
          </li>
        ))}
      </ul>
    </Rates>
  );
};

export default ExchangeRates;
