import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';
import { message, Row, Col } from 'antd';
import _ from 'lodash';
import Modal from './Modal';
import Select from './Select';
import TextArea from './TextArea';
import Button from './Button';
import Message from './Message';
import Input from './Input';
import DatePicker from './DatePicker';
import { updateQuoteStatus, getQuoteDetail } from '../actions/quoteDetail';
import {
  TEXT_QUOTE_STATUS_ERROR,
  TEXT_QUOTE_STATUS_SUCCESS
} from '../constants/message';
import { CANCELLED } from '../constants/status';
import getPortsList from '../helpers/portList';
import SearchInput from './SearchInput';

const { Option } = Select;

const StatusDropdown = styled(Select)`
  width: 300px;
  display: inline-block;
  margin-right: 10px;
`;
const statuses = {
  SHIPMENT_CONFIRMED: 'Shipment Confirmed',
  SCHEDULED_FOR_PICKUP: 'Scheduled for Pickup',
  SCHEDULED_FOR_DROPOFF: 'Scheduled for Dropoff',
  RESCHEDULED_FOR_PICKUP: 'Rescheduled for Pickup',
  RESCHEDULED_FOR_DROPOFF: 'Rescheduled for Dropoff',
  SHIPMENT_PICKEDUP: 'Shipment Picked Up',
  REACHED_ORIGIN_WAREHOUSE: 'Reached Origin Warehouse',
  CARGO_DELIVERED_TO_AIRLINES_TERMINAL: 'Cargo delivered to Airlines Terminal',
  FLIGHT_RESCHEDULED: 'Flight Rescheduled',
  CONTAINER_LOADING: 'Container Loading',
  RECEIVED_BY_AIRLINES: 'Received by Airlines',
  CARGO_ONBOARD_VESSEL: 'Cargo Onboard Vessel',
  ON_SAIL: 'On Sail',
  PARTIAL_UPLIFT_TO_DESTINATION: 'Partial Uplift to Destination',
  FREIGHT_UPLIFT_COMPLETED: 'Freight Uplift Completed',
  OFFLOADED_BY_AIRLINES: 'Offloaded By Airlines',
  IN_TRANSHIPMENT_COUNTRY: 'In Transhipment Country',
  CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY:
    'Custom Clearance in Destination Country',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  RESCHEDULED_FOR_DELIVERY: 'Rescheduled for Delivery',
  DELIVERY_FAILED: 'Delivery Failed',
  DELIVERED_WITHOUT_POD: 'Delivered without POD',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  DELIVERED: 'Delivered',
  BILLED: 'Billed',
  VESSEL_DELAYED: 'Vessel Delayed',
  SPACE_BOOKING_IN_PROGRESS: 'Space Booking In Progress'
};
const StyledShipmentTrackingStatus = styled.div`
  padding: 0 14px;
`;

const TitleReject = styled.span`
  color: #d5152f;
`;
const TitleUpdateModal = styled.span`
  color: #050593;
`;
const StyledModalContent = styled.div`
  padding: 0 5px 5px 5px;
`;
const StatusSubTitle = styled.p`
  font-size: 16px;
  font-family: AvenirLTStdHeavy;
  margin-bottom: 15px;
`;
const StatusInfoTitle = styled.p`
  font-family: AvenirLTStdHeavy;
  font-size: 14px;
  color: #707070;
`;
const StatusInfoValue = styled.p`
  color: #000000;
  font-size: 16px;
`;
const StatusModalForm = styled.div`
  display: flex;
  justify-content: space-between;
`;
const H5 = styled.h5`
  margin-bottom: 20px;
  color: #000000;
`;
const InformationDiv = styled.div`
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 20px 0 10px 0;
  padding: 20px 0 10px 0;

  .info-row {
    & > ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;
      margin-left: -10px;
      margin-right: -10px;
      & > li {
        margin-bottom: 15px;
        width: 50%;
        padding: 0 10px;
      }
    }
  }
`;

const reducer = (state, value) => ({ ...state, ...value });
// TODO: implement loading state and fetch data
const ShipmentTrackingStatus = () => {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const status = useSelector(
    (state) => state?.quoteDetail?.quote?.quote_status
  );
  const quoteDetail = useSelector((state) => state?.quoteDetail?.quote);
  const statusDate = useSelector(
    (state) => state?.quoteDetail?.quote?.shipment_status_updated_at
  );
  const quoteId = useSelector((state) => state?.quoteDetail?.quote?.quote_id);
  const [statusValue, setStatusValue] = useState(undefined);
  const [statusDateValue, setStatusDateValue] = useState();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonError, setCancelReasonError] = useState('');
  const [updateStatusError, setUpdateStatusError] = useState({});
  const [vehicleInformation, setVehicleInformation] = useReducer(reducer, {
    import_awb: quoteDetail.import_awb,
    export_awb: quoteDetail.export_awb,
    origin_port: quoteDetail.origin?.port,
    destination_port: quoteDetail.destination?.port,
    estimated_arrival_time: quoteDetail.estimated_arrival_time,
    estimated_departure_time: quoteDetail.estimated_departure_time,
    vehicle_number: quoteDetail.vehicle_number
  });
  const getInfoTitle = () => {
    switch (quoteDetail.shipment_mode) {
      case 'Sea':
        return 'Vessel';
      case 'Air':
        return 'Flight';
      default:
        return 'Truck';
    }
  };
  const informationTitle = getInfoTitle();
  const statusOptions = [];
  const statusTillReachedWarehouse = [
    'SHIPMENT_CONFIRMED',
    'SCHEDULED_FOR_PICKUP',
    'SCHEDULED_FOR_DROPOFF',
    'RESCHEDULED_FOR_PICKUP',
    'RESCHEDULED_FOR_DROPOFF',
    'SHIPMENT_PICKEDUP',
    'REACHED_ORIGIN_WAREHOUSE',
    'BILLED',
    'VESSEL_DELAYED',
    'SPACE_BOOKING_IN_PROGRESS'
  ];
  const originPortList = getPortsList(
    quoteDetail?.shipment_mode,
    quoteDetail?.origin?.country
  );
  const destinationPortList = getPortsList(
    quoteDetail?.shipment_mode,
    quoteDetail?.destination?.country
  );

  useEffect(() => {
    if (isUpdateModalVisible) {
      setVehicleInformation({
        import_awb: quoteDetail.import_awb,
        export_awb: quoteDetail.export_awb,
        origin_port: quoteDetail.origin?.port,
        destination_port: quoteDetail.destination?.port,
        estimated_arrival_time: quoteDetail.estimated_arrival_time,
        estimated_departure_time: quoteDetail.estimated_departure_time,
        vehicle_number: quoteDetail.vehicle_number
      });
    }
    setUpdateStatusError({});
  }, [isUpdateModalVisible, quoteDetail]);

  Object.entries(statuses).forEach(([key, value]) => {
    statusOptions.push(
      <Option key={key} value={key}>
        {value}
      </Option>
    );
  });
  const updateStatus = async (
    newStatus,
    reason,
    date = null,
    userTimeZoneOffset = 0,
    otherInformation = null
  ) => {
    try {
      setUpdating(true);
      await dispatch(
        updateQuoteStatus({
          id: quoteId,
          status: newStatus,
          reason,
          updateDate: date,
          userTimeZoneOffset,
          otherInformation
        })
      );
      if (otherInformation) {
        await dispatch(getQuoteDetail(quoteId));
      }
      setUpdating(false);
      message.success(TEXT_QUOTE_STATUS_SUCCESS);
    } catch (error) {
      const result = error?.response?.data?.result;
      setUpdating(false);
      message.error(result || TEXT_QUOTE_STATUS_ERROR);
      return null;
    }
    return null;
  };
  const handleSetCancelReason = (e) => {
    setCancelReason(e.target.value);
  };
  const handleCancelShipment = () => {
    if (cancelReason) {
      const date = moment()
        .endOf('day')
        .toISOString();
      const userTimeZoneOffset = moment().utcOffset();
      updateStatus(CANCELLED, cancelReason, date, userTimeZoneOffset);
      setCancelReasonError('');
    } else {
      setCancelReasonError('Please enter Reason for Cancellation.');
    }
  };

  const getLabelMapping = () => {
    switch (quoteDetail.shipment_mode) {
      case 'Sea':
        return {
          import_awb: 'OOBL',
          export_awb: 'HBL',
          origin_port: 'Origin Port',
          destination_port: 'Destination Port',
          estimated_arrival_time: 'Estimated Time of Arrival (ETA)',
          estimated_departure_time: 'Estimated Time of Departure (ETD)',
          vehicle_number: 'Vessel Number'
        };
      case 'Air':
        return {
          import_awb: 'MAWB',
          export_awb: 'HAWB',
          origin_port: 'Origin Airport',
          destination_port: 'Destination Airport',
          estimated_arrival_time: 'Estimated Time of Arrival (ETA)',
          estimated_departure_time: 'Estimated Time of Departure (ETD)',
          vehicle_number: 'Flight Number'
        };
      default:
        return {
          import_awb: 'D/O Number',
          origin_port: 'Origin Port',
          destination_port: 'Destination Port',
          estimated_arrival_time: 'Estimated Time of Arrival (ETA)',
          estimated_departure_time: 'Estimated Time of Departure (ETD)',
          vehicle_number: 'Truck Number'
        };
    }
  };
  const infoLabelMapping = getLabelMapping();

  const ERROR_MESSAGES = {
    status: 'Please select a valid status',
    date: 'Please select a valid date',
    estimated_arrival_time: 'Please select a valid date',
    estimated_departure_time: 'Please select a valid date',
    vehicle_number: `Please enter ${infoLabelMapping.vehicle_number}`,
    import_awb: `Please enter ${infoLabelMapping.import_awb}`,
    export_awb: `Please enter ${infoLabelMapping.export_awb}`,
    origin_port: `Please select ${infoLabelMapping.origin_port}`,
    destination_port: `Please select ${infoLabelMapping.destination_port}`
  };

  const validateInformation = () => {
    const { shipment_mode } = quoteDetail;
    let isValid = true;
    const error = {};
    if (!statusValue) {
      isValid = false;
      error.status = ERROR_MESSAGES.status;
    }
    if (!statusDateValue) {
      isValid = false;
      error.date = ERROR_MESSAGES.date;
    }
    if (statusValue && statusTillReachedWarehouse.indexOf(statusValue) === -1) {
      _.forIn(vehicleInformation, function(value, key) {
        if (
          shipment_mode !== 'Land' ||
          (shipment_mode === 'Land' && key !== 'export_awb')
        ) {
          if (!value) {
            isValid = false;
            _.set(error, key, ERROR_MESSAGES[key]);
          }
        }
      });
    }
    setUpdateStatusError(error);
    return isValid;
  };
  const onInformationChanged = (key, value) => {
    const updateStatusErrorObj = { ...updateStatusError };
    if (value) {
      _.set(updateStatusErrorObj, key, '');
    } else {
      _.set(updateStatusErrorObj, key, ERROR_MESSAGES[key]);
    }
    setUpdateStatusError(updateStatusErrorObj);
    setVehicleInformation({ [key]: value });
  };
  const updateStatusModal = () => {
    if (quoteDetail.shipment_mode === 'Air') {
      const date = statusDateValue?.endOf('day').toISOString();
      const userTimeZoneOffset = statusDateValue.utcOffset();
      updateStatus(statusValue, '', date, userTimeZoneOffset);
      setIsUpdateModalVisible(false);
    } else if (validateInformation()) {
      const date = statusDateValue?.endOf('day').toISOString();
      const userTimeZoneOffset = statusDateValue.utcOffset();
      let otherInformation = {};
      if (
        statusValue &&
        statusTillReachedWarehouse.indexOf(statusValue) === -1
      ) {
        otherInformation = vehicleInformation;
      }
      updateStatus(statusValue, '', date, userTimeZoneOffset, otherInformation);
      setIsUpdateModalVisible(false);
    }
  };
  return (
    <>
      <Modal
        title={<TitleReject>Cancel Shipment</TitleReject>}
        visible={isCancelModalVisible}
        closable={false}
        footer={[
          <Button
            key="back"
            type="secondary"
            onClick={() => {
              setIsCancelModalVisible(false);
              setCancelReasonError('');
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" onClick={handleCancelShipment}>
            Confirm
          </Button>
        ]}
      >
        <StyledModalContent>
          <p>Reason for cancellation</p>
          <TextArea placeholder="Type Here" onChange={handleSetCancelReason} />
          <Message type="error">{cancelReasonError}</Message>
        </StyledModalContent>
      </Modal>
      <Modal
        title={<TitleUpdateModal>Update Status</TitleUpdateModal>}
        visible={isUpdateModalVisible}
        closable={false}
        width="680px"
        footer={[
          <Button
            key="back"
            type="secondary"
            onClick={() => {
              setIsUpdateModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" onClick={updateStatusModal}>
            Update
          </Button>
        ]}
      >
        <StyledModalContent>
          <p style={{ marginBottom: 20 }}>
            After the tracking is changed, an email will be delivered to
            subscribed emails to update them of the new tracking status of this
            shipment.
          </p>
          <StatusModalForm>
            <div>
              <p>Tracking Status</p>
              <StatusDropdown
                placeholder="Select..."
                allowClear
                onChange={(value) => {
                  const updateStatusErrorObj = { ...updateStatusError };
                  if (value) {
                    _.set(updateStatusErrorObj, 'status', '');
                  } else {
                    _.set(
                      updateStatusErrorObj,
                      'status',
                      ERROR_MESSAGES.status
                    );
                  }
                  setUpdateStatusError(updateStatusErrorObj);
                  setStatusValue(value);
                }}
              >
                {statusOptions}
              </StatusDropdown>
              <Message type="error">
                {_.get(updateStatusError, 'status', '')}
              </Message>
            </div>
            <div>
              <p>Date of Tracking Status</p>
              <DatePicker
                style={{ width: 300 }}
                id="pickup-date"
                onChange={(val) => {
                  const updateStatusErrorObj = { ...updateStatusError };
                  if (val) {
                    _.set(updateStatusErrorObj, 'date', '');
                  } else {
                    _.set(updateStatusErrorObj, 'date', ERROR_MESSAGES.date);
                  }
                  setUpdateStatusError(updateStatusErrorObj);
                  setStatusDateValue(val);
                }}
              />
              <Message type="error">
                {_.get(updateStatusError, 'date', '')}
              </Message>
            </div>
          </StatusModalForm>
          {quoteDetail.shipment_mode !== 'Air' &&
            statusValue &&
            statusTillReachedWarehouse.indexOf(statusValue) === -1 && (
              <InformationDiv>
                <H5>{informationTitle} Information</H5>
                <div className="info-row">
                  <ul>
                    <li>
                      <p>{infoLabelMapping.import_awb}</p>
                      <Input
                        value={_.get(vehicleInformation, 'import_awb', '')}
                        placeholder="Type here"
                        onChange={(event) => {
                          const val = event.target.value;
                          onInformationChanged('import_awb', val);
                        }}
                      />
                      <Message type="error">
                        {_.get(updateStatusError, 'import_awb', '')}
                      </Message>
                    </li>
                    {infoLabelMapping.export_awb && (
                      <>
                        <li>
                          <p>{infoLabelMapping.export_awb}</p>
                          <Input
                            value={_.get(vehicleInformation, 'export_awb', '')}
                            placeholder="Type here"
                            onChange={(event) => {
                              const val = event.target.value;
                              onInformationChanged('export_awb', val);
                            }}
                          />
                          <Message type="error">
                            {_.get(updateStatusError, 'export_awb', '')}
                          </Message>
                        </li>
                      </>
                    )}
                    <li>
                      <p>{infoLabelMapping.origin_port}</p>
                      {quoteDetail.shipment_mode !== 'Land' ? (
                        <>
                          <SearchInput
                            value={_.get(
                              vehicleInformation,
                              'origin_port',
                              undefined
                            )}
                            onChange={(selectedValue) => {
                              onInformationChanged(
                                'origin_port',
                                selectedValue
                              );
                            }}
                            allowClear
                            placeholder="Select..."
                            loading={false}
                            items={originPortList}
                          />
                          <Message type="error">
                            {_.get(updateStatusError, 'origin_port', '')}
                          </Message>
                        </>
                      ) : (
                        <>
                          {' '}
                          <Input
                            value={_.get(vehicleInformation, 'origin_port', '')}
                            placeholder="Type here"
                            onChange={(event) => {
                              const val = event.target.value;
                              onInformationChanged('origin_port', val);
                            }}
                          />
                          <Message type="error">
                            {_.get(updateStatusError, 'origin_port', '')
                              ? 'Please enter Origin Port'
                              : ''}
                          </Message>
                        </>
                      )}
                    </li>
                    <li>
                      <p>{infoLabelMapping.destination_port}</p>
                      {quoteDetail.shipment_mode !== 'Land' ? (
                        <>
                          <SearchInput
                            value={_.get(
                              vehicleInformation,
                              'destination_port',
                              undefined
                            )}
                            onChange={(selectedValue) => {
                              onInformationChanged(
                                'destination_port',
                                selectedValue
                              );
                            }}
                            allowClear
                            placeholder="Select..."
                            loading={false}
                            items={destinationPortList}
                          />
                          <Message type="error">
                            {_.get(updateStatusError, 'destination_port', '')}
                          </Message>
                        </>
                      ) : (
                        <>
                          <Input
                            value={_.get(
                              vehicleInformation,
                              'destination_port',
                              ''
                            )}
                            placeholder="Type here"
                            onChange={(event) => {
                              const val = event.target.value;
                              onInformationChanged('destination_port', val);
                            }}
                          />
                          <Message type="error">
                            {_.get(updateStatusError, 'destination_port', '')
                              ? 'Please enter Destination Port'
                              : ''}
                          </Message>
                        </>
                      )}
                    </li>
                    <li>
                      <p>{infoLabelMapping.estimated_departure_time}</p>
                      <DatePicker
                        style={{ width: 300 }}
                        value={
                          _.get(
                            vehicleInformation,
                            'estimated_departure_time',
                            null
                          )
                            ? moment(
                                vehicleInformation.estimated_departure_time
                              )
                            : undefined
                        }
                        disabledDate={(date) => {
                          if (!vehicleInformation.estimated_arrival_time) {
                            return false;
                          }
                          return date.isAfter(
                            vehicleInformation.estimated_arrival_time,
                            'day'
                          );
                        }}
                        onChange={(...args) => {
                          onInformationChanged(
                            'estimated_departure_time',
                            args[1]
                          );
                        }}
                      />
                      <Message type="error">
                        {_.get(
                          updateStatusError,
                          'estimated_departure_time',
                          ''
                        )}
                      </Message>
                    </li>
                    <li>
                      <p>{infoLabelMapping.estimated_arrival_time}</p>
                      <DatePicker
                        style={{ width: 300 }}
                        value={
                          _.get(
                            vehicleInformation,
                            'estimated_arrival_time',
                            null
                          )
                            ? moment(vehicleInformation.estimated_arrival_time)
                            : undefined
                        }
                        disabledDate={(date) => {
                          if (!vehicleInformation.estimated_departure_time) {
                            return false;
                          }
                          return date.isBefore(
                            vehicleInformation.estimated_departure_time,
                            'day'
                          );
                        }}
                        onChange={(...args) => {
                          onInformationChanged(
                            'estimated_arrival_time',
                            args[1]
                          );
                        }}
                      />
                      <Message type="error">
                        {_.get(updateStatusError, 'estimated_arrival_time', '')}
                      </Message>
                    </li>
                    <li>
                      <p>{infoLabelMapping.vehicle_number}</p>
                      <Input
                        value={_.get(vehicleInformation, 'vehicle_number', '')}
                        placeholder="Type here"
                        onChange={(event) => {
                          const val = event.target.value;
                          onInformationChanged('vehicle_number', val);
                        }}
                      />
                      <Message type="error">
                        {_.get(updateStatusError, 'vehicle_number', '')}
                      </Message>
                    </li>
                  </ul>
                </div>
              </InformationDiv>
            )}
        </StyledModalContent>
      </Modal>
      <StyledShipmentTrackingStatus id="tracking-status">
        <StatusSubTitle>Current Status</StatusSubTitle>
        <Row gutter={[24, 16]} justify="space-between">
          <Col span={8} xxl={{ span: 6 }}>
            <StatusInfoTitle>Tracking Status</StatusInfoTitle>
            <StatusInfoValue>{statuses[status]}</StatusInfoValue>
          </Col>
          <Col span={8} xxl={{ span: 6 }}>
            <StatusInfoTitle>Date of Status</StatusInfoTitle>
            <StatusInfoValue>
              {moment(statusDate).format('DD MMMM YYYY')}
            </StatusInfoValue>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 30 }}>
          <Col span={12}>
            <Button
              type="secondary"
              loading={updating}
              onClick={() => setIsUpdateModalVisible(true)}
            >
              Update Status
            </Button>
          </Col>
          <Col span={12} />
        </Row>
      </StyledShipmentTrackingStatus>
    </>
  );
};

export default ShipmentTrackingStatus;
