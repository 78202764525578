/* eslint-disable func-names */
import * as Yup from 'yup';

export const isValidNumber = (value) => {
  if (value == null || value === '') return true;
  return /^[0-9]+(\.[0-9]+)?$/.test(value);
};

export const isValidInteger = (value) => {
  if (value == null || value === '') return true;
  return /^[0-9]+$/.test(value);
};

export const isValidDecimalPlaces = (value) => {
  if (value == null || value === '') return true;
  if (!isValidNumber(value)) return true;
  return /^[0-9]+(\.[0-9]{1,3})?$/.test(value);
};

export const initValidators = () => {
  Yup.addMethod(Yup.mixed, 'validNumber', function(
    msg = 'Please enter positive numbers.'
  ) {
    return this.test('validNumber', msg, isValidNumber);
  });
  Yup.addMethod(Yup.mixed, 'validInteger', function(
    msg = 'Please enter positive integers.'
  ) {
    return this.test('validInteger', msg, isValidInteger);
  });
  Yup.addMethod(Yup.mixed, 'validDecimalPlaces', function(
    msg = 'Please do not enter more than 3 decimal places.'
  ) {
    return this.test('validDecimalPlaces', msg, isValidDecimalPlaces);
  });
  Yup.addMethod(Yup.mixed, 'minUnit', function(
    defUnit,
    [max1 = -1, max2 = -1],
    unitLabels = []
  ) {
    return this.test('minUnit', '', function(value) {
      const { path, parent, createError } = this;
      const min = parent.unit === defUnit ? max1 : max2;
      let unitLabel = parent.unit || [defUnit, defUnit];
      if (unitLabel.length > 0) {
        unitLabel = parent.unit === defUnit ? unitLabels[0] : unitLabels[1];
      }
      if (min === -1) return true;
      if (value == null) return true;
      if (parseFloat(value, 10) < min) {
        return createError({
          path,
          message: `Minimum is ${min} ${unitLabel}`
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.mixed, 'min', function(unit, min) {
    return this.test('min', '', function(value) {
      const { path, createError } = this;
      if (min === -1) return true;
      if (value == null) return true;
      if (!isValidNumber(value)) return true;
      if (parseFloat(value, 10) < min) {
        return createError({
          path,
          message: `Minimum is ${min} ${unit}`
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.mixed, 'maxUnit', function(
    defUnit,
    [max1 = -1, max2 = -1],
    unitLabels = []
  ) {
    return this.test('maxUnit', '', function(value) {
      const { path, parent, createError } = this;
      const max = parent.unit === defUnit ? max1 : max2;
      let unitLabel = parent.unit || [defUnit, defUnit];
      if (unitLabel.length > 0) {
        unitLabel = parent.unit === defUnit ? unitLabels[0] : unitLabels[1];
      }
      if (value == null) return true;
      if (max === -1) return true;
      if (parseFloat(value, 10) > max) {
        return createError({
          path,
          message: `Maximum is ${max} ${unitLabel}`
        });
      }
      return true;
    });
  });
  Yup.addMethod(Yup.mixed, 'max', function(unit, max) {
    return this.test('max', '', function(value) {
      const { path, createError } = this;
      if (value == null) return true;
      if (max === -1) return true;
      if (!isValidNumber(value)) return true;
      if (parseFloat(value, 10) > max) {
        return createError({
          path,
          message: `Maximum is ${max} ${unit}`
        });
      }
      return true;
    });
  });
};
