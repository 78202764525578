import React, { useEffect, useState } from 'react';
import { Result, Tooltip, Typography } from 'antd';
import Button from '../Button';
import Modal from '../Modal';

const { Paragraph, Text } = Typography;

const ErrorMessage = ({
  message = '',
  visible = false,
  setVisible = () => {}
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [tooltip, setTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(errorMessage);
    setTooltip(true);
    setTimeout(() => setTooltip(false), 2000);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setErrorMessage('');
  };

  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  return (
    <Modal
      width={820}
      visible={visible}
      onCancel={onCancel}
      afterClose={afterClose}
      destroyOnClose
      footer={null}
    >
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={[
          <Tooltip
            key={new Date()}
            visible={tooltip}
            placement="top"
            title="Copied!"
          >
            <Button type="secondary" onClick={copyToClipboard}>
              Copy to clipboard
            </Button>
          </Tooltip>
        ]}
      >
        <div className="desc">
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>{errorMessage}</Paragraph>
        </div>
      </Result>
    </Modal>
  );
};

export default ErrorMessage;
