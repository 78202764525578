import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import SearchInput from '../SearchInput';
import NumberInput from '../NumberInput';
import Message from '../Message';
import FullLoadSeals from './FullLoadSeals';
import { initValidators } from '../../helpers/validation';

initValidators();

const Container = styled.div`
  .load-row {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #cccccc;
  }
  .ant-input-number {
    width: 100%;
  }
  .delete-load {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    transition: none;
  }
  .delete-load:hover,
  .delete-load:focus {
    border-color: #050593;
    color: #050593;
  }
  &:first-of-type {
    padding-top: 30px;
  }
  &:last-of-type {
    .load-row {
      border-bottom: none;
    }
  }
`;

const FullLoadInput = ({
  value,
  label,
  fullLessLoad,
  showDelete = true,
  container = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {},
  loadTypes = {},
  loading = false,
  isQuote = false
}) => {
  const type = container ? 'container' : 'truck';
  const [loadState, setLoadState] = useState(() => {
    const { seals, ...values } = value;
    return values;
  });
  const [loadSeals, setLoadSeals] = useState(value.seals);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema =
    fullLessLoad === 'Full'
      ? Yup.object().shape({
          type: Yup.string()
            .nullable()
            .required(`Please enter type of ${type}`),
          amount: Yup.mixed()
            .nullable()
            .test('amount', `Please enter no. of ${type}s`, (val) => {
              return val;
            })
            .validNumber()
            .min('', 1)
            .max('', 50)
        })
      : Yup.object().shape({});

  const validate = () => {
    validationSchema
      .validate(loadState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          return {
            ...ers,
            [er.path]: er.message
          };
        }, {});
        setErrors(errs);
      });
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }
    onChange({ ...loadState, seals: loadSeals }, errors);
  }, [loadState, loadSeals, errors]);

  useEffect(() => {
    if (dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  const handleChange = (name) => (e) => {
    dirty.current = true;
    switch (name) {
      case 'type':
        setLoadState({ ...loadState, type: e });
        break;
      case 'amount':
        setLoadState({ ...loadState, amount: e });
        break;
      case 'seals':
        setLoadSeals(e);
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur({ ...loadState, seals: loadSeals }, errors);
  };

  return (
    <Container>
      <div className="load-row">
        <h5>
          {fullLessLoad === 'Full' && label}
          {showDelete && (
            <Button className="delete-load" icon="delete" onClick={onDelete} />
          )}
        </h5>
        {fullLessLoad === 'Full' && (
          <Row gutter={20}>
            <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
              <p>Type of {type} </p>
              <SearchInput
                placeholder="Select"
                items={(loadTypes[`${type}s`] || []).map((item) => ({
                  label: item.value,
                  value: item.key
                }))}
                value={loadState?.type}
                loading={loading}
                onChange={handleChange('type')}
                onBlur={handleBlur}
              />
              {errors?.type && dirty.current && (
                <Message type="error">{errors?.type}</Message>
              )}
            </Col>
            <Col span={isQuote ? 6 : 12} xxl={{ span: 6 }}>
              <p>No. of {type}s</p>
              <NumberInput
                placeholder="How much"
                value={loadState?.amount}
                onChange={handleChange('amount')}
                onBlur={handleBlur}
              />
              {errors?.amount && dirty.current && (
                <Message type="error">{errors?.amount}</Message>
              )}
            </Col>
          </Row>
        )}
        <FullLoadSeals
          seals={loadSeals}
          loadType={fullLessLoad}
          amount={loadState.amount <= 50 ? loadState.amount : 50}
          type={type}
          onChange={handleChange('seals')}
          onBlur={handleBlur}
          isQuote={isQuote}
        />
      </div>
    </Container>
  );
};
export default FullLoadInput;
