import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { message, Input as AntInput } from 'antd';
import _ from 'lodash';
import Modal from '../Modal';
import Select from '../Select';
import Button from '../Button';
import Message from '../Message';
import Input from '../Input';
import FloatInput from '../FloatInput';

import {
  getRequestQuotesDetail,
  updateQuoteWinLossStatus,
  getQuoteShipmentLog
} from '../../actions/quoteDetail';
import {
  TEXT_QUOTE_STATUS_ERROR,
  TEXT_QUOTE_STATUS_SUCCESS
} from '../../constants/message';
import { WIN, LOSS, QUOTE_STATUS_MAPPING } from '../../constants/status';
import INVOICE_CURRENCIES from '../../constants/invoiceCurrencies';

const { Option } = Select;

const StatusDropdown = styled(Select)`
  width: 300px;
  display: inline-block;
  margin-right: 10px;
`;
const TitleUpdateModal = styled.span`
  color: #050593;
  margin-left: 10px;
`;
const StyledModalContent = styled.div`
  padding: 0 10px 0 10px;
`;
const StatusModalForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
`;
const H5 = styled.h5`
  margin-bottom: 20px;
  color: #000000;
`;
const InformationDiv = styled.div`
  border-bottom: 1px solid #cccccc;
  padding: 20px 0 5px 0;

  .info-row {
    & > ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;
      margin-left: -10px;
      margin-right: -10px;
      & > li {
        margin-bottom: 15px;
        width: 50%;
        padding: 0 10px;
      }
    }
  }
`;

const reducer = (state, value) => ({ ...state, ...value });
const QuoteWinLossModal = ({
  isUpdateModalVisible,
  setIsUpdateModalVisible
}) => {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const quoteDetail = useSelector((state) => state?.quoteRequestDetails?.quote);
  const quoteId = useSelector(
    (state) => state?.quoteRequestDetails?.quote?.quote_id
  );
  const [updateStatusError, setUpdateStatusError] = useState({});
  const [state, setState] = useReducer(reducer, {});

  useEffect(() => {
    if (isUpdateModalVisible) {
      setState({
        quote_status: [WIN, LOSS].includes(quoteDetail?.quote_status)
          ? quoteDetail?.quote_status
          : undefined,
        [WIN]: {
          win_loss_reason:
            quoteDetail?.quote_status !== '' ? quoteDetail?.win_loss_reason : ''
        },
        [LOSS]: {
          win_loss_reason:
            quoteDetail?.quote_status !== ''
              ? quoteDetail?.win_loss_reason
              : '',
          network_partner_cost:
            quoteDetail?.quote_status === LOSS
              ? quoteDetail?.network_partner_cost
              : '',
          total_quote_price:
            quoteDetail?.quote_status === LOSS
              ? quoteDetail?.total_quote_price
              : '',
          np_currency:
            quoteDetail?.quote_status === LOSS
              ? quoteDetail?.np_currency
              : 'USD',
          qp_currency:
            quoteDetail?.quote_status === LOSS
              ? quoteDetail?.qp_currency
              : 'USD'
        }
      });
    }
    setUpdateStatusError({});
  }, [isUpdateModalVisible, quoteDetail]);

  const updateStatus = async (stateData) => {
    try {
      const data = {
        quote_status: stateData.quote_status,
        ...stateData[stateData.quote_status]
      };
      setUpdating(true);
      await dispatch(
        updateQuoteWinLossStatus({
          id: quoteId,
          data
        })
      );
      await dispatch(getRequestQuotesDetail(quoteId));
      await dispatch(getQuoteShipmentLog(quoteId));
      setUpdating(false);
      setIsUpdateModalVisible(false);
      message.success(TEXT_QUOTE_STATUS_SUCCESS);
    } catch (error) {
      const result = error?.response?.data?.result;
      setUpdating(false);
      message.error(result || TEXT_QUOTE_STATUS_ERROR);
      return null;
    }
    return null;
  };

  const ERROR_MESSAGES = {
    quote_status: 'Please select a valid status',
    win_loss_reason: 'Please enter Reason for the Loss',
    network_partner_cost: 'Please enter Total Cost from NP',
    total_quote_price: 'Please enter Total Quote Price'
  };

  const validateState = () => {
    let isValid = true;
    const error = {};
    if (state.quote_status) {
      const data = state[state.quote_status];
      _.forIn(data, function(value, key) {
        if (
          state.quote_status !== WIN ||
          (state.quote_status === WIN && key !== 'win_loss_reason')
        )
          if (!value) {
            isValid = false;
            _.set(error, key, ERROR_MESSAGES[key]);
          }
      });
    } else {
      isValid = false;
      error.quote_status = ERROR_MESSAGES.quote_status;
    }
    setUpdateStatusError(error);
    return isValid;
  };
  const onChange = (key, value) => {
    const updateStatusErrorObj = { ...updateStatusError };
    if (value) {
      _.set(updateStatusErrorObj, key, '');
    } else {
      _.set(updateStatusErrorObj, key, ERROR_MESSAGES[key]);
    }
    const stateObj = { ...state };
    if (key !== 'quote_status') {
      stateObj[state.quote_status][key] = value;
    } else {
      _.set(updateStatusErrorObj, 'win_loss_reason', '');
      stateObj[key] = value;
    }
    setUpdateStatusError(updateStatusErrorObj);
    setState(stateObj);
  };

  const updateStatusModal = () => {
    if (validateState()) {
      updateStatus(state);
    }
  };

  return (
    <Modal
      title={<TitleUpdateModal>Update Quote Status</TitleUpdateModal>}
      visible={isUpdateModalVisible}
      closable={false}
      width="680px"
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => {
            setIsUpdateModalVisible(false);
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" loading={updating} onClick={updateStatusModal}>
          Update
        </Button>
      ]}
    >
      <StyledModalContent>
        <p style={{ marginBottom: 15 }}>
          Please update this quote as either a &apos;win&apos; or a
          &apos;loss&apos;. A quote &apos;win&apos; means that a shipment has
          been successfully created based on this quote, while a
          &apos;loss&apos; means that this quote is ultimately not converted
          into a shipment.
        </p>
        <StatusModalForm>
          <div>
            <p>Quote Win/Loss</p>
            <StatusDropdown
              placeholder="Select..."
              style={{ width: 300 }}
              value={_.get(state, 'quote_status', undefined)}
              allowClear
              onChange={(value) => {
                onChange('quote_status', value);
              }}
            >
              <Option key={WIN} value={WIN}>
                {QUOTE_STATUS_MAPPING[WIN]}
              </Option>
              <Option key={LOSS} value={LOSS}>
                {QUOTE_STATUS_MAPPING[LOSS]}
              </Option>
            </StatusDropdown>
            <Message type="error">
              {_.get(updateStatusError, 'quote_status', '')}
            </Message>
          </div>
          {state.quote_status && [WIN, LOSS].includes(state.quote_status) && (
            <div>
              <p>
                Reason for the {QUOTE_STATUS_MAPPING[state.quote_status]}{' '}
                {state.quote_status === WIN && '(Optional)'}
              </p>
              <Input
                value={_.get(
                  state,
                  [state.quote_status, 'win_loss_reason'],
                  ''
                )}
                placeholder="Type here"
                style={{ width: 300 }}
                onChange={(event) => {
                  const val = event.target.value;
                  onChange('win_loss_reason', val);
                }}
              />
              <Message type="error">
                {_.get(updateStatusError, 'win_loss_reason', '')}
              </Message>
            </div>
          )}
        </StatusModalForm>
        {state.quote_status && state.quote_status === LOSS && (
          <InformationDiv>
            <H5>Quote {QUOTE_STATUS_MAPPING[state.quote_status]}</H5>
            <div className="info-row">
              <ul>
                <li>
                  <p>Total Cost from Network Partner</p>
                  <AntInput.Group compact>
                    <Select
                      style={{
                        display: 'inline-block',
                        width: '30%'
                      }}
                      value={_.get(
                        state,
                        [state.quote_status, 'np_currency'],
                        'USD'
                      )}
                      onChange={(value) => {
                        onChange('np_currency', value);
                      }}
                    >
                      {INVOICE_CURRENCIES.map((currency) => (
                        <Select.Option
                          key={currency.value}
                          value={currency.value}
                        >
                          {currency.value}
                        </Select.Option>
                      ))}
                    </Select>
                    <FloatInput
                      className="site-input-right"
                      placeholder="Type here"
                      value={_.get(
                        state,
                        [state.quote_status, 'network_partner_cost'],
                        ''
                      )}
                      style={{
                        display: 'inline-block',
                        width: '70%'
                      }}
                      onChange={(event) => {
                        const { value } = event.target;
                        onChange('network_partner_cost', value);
                      }}
                    />
                  </AntInput.Group>
                  <Message type="error">
                    {_.get(updateStatusError, 'network_partner_cost', '')}
                  </Message>
                </li>
                <li>
                  <p>Total Quote Price</p>
                  <AntInput.Group compact>
                    <Select
                      style={{
                        display: 'inline-block',
                        width: '30%'
                      }}
                      value={_.get(
                        state,
                        [state.quote_status, 'qp_currency'],
                        'USD'
                      )}
                      onChange={(value) => {
                        onChange('qp_currency', value);
                      }}
                    >
                      {INVOICE_CURRENCIES.map((currency) => (
                        <Select.Option
                          key={currency.value}
                          value={currency.value}
                        >
                          {currency.value}
                        </Select.Option>
                      ))}
                    </Select>
                    <FloatInput
                      className="site-input-right"
                      placeholder="Type here"
                      value={_.get(
                        state,
                        [state.quote_status, 'total_quote_price'],
                        ''
                      )}
                      style={{
                        display: 'inline-block',
                        width: '70%'
                      }}
                      onChange={(event) => {
                        const { value } = event.target;
                        onChange('total_quote_price', value);
                      }}
                    />
                  </AntInput.Group>
                  <Message type="error">
                    {_.get(updateStatusError, 'total_quote_price', '')}
                  </Message>
                </li>
              </ul>
            </div>
          </InformationDiv>
        )}
      </StyledModalContent>
    </Modal>
  );
};

export default QuoteWinLossModal;
