import React, { useEffect, useState } from 'react';
import { Row, Col, message, Divider } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import DatePicker from '../DatePicker';
import SearchInput from '../SearchInput';
import { shopeeService } from '../../services/shopee';
import { removeEmpty } from '../../utils/formatString';
import { SHOPEE_DESTINATION_AIRPORTS } from '../../constants/ports';
import { OP_CODES } from '../../constants/opCodes';
import ErrorMessage from './ErrorMessage';

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;

  input:disabled {
    color: #000000;
    background-color: #ffffff;
  }
`;

const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const initialState = {
  opCode: undefined,
  opTime: '',
  pcs: '',
  ctns: '',
  opLocationCode: undefined,
  cartonNos: []
};

const TrackingReceive = ({ data = [] }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visibleError, setVisibleError] = useState(false);

  const [
    { opCode, opTime, pcs, ctns, opLocationCode, cartonNos },
    setState
  ] = useState(initialState);

  const handleSearchInputChange = (field) => (value, option) => {
    setState((prevState) => ({ ...prevState, [field]: option?.props?.value }));
  };

  const handleDatePickerChange = (name) => (value, dateString) => {
    setState((prevState) => ({ ...prevState, [name]: dateString }));
  };

  const submit = () => {
    const key = `tracking-receive-${Date.now()}`;
    message.loading({ content: 'Processing...', duration: 0, key });
    setLoading(true);

    const emptyObj = removeEmpty({
      opTime,
      pcs,
      ctns,
      opLocationCode
    });

    if (Object.keys(emptyObj).length !== 4) {
      setLoading(false);
      message.error({
        content: 'Please fill in all the details and attach an attachment',
        key
      });
      return;
    }

    const body = {
      packer_code: '110040',
      op_code: opCode,
      op_time: opTime,
      time_zone: 8,
      transport_type: 1,
      op_location_code: opLocationCode,
      ctns,
      pcs
    };

    shopeeService
      .trackingReceive(cartonNos, body)
      .then(() => {
        setLoading(false);
        message.success({ content: 'Success!', key });
      })
      .catch((err) => {
        message.destroy();
        setLoading(false);

        const errMessage =
          err?.message ||
          'Failed to process. Please reach out Janio Technical Team.';

        setErrorMessage(errMessage);
        setVisibleError(true);
      });
  };

  useEffect(() => {
    if (!data.length) {
      return;
    }

    const cartons = [];
    data.forEach((val) => {
      cartons.push(val['Carton No']);
    });

    setState((prevState) => ({
      ...prevState,
      cartonNos: cartons,
      ctns: new Set(cartons).size,
      pcs: data.length
    }));
  }, [data]);

  return (
    <StyledContainer>
      <StyledRow gutter={[16, 24]}>
        <Col span={12}>
          <p>OP Code</p>
          <SearchInput
            id="op-code"
            allowClear
            placeholder="Type or select..."
            items={OP_CODES}
            value={opCode}
            onChange={handleSearchInputChange('opCode')}
          />
        </Col>

        <Col span={12}>
          <p>Total Cartons</p>
          <Input disabled value={ctns} />
        </Col>

        <Col span={12}>
          <p>OP Time</p>
          <DatePicker showTime onChange={handleDatePickerChange('opTime')} />
        </Col>

        <Col span={12}>
          <p>Pieces</p>
          <Input disabled value={pcs} />
        </Col>

        <Col span={12}>
          <p>OP Location Code</p>
          <SearchInput
            id="op-location-code"
            allowClear
            placeholder="Type or select..."
            items={SHOPEE_DESTINATION_AIRPORTS}
            value={opLocationCode}
            onChange={handleSearchInputChange('opLocationCode')}
          />
        </Col>
      </StyledRow>

      <Divider />

      <Col>
        <Button onClick={submit} loading={loading}>
          Submit
        </Button>
      </Col>

      <ErrorMessage
        key={new Date()}
        message={errorMessage}
        visible={visibleError}
        setVisible={setVisibleError}
      />
    </StyledContainer>
  );
};

export default TrackingReceive;
