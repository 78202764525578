/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col, Skeleton as AntSekeleton } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  Table,
  Card,
  ActionBlock,
  ActionLink,
  // ShipmentFilterDropdown,
  ShipmentColumnFilter,
  ColumnDropdown,
  SelectDropdown,
  Button,
  GenerateCSVModal,
  Modal
} from '../components';
import FilterSVG from '../assets/icons/filter.svg';
import FilterSelectedSVG from '../assets/icons/filterSelected.svg';

import {
  getShipments,
  getChoices,
  setPageSizeFilter,
  setPageFilter,
  setShipmentFilters,
  cancelFilters,
  resetFilters
} from '../actions/shipmentList';
import { formatShipmentModeValue } from '../utils/formatString';
import duplicateIcon from '../assets/images/duplicate-icon.png';
import deleteIcon from '../assets/images/delete-icon.svg';
import { deleteDraftShipment } from '../services/shipments';

const { confirm: modalConfirm } = Modal;

const filterIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSVG}
  />
);
const filterSelectedIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSelectedSVG}
  />
);

const DuplicateButton = styled(Link)`
  text-align: center;
  display: block;
  img {
    width: 16px;
    cursor: pointer;
  }
`;

const DeleteButton = styled(Link)`
  text-align: center;
  display: block;
  img {
    width: 16px;
    cursor: pointer;
  }
`;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 30px 40px;
`;

const Shipments = () => {
  const dispatch = useDispatch();
  const tableColumns = useSelector((state) =>
    Object.keys(state.shipmentList.columns).reduce((cols, curr) => {
      return [...cols, ...state.shipmentList.columns[curr]];
    }, [])
  );
  const filtered = useSelector((state) => state.shipmentList.filtered);
  const currentPage = useSelector((state) => state.shipmentList.filters.page);
  const pageSize = useSelector((state) => state.shipmentList.filters.records);
  const [filters, setFilters] = useState();
  const [generateCSVModalVisible, setGenerateCSVModalVisible] = useState();
  const { data, total: totalCount, loading } = useSelector(
    (state) => state.shipmentList.shipments
  );

  const handleFilter = (value) => {
    dispatch(setShipmentFilters({ page: 1 }));
    setFilters(value);
  };

  const handleResetFilter = () => {
    dispatch(resetFilters());
    handleFilter({});
    dispatch(cancelFilters());
  };

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) dispatch(cancelFilters());
  };

  useEffect(() => {
    const recordFilters = {
      page: currentPage,
      pageSize,
      ...filters
    };
    dispatch(getShipments(recordFilters));
    dispatch(getChoices());
  }, [dispatch, currentPage, pageSize, filters]);

  const handleDeleteDraftShipment = (id) => () => {
    // confirm delete
    // delete
    modalConfirm({
      title: 'Do you want to delete draft shipment?',
      okText: 'Yes',
      className: 'b2b-confirm-modal',
      cancelText: 'No',
      width: 436,
      onOk() {
        return deleteDraftShipment(id)
          .exec()
          .then(() => {
            const recordFilters = { page: currentPage, pageSize, ...filters };
            dispatch(getShipments(recordFilters));
            dispatch(getChoices());
          });
      },
      onCancel() {}
    });
  };

  const columns = [
    {
      title: 'Duplicate/Delete',
      dataIndex: 'duplicate',
      key: 'action',
      width: 160,
      align: 'center',
      ellipsis: true,
      render: (_, row) => {
        const isDraftShipment = row.trackingNumber.startsWith('DFT');
        return (
          <React.Fragment>
            {!isDraftShipment && (
              <DuplicateButton
                to={`/shipments/create-shipment/${row.trackingNumber}`}
              >
                <img alt="duplicate" src={duplicateIcon} />
              </DuplicateButton>
            )}
            {isDraftShipment && (
              <DeleteButton
                onClick={handleDeleteDraftShipment(row.trackingNumber)}
              >
                <img alt="delete" src={deleteIcon} />
              </DeleteButton>
            )}
          </React.Fragment>
        );
      },
      fixed: 'left'
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: 200,
      render: (text) => (
        <ActionLink
          to={
            text.startsWith('DFT')
              ? `/shipments/create-shipment/${text}`
              : `shipments/${text}`
          }
        >
          {text}
        </ActionLink>
      ),
      fixed: 'left',
      filterIcon:
        filters && filters.trackingNumber ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="trackingNumber"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Tracking Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (text, record) => (
        <ActionBlock
          status={record.status}
          trackingNumber={record.trackingNumber}
          text={text}
        />
      ),
      ellipsis: true,
      filterIcon: filters && filters.status ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="status"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Quote ID',
      dataIndex: 'quoteRequestID',
      key: 'quoteRequestID',
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.quoteRequestID ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="quoteRequestID"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.merchantName ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="merchantName"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Shipment Name',
      dataIndex: 'shipmentName',
      key: 'shipmentName',
      width: 200,
      ellipsis: true
    },
    {
      title: 'Shipment Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 230,
      ellipsis: true,
      filterIcon:
        filters && filters.createdAt?.length ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="createdAt"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Mode of Shipment',
      dataIndex: 'shipmentMode',
      key: 'shipmentMode',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.shipmentMode ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="shipmentMode"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Origin Country',
      dataIndex: 'origin',
      key: 'origin',
      width: 190,
      ellipsis: true,
      filterIcon: filters && filters.origin ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="origin"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Origin Airport/Port',
      dataIndex: 'originPort',
      key: 'originPort',
      width: 190,
      ellipsis: true,
      filterIcon:
        filters && filters.originPort ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="originPort"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Destination Country',
      dataIndex: 'destination',
      key: 'destination',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.destination ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="destination"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Destination Airport/Port',
      dataIndex: 'destinationPort',
      key: 'destinationPort',
      width: 240,
      ellipsis: true,
      filterIcon:
        filters && filters.destinationPort ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="destinationPort"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Transhipment Country',
      dataIndex: 'transhipmentCountry',
      key: 'transhipmentCountry',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.transhipmentCountry
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="transhipmentCountry"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Transhipment Airport/Port',
      dataIndex: 'transhipmentPort',
      key: 'transhipmentPort',
      width: 250,
      ellipsis: true,
      filterIcon:
        filters && filters.transhipmentPort ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="transhipmentPort"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (text, record) => {
        if (text) {
          const value = formatShipmentModeValue(record.shipmentMode, text);
          return `${value} CBM`;
        }
        return '-';
      },
      width: 140,
      ellipsis: true
    },
    {
      title: 'Total Actual Weight',
      dataIndex: 'actualWeight',
      key: 'actualWeight',
      render: (text) => {
        if (text) {
          return `${Math.ceil(parseFloat(text))} KG`;
        }
        return '-';
      },
      width: 200,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.actualWeight &&
        (filters.actualWeight.min || filters.actualWeight.max)
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          isMinMaxFilter
          filterName="actualWeight"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Volumetric Weight',
      dataIndex: 'volumetricWeight',
      key: 'volumetricWeight',
      render: (text, record) => {
        if (text) {
          const value = formatShipmentModeValue(record.shipmentMode, text);
          return `${value} KG`;
        }
        return '-';
      },
      width: 230,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.volumetricWeight &&
        (filters.volumetricWeight.min || filters.volumetricWeight.max)
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          isMinMaxFilter
          filterName="volumetricWeight"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Chargeable Weight',
      dataIndex: 'chargeableWeight',
      key: 'chargeableWeight',
      render: (text, record) => {
        if (text) {
          const value = formatShipmentModeValue(record.shipmentMode, text);
          if (record.chargeableWeightUnit) {
            return `${value} ${record.chargeableWeightUnit}`;
          }
          return `${value} ${record.shipmentMode === 'Sea' ? 'CBM' : 'KG'}`;
        }
        return '-';
      },
      width: 270,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.chargeableWeight &&
        (filters.chargeableWeight.min ||
          filters.chargeableWeight.max ||
          (filters.chargeableWeight.unit &&
            filters.chargeableWeight.unit !== 'All'))
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="chargeableWeight"
          isMinMaxFilter
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Cost',
      dataIndex: 'costAmount',
      key: 'costAmount',
      render: (text) => (text ? `USD ${text}` : ''),
      width: 140,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.costAmount &&
        (filters.costAmount.min || filters.costAmount.max)
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          isMinMaxFilter
          filterName="costAmount"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Network Partners',
      dataIndex: 'networkPartners',
      key: 'networkPartners',
      render: (text) =>
        text
          ? JSON.parse(text)
              .map((partner) => partner && partner.trim())
              .join(', ')
          : '-',
      width: 160,
      ellipsis: true
    },
    {
      title: 'Shipment Confirmation Date',
      dataIndex: 'confirmedDate',
      key: 'confirmedDate',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.confirmedShipmentDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="confirmedShipmentDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Estimated Delivery Date',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.estimatedDeliveryDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="estimatedDeliveryDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Status Last Updated Date',
      dataIndex: 'lastStatusUpdateDate',
      key: 'lastStatusUpdateDate',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 240,
      ellipsis: true,
      filterIcon:
        filters && filters.lastStatusUpdateDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="lastStatusUpdateDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Actual Delivery Date',
      dataIndex: 'actualDeliveryDate',
      key: 'actualDeliveryDate',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.actualDeliveryDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="actualDeliveryDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Bill Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) => {
        return `${record.billingCurrency} ${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        })
          .format(record?.billingAmount)
          .slice(1)}`;
      },
      width: 140,
      ellipsis: true
    },
    {
      title: 'Bill Amount (in USD)',
      dataIndex: 'totalUSDAmount',
      key: 'totalUSDAmount',
      render: (text, record) => {
        return `${
          record?.billingUSDAmount ? 'USD' : ''
        } ${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        })
          .format(record?.billingUSDAmount)
          .slice(1)}`;
      },
      width: 180,
      ellipsis: true
    },
    {
      title: 'Janio Billing Branch',
      dataIndex: 'billingEntity',
      key: 'billingEntity',
      render: (text) => text || '',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.billingEntity ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="billingEntity"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Cost > Bill Price',
      dataIndex: 'costBillComparison',
      key: 'costBillComparison',
      render: (text, record) => {
        if (
          record?.costAmount &&
          record?.billingUSDAmount &&
          record?.costAmount > record?.billingUSDAmount
        ) {
          return 'Yes';
        }
        return 'No';
      },
      width: 160,
      ellipsis: true
    },
    {
      title: 'Gross profit margin (%)',
      dataIndex: 'grossProfitMargin',
      key: 'grossProfitMargin',
      render: (text, record) => {
        if (record?.costAmount && record?.billingUSDAmount) {
          const billingAmount = Number(record?.billingUSDAmount);
          const costAmount = Number(record?.costAmount);
          const grossProfitMargin = (
            ((billingAmount - costAmount) / billingAmount) *
            100
          ).toFixed(1);
          return `${grossProfitMargin}%`;
        }
        return '';
      },
      width: 220,
      ellipsis: true
    },
    {
      title: 'Billing Invoice No.',
      dataIndex: 'billingInvoiceNumber',
      key: 'billingInvoiceNumber',
      render: (text) => text || '',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.billingInvoiceNumber
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="billingInvoiceNumber"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Credit Note No.',
      dataIndex: 'billingCreditNoteNumber',
      key: 'billingCreditNoteNumber',
      render: (text) => text || '',
      width: 160,
      ellipsis: true,
      filterIcon:
        filters && filters.billingCreditNoteNumber
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="billingCreditNoteNumber"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Cost & billing lock',
      dataIndex: 'costBillingLock',
      key: 'costBillingLock',
      render: (text) => text || '',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.costBillingLock ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="costBillingLock"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Ready to Bill',
      dataIndex: 'readyToBill',
      key: 'readyToBill',
      render: (text) => (text ? 'Yes' : 'No'),
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.readyToBill ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="readyToBill"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Ready to Bill Date',
      dataIndex: 'readyToBillDate',
      key: 'readyToBillDate',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.readyToBillDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="readyToBillDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total No. of Units',
      dataIndex: 'packageTypeUnits',
      key: 'packageTypeUnits',
      render: (text) => text || '-',
      width: 220,
      ellipsis: true
    },
    {
      title: 'Container/truck type',
      dataIndex: 'shipmentLoadType',
      key: 'shipmentLoadType',
      render: (text, record) => {
        if (text === null) {
          return '-';
        }
        switch (record.shipmentMode) {
          case 'Sea':
            return text ? 'FCL' : 'LCL';
          case 'Land':
            return text ? 'FTL' : 'LTL';
          default:
            return '-';
        }
      },
      width: 140,
      ellipsis: true
    },
    {
      title: 'No. of trucks/containers',
      dataIndex: 'loadTypeUnits',
      key: 'loadTypeUnits',
      render: (text) => text || '-',
      width: 140,
      ellipsis: true
    },
    {
      title: 'MAWB, OOBL, D/O Number',
      dataIndex: 'importAwb',
      key: 'importAwb',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.importAwb ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="importAwb"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Transhipment MAWB',
      dataIndex: 'transhipmentMawb',
      key: 'transhipmentMawb',
      width: 140,
      ellipsis: true
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.createdBy ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="createdBy"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Creator Email',
      dataIndex: 'creatorEmail',
      key: 'creatorEmail',
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.creatorEmail ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="creatorEmail"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Operator Incharge',
      dataIndex: 'operatorInCharge',
      key: 'operatorInCharge',
      width: 240,
      ellipsis: true,
      filterIcon: filters && filters.operator ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="operator"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Operator Branch Code',
      dataIndex: 'operatorBranchCode',
      key: 'operatorBranchCode',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.operatorBranchCode ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="operatorBranchCode"
          onFilter={handleFilter}
        />
      )
    }
  ];

  return (
    <div>
      <GenerateCSVModal
        isModalVisible={generateCSVModalVisible}
        setIsModalVisible={setGenerateCSVModalVisible}
      />
      <StyledCard>
        <Row type="flex" sx={{ mb: '20px' }} gutter={16}>
          {/* <Col>
            <ShipmentFilterDropdown onFilter={handleFilter} />
          </Col> */}
          <Col>
            <ColumnDropdown />
          </Col>
          <Col>
            <Link to="/shipments/create-shipment">
              <Button type="secondary">Create Shipment</Button>
            </Link>
          </Col>

          <Col sx={{ ml: 'auto' }}>
            <Row type="flex">
              {/* {!loading && ( */}
              <Button
                sx={{ mr: '20px' }}
                type="secondary"
                onClick={() => setGenerateCSVModalVisible(true)}
              >
                Generate CSV
              </Button>
              {/* )} */}
              <SelectDropdown
                value={`${pageSize}`}
                items={[
                  { label: '20 items/page', value: '20' },
                  { label: '50 items/page', value: '50' },
                  { label: '100 items/page', value: '100' }
                ]}
                onChange={(value) => {
                  dispatch(setPageSizeFilter(value));
                }}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Table
            resizeable
            renderEmptyTable
            loading={loading}
            type="secondary"
            // rowSelection={rowSelection}
            // rowKey={(row) => `${row.trackingNumber}|${row.action}`}
            columns={columns.filter(
              (column) =>
                tableColumns.includes(column.key) || column.key === 'action'
            )}
            dataSource={data}
            pageSize={parseInt(pageSize, 10)}
            currentPage={currentPage}
            onChange={(page) => dispatch(setPageFilter(page))}
            totalCount={totalCount}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No shipment is found.</span>&nbsp;
                    {filtered === true && (
                      <span
                        className="link"
                        role="button"
                        tabIndex={0}
                        onKeyDown={handleResetFilter}
                        onClick={handleResetFilter}
                      >
                        Reset all filters
                      </span>
                    )}
                  </div>
                )}
                {loading && (
                  <AntSekeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
            // onChange={(page) => dispatch(setPageFilter(page))}
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default Shipments;
