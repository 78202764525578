import React from 'react';
import styled from '@emotion/styled';
import { AutoComplete as AntAutoComplete, Icon } from 'antd';

const StyledAutoComplete = styled.div`
  position: relative;
  .ant-select-auto-complete {
    border-radius: 4px;
    width: 100% !important;
  }

  .ant-input {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000;
    padding: 6px 11px 3px 11px;
  }

  .ant-input:hover {
    border-color: #050593 !important;
  }
  .ant-input:focus {
    border-color: #050593 !important;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
  .anticon-loading {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #8084c1;
  }
`;

const AutoComplete = ({ loading, ...props }) => {
  return (
    <StyledAutoComplete>
      <AntAutoComplete {...props} />
      {loading && <Icon type="loading" />}
    </StyledAutoComplete>
  );
};

export default AutoComplete;
