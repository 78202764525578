import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { Row, Col, Checkbox, Popover, Icon, message } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _, { get } from 'lodash';
import randomstring from 'randomstring';
import moment from 'moment';
import { FormSection, Radio, Button, Modal } from '../components';
import {
  FormSearchInput,
  FormRadioGroup,
  FormInput,
  FormPackageDimension,
  FormCheckboxGroup
} from '../components/Forms';
import { merchantService } from '../services/merchants';
import { shipmentService } from '../services/shipments';
import { packageService } from '../services/packages';
import { quoteService } from '../services/quotes';
import FormPickupDeliveryDetails from '../components/Forms/FormPickupDeliveryDetails';
import FormFullLoad from '../components/Forms/FormFullLoad';
import { toTitleCase } from '../utils/formatString';
import { usePrevious } from '../helpers/hooks';

const { confirm: modalConfirm } = Modal;

// TODO: this form can be further optimized by reducing re-renders where possible

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;

const StyledCreateShipment = styled.div`
  padding-bottom: 10px;
  .create-shipment {
    width: 100%;
  }
  .shipmentBtns {
    max-width: 975px;
    margin: 5px auto 25px auto !important;
  }
`;

const StyledFormPanel = styled.div`
  .divider {
    position: relative;
    margin-top: 10px;
    height: 1px;
  }
  & > .ant-row {
    margin: 30px 0;
    .ant-row:first-of-type {
      margin-top: 0;
    }
    .ant-row:last-of-type {
      margin-bottom: 0;
    }
    .ant-row {
      margin: 30px 0;
    }
  }
  .dimension-type {
    margin-bottom: 10px;
  }
`;

const FormPanel = ({ children }) => (
  <StyledFormPanel>
    <div className="divider" />
    <Row>{children}</Row>
  </StyledFormPanel>
);

// TODO: make old data work with new UI, remove this if data is ever migrated
const getMetricType = (shipment, item) => {
  if (shipment.quote_shipment_dimension_type) {
    return [
      shipment.quote_shipment_dimension_type,
      shipment.quote_shipment_dimension_type
    ];
  }
  return [item.weight_type, item.volume_type];
};

const FormActivator = ({
  errors,
  values,
  setFieldValue,
  children,
  validateForm = () => {},
  onLoadChange = () => {}
}) => {
  const [activeForms, setActiveForms] = useState([0]);
  const [lastIndex, setLastIndex] = useState(false);
  const prevModeOfShipment = usePrevious(values.modeOfShipment);
  useEffect(() => {
    const list = [
      ['merchantName'],
      [
        'shipmentName',
        'modeOfShipment',
        'firstMile',
        'internal.origin',
        'midMile',
        'lastMile',
        'internal.destination',
        'incoterm',
        'shipperName',
        'consigneeName',
        'agreedPrice',
        'agreedPriceText'
      ],
      [
        'load',
        'internal.loads',
        'internal.dimensions',
        'dangerousGoods',
        'quoteID',
        'shipmentDescription'
      ],
      ['customsClearance', 'hasEor', 'hasIor', 'relevantDocuments']
    ];
    let active = [0];
    for (let item = 0; item < list.length; item += 1) {
      let error = false;
      for (let prop = 0; prop < list[item].length; prop += 1) {
        if (
          values.modeOfShipment !== 'Air' &&
          list[item][prop] === 'internal.loads' &&
          values.load === false
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (get(errors, list[item][prop])) {
          error = true;
          break;
        }
      }
      if (error) {
        break;
      }
      if (item < list.length) {
        active = [...active, item + 1];
      }
    }
    // const active = [0, 1, 2, 3];
    setActiveForms(active);
    // setLastIndex(true);
    setLastIndex(list.length === active.length - 1);
  }, [errors, values.load, values.modeOfShipment]);

  useEffect(() => {
    if (
      values?.dangerousGoods?.includes('no_special_goods') &&
      values?.dangerousGoods?.length > 1
    ) {
      setFieldValue('dangerousGoods', ['no_special_goods']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dangerousGoods]);

  useEffect(() => {
    if (values?.load === false) {
      setFieldValue('loads', [
        {
          pId: randomstring.generate(),
          amount: null,
          type: undefined
        }
      ]);
    }
    onLoadChange(values.load, validateForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.load]);

  useEffect(() => {
    if (values.modeOfShipment === 'Air') {
      setFieldValue('load', undefined);
    }
    if (
      prevModeOfShipment !== undefined &&
      values.modeOfShipment !== prevModeOfShipment
    ) {
      setFieldValue('loads', [
        {
          pId: randomstring.generate(),
          amount: null,
          type: undefined
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modeOfShipment]);

  return children({ active: activeForms, lastIndex });
};

const getLoadType = (modeOfShipment, titleCase = false) => {
  let label = '';
  if (modeOfShipment === 'Sea') {
    label = 'container';
  }
  if (modeOfShipment === 'Land') {
    label = 'truck';
  }
  if (titleCase) {
    return toTitleCase(label);
  }
  return label;
};

const CreateShipment = (props) => {
  const { match = { params: {} } } = props;
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [isConvertQuote, setIsConvertQuote] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [fullLoad, setFullLoad] = useState();
  const [loadTypes, setLoadTypes] = useState({
    containers: [],
    trucks: []
  });
  const [incoterms, setIncoterms] = useState([]);
  const [formValues, setFormValues] = useState({
    merchantName: undefined,
    modeOfShipment: match?.params?.trackingId ? undefined : 'Air',
    firstMile: 1,
    origin: {
      country: undefined,
      port: '',
      address: undefined,
      date: undefined
    },
    shipper_name: undefined,
    consignee_name: undefined,
    midMile: true,
    lastMile: true,
    destination: {
      country: undefined,
      port: '',
      address: undefined,
      date: undefined
    },
    incoterm: undefined,
    agreedPriceText: '',
    dimensions: [
      {
        pId: randomstring.generate(),
        amount: 1,
        name: '',
        type: undefined,
        dimensions: {
          height: null,
          isCm: true,
          length: null,
          width: null
        },
        weight: {
          amount: null,
          isKg: true
        },
        weightType: false,
        volumeType: true,
        chargeableWeight: null
      }
    ],
    loads: [
      {
        pId: randomstring.generate(),
        amount: null,
        type: undefined
      }
    ],
    shipmentName: '',
    quoteID: '',
    shipmentDescription: '',
    relevantDocuments: ''
  });

  const onLoadChange = async (load, validateForm) => {
    await setFullLoad(load);
    validateForm();
  };

  useEffect(() => {
    const fetchDetails = async () => {
      let isConvertQuoteRequest = false;
      if (match.params.trackingId.startsWith('Q')) {
        setIsConvertQuote(true);
        isConvertQuoteRequest = true;
      }
      let resp = {};
      let shipment = {};
      if (!isConvertQuoteRequest) {
        resp = await quoteService.getQuoteDetail(match.params.trackingId);
        shipment = resp?.quote;
      } else {
        resp = await quoteService.getRequestQuotesDetail(
          match.params.trackingId
        );
        shipment = resp?.quoteRequest;
      }

      const shipmentInitialValues = {
        merchantName: shipment.merchant_id,
        modeOfShipment: shipment.shipment_mode,
        firstMile: shipment.origin.is_using_first_mile,
        origin: {
          country: shipment.origin.country,
          address: shipment.origin.location_id,
          port: shipment.origin.port,
          date: isConvertQuoteRequest
            ? moment(shipment.origin.pickup_date)
            : null,
          // etra fields
          locationCountry: shipment.origin.country,
          street: shipment.origin.address,
          city: shipment.origin.city,
          locationName: shipment.origin.location_name,
          pocEmail: shipment.origin.poc_email,
          pocNumber: shipment.origin.poc_number,
          pocName: shipment.origin.poc_name,
          postalCode: shipment.origin.postal_code,
          state: shipment.origin.state
        },
        shipperName: shipment.shipper_name,
        consigneeName: shipment.consignee_name,
        midMile: shipment.is_using_mid_mile,
        lastMile: shipment.destination.is_using_last_mile,
        destination: {
          country: shipment.destination.country,
          address: shipment.destination.location_id,
          port: shipment.destination.port,
          date: isConvertQuoteRequest
            ? moment(shipment.destination.pickup_date)
            : null,
          // etra fields
          locationCountry: shipment.destination.country,
          street: shipment.destination.address,
          city: shipment.destination.city,
          locationName: shipment.destination.location_name,
          pocEmail: shipment.destination.poc_email,
          pocNumber: shipment.destination.poc_number,
          pocName: shipment.destination.poc_name,
          postalCode: shipment.destination.postal_code,
          state: shipment.destination.state
        },
        incoterm: shipment.incoterm_id,
        agreedPrice: Boolean(shipment.agreed_price),
        agreedPriceText: shipment.shipment_price,
        // TODO: fix duplicate logic for loads
        load: shipment?.shipment_load_type,
        loads: shipment?.loads?.length
          ? shipment?.loads.map((loadItem) => ({
              pId: loadItem.id,
              amount: loadItem.units,
              type: loadItem.type_id
            }))
          : [
              {
                pId: randomstring.generate(),
                amount: null,
                type: undefined
              }
            ],
        dimensions: shipment?.packages?.length
          ? shipment?.packages?.map((packageItem) => {
              const [weightType, volumeType] = getMetricType(
                shipment,
                packageItem
              );
              return {
                pId: packageItem.id,
                amount: packageItem.units || 1,
                type: packageItem.type_id,
                dimensions: {
                  height: packageItem.height,
                  isCm: packageItem.length_uom === 'cm',
                  length: packageItem.length,
                  width: packageItem.width,
                  amount: packageItem.total_volume
                },
                weight: {
                  amount: weightType
                    ? packageItem.weight
                    : packageItem.total_weight,
                  isKg: packageItem.weight_uom === 'kg'
                },
                weightType,
                volumeType,
                chargeableWeight: packageItem.chargeable_weight
              };
            })
          : [
              {
                pId: randomstring.generate(),
                amount: 1,
                name: '',
                type: undefined,
                dimensions: {
                  height: null,
                  isCm: true,
                  length: null,
                  width: null
                },
                weight: {
                  amount: null,
                  isKg: true
                },
                weightType: false,
                volumeType: false,
                chargeableWeight: null
              }
            ],
        dangerousGoods: Object.keys(shipment.shipment_details).reduce(
          (goods, good) => {
            if (shipment.shipment_details[good] && good !== 'description') {
              if (good === 'contains_no_special_goods') {
                return [...goods, 'no_special_goods'];
              }
              return [...goods, good];
            }
            return goods;
          },
          []
        ),
        shipmentDescription: shipment.shipment_details.description,
        shipmentName: shipment.shipment_name,
        quoteID: isConvertQuoteRequest
          ? shipment.quote_id
          : shipment.quote_request_id,
        customsClearance: shipment.is_using_custom_clearance,
        hasEor: shipment.is_eor_present,
        hasIor: shipment.is_ior_present
      };
      setFormValues(shipmentInitialValues);
    };

    if (match.params.trackingId) {
      fetchDetails();
    }
  }, [match.params.trackingId, setIsConvertQuote]);

  const getFormSectionNumber = (name) => {
    const list = [
      'merchant',
      'shipmentMode',
      'shipmentDetails',
      'customs',
      'create'
    ];
    return list.findIndex((item) => item === name) + 1;
  };

  useEffect(() => {
    const fetchMerchants = merchantService.getMerchants();
    fetchMerchants.exec().then((res) => {
      setMerchants(
        res.data.data.map((merchant) => ({
          label: merchant.name,
          value: merchant.merchant_id
        }))
      );
    });
    return () => fetchMerchants.cancel();
  }, []);

  useEffect(() => {
    setLoadingPackage(true);
    const fetchLookupValues = packageService.getPackageTypes();
    fetchLookupValues.exec().then((res) => {
      setPackageTypes(res.data.data.package_types);
      setIncoterms(res.data.data.incoterms);
      setLoadTypes({
        containers: res.data.data.container_types,
        trucks: res.data.data.truck_types
      });
      setLoadingPackage(false);
    });
    return () => fetchLookupValues.cancel();
  }, []);

  const getValidationSchema = useCallback(
    () =>
      Yup.object().shape({
        merchantName: Yup.mixed()
          .nullable()
          .required('Please enter Merchant Name'),
        shipperName: Yup.mixed()
          .nullable()
          .required(`Please enter Shipper's Name`),
        consigneeName: Yup.mixed()
          .nullable()
          .required(`Please enter Consignee's Name`),
        internal: Yup.object().shape({
          destination: Yup.mixed().test('internal', 'error', (value) => !value),
          loads: Yup.mixed().test('internal', 'error', (value) => {
            if (fullLoad) {
              return !value;
            }
            return true;
          }),
          dimensions: Yup.mixed().test('internal', 'error', (value) => !value),
          origin: Yup.mixed().test('internal', 'error', (value) => !value)
        }),
        load: Yup.mixed().test(
          'load',
          'error',
          // eslint-disable-next-line func-names
          function(value) {
            const { parent } = this;
            if (parent.modeOfShipment !== 'Air') {
              return value !== undefined;
            }
            return true;
          }
        ),
        dangerousGoods: Yup.array()
          .nullable()
          .required(),
        shipmentName: Yup.mixed()
          .nullable()
          .test(
            'max',
            'You have exceeded the maximum 200 characters length limit',
            (value) => {
              return (value?.length || 0) <= 200;
            }
          ),
        quoteID: Yup.mixed()
          .nullable()
          .required(`Please enter quote ID provided to you`)
          .test(
            'max',
            'You have exceeded the maximum 10 characters length limit',
            (value) => {
              return (value?.length || 0) <= 10;
            }
          ),
        shipmentDescription: Yup.mixed()
          .nullable()
          .test(
            'max',
            'You have exceeded the maximum 2000 characters length limit',
            (value) => {
              return (value?.length || 0) <= 2000;
            }
          ),
        customsClearance: Yup.mixed()
          .nullable()
          .required(),
        hasEor: Yup.mixed()
          .nullable()
          .required(),
        hasIor: Yup.mixed()
          .nullable()
          .required(),
        agreedPrice: Yup.mixed()
          .nullable()
          .required(),
        agreedPriceText: Yup.mixed().test(
          'agreedPrice',
          'Please enter Agreed Price',
          // eslint-disable-next-line func-names
          function(value) {
            const { parent } = this;
            if (parent.agreedPrice) {
              return value;
            }
            return true;
          }
        )
      }),
    [fullLoad]
  );

  const handleDraftSubmit = (values) => {
    let draft_tracking_id = '';
    if (
      match?.params?.trackingId &&
      match?.params?.trackingId.startsWith('DFT')
    ) {
      draft_tracking_id = match?.params?.trackingId;
    }
    const shipment = {
      draft_tracking_id,
      save_to_draft: true,
      merchant_id: values?.merchantName || null,
      origin: {
        is_first_mile_purchase: values?.firstMile,
        address_id: values?.origin?.address || null,
        port: values?.origin?.port || null,
        estimated_dpd_dt:
          (values?.origin?.date && values?.origin?.date.format('YYYY-MM-DD')) ||
          null,
        country: values?.origin?.country || null
      },
      shipper_name: values?.shipperName || null,
      consignee_name: values?.consigneeName || null,
      is_using_mid_mile: values?.midMile,
      destination: {
        is_last_mile_purchase: values?.lastMile,
        address_id: values?.destination?.address || null,
        port: values?.destination?.port || null,
        estimated_delivery_dt:
          (values?.destination?.date &&
            values?.destination?.date.format('YYYY-MM-DD')) ||
          null,
        country: values?.destination?.country || null
      },
      shipment_mode: values?.modeOfShipment,
      shipping_incoterm_id: values?.incoterm || null,
      has_agreed_price: values?.agreedPrice || null,
      shipment_price: values?.agreedPriceText || null,
      shipment_load: values?.load || null,
      shipment_loads: values?.load
        ? values?.loads.map((loadItem) => {
            return {
              type_id: loadItem.type,
              units: loadItem.amount
            };
          })
        : [],
      shipments_packages: {
        packages: values?.dimensions?.map((packageItem) => {
          return {
            type_id: packageItem.type,
            units: packageItem.amount,
            weight_per_unit: packageItem.weightType
              ? packageItem?.weight?.amount
              : null,
            weight_uom:
              packageItem?.weight?.isKg && packageItem?.weight?.isKg
                ? 'kg'
                : 'lb',
            dimensions: {
              length: packageItem?.dimensions?.length || null,
              width: packageItem?.dimensions?.width || null,
              height: packageItem?.dimensions?.height || null,
              length_uom: packageItem?.dimensions?.isCm ? 'cm' : 'in'
            },
            cbm: packageItem.volumeType
              ? null
              : packageItem?.dimensions?.amount,
            total_weight: packageItem.weightType
              ? null
              : packageItem?.weight?.amount,
            weight_type: packageItem.weightType,
            volume_type: packageItem.volumeType,
            chargeable_weight: packageItem.chargeableWeight
          };
        })
      },
      shipment_name: values?.shipmentName,
      quoteID: values?.quoteID,
      shipment_details: {
        description: values?.shipmentDescription || null,
        contains_battery:
          values?.dangerousGoods?.includes('contains_battery') || null,
        contains_hazardous_goods:
          values?.dangerousGoods?.includes('contains_hazardous_goods') || null,
        other: values?.dangerousGoods?.includes('other') || null,
        contains_no_special_goods:
          values?.dangerousGoods?.includes('no_special_goods') || null
      },
      is_eor_present:
        _.get(values, 'hasEor', null) !== null ? !!values.hasEor : null,
      is_ior_present:
        _.get(values, 'hasIor', null) !== null ? !!values.hasIor : null,
      is_using_custom_clearance:
        _.get(values, 'customsClearance', null) !== null
          ? !!values.customsClearance
          : null
    };
    shipmentService
      .createShipment(shipment)
      .exec()
      .then(async () => {
        setSavingDraft(false);
        history.push(`/shipments`);
      })
      .catch((err) => {
        setSavingDraft(false);
        message.error(
          err?.response?.data?.result ||
            'Shipment failed to save as draft. Please check the request.'
        );
      });
  };

  return (
    <StyledCreateShipment>
      <Formik
        enableReinitialize
        validationSchema={getValidationSchema()}
        initialValues={formValues}
        onSubmit={(values) => {
          let draft_tracking_id = '';
          if (
            match?.params?.trackingId &&
            match?.params?.trackingId.startsWith('DFT')
          ) {
            draft_tracking_id = match?.params?.trackingId;
          }
          const shipment = {
            draft_tracking_id,
            save_to_draft: false,
            merchant_id: values?.merchantName,
            origin: {
              is_first_mile_purchase: values?.firstMile,
              address_id: values?.origin?.address,
              port: values?.origin?.port,
              estimated_dpd_dt: values?.origin?.date.format('YYYY-MM-DD'),
              country: values?.origin?.country
            },
            shipper_name: values?.shipperName,
            consignee_name: values?.consigneeName,
            is_using_mid_mile: values?.midMile,
            destination: {
              is_last_mile_purchase: values?.lastMile,
              address_id: values?.destination?.address,
              port: values?.destination?.port,
              estimated_delivery_dt: values?.destination?.date.format(
                'YYYY-MM-DD'
              ),
              country: values?.destination?.country
            },
            shipment_mode: values?.modeOfShipment,
            shipping_incoterm_id: values?.incoterm,
            has_agreed_price: values?.agreedPrice || null,
            shipment_price: values?.agreedPriceText || null,
            shipment_load: values?.load,
            shipment_loads: values?.load
              ? values?.loads.map((loadItem) => {
                  return {
                    type_id: loadItem.type,
                    units: loadItem.amount
                  };
                })
              : [],
            shipments_packages: {
              packages: values?.dimensions?.map((packageItem) => {
                return {
                  type_id: packageItem.type,
                  units: packageItem.amount,
                  weight_per_unit: packageItem.weightType
                    ? packageItem?.weight?.amount
                    : null,
                  weight_uom: packageItem?.weight?.isKg ? 'kg' : 'lb',
                  dimensions: {
                    length: packageItem?.dimensions?.length || null,
                    width: packageItem?.dimensions?.width || null,
                    height: packageItem?.dimensions?.height || null,
                    length_uom: packageItem?.dimensions?.isCm ? 'cm' : 'in'
                  },
                  cbm: packageItem.volumeType
                    ? null
                    : packageItem?.dimensions?.amount,
                  total_weight: packageItem.weightType
                    ? null
                    : packageItem?.weight?.amount,
                  weight_type: packageItem.weightType,
                  volume_type: packageItem.volumeType,
                  chargeable_weight: packageItem.chargeableWeight
                };
              })
            },
            shipment_name: values?.shipmentName,
            quoteID: values?.quoteID,
            shipment_details: {
              description: values?.shipmentDescription,
              contains_battery: values?.dangerousGoods?.includes(
                'contains_battery'
              ),
              contains_hazardous_goods: values?.dangerousGoods?.includes(
                'contains_hazardous_goods'
              ),
              other: values?.dangerousGoods?.includes('other'),
              contains_no_special_goods: values?.dangerousGoods?.includes(
                'no_special_goods'
              )
            },
            is_eor_present: values?.hasEor,
            is_ior_present: values?.hasIor,
            is_using_custom_clearance: values?.customsClearance,
            is_quote_convertion: isConvertQuote
          };
          setSaving(true);
          shipmentService
            .createShipment(shipment)
            .exec()
            .then(async (resp) => {
              setSaving(false);
              history.push(`/shipments/${resp?.data?.data.tracking_id}`);
            })
            .catch((err) => {
              setSaving(false);
              message.error(
                err?.response?.data?.result ||
                  'Shipment creation failed. Please check the request.'
              );
            });
        }}
      >
        {({ values, handleSubmit, errors, setFieldValue, validateForm }) => (
          <FormActivator
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            onLoadChange={onLoadChange}
            validateForm={validateForm}
          >
            {({ active, lastIndex }) => (
              <>
                <FormSection
                  number={getFormSectionNumber('merchant')}
                  title="Which merchant is creating shipment?"
                  locked={
                    !active.includes(getFormSectionNumber('merchant') - 1)
                  }
                  deps={[values.merchantName, merchants]}
                >
                  <FormPanel>
                    <h4>Merchant Information</h4>
                    <Col className="input-wrapper">
                      <p>Merchant Name</p>
                      <FormSearchInput
                        name="merchantName"
                        placeholder="Select..."
                        items={merchants}
                      />
                    </Col>
                  </FormPanel>
                </FormSection>
                <FormSection
                  number={getFormSectionNumber('shipmentMode')}
                  title="How do you want your shipment to be delivered?"
                  locked={
                    !active.includes(getFormSectionNumber('shipmentMode') - 1)
                  }
                >
                  <FormPanel>
                    <h4>Your Shipment</h4>
                    <Col className="input-wrapper">
                      <p style={{ width: 270 }}>Shipment Name (Optional)</p>
                      <FormInput placeholder="Type here" name="shipmentName" />
                    </Col>
                    <Col className="input-wrapper">
                      <p style={{ width: 270 }}>Quote ID</p>
                      <FormInput
                        placeholder="Type here"
                        name="quoteID"
                        disabled={isConvertQuote}
                      />
                    </Col>
                  </FormPanel>
                  <FormPanel>
                    <h4>Mode of Shipment</h4>
                    <Col>
                      <p>How do you want your shipment to be delivered?</p>
                      <FormRadioGroup name="modeOfShipment">
                        <Radio value="Air">Air</Radio>
                        <Radio value="Sea">Sea</Radio>
                        <Radio value="Land">Land</Radio>
                      </FormRadioGroup>
                    </Col>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Origin/Shipper</h4>
                      <Col>
                        <p>Which first-mile service are you using?</p>
                        <FormRadioGroup name="firstMile">
                          <Radio value={1}>Pickup</Radio>
                          <Radio value={0}>Dropoff</Radio>
                          <Radio value={2}>No first mile</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <FormPickupDeliveryDetails
                      name="origin"
                      otherDate={values?.destination?.date}
                      source="origin"
                      service={values?.firstMile}
                      shipmentType={values?.modeOfShipment}
                      isInternal
                      setFieldValue={setFieldValue}
                      isConvertQuote={isConvertQuote}
                    />
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Mid-mile</h4>
                      <Col>
                        <p>
                          Do you require the mid-mile freight-forwarding
                          service?
                        </p>
                        <FormRadioGroup name="midMile">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Destination/Consignee</h4>
                      <Col>
                        <p>Do you require last-mile delivery?</p>
                        <FormRadioGroup name="lastMile">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <FormPickupDeliveryDetails
                      name="destination"
                      otherDate={values?.origin?.date}
                      source="destination"
                      service={values?.lastMile}
                      isInternal
                      shipmentType={values?.modeOfShipment}
                      isConvertQuote={isConvertQuote}
                    />
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipping Information</h4>
                      <Col className="input-wrapper">
                        <p>
                          Incoterm (Optional)
                          <StyledPopover
                            title={<h4>Incoterm</h4>}
                            content={
                              <p>
                                Incoterms are an internationally recognised set
                                of instructions used in the global
                                transportation of goods. They define the
                                division of responsibility between the Shipper
                                (usually the supplier/consignor) and the
                                Consignee (usually the buyer). The terms dictate
                                which party is responsible for the risks, costs
                                and liabilities associated with the shipment at
                                each stage of the shipping process
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormSearchInput
                          allowClear
                          name="incoterm"
                          placeholder="Select..."
                          items={incoterms.map((type) => ({
                            label: type.value,
                            value: type.key
                          }))}
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipment Price</h4>
                      <Col>
                        <p>
                          Does this shipment already have a price that is agreed
                          upon?
                        </p>
                        <FormRadioGroup name="agreedPrice">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Agreed Price</p>
                        <FormInput.FormTextArea
                          name="agreedPriceText"
                          placeholder="Let us know your shipment's price and its breakdown, etc..."
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <FormSection
                  number={getFormSectionNumber('shipmentDetails')}
                  title="What is your shipment?"
                  locked={
                    !active.includes(
                      getFormSectionNumber('shipmentDetails') - 1
                    )
                  }
                >
                  {values?.modeOfShipment && values?.modeOfShipment !== 'Air' && (
                    <FormPanel>
                      <Row>
                        <h4>
                          {getLoadType(values?.modeOfShipment, true)} Load
                        </h4>
                        <Col>
                          <p>
                            What is the {getLoadType(values?.modeOfShipment)}{' '}
                            load of your shipment?{' '}
                            <StyledPopover
                              content={
                                values?.modeOfShipment === 'Sea' ? (
                                  <>
                                    <p>
                                      <strong>FCL</strong>: Full container load
                                      is an ocean shipment in which the cargo
                                      occupies a full container (of any size).
                                    </p>
                                    <p>
                                      <strong>LCL</strong>: Less than Container
                                      Load is an ocean shipment not requiring
                                      full capacity of an ocean container and is
                                      usually less than 20 CBM (cubic meters)
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <strong>FTL</strong>: Full truck load is
                                      the transport of goods that fill up a full
                                      truck, or a partial load shipment
                                      occupying an entire truck
                                    </p>
                                    <p>
                                      <strong>LTL</strong>: Less than truck load
                                      is the transport of goods which occupies
                                      partial capacity of a truck
                                    </p>
                                  </>
                                )
                              }
                            >
                              <Icon
                                className="question-circle"
                                type="question-circle"
                              />
                            </StyledPopover>
                          </p>
                          <FormRadioGroup name="load">
                            <Radio value>
                              Full{' '}
                              {getLoadType(
                                values?.modeOfShipment,
                                true
                              ).toLowerCase()}{' '}
                              load
                            </Radio>
                            <Radio value={false} style={{ marginLeft: 50 }}>
                              Less than{' '}
                              {getLoadType(
                                values?.modeOfShipment,
                                true
                              ).toLowerCase()}{' '}
                              load
                            </Radio>
                          </FormRadioGroup>
                          {values?.load && (
                            <FormFullLoad
                              loading={loadingPackage}
                              loadTypes={loadTypes}
                              name="loads"
                              modeOfShipment={values?.modeOfShipment}
                            />
                          )}
                        </Col>
                      </Row>
                    </FormPanel>
                  )}
                  <FormPanel>
                    <Row>
                      <h4>Shipment Dimension</h4>
                      <Col>
                        <p>
                          Add weight, chargeable weight and other information
                          for your shipment. If you have the information, please
                          enter shipment dimensions by unit volume and add a new
                          set of package when dimensions between
                          packages/cargoes in the shipment differ.
                        </p>
                        <FormPackageDimension
                          name="dimensions"
                          loading={loadingPackage}
                          modeOfShipment={values?.modeOfShipment}
                          packageTypes={packageTypes}
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipment Details</h4>
                      <Col>
                        <p>
                          Does your shipment contain any of the following goods?
                        </p>
                        <FormCheckboxGroup name="dangerousGoods">
                          <Row>
                            <Col span={10}>
                              <Checkbox value="contains_battery">
                                Batteries/Powerbanks
                                <Popover
                                  title={<h4>Batteries</h4>}
                                  content={
                                    <p>
                                      Please select if you are shipping
                                      batteries. A valid Material Safety Data
                                      Sheet will be required for the battery
                                      type.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={14}>
                              <Checkbox value="contains_hazardous_goods">
                                Hazardous Materials
                                <Popover
                                  title={<h4>Hazardous Materials</h4>}
                                  content={
                                    <p>
                                      Please select if you are shipping
                                      hazardous material/dangerous goods. A
                                      valid Material Safety Data Sheet will be
                                      required for these items.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="other">
                                Other (powders, creams, liquids)
                                <Popover
                                  title={
                                    <h4>Other (powders, liquids, creams)</h4>
                                  }
                                  content={
                                    <p>
                                      Material Safety Data Sheets will be
                                      required for these commodities to certify
                                      that they are not classified as dangerous
                                      goods.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={14}>
                              <Checkbox value="no_special_goods">
                                No, my shipment does not contain any of the
                                goods above
                              </Checkbox>
                            </Col>
                          </Row>
                        </FormCheckboxGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Shipment Notes/Remarks (Optional)</p>
                        <FormInput.FormTextArea
                          name="shipmentDescription"
                          placeholder="Let us know your shipment's commodity, etc..."
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <FormSection
                  number={getFormSectionNumber('customs')}
                  title="How will the shipments clear customs?"
                  locked={!active.includes(getFormSectionNumber('customs') - 1)}
                >
                  <FormPanel>
                    <Row>
                      <h4>Customs Clearance</h4>
                      <Col>
                        <p>
                          Will you be using Janio&apos;s customs clearance
                          service?
                        </p>
                        <FormRadioGroup name="customsClearance">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Customs Records</h4>
                      <Col>
                        <p>
                          Do you have an exporter of record (EOR) for the origin
                          country?
                          <StyledPopover
                            title={<h4>EOR (Importer Of Record)</h4>}
                            content={
                              <p>
                                An Exporter of Record is the legal exporting
                                entity for goods exiting a country.
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormRadioGroup name="hasEor">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          Do you have an importer of record (IOR) for the
                          destination country?
                          <StyledPopover
                            title={<h4>IOR (Importer Of Record)</h4>}
                            content={
                              <p>
                                Is a person or a company responsible for
                                arranging everything legal related in an import
                                operation on the country of importing.
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormRadioGroup name="hasIor">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <Row className="shipmentBtns" gutter={16}>
                  {!isConvertQuote && (
                    <Col span={12}>
                      <Button
                        loading={savingDraft}
                        disabled={saving || !values.merchantName}
                        size="large"
                        className="create-shipment"
                        type="secondary"
                        onClick={() => {
                          modalConfirm({
                            title: 'Do you want to save as draft?',
                            content:
                              'You may save this form as a draft so that you can continue where you left off later.',
                            okText: 'Yes',
                            cancelText: 'No',
                            className: 'b2b-confirm-modal',
                            width: 530,
                            onOk() {
                              setSavingDraft(true);
                              handleDraftSubmit(values);
                            },
                            onCancel() {}
                          });
                        }}
                      >
                        Save As Draft
                      </Button>
                    </Col>
                  )}
                  <Col span={isConvertQuote ? 24 : 12}>
                    <Button
                      loading={saving}
                      disabled={!lastIndex}
                      size="large"
                      className="create-shipment"
                      onClick={handleSubmit}
                    >
                      Create Shipment
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </FormActivator>
        )}
      </Formik>
    </StyledCreateShipment>
  );
};

export default withRouter(CreateShipment);
