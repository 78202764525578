import { Machine } from 'xstate';

const QuoteMachine = Machine({
  id: 'quoteMachine',
  initial: 'QUOTE_REQUESTED',
  states: {
    QUOTE_REQUESTED: {
      on: {
        NEXT: 'QUOTE_DRAFT_CREATED',
        CANCEL: 'QUOTE_EXPIRED'
      }
    },
    QUOTE_EXPIRED: {},
    QUOTE_DRAFT_CREATED: {
      on: {
        SAVE: 'QUOTE_DRAFT_CREATED',
        NEXT: 'QUOTE_CREATED',
        SEND: 'QUOTE_CREATED'
      }
    },
    QUOTE_REREQUESTED_DRAFT: {
      on: {
        NEXT: 'QUOTE_CREATED',
        SEND: 'QUOTE_CREATED'
      }
    },
    QUOTE_CREATED: {
      on: {
        SAVE: 'QUOTE_REREQUESTED_DRAFT',
        PREV: 'QUOTE_REREQUESTED_DRAFT',
        NEXT: 'QUOTE_ACCEPTED',
        CANCEL: 'QUOTE_REQUEST_UNSUCCESSFUL'
      }
    },
    QUOTE_ACCEPTED: {
      on: {
        NEXT: 'DOCUMENT_UPLOADED'
      }
    },
    QUOTE_REQUEST_UNSUCCESSFUL: {},
    DOCUMENT_UPLOADED: {
      on: {
        NEXT: 'SHIPMENT_CONFIRMED'
      }
    },
    SHIPMENT_CONFIRMED: {
      on: {
        NEXT: 'RESCHEDULED_FOR_DROPOFF'
      }
    },
    RESCHEDULED_FOR_DROPOFF: {
      on: {
        NEXT: 'REACHED_ORIGIN_WAREHOUSE'
      }
    },
    REACHED_ORIGIN_WAREHOUSE: {
      on: {
        NEXT: 'SCHEDULED_FOR_DROPOFF'
      }
    },
    SCHEDULED_FOR_DROPOFF: {
      on: {
        NEXT: 'SCHEDULED_FOR_PICKUP'
      }
    },
    SCHEDULED_FOR_PICKUP: {},
    DELIVERED: {},
    RESCHEDULED_FOR_DELIVERY: {},
    DELIVERY_FAILED: {},
    RECHEDULED_FOR_PICKUP: {},
    PENDING_CUSTOM_CLEARANCE: {},
    RECEIVED_BY_AIRLINES: {},
    CANCELLED: {},
    OUT_FOR_DELIVERY: {},
    PARTIALLY_DELIVERED: {},
    VESSEL_DELAYED: {},
    SPACE_BOOKING_IN_PROGRESS: {}
  }
});
const getNewStateFromCurrent = (currentState, eventType) => {
  return QuoteMachine.transition(currentState, eventType).value;
};
export { QuoteMachine, getNewStateFromCurrent };
