const currencyMap = new Map([
  ['IDR', 'idr_sgd_100'],
  ['JPY', 'jpy_sgd_100'],
  ['MYR', 'myr_sgd_100'],
  ['USD', 'usd_sgd'],
  ['AUD', 'aud_sgd'],
  ['CNY', 'cny_sgd_100'],
  ['PHP', 'php_sgd_100'],
  ['GBP', 'gbp_sgd'],
  ['KRW', 'krw_sgd_100'],
  ['TWD', 'twd_sgd_100'],
  ['SGD', ''],
  ['HKD', 'hkd_sgd_100'],
  ['VND', 'vnd_sgd_100'],
  ['THB', 'thb_sgd_100']
]);

const calculateExchangeRate = ({
  source,
  target,
  value = 1,
  sourceRate,
  targetRate
}) => {
  if (source === 'SGD' && target === 'SGD') {
    return 1;
  }

  const getSgdRate = (src = false) => {
    const { rate, hundred } = src ? sourceRate : targetRate;
    if (hundred) {
      return rate / 100;
    }
    return rate;
  };

  const getSgdValue = (src) => {
    const { rate, hundred } = src ? targetRate : sourceRate;
    if (hundred) {
      return 100 / rate;
    }
    return 1 / rate;
  };

  if (target === 'SGD') {
    return getSgdRate(true);
  }

  if (source === 'SGD') {
    return value * getSgdValue(true);
  }

  return calculateExchangeRate({
    source: 'SGD',
    target,
    value: getSgdRate(true),
    sourceRate: null,
    targetRate
  });
};

const calculateRates = ({ target = null, currencies = [], rates = null }) => {
  if (!rates) {
    return new Map();
  }
  const exchangeRates = new Map();
  currencyMap.forEach((value, key) => {
    if (currencies.includes(key)) {
      const exchangeOptions = {
        source: key,
        target,
        sourceRate:
          key !== 'SGD'
            ? {
                rate: parseFloat(rates[value]),
                hundred: value.includes('100')
              }
            : null,
        targetRate:
          target !== 'SGD'
            ? {
                rate: parseFloat(rates[currencyMap.get(target)]),
                hundred: currencyMap.get(target).includes('100')
              }
            : null
      };
      const rate = calculateExchangeRate(exchangeOptions);
      exchangeRates.set(key, rate);
    }
  });
  return exchangeRates;
};

export const calculateTotalFromRates = ({
  target = null,
  lineTotals = [],
  rates = null,
  existingRates = null
}) => {
  if ((!rates && !existingRates) || !target) {
    return {
      totalCost: 0,
      totalCostTax: 0,
      totalBilling: 0,
      totalBillingTax: 0,
      grossProfit: 0,
      grossProfitMargin: 0,
      exchangeRates: []
    };
  }
  let currencies = [];
  lineTotals.forEach((lineTotal) => {
    currencies = currencies.concat([
      lineTotal?.cost?.currency,
      lineTotal?.billing?.currency
    ]);
  });
  const exchangeRates =
    existingRates ||
    calculateRates({
      target,
      currencies: currencies.filter(Boolean),
      rates
    });
  const total = lineTotals.reduce(
    (val, lineTotal) => {
      let isTaxLineItem = false;
      let totalCost = 0;
      let totalCostTax = 0;
      let totalBilling = 0;
      let totalBillingTax = 0;
      if (lineTotal?.fee_name?.includes('Tax')) {
        isTaxLineItem = true;
      }
      if (lineTotal?.cost?.currency) {
        const totalAmount =
          parseFloat(lineTotal?.cost?.total_amount) *
          exchangeRates.get(lineTotal?.cost?.currency);
        totalCost = !isTaxLineItem ? totalAmount : 0;
        totalCostTax = isTaxLineItem ? totalAmount : 0;
      }
      if (lineTotal?.billing?.currency) {
        const totalAmount =
          parseFloat(lineTotal?.billing?.total_amount) *
          exchangeRates.get(lineTotal?.billing?.currency);
        totalBilling = !isTaxLineItem ? totalAmount : 0;
        totalBillingTax = isTaxLineItem ? totalAmount : 0;
      }
      return {
        ...val,
        totalCost: val.totalCost + totalCost,
        totalCostTax: val.totalCostTax + totalCostTax,
        totalBilling: val.totalBilling + totalBilling,
        totalBillingTax: val.totalBillingTax + totalBillingTax
      };
    },
    { totalCost: 0, totalCostTax: 0, totalBilling: 0, totalBillingTax: 0 }
  );
  const grossProfit = total.totalBilling - total.totalCost || 0;
  const grossProfitMargin =
    total.totalBilling > 0
      ? ((grossProfit / total.totalBilling) * 100).toFixed(1)
      : 0;
  return {
    totalCost: total.totalCost,
    totalCostTax: total.totalCostTax,
    totalBilling: total.totalBilling,
    totalBillingTax: total.totalBillingTax,
    grossProfit,
    grossProfitMargin,
    exchangeRates
  };
};
