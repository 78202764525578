import React from 'react';
import { Tabs, Card } from 'antd';
import styled from '@emotion/styled';
import { CreateUser, BlockUnblockUser } from '../components/Admin';

const { TabPane } = Tabs;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 0 10px;
  color: #000000;
  font-family: AvenirLTStdBook;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 15px;
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
  }
`;
const SuperAdmin = () => {
  return (
    <StyledCard>
      <StyledTabs defaultActiveKey="1">
        <TabPane tab="Create User" key="1">
          <CreateUser />
        </TabPane>
        <TabPane tab="Block/Unblock User" key="2">
          <BlockUnblockUser />
        </TabPane>
      </StyledTabs>
    </StyledCard>
  );
};

export default SuperAdmin;
