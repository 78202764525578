import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import FullLoadInput from './FullLoadInput';
import SearchInput from '../SearchInput';
import Button from '../Button';

const FullLoadOperations = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const FullLoadRow = styled.div`
  display: grid;
  grid-template-columns: ${(prop) =>
    prop.isQuote
      ? 'repeat(auto-fit,minmax(calc(100% - 75%),1fr)) 25% 25% 25%'
      : '1fr 1fr'};
  grid-gap: 15px;
  align-items: baseline;
  border-bottom: ${(props) =>
    props.fullLessLoad === 'Full' ? '1px solid #ccc' : 'none'};
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  h5 {
    margin-bottom: 20px;
  }
`;

const FullLoad = ({
  modeOfShipment,
  load = false,
  loads = [],
  loadTypes = [],
  collapsed,
  onChange = () => {},
  // eslint-disable-next-line no-unused-vars
  onBlur = () => {},
  loading = false,
  isQuote = false
}) => {
  const [fullLessLoad, setFullLessLoad] = useState(() =>
    load ? 'Full' : 'Less'
  );
  const loadItemInit = (containerLoadType = fullLessLoad) => {
    if (containerLoadType === 'Less') {
      return {
        id: randomstring.generate(),
        amount: 1,
        type: null
      };
    }
    return {
      id: randomstring.generate(),
      amount: null,
      type: undefined
    };
  };

  const [container, setContainer] = useState(true);
  const [errors, setErrors] = useState({});
  const [label, setLabel] = useState('');
  const [fullLessLoadChoices, setFullLessLoadChoices] = useState([]);
  const [loadItems, setLoads] = useState(() => {
    if (loads.length > 0) {
      return loads.map((loadItem) => {
        return {
          ...loadItem,
          id: loadItem.pId
        };
      });
    }
    return [loadItemInit()];
  });

  useEffect(() => {
    if (fullLessLoad === 'Less') {
      setErrors({});
    }
  }, [fullLessLoad]);

  useEffect(() => {
    if (fullLessLoad === 'Full') {
      if (modeOfShipment === 'Sea') {
        setLabel('FCL');
      }
      if (modeOfShipment === 'Land') {
        setLabel('FTL');
      }
    }
  }, [fullLessLoad, modeOfShipment]);

  const handleChange = (idx) => (e, err) => {
    const loadChange = [...loadItems];
    loadChange[idx] = e;
    setLoads(loadChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [idx]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [idx]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const handleOnBlur = (idx) => (e, err) => {
    if (err !== null) {
      setErrors({
        ...errors,
        [idx]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [idx]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  useDeepCompareEffect(() => {
    onChange({ fullLessLoad, loadItems }, Object.keys(errors).length > 0);
  }, [fullLessLoad, loadItems, errors]);

  useEffect(() => {
    if (modeOfShipment === 'Sea') {
      setFullLessLoadChoices([
        {
          label: 'Full container load (FCL)',
          value: 'Full'
        },
        {
          label: 'Less than container Load (LCL)',
          value: 'Less'
        }
      ]);
      setContainer(true);
    }
    if (modeOfShipment === 'Land') {
      setFullLessLoadChoices([
        {
          label: 'Full truck load (FTL)',
          value: 'Full'
        },
        {
          label: 'Less than truck Load (LTL)',
          value: 'Less'
        }
      ]);
      setContainer(false);
    }
  }, [modeOfShipment]);

  const newLoad = () => {
    setLoads([...loadItems, loadItemInit()]);
  };

  const removeLoad = (idx) => () => {
    const newLoads = [...loadItems];
    newLoads.splice(idx, 1);
    const { [idx]: _err, ...errs } = errors;
    setErrors(errs);
    setLoads(newLoads);
  };

  return (
    <>
      {!collapsed && (
        <>
          {fullLessLoad === 'Full' && (
            <FullLoadOperations>
              <Button type="secondary" onClick={newLoad}>
                <Icon type="plus-circle" />
                Add {modeOfShipment === 'Sea' ? 'container' : 'truck'} type
              </Button>
            </FullLoadOperations>
          )}
          <FullLoadRow fullLessLoad={fullLessLoad} isQuote={isQuote}>
            <div>
              <h5>Details</h5>
              <p>Container Load</p>
              <SearchInput
                value={fullLessLoad}
                items={fullLessLoadChoices}
                onChange={(shipmentLoad) => {
                  setFullLessLoad(shipmentLoad);
                  setLoads([loadItemInit(shipmentLoad)]);
                }}
              />
            </div>
          </FullLoadRow>
          {loadItems.map((loadItem, idx) => {
            return (
              <FullLoadInput
                key={loadItem.id}
                label={`${label} Set ${idx + 1}`}
                value={loadItem}
                showDelete={loadItems.length > 1}
                onDelete={removeLoad(idx)}
                loadTypes={loadTypes}
                loading={loading}
                onChange={handleChange(idx)}
                onBlur={handleOnBlur(idx)}
                container={container}
                fullLessLoad={fullLessLoad}
                isQuote={isQuote}
              />
            );
          })}
        </>
      )}
    </>
  );
};
export default FullLoad;
