import React, { useState } from 'react';
import { Icon } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import Button from '../Button';
import MawbInput from './MawbInput';
import HawbInput from './HawbInput';

const ContainerAddMawb = styled.div`
  display: flex;
  padding-top: 20px;
  border-top: solid 2px #cccccc;
`;

const ContainerAddHawb = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

const FlightInformation = ({
  mawbs = [],
  collapsed,
  onChange = () => {},
  // eslint-disable-next-line no-unused-vars
  onBlur = () => {}
}) => {
  const mawbItemInit = () => {
    return {
      id: randomstring.generate(),
      mawbNo: null,
      originAirport: null,
      destinationAirport: null,
      etd: null,
      eta: null,
      flightNo: null,
      grossWeight: null,
      chargeableWeight: null,
      totalUnit: null,
      hawbs: []
    };
  };

  const hawbItemInit = () => {
    return {
      id: randomstring.generate(),
      hawbNo: null,
      grossWeight: null,
      chargeableWeight: null,
      totalUnit: null
    };
  };

  const [errors, setErrors] = useState({});
  const [mawbItems, setMawbs] = useState(() => {
    if (mawbs.length > 0) {
      return mawbs.map((mawbItem) => {
        return {
          ...mawbItem,
          id: randomstring.generate()
        };
      });
    }
    return [mawbItemInit()];
  });

  const handleOnBlur = (idx) => (e, err) => {
    if (err !== null) {
      setErrors({
        ...errors,
        [idx]: err
      });
    } else {
      const { [idx]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const handleMawbChange = (idx) => (e, err) => {
    const mawbChange = [...mawbItems];
    mawbChange[idx] = e;
    setMawbs(mawbChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [idx]: err
      });
    } else {
      const { [idx]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const newMawb = () => {
    setMawbs([...mawbItems, mawbItemInit()]);
  };

  const removeMawb = (idx) => () => {
    const newMawbs = [...mawbItems];
    newMawbs.splice(idx, 1);
    const { [idx]: _err, ...errs } = errors;
    setErrors(errs);
    setMawbs(newMawbs);
  };

  const handleHawbChange = (idx, jdx) => (e, err) => {
    const mawbChange = [...mawbItems];
    mawbChange[idx].hawbs[jdx] = e;

    setMawbs(mawbChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [idx]: err
      });
    } else {
      const { [idx]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const newHawb = (idx) => () => {
    const mawbChange = [...mawbItems];
    const newHawbItem = [...mawbChange[idx].hawbs, hawbItemInit()];

    const changeMawbItem = {
      ...mawbChange[idx],
      hawbs: newHawbItem
    };

    mawbChange[idx] = changeMawbItem;
    setMawbs(mawbChange);
  };

  const removeHawb = (idx, id) => () => {
    const mawbChange = [...mawbItems];
    const newHawbItem = mawbChange[idx].hawbs.filter((hawb) => hawb.id !== id);

    const changeMawbItem = {
      ...mawbChange[idx],
      hawbs: newHawbItem
    };

    mawbChange[idx] = changeMawbItem;
    setMawbs(mawbChange);
  };

  useDeepCompareEffect(() => {
    const newErrors = {};
    mawbItems.forEach((val, index) => {
      if (errors[index]) {
        newErrors[index] = errors[index];
      }
    });

    setErrors(newErrors);
    onChange(mawbItems, Object.keys(newErrors).length > 0);
  }, [mawbItems, errors]);

  return (
    <>
      {!collapsed && (
        <>
          <div>
            {mawbItems.map((mawbItem, idx) => {
              return (
                <>
                  <MawbInput
                    id={mawbItem.id}
                    key={mawbItem.id}
                    mawb={mawbItem}
                    hawbs={mawbItem?.hawbs}
                    label={`MAWB ${idx + 1}`}
                    showDelete={mawbItems.length > 1}
                    onDelete={removeMawb(idx)}
                    onChange={handleMawbChange(idx)}
                    onBlur={handleOnBlur(idx)}
                  />
                  {mawbItem?.hawbs?.map((hawbItem, jdx) => {
                    return (
                      <HawbInput
                        id={hawbItem.id}
                        key={hawbItem.id}
                        value={hawbItem}
                        label={`MAWB ${idx + 1} > HAWB ${jdx + 1}`}
                        onDelete={removeHawb(idx, hawbItem.id)}
                        onChange={handleHawbChange(idx, jdx)}
                        onBlur={handleOnBlur(jdx)}
                      />
                    );
                  })}
                  <ContainerAddHawb>
                    <Button type="secondary" onClick={newHawb(idx, mawbItem)}>
                      <Icon type="plus-circle" />
                      Add new HAWB
                    </Button>
                  </ContainerAddHawb>
                </>
              );
            })}
          </div>
          <ContainerAddMawb>
            <Button
              type="secondary"
              onClick={newMawb}
              disabled={mawbItems?.length > 2}
            >
              <Icon type="plus-circle" />
              Add new MAWB
            </Button>
          </ContainerAddMawb>
        </>
      )}
    </>
  );
};

export default FlightInformation;
