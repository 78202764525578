import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Switch from '../Switch';
import { adminService } from '../../services';

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;
`;
const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const BlockUnblockUser = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(false);

  const updateUser = async () => {
    try {
      setLoading(true);
      await adminService.blockUnblockUser(email, status);
      message.success(`User ${status ? 'Blocked' : 'Unblocked'} successfully`);
      setEmail('');
      setStatus(false);
    } catch (err) {
      const errMessage =
        err?.response?.data?.data?.message ||
        'Error Occured while creating merchant';
      message.error(errMessage);
    }
    setLoading(false);
  };

  return (
    <StyledContainer>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col span={12}>
          <p>Email</p>
          <Input
            placeholder="Type Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <p>Block User</p>
          <Switch
            checked={status}
            onChange={(checked) => {
              setStatus(checked);
            }}
          />
        </Col>
      </StyledRow>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col>
          <Button onClick={updateUser} disabled={!email} loading={loading}>
            Submit
          </Button>
        </Col>
      </StyledRow>
    </StyledContainer>
  );
};

export default BlockUnblockUser;
