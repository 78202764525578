import * as React from 'react';
import { Field } from 'formik';
import SearchInput from '../SearchInput';
import Message from '../Message';

const FormSearchInput = ({
  name,
  validate,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  validateDirty = true,
  ...restProps
}) => {
  const showError = (touched, error) => {
    if (validateDirty) {
      return <>{error && touched && <Message type="error">{error}</Message>}</>;
    }
    return <>{error && <Message type="error">{error}</Message>}</>;
  };
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta
      }) => (
        <>
          <SearchInput
            value={value}
            onChange={(selectedValue) => {
              setFieldValue(name, selectedValue);
              setFieldTouched(name, true);
              return onChange(selectedValue, setFieldValue);
            }}
            onBlur={(e) => {
              setFieldTouched(name, true);
              onBlur(e);
            }}
            onFocus={(e) => {
              onFocus(e);
            }}
            {...restProps}
          />
          {showError(meta.touched, meta.error)}
        </>
      )}
    </Field>
  );
};

export default FormSearchInput;
