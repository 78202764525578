import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { QUOTE_ACCEPT } from '../constants/events';

const StyledQuoteHeaderTitle = styled.div`
  a,
  a:hover {
    font-family: AvenirLTStdBookOblique;
    font-size: 16px;
    color: #050593;
    text-decoration: underline;
  }
`;

const QuoteHeaderTitle = ({ quoteStatus, onClick = () => {} }) => {
  const handleClick = (event) => (e) => {
    e.preventDefault();
    onClick(event);
  };

  const rejectionReason = useSelector(
    (state) => state?.quoteDetail?.quote?.quote_rejection_reason
  );
  const cancelledReason = useSelector(
    (state) => state?.quoteDetail?.quote?.shipment_cancellation_reason
  );
  const stateTitle = {
    QUOTE_REQUEST: {
      title: 'Quote has been requested',
      render: <p />
    },
    APPROVED: {
      title: 'Quote has been requested',
      render: <p />
    },
    REJECTED: {
      title: 'Quote has been requested',
      render: <p />
    },
    WIN: {
      title: 'Quote has been requested',
      render: <p />
    },
    LOSS: {
      title: 'Quote has been requested',
      render: <p />
    },
    QUOTE_DRAFT_CREATED: {
      title: 'Draft quote generated: Please review quote',
      render: <p />
    },
    QUOTE_REREQUESTED_DRAFT: {
      title: 'Draft quote generated: Please review quote',
      render: <p />
    },
    QUOTE_CREATED: {
      title: 'Quote sent: Waiting for merchant to accept quote',
      render: (
        <>
          <p>The merchant will choose if they want to accept the quote.</p>
          <p>
            Alternatively, you may choose to{' '}
            <a href="/#" onClick={handleClick(QUOTE_ACCEPT)}>
              accept the quote on behalf of your merchant
            </a>
          </p>
        </>
      )
    },
    SHIPMENT_CREATED: {
      title: 'Shipment created',
      render: <p />
    },
    QUOTE_ACCEPTED: {
      title: 'Quote accepted: Waiting for merchant documents',
      render: <p>The merchant will be uploading the required documents.</p>
    },
    QUOTE_REQUEST_UNSUCCESSFUL: {
      title: 'Quote request rejected/unsuccessful',
      render: <p>Reason: {rejectionReason}</p>
    },
    QUOTE_EXPIRED: {
      title: 'This shipment quote has expired',
      render: <p>Please request for a new quote</p>
    },
    DOCUMENT_UPLOADED: {
      title: 'Documents uploaded: Please review documents',
      render: <p>Review all the documents and confirm shipment</p>
    },
    SHIPMENT_CONFIRMED: {
      title: 'Shipment confirmed',
      render: <p />
    },
    SCHEDULED_FOR_PICKUP: {
      title: 'Shipment scheduled for pickup',
      render: <p />
    },
    SCHEDULED_FOR_DROPOFF: {
      title: 'Shipment scheduled for dropoff',
      render: <p />
    },
    RESCHEDULED_FOR_PICKUP: {
      title: 'Shipment rescheduled for pickup',
      render: <p />
    },
    RESCHEDULED_FOR_DROPOFF: {
      title: 'Shipment rescheduled for dropoff',
      render: <p />
    },
    SHIPMENT_PICKED_UP: {
      title: 'Shipment Picked Up',
      render: <p />
    },
    CARGO_DELIVERED_TO_AIRLINES_TERMINAL: {
      title: 'Cargo delivered to Airlines Terminal',
      render: <p />
    },
    FLIGHT_RESCHEDULED: {
      title: 'Flight is rescheduled',
      render: <p />
    },
    CONTAINER_LOADING: {
      title: 'Container is loading',
      render: <p />
    },
    IN_TRANSHIPMENT_COUNTRY: {
      title: 'Shipment In Transhipment Country',
      render: <p />
    },
    REACHED_ORIGIN_WAREHOUSE: {
      title: 'Shipment reached origin warehouse',
      render: <p />
    },
    RECEIVED_BY_AIRLINES: { title: 'Received by airlines', render: <p /> },
    CARGO_ONBOARD_VESSEL: { title: 'Cargo is onboard vessel', render: <p /> },
    ON_SAIL: { title: 'Shipment on sail', render: <p /> },
    PARTIAL_UPLIFT_TO_DESTINATION: {
      title: 'Partial uplift to destination',
      render: <p />
    },
    FREIGHT_UPLIFT_COMPLETED: {
      title: 'Shipment has been uplifted',
      render: <p />
    },
    OFFLOADED_BY_AIRLINES: {
      title: 'Shipment offloaded by airlines',
      render: <p />
    },
    PENDING_CUSTOM_CLEARANCE: {
      title: 'Shipment pending custom clearance',
      render: <p />
    },
    DELIVERED_WITHOUT_POD: {
      title: 'Shipment is delivered without POD',
      render: <p />
    },
    BILLED: {
      title: 'Shipment has been billed',
      render: <p />
    },
    CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY: {
      title: 'Custom Clearance in Destination Country',
      render: <p />
    },
    OUT_FOR_DELIVERY: { title: 'Shipment out for delivery', render: <p /> },
    PARTIALLY_DELIVERED: {
      title: 'Shipment partially delivered',
      render: <p />
    },
    VESSEL_DELAYED: { title: 'Vessel delayed', render: <p /> },
    SPACE_BOOKING_IN_PROGRESS: {
      title: 'Space booking in progress',
      render: <p />
    },
    DELIVERED: { title: 'Shipment delivered', render: <p /> },
    RESCHEDULED_FOR_DELIVERY: {
      title: 'Shipment rescheduled for delivery',
      render: <p />
    },
    DELIVERY_FAILED: { title: 'Shipment delivery failed', render: <p /> },
    CANCELLED: {
      title: 'Shipment cancelled',
      render: <p>Reason: {cancelledReason}</p>
    }
  };

  const { title, render } = stateTitle[quoteStatus] || {
    title: '',
    render: <></>
  };
  return (
    <StyledQuoteHeaderTitle id="quote-header-title">
      <h3>{title}</h3>
      {render}
    </StyledQuoteHeaderTitle>
  );
};

export default QuoteHeaderTitle;
