import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Popover, Icon } from 'antd';
import moment from 'moment';
import Dropdown from './Dropdown';
import Input from './Input';
import DatePicker from './DatePicker';
import Button from './Button';
import SearchInput from './SearchInput';
import { COUNTRIES } from '../constants/countries';
import { setQuoteFilters, resetFilters } from '../actions/quoteList';

const FilterContainer = styled.div`
  user-select: none;
  width: 920px;
  height: 295px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdown = ({ label, onFilter = () => {} }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const [endOpen, setEndOpen] = useState(false);
  const dispatch = useDispatch();
  const { merchants, statuses, actions, loading } = useSelector(
    (state) => state.quoteList.choices
  );

  const status = useSelector((state) => state.quoteList.filters.status);
  const trackingNumber = useSelector(
    (state) => state.quoteList.filters.trackingNumber
  );
  const quoteRequestID = useSelector(
    (state) => state.quoteList.filters.quoteRequestID
  );
  const merchantName = useSelector(
    (state) => state.quoteList.filters.merchantName
  );
  const action = useSelector((state) => state.quoteList.filters.action);
  const fromDate = useSelector((state) =>
    state.quoteList.filters.fromDate
      ? moment(state.quoteList.filters.fromDate)
      : null
  );
  const toDate = useSelector((state) =>
    state.quoteList.filters.toDate
      ? moment(state.quoteList.filters.toDate)
      : null
  );
  const origin = useSelector((state) => state.quoteList.filters.origin);
  const destination = useSelector(
    (state) => state.quoteList.filters.destination
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };
  const handleFilter = () => {
    onFilter({
      status: status === 'All' ? null : status,
      trackingNumber,
      merchantName,
      quoteRequestID,
      action: action === 'All' ? null : action,
      fromDate,
      toDate,
      origin: origin === 'All' ? null : origin,
      destination: destination === 'All' ? '' : destination
    });
    setVisible(false);
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setQuoteFilters({ [field]: option?.props?.value }));
  };

  const handleStartOpenChange = (open) => {
    if (!open && !toDate) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const disabledStartDate = (startDate) => {
    if (!startDate || !toDate) {
      return false;
    }
    return startDate.valueOf() > toDate.valueOf();
  };

  const disabledEndDate = (endDate) => {
    if (!endDate || !fromDate) {
      return false;
    }
    return endDate.valueOf() <= fromDate.valueOf();
  };

  const handleDatePickerChange = (field) => (value) => {
    if (field === 'fromDate') {
      dispatch(
        setQuoteFilters({ fromDate: value.startOf('day').toISOString() })
      );
    }
    if (field === 'toDate') {
      dispatch(setQuoteFilters({ toDate: value.endOf('day').toISOString() }));
    }
  };

  const handleTrackerChange = (e) => {
    dispatch(setQuoteFilters({ trackingNumber: e.target.value }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (!node.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <header>By Quote</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="tracking-number">
                    Tracking Number{' '}
                    <Popover
                      placement="topLeft"
                      arrowPointAtCenter
                      overlayClassName="tooltip"
                      content={
                        <div className="tooltip-content">
                          The tracking number is a unique ID referring to a
                          quote or shipment
                        </div>
                      }
                      title="Tracking Number"
                      getPopupContainer={() => node.current}
                    >
                      <Icon type="question-circle" />
                    </Popover>
                  </label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={trackingNumber}
                    onChange={handleTrackerChange}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="merchant-name">Merchant Name</label>
                  <SearchInput
                    allowClear
                    placeholder="Type or select..."
                    id="merchant-name"
                    items={merchants}
                    loading={loading}
                    value={merchantName}
                    onChange={handleSearchInputChange('merchantName')}
                  />
                </Col>
              </Row>
              <header>By Status</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="quote-public-status">
                    Quote Public Status
                  </label>
                  <SearchInput
                    id="quote-public-status"
                    items={statuses}
                    loading={loading}
                    value={status}
                    onChange={handleSearchInputChange('status')}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="action-required">Action Required</label>
                  <SearchInput
                    id="action-required"
                    items={actions}
                    loading={loading}
                    value={action}
                    onChange={handleSearchInputChange('action')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <header>By Date</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="from-date">
                    From Date{' '}
                    <Popover
                      placement="topLeft"
                      arrowPointAtCenter
                      overlayClassName="tooltip"
                      content={
                        <div className="tooltip-content">
                          A quote may be filtered here by the date range where
                          it is first requested
                        </div>
                      }
                      title="Filter by Dates"
                      getPopupContainer={() => node.current}
                    >
                      <Icon type="question-circle" />
                    </Popover>
                  </label>
                  <DatePicker
                    id="from-date"
                    disabledDate={disabledStartDate}
                    value={fromDate}
                    onChange={handleDatePickerChange('fromDate')}
                    onOpenChange={handleStartOpenChange}
                    contained
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="to-date">To Date</label>
                  <DatePicker
                    id="to-date"
                    disabledDate={disabledEndDate}
                    value={toDate}
                    onChange={handleDatePickerChange('toDate')}
                    onOpenChange={handleEndOpenChange}
                    open={endOpen}
                    contained
                  />
                </Col>
              </Row>
              <header>By Place</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="origin-country">Origin Country</label>
                  <SearchInput
                    id="origin-country"
                    items={COUNTRIES}
                    value={origin}
                    valueKey="value"
                    onChange={handleSearchInputChange('origin')}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="destination-country">
                    Destination Country
                  </label>
                  <SearchInput
                    id="destination-country"
                    items={COUNTRIES}
                    value={destination}
                    valueKey="value"
                    onChange={handleSearchInputChange('destination')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdown;
