/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import moment from 'moment';
import * as Yup from 'yup';
import COUNTRY_LIST from '../constants/countries';
import SearchInput from './SearchInput';
import DatePicker from './DatePicker';
import AddAddressInput from './AddAddressInput';
import Message from './Message';
import getPortsList from '../helpers/portList';
import { FormInput, FormSearchInput } from './Forms';

import {
  getFormAddress,
  resetFormAddress,
  setFormAddress,
  setFormAddressCountry,
  setFormAddressDate,
  setFormAddressLocation,
  setFormAddressPort,
  setFormAddressReadinessDate
} from '../actions/shipmentForm';

const CustomMessage = styled.p`
  color: #050593;
  margin-top: 8px;
`;
const PickupDeliveryDetails = ({
  otherDate,
  source,
  service: currentService,
  value,
  shipmentType = undefined,
  isInternal = false,
  onChange = () => {},
  onBlur = () => {},
  isQuote = false,
  isConvertQuote = false
}) => {
  const dispatch = useDispatch();
  const [hideAdd, setHideAdd] = useState(false);
  const [service, setService] = useState(1);
  const sourceData = useSelector((state) => state.shipmentForm[source]);
  const addressList = useSelector(
    (state) => state.shipmentForm[source].data || []
  );
  const loadAddress = useSelector(
    (state) => state.shipmentForm[source]?.loading
  );
  const country = useSelector((state) => state.shipmentForm[source]?.country);
  const address = useSelector(
    (state) => state.shipmentForm[source]?.value?.location_id
  );
  const date = useSelector((state) => state.shipmentForm[source]?.date);
  const cargoReadinessDate = useSelector(
    (state) => state.shipmentForm[source]?.cargoReadinessDate
  );
  const port = useSelector((state) => state.shipmentForm[source]?.port);
  const [errors, setErrors] = useState(null);

  const dirty = useRef({
    country: false,
    date: false,
    address: false
  });
  const [labels, setLabels] = useState({
    address: 'Address',
    date: 'Date',
    customMessage: ''
  });

  useDeepCompareEffect(() => {
    if (value.street || value.country) {
      dispatch(setFormAddress({ source, data: value }));
    }
  }, [source, value]);

  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .nullable()
      .required(`Please enter ${labels.date}`),
    address: Yup.string()
      .nullable()
      .test('address', `Please enter ${labels.address}`, (val) => {
        if (currentService !== 2) {
          return val !== undefined;
        }
        return true;
      }),
    country: Yup.string()
      .nullable()
      .required(
        `Please enter ${source === 'origin' ? 'Origin' : 'Destination'} Country`
      )
  });

  const validate = () => {
    validationSchema
      .validate({ country, date, address }, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          return {
            ...ers,
            [er.path]: er.message
          };
        }, {});
        setErrors(errs);
      });
  };

  useEffect(() => {
    if (!isConvertQuote) {
      if (
        !dirty.current.country ||
        !dirty.current.address ||
        !dirty.current.date
      ) {
        validate();
      }
    }
    return () => {
      dispatch(resetFormAddress(source));
    };
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current.country || dirty.current.address || dirty.current.date) {
      validate();
    }
    onChange(
      {
        country: sourceData.country,
        date: sourceData.date,
        port: sourceData.port,
        cargoReadinessDate: sourceData.cargoReadinessDate,
        address: sourceData.value?.location_id || undefined,
        ...sourceData.value
      },
      errors !== null
    );
  }, [sourceData, errors]);

  useEffect(() => {
    setService(currentService);
    if (!isConvertQuote) {
      validate();
    }
  }, [currentService]);

  useEffect(() => {
    if (country) {
      if (service !== 2) dispatch(getFormAddress(source, country, !!service));
    }
  }, [country]);

  useEffect(() => {
    switch (source) {
      case 'origin':
        if (service === 1) {
          setLabels({
            address: 'Address',
            date: 'Estimated Pickup Date'
          });
          setHideAdd(false);
        } else if (service === 0) {
          setLabels({
            address: 'Dropoff Address',
            date: 'Estimated Dropoff Date'
          });
          setHideAdd(true);
        } else {
          setLabels({
            date: 'Estimated Pickup Date'
          });
        }
        break;
      case 'destination': {
        let estDeliveryDate = '';
        if (shipmentType == 'Air') estDeliveryDate = '5-7 days';
        if (shipmentType == 'Sea') estDeliveryDate = '10-14 days';
        if (shipmentType == 'Land') estDeliveryDate = '5-7 days';

        if (service) {
          setLabels({
            address: 'Address',
            date: 'Target Delivery Date',
            customMessage: `Est. delivery in ${estDeliveryDate}`
          });
          setHideAdd(false);
        } else {
          setLabels({
            address: 'Pickup Address',
            date: 'Target Delivery Date',
            customMessage: `Est. delivery in ${estDeliveryDate}`
          });
          setHideAdd(true);
        }
        break;
      }
      default:
    }
    if (dirty.current) {
      dirty.current = {
        country: false,
        address: false,
        date: false
      };
      // dispatch(resetFormAddress(source));
    } else if (country && service !== 2) {
      dispatch(getFormAddress(source, country, !!service));
    }
    if (service === 2) {
      dispatch(setFormAddressLocation({ source, data: undefined }));
    }
  }, [source, service, shipmentType]);

  useEffect(() => {
    dispatch(setFormAddressPort({ source, data: value.port }));
  }, []);

  useEffect(() => {
    if (country && service !== 2 && !address) {
      dispatch(getFormAddress(source, country, !!service, true));
    }
  }, [service]);

  const sourceDateCondition = (sourceDate) => {
    if (source === 'destination') return sourceDate.isBefore(otherDate, 'day');
    return sourceDate.isAfter(otherDate, 'day');
  };

  const disableDates = (sourceDate) => {
    if (!otherDate) {
      return isInternal ? false : sourceDate.isBefore(moment(), 'day');
    }

    if (isInternal) {
      return sourceDateCondition(sourceDate);
    }
    return (
      sourceDate.isBefore(moment(), 'day') || sourceDateCondition(sourceDate)
    );
  };

  const formatAddress = (addr) => {
    const label = [
      addr?.location_name,
      addr?.street_address,
      addr?.city,
      addr?.state,
      addr?.postal_code
    ]
      .filter(Boolean)
      .join(', ');
    return {
      label,
      value: addr?.location_id
    };
  };

  const handleChange = (name) => (e) => {
    switch (name) {
      case 'country':
        dirty.current.country = true;
        dispatch(setFormAddressCountry({ source, data: e }));
        break;
      case 'date':
        dirty.current.date = true;
        dispatch(setFormAddressDate({ source, data: e }));
        break;
      case 'address':
        dirty.current.address = true;
        dispatch(setFormAddressLocation({ source, data: e }));
        break;
      case 'port':
        dispatch(setFormAddressPort({ source, data: e }));
        break;
      case 'cargoReadinessDate':
        dispatch(setFormAddressReadinessDate({ source, data: e }));
        break;
      default:
    }
  };

  const handleFocus = () => {
    if (!country) {
      // this or remove abortEarly from validate
      dirty.current.country = true;
      validate();
    }
  };

  const handleAddAddress = (add) => {
    if (country === add.country && service !== 2) {
      dispatch(getFormAddress(source, country, !!service, true));
    }
  };

  const handleBlur = (field) => () => {
    if (
      (field === 'address' && country) ||
      field === 'country' ||
      field === 'date'
    ) {
      dirty.current[field] = true;
      validate();
    }
    onBlur({ country, address, date }, errors !== null);
  };

  const portList = getPortsList(shipmentType, country);

  return (
    <>
      <Row>
        <Col className="input-wrapper">
          <p>Country/Region</p>
          <SearchInput
            name="country"
            placeholder="Select..."
            value={country}
            items={COUNTRY_LIST}
            onChange={handleChange('country')}
            onBlur={handleBlur('country')}
          />
          {errors?.country && dirty.current.country && (
            <Message type="error">{errors?.country}</Message>
          )}
        </Col>
        {service !== 2 && (
          <Col className="input-wrapper">
            <p>{labels.address}</p>
            <AddAddressInput
              hideAdd={hideAdd}
              name="address"
              placeholder="Select..."
              value={address}
              items={addressList.map(formatAddress)}
              loading={loadAddress}
              onChange={handleChange('address')}
              onAdded={handleAddAddress}
              onFocus={handleFocus}
              onBlur={handleBlur('address')}
            />
            {errors?.address && dirty.current.address && (
              <Message type="error">{errors?.address}</Message>
            )}
          </Col>
        )}
        <Col className="input-wrapper">
          <p>
            {source === 'origin' ? 'Origin' : 'Destination'} Airport/Port
            (Optional)
          </p>
          {shipmentType === 'Land' && (
            <FormInput
              name={`${source}.port`}
              onChange={(e) => handleChange('port')(e.target.value)}
              value={port}
            />
          )}
          {shipmentType !== 'Land' && (
            <FormSearchInput
              items={portList}
              allowClear
              name={`${source}.port`}
              onChange={handleChange('port')}
              value={port}
              loading={false}
            />
          )}
        </Col>
        {service === 2 && (
          <Col className="input-wrapper">
            <p>{labels.date}</p>
            <DatePicker
              name="date"
              value={date}
              placeholder="Select..."
              disabledDate={disableDates}
              onChange={handleChange('date')}
              onOpenChange={(opened) => {
                if (!opened) {
                  handleBlur('date')();
                }
              }}
              contained
            />
            {shipmentType && (
              <CustomMessage>{labels.customMessage}</CustomMessage>
            )}
            {errors?.date && dirty.current.date && (
              <Message type="error">{errors?.date}</Message>
            )}
          </Col>
        )}
      </Row>
      <Row>
        {service !== 2 && (
          <Col className="input-wrapper">
            <p>{labels.date}</p>
            <DatePicker
              name="date"
              value={date}
              placeholder="Select..."
              disabledDate={disableDates}
              onChange={handleChange('date')}
              onOpenChange={(opened) => {
                if (!opened) {
                  handleBlur('date')();
                }
              }}
              contained
            />
            {shipmentType && (
              <CustomMessage>{labels.customMessage}</CustomMessage>
            )}
            {errors?.date && dirty.current.date && (
              <Message type="error">{errors?.date}</Message>
            )}
          </Col>
        )}
        {source === 'origin' && (
          <>
            <Col className="input-wrapper">
              <p>Shipper&apos;s Name</p>
              <FormInput placeholder="Type here" name="shipperName" />
            </Col>
            {isQuote && (
              <Col className="input-wrapper">
                <p>Cargo Readiness Date (Optional)</p>
                <DatePicker
                  name="cargoReadinessDate"
                  value={cargoReadinessDate}
                  placeholder="Select..."
                  onChange={handleChange('cargoReadinessDate')}
                  onOpenChange={(opened) => {
                    if (!opened) {
                      handleBlur('cargoReadinessDate')();
                    }
                  }}
                  contained
                />
                {errors?.cargoReadinessDate &&
                  dirty.current.cargoReadinessDate && (
                    <Message type="error">{errors?.cargoReadinessDate}</Message>
                  )}
              </Col>
            )}
          </>
        )}
        {source === 'destination' && (
          <Col className="input-wrapper">
            <p>Consignee&apos;s Name</p>
            <FormInput placeholder="Type here" name="consigneeName" />
          </Col>
        )}
      </Row>
    </>
  );
};

export default PickupDeliveryDetails;
