import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, message, Tooltip, Popover, Icon } from 'antd';
import * as Yup from 'yup';
import useDeepCompareEffect from 'use-deep-compare-effect';
import debounce from 'lodash/debounce';
import { billingService } from '../../services/billing';
import { costService } from '../../services/cost';
import Button from '../Button';
import SelectTags from '../SelectTags';
import TextArea from '../TextArea';
import Input from '../Input';
import Table from '../Table';
import FloatInput from '../FloatInput';
import SearchInput from '../SearchInput';
import Message from '../Message';
import Modal from '../Modal';
import AutoComplete from '../AutoComplete';
import TotalBillingCost from './TotalBillingCost';
import ExchangeRates from '../ExchangeRates';
import { ReactComponent as CopySVG } from '../../assets/icons/copy.svg';
import { ReactComponent as PasteSVG } from '../../assets/icons/paste.svg';
import { ReactComponent as DeleteSVG } from '../../assets/icons/delete.svg';
import {
  updateCostBillingDetails,
  addLineItems,
  removeLineItem,
  updateLineItem,
  saveLineItems,
  cancelLineItems,
  updateInvoiceDetails
} from '../../actions/costBillingDetail';
import { toCamelCase } from '../../utils/formatString';
import { initValidators } from '../../helpers/validation';
import { getProfileSync } from '../../auth0';
import { calculateTotalFromRates } from '../../utils/calculateRates';
import { BILLING_COUNTRIES } from '../../constants/countries';
import feeName from '../../constants/feeNames';
import {
  TEXT_BILLING_SAVE_SUCCESS,
  TEXT_BILLING_SAVE_ERROR
} from '../../constants/message';
import invoiceCurrencies from '../../constants/invoiceCurrencies';
import ColumnDropdown from './ColumnDropdown';

const { confirm: modalConfirm } = Modal;
const { Option } = SelectTags;

const StyledPopover = styled(Popover)`
  cursor: pointer;
  i {
    font-size: 14px;
  }
`;
const StyledShipmentQuote = styled.div`
  font-family: AvenirLTStdBook;
`;
const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubTitle = styled.p`
  font-size: 16px;
  font-family: AvenirLTStdHeavy;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const InfoTitle = styled.p`
  font-size: 16px;
  color: #000;
  font-style: normal;
  margin-bottom: 8px;
`;
const InfoValue = styled.p`
  color: #000000;
  font-size: 16px;
`;
const Section = styled.div`
  padding-bottom: 15px;
  border-bottom: ${(props) => (props.last ? '0' : '1px solid #cccccc')};
  .ant-table-body {
    margin: 0 !important;
  }
  .ant-table-tbody tr {
    td div.actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
  }
  .ant-select-selection--multiple .ant-select-selection__choice__content {
    max-width: 135px !important;
  }
  .ant-select-combobox .ant-select-search__field {
    z-index: 0 !important;
  }
  /* Chrome Fixes */
  .ant-table-thead tr {
    th:nth-child(1) {
      min-width: 160px;
    }
    th:nth-child(2) {
      min-width: 140px;
    }
    th:nth-child(3) {
      min-width: 75px;
    }
    th:nth-child(4) {
      min-width: 100px;
    }
    th:nth-child(5) {
      min-width: 75px;
    }
    th:nth-child(6) {
      min-width: 125px;
    }
    th:nth-child(7) {
      min-width: 41px;
    }
  }
`;
const ActionButtonContainer = styled.div`
  padding-top: 15px;
  border-top: 1px solid #050593;
`;
const LineItemButtom = styled(Button)`
  margin: 10px 0;
`;
const StyledButton = styled(Button)`
  width: 150px;
  padding: 0;
`;

const comments = ['Flat fee', 'Per Unit'];

const isBillingLineItemBlank = (data) => {
  return (
    !data?.comment &&
    !data?.description &&
    !data?.billing_awb &&
    !data?.weight &&
    !data?.unit_price &&
    !data?.currency &&
    !data?.total_amount &&
    !data?.xero_client_id &&
    !data?.billing_country &&
    !data?.bill_invoice_number &&
    !data?.invoice_link &&
    !data?.credit_note_no &&
    !data?.credit_note_link
  );
};

const isCostLineItemBlank = (data) => {
  return (
    !data?.network_partner &&
    !data?.cost_description &&
    !data?.cost_awb &&
    !data?.invoice_number &&
    !data?.fee_type &&
    !data?.units &&
    !data?.currency &&
    !data?.unit_price &&
    !data?.total_amount
  );
};

const CostBillingDetailsEdit = ({ quoteId, onCancel }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [validationType, setValidationType] = useState(null);
  const [saving, setSaving] = useState();
  const [submitBtn, setSubmitBtn] = useState();
  const [rates, setRates] = useState(null);
  const [totalRates, setTotalRates] = useState({ total: 0, exchangeRates: [] });
  const [options, setOptions] = useState({});
  const [copyInvoiceInfo, setCopyInvoiceInfo] = useState(null);
  const [xeroLoading, setXeroLoading] = useState({});
  useEffect(() => {
    const getRates = async () => {
      const rateList = await billingService.getExchangeRates();
      setRates(rateList.data);
    };
    getRates();
  }, [dispatch, quoteId]);
  const handleInfoChange = (type, value) => {
    dispatch(updateCostBillingDetails({ type, value }));
  };
  const handleAddLineItems = () => {
    dispatch(addLineItems());
  };
  const handleRemoveLineItem = (key) => {
    dispatch(removeLineItem(key));
  };
  const handleLineItemChange = (key, type, name, value) => {
    dispatch(updateLineItem({ key, type, name, value }));
  };
  const onPasteInvoiceDetails = (key, value) => {
    dispatch(updateInvoiceDetails({ key, value }));
  };
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const costBillingData = useSelector(
    (state) => state?.costBillingDetails?.costBillingData
  );
  const dataSource = costBillingData?.temp_quote_line_items.map((item) => ({
    key: item.key,
    fee_name: item.fee_name || undefined,
    network_partner: item?.cost?.network_partner || undefined,
    np_invoice_number: item?.cost?.invoice_number || undefined,
    cost_awb: item?.cost?.cost_awb || undefined,
    cost_fee_type: item?.cost?.fee_type || undefined,
    cost_currency: item?.cost?.currency || undefined,
    cost_units: item?.cost?.units,
    cost_description: item?.cost?.cost_description || undefined,
    cost_unit_price: item?.cost?.unit_price,
    cost_total_amount: item?.cost?.total_amount
      ? `${item?.cost?.currency || ''} ${item?.cost?.total_amount}`
      : undefined,
    xero_client_name: item?.billing?.xero_client_name || undefined,
    xero_client_id: item?.billing?.xero_client_id || undefined,
    billing_awb: item?.billing?.billing_awb || undefined,
    billing_country: item?.billing?.billing_country || undefined,
    comment: item?.billing?.comment || undefined,
    description: item?.billing?.description || undefined,
    weight: item?.billing?.weight,
    billing_unit_price: item?.billing?.unit_price,
    billing_total_amount: item?.billing?.total_amount
      ? `${item?.billing?.currency || ''} ${item?.billing?.total_amount}`
      : undefined,
    billing_currency: item?.billing?.currency || undefined,
    invoice_number: item?.billing?.bill_invoice_number || undefined,
    invoice_link: item?.billing?.invoice_link || undefined,
    credit_note_no: item?.billing?.credit_note_no || undefined,
    credit_note_link: item?.billing?.credit_note_link || undefined
  }));
  const validationSchema = Yup.object().shape({
    lineItems: Yup.object().shape({
      billing: Yup.array().of(
        Yup.object().shape({
          billing_country: Yup.string()
            .nullable()
            .required('Please choose Janio billing entity'),
          xero_client_id: Yup.string()
            .nullable()
            .required('Please choose Bill to'),
          weight: Yup.string()
            .nullable()
            .required('Please enter No. of units'),
          currency: Yup.string()
            .nullable()
            .required('Please choose a Bill currency'),
          unit_price: Yup.string()
            .nullable()
            .required('Please enter Bill unit price')
        })
      ),
      cost: Yup.array().of(
        Yup.object().shape({
          units: Yup.string()
            .nullable()
            .required('Please enter No. of units'),
          currency: Yup.string()
            .nullable()
            .required('Please choose a Cost currency'),
          unit_price: Yup.string()
            .nullable()
            .required('Please enter Cost unit price')
        })
      )
    }),
    operator_remark: Yup.string()
      .nullable()
      .test(
        'length',
        'You have exceeded the maximum 250 characters length limit',
        (val) => ((val && val.length) || 0) < 250
      ),
    finance_remark: Yup.string()
      .nullable()
      .test(
        'length',
        'You have exceeded the maximum 250 characters length limit',
        (val) => ((val && val.length) || 0) < 250
      )
  });
  const validate = (type) => {
    setErrors(null);
    if (type === 'billing') {
      return validationSchema.validate(
        {
          lineItems: {
            billing: costBillingData?.temp_quote_line_items
              .filter((line_item) => !isBillingLineItemBlank(line_item.billing))
              .map((line_item) => line_item.billing)
          },
          operator_remark: costBillingData?.operator_remark,
          finance_remark: costBillingData?.finance_remark
        },
        { abortEarly: false }
      );
    }
    return validationSchema.validate(
      {
        lineItems: {
          cost: costBillingData?.temp_quote_line_items
            .filter((line_item) => !isCostLineItemBlank(line_item.cost))
            .map((line_item) => line_item.cost)
        },
        operator_remark: costBillingData?.operator_remark,
        finance_remark: costBillingData?.finance_remark
      },
      { abortEarly: false }
    );
  };
  const buildErrors = (err) => {
    let errs = err.inner.reduce((ers, er) => {
      return {
        ...ers,
        [er.path]: er.message
      };
    }, {});
    Object.keys(errs).some((errKey) => {
      if (errKey.startsWith('lineItems')) {
        errs = { ...errs, lineItems: errs[errKey] };
        return true;
      }
      return false;
    });
    setErrors(errs);
    return errs;
  };
  useDeepCompareEffect(() => {
    setTotalRates(
      calculateTotalFromRates({
        target: 'USD',
        lineTotals: costBillingData?.temp_quote_line_items,
        rates
      })
    );
    const validateErrors = async () => {
      if (errors) {
        try {
          if (validationType) await validate(validationType);
        } catch (err) {
          buildErrors(err);
        }
      }
    };
    validateErrors();
  }, [costBillingData?.temp_quote_line_items, rates]);

  initValidators();
  const profile = getProfileSync();
  const isFinance = () => {
    const roles = profile['https://b2b.janio.asia/roles'] || [];
    return roles.includes('ops/finance');
  };
  const handleCancel = () => {
    dispatch(cancelLineItems());
    onCancel();
  };

  const handleSave = async (cost_locked = false, billing_locked = false) => {
    try {
      setSaving(true);
      const isCostExceedBillingPrice =
        ((costBillingData?.is_cost_locked &&
          !costBillingData?.is_billing_locked &&
          billing_locked) ||
          (costBillingData?.is_billing_locked &&
            !costBillingData?.is_cost_locked &&
            cost_locked)) &&
        totalRates.grossProfit < 0;
      const billingData = {
        quoteId,
        operatorName: profile.name,
        billingInfoAlert: false,
        quote_status: detailAPI.quote_status.replace(new RegExp('_', 'g'), ' '),
        quote_line_items: costBillingData?.temp_quote_line_items
          .filter((line_item) => !isBillingLineItemBlank(line_item.billing))
          .map((line_item) => line_item.billing),
        billings: {
          invoiceCurrency: 'USD',
          readyToBill: billing_locked,
          operatorRemark: '',
          financeRemark: '',
          billingRemark: ''
        },
        costExceedBillingPrice: isCostExceedBillingPrice,
        alertData: isCostExceedBillingPrice
          ? {
              merchantName: detailAPI?.merchant_name,
              invoiceCurrency: 'USD',
              costUSDAmount: totalRates.totalCost,
              billingUSDAmount: totalRates.totalBilling,
              billingAmount: totalRates.totalBilling
            }
          : null
      };
      await billingService
        .saveBillingInfo({ data: billingData, id: quoteId })
        .exec();
      const costData = {
        quoteId,
        operatorName: profile.name,
        quote_line_items: costBillingData?.temp_quote_line_items
          .filter((line_item) => !isCostLineItemBlank(line_item.cost))
          .map((line_item) => line_item.cost),
        cost_already_locked: costBillingData?.is_cost_locked,
        costs: {
          operatorRemark: costBillingData?.operator_remark,
          financeRemark: costBillingData?.finance_remark,
          costLocked: cost_locked
        }
      };
      await costService.saveCostInfo({ data: costData, id: quoteId }).exec();
      dispatch(saveLineItems());
      setSaving(false);
      message.success(TEXT_BILLING_SAVE_SUCCESS);
      onCancel();
    } catch (error) {
      const result = error?.response?.data?.result;
      setSaving(false);
      message.error(result || TEXT_BILLING_SAVE_ERROR);
      return null;
    }
    return null;
  };

  const handleLockCostBilling = async (type) => {
    try {
      setValidationType(type);
      await validate(type);
    } catch (err) {
      const errs = buildErrors(err);
      if (errs && Object.keys(errs).length > 0) {
        window.scrollTo(0, 160);
        return null;
      }
    }
    modalConfirm({
      title: `Are you sure you want to ${
        type === 'cost' ? 'lock cost' : "mark as 'Ready to Bill'"
      }?`,
      content: `${
        type === 'cost' ? 'Cost' : 'Billing'
      } information will be finalized and will be not editable for this shipment.`,
      okText: 'Yes',
      cancelText: 'No',
      width: 530,
      onOk() {
        return (async () => {
          try {
            let keyName = 'is_billing_locked';
            let cost_locked = costBillingData?.is_cost_locked;
            let billing_locked = costBillingData?.is_billing_locked;
            if (type === 'cost') {
              keyName = 'is_cost_locked';
              cost_locked = true;
            } else {
              billing_locked = true;
            }
            handleSave(cost_locked, billing_locked).then(() => {
              dispatch(
                updateCostBillingDetails({ type: keyName, value: true })
              );
            });
          } catch (error) {
            const result = error?.response?.data?.result;
            message.error(result || TEXT_BILLING_SAVE_ERROR);
          }
          return null;
        })();
      },
      onCancel() {}
    });
    return null;
  };

  const searchXero = useCallback(
    debounce(async (key, billingEntity, searchText) => {
      if (!searchText) {
        setOptions({ ...options, [key]: [] });
      } else {
        setXeroLoading({ ...xeroLoading, [key]: true });
        billingService
          .getClients({
            billingEntity,
            query: searchText
          })
          .then((contacts) => {
            const data = contacts?.data?.data || [];
            const values = data.map((contact) => ({
              text: contact.name,
              value: contact.id
            }));
            setOptions({ ...options, [key]: values });
          })
          .catch(() => {
            setOptions({ ...options, [key]: [] });
          })
          .finally(() => {
            setXeroLoading({ ...xeroLoading, [key]: false });
          });
      }
    }, 500),
    []
  );

  const tableColumns = useSelector((state) =>
    Object.keys(state.costBillingDetails.columns).reduce((cols, curr) => {
      return [
        'fee_name',
        'cost_total_amount',
        'billing_total_amount',
        ...cols,
        ...state.costBillingDetails.columns[curr]
      ];
    }, [])
  );
  const columns = [
    {
      title: 'Fee name',
      dataIndex: 'fee_name',
      key: 'fee_name',
      width: 220,
      fixed: 'left',
      className: 'tableSeparator',
      render: (text, record) => {
        const currentLineItem = costBillingData?.temp_quote_line_items.filter(
          (line_item) => line_item.key === record.key
        );
        return (costBillingData.is_cost_locked &&
          costBillingData.is_billing_locked) ||
          (costBillingData.is_cost_locked &&
            !isCostLineItemBlank(currentLineItem[0]?.cost)) ||
          (costBillingData.is_billing_locked &&
            !isBillingLineItemBlank(currentLineItem[0]?.billing)) ? (
          text || '-'
        ) : (
          <SelectTags
            mode="multiple"
            value={text}
            placeholder="Type or select"
            onChange={(value) =>
              handleLineItemChange(record.key, 'cost', 'fee_name', value)
            }
          >
            {feeName.map((name, feeNameIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={feeNameIndex} value={name}>
                {name}
              </Option>
            ))}
          </SelectTags>
        );
      }
    },
    {
      title: 'N/P name',
      dataIndex: 'network_partner',
      key: 'network_partner',
      width: 160,
      className: 'column-spacing',
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Input
            value={text}
            placeholder="Type here"
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'cost',
                'network_partner',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'Cost description',
      dataIndex: 'cost_description',
      key: 'cost_description',
      width: 240,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'cost',
                'cost_description',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'MAWB/HAWB',
      dataIndex: 'cost_awb',
      key: 'cost_awb',
      width: 180,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Input
            value={text}
            placeholder="Type here"
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'cost',
                'cost_awb',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'N/P invoice no.',
      dataIndex: 'np_invoice_number',
      key: 'np_invoice_number',
      width: 180,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Input
            value={text}
            placeholder="Type here"
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'cost',
                'invoice_number',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'Cost fee type',
      dataIndex: 'cost_fee_type',
      key: 'cost_fee_type',
      width: 140,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <SelectTags
            styles={{ minWidth: '140px' }}
            mode="multiple"
            value={text}
            placeholder="Type or select"
            onChange={(value) =>
              handleLineItemChange(record.key, 'cost', 'fee_type', value)
            }
          >
            {comments.map((comment, commentIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={commentIndex} value={comment}>
                {comment}
              </Option>
            ))}
          </SelectTags>
        )
    },
    {
      title: 'No. of units',
      dataIndex: 'cost_units',
      key: 'cost_units',
      width: 140,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Tooltip placement="bottom" title={text}>
            <FloatInput
              value={text}
              min={0}
              placeholder="Type here"
              onChange={(e) =>
                handleLineItemChange(
                  record.key,
                  'cost',
                  'units',
                  e.target.value
                )
              }
            />
          </Tooltip>
        )
    },
    {
      title: 'Cost currency',
      dataIndex: 'cost_currency',
      key: 'cost_currency',
      width: 140,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <SearchInput
            items={invoiceCurrencies.map((currency) => ({
              label: currency.value,
              value: currency.value
            }))}
            placeholder="Select"
            value={text}
            onChange={(value) =>
              handleLineItemChange(record.key, 'cost', 'currency', value)
            }
          />
        )
    },
    {
      title: 'Cost unit price',
      dataIndex: 'cost_unit_price',
      key: 'cost_unit_price',
      width: 140,
      render: (text, record) =>
        costBillingData.is_cost_locked ? (
          text || '-'
        ) : (
          <Tooltip placement="bottom" title={text}>
            <FloatInput
              value={text}
              allowNegative
              placeholder="Type here"
              onChange={(e) =>
                handleLineItemChange(
                  record.key,
                  'cost',
                  'unit_price',
                  e.target.value
                )
              }
            />
          </Tooltip>
        )
    },
    {
      title: 'Cost total',
      dataIndex: 'cost_total_amount',
      key: 'cost_total_amount',
      width: 150,
      className: 'tableSeparator',
      render: (text) => (costBillingData.is_cost_locked ? text || '-' : text)
    },
    {
      title: 'Janio billing entity',
      dataIndex: 'billing_country',
      key: 'billing_country',
      width: 180,
      className: 'column-spacing',
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          toCamelCase(text)
        ) : (
          <SearchInput
            items={BILLING_COUNTRIES.map((name) => ({
              label: toCamelCase(name),
              value: name
            }))}
            value={text}
            allowClear
            placeholder="Select"
            onChange={(value) => {
              handleLineItemChange(
                record.key,
                'billing',
                'billing_country',
                value
              );
              setOptions({ ...options, [record.key]: [] });
              handleLineItemChange(record.key, 'billing', 'xeroClient', {
                value: undefined,
                text: undefined
              });
            }}
          />
        )
    },
    {
      title: 'Bill to',
      dataIndex: 'xero_client_id',
      key: 'xero_client_id',
      width: 200,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          record.xero_client_name || '-'
        ) : (
          <AutoComplete
            loading={xeroLoading[record.key] || false}
            dataSource={options[record.key] || []}
            placeholder="Type here"
            value={options[record.key]?.length ? text : record.xero_client_name}
            style={{
              width: 200
            }}
            onChange={(value, option) => {
              handleLineItemChange(record.key, 'billing', 'xeroClient', {
                value,
                text: option.props.children
              });
            }}
            onBlur={() => {
              if (record?.xero_client_id === record?.xero_client_name)
                handleLineItemChange(record.key, 'billing', 'xeroClient', {
                  value: undefined,
                  text: undefined
                });
            }}
            onSearch={(searchText) =>
              record?.billing_country &&
              searchXero(record.key, record?.billing_country, searchText)
            }
          />
        )
    },
    {
      title: 'Bill comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 160,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <SelectTags
            mode="multiple"
            value={text}
            placeholder="Type or select"
            onChange={(value) =>
              handleLineItemChange(record.key, 'billing', 'comment', value)
            }
          >
            {comments.map((comment, commentIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={commentIndex} value={comment}>
                {comment}
              </Option>
            ))}
          </SelectTags>
        )
    },
    {
      title: 'Billing description',
      dataIndex: 'description',
      key: 'description',
      width: 240,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'description',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'MAWB/HAWB',
      dataIndex: 'billing_awb',
      key: 'billing_awb',
      width: 180,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <Input
            value={text}
            placeholder="Type here"
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'billing_awb',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'No. of units',
      dataIndex: 'weight',
      key: 'weight',
      width: 120,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <Tooltip placement="bottom" title={text}>
            <FloatInput
              value={text}
              min={0}
              placeholder="Type here"
              onChange={(e) =>
                handleLineItemChange(
                  record.key,
                  'billing',
                  'weight',
                  e.target.value
                )
              }
            />
          </Tooltip>
        )
    },
    {
      title: 'Bill currency',
      dataIndex: 'billing_currency',
      key: 'billing_currency',
      width: 110,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <SearchInput
            styles={{ minWidth: '100px' }}
            allowClear
            items={invoiceCurrencies.map((currency) => ({
              label: currency.value,
              value: currency.value
            }))}
            value={text}
            placeholder="Select"
            onChange={(value) =>
              handleLineItemChange(record.key, 'billing', 'currency', value)
            }
          />
        )
    },
    {
      title: 'Bill unit price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      width: 140,
      render: (text, record) =>
        costBillingData.is_billing_locked ? (
          text || '-'
        ) : (
          <Tooltip placement="bottom" title={text}>
            <FloatInput
              value={text}
              placeholder="Type here"
              onChange={(e) =>
                handleLineItemChange(
                  record.key,
                  'billing',
                  'unit_price',
                  e.target.value
                )
              }
            />
          </Tooltip>
        )
    },
    {
      title: 'Bill total',
      dataIndex: 'billing_total_amount',
      key: 'billing_total_amount',
      width: 150,
      className: 'tableSeparator',
      render: (text) => (costBillingData.is_billing_locked ? text || '-' : text)
    },
    {
      title: 'Invoice no.',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: 120,
      className: 'column-spacing',
      render: (text, record) =>
        !isFinance() ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'bill_invoice_number',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'Invoice link',
      dataIndex: 'invoice_link',
      key: 'invoice_link',
      width: 100,
      render: (text, record) =>
        !isFinance() ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'invoice_link',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'Credit note no.',
      dataIndex: 'credit_note_no',
      key: 'credit_note_no',
      width: 140,
      render: (text, record) =>
        !isFinance() ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'credit_note_no',
                e.target.value
              )
            }
          />
        )
    },
    {
      title: 'Credit note link',
      dataIndex: 'credit_note_link',
      key: 'credit_note_link',
      width: 140,
      render: (text, record) =>
        !isFinance() ? (
          text || '-'
        ) : (
          <Input
            placeholder="Type here"
            style={{ width: '200px' }}
            value={text}
            onChange={(e) =>
              handleLineItemChange(
                record.key,
                'billing',
                'credit_note_link',
                e.target.value
              )
            }
          />
        )
    },
    {
      key: 'action',
      width: 80,
      render: (text, record) => {
        const currentLineItem = costBillingData?.temp_quote_line_items.filter(
          (line_item) => line_item.key === record.key
        );
        return dataSource.length > 1 ? (
          <div className="actions">
            {costBillingData.is_cost_locked &&
              costBillingData.is_billing_locked && (
                <>
                  <StyledPopover
                    overlayClassName="copy-paste-popover"
                    content={
                      <p>Copy invoice and credit note info on this line item</p>
                    }
                  >
                    <Icon
                      onClick={() => {
                        setCopyInvoiceInfo({
                          bill_invoice_number: record.invoice_number,
                          invoice_link: record.invoice_link,
                          credit_note_no: record.credit_note_no,
                          credit_note_link: record.credit_note_link
                        });
                      }}
                      component={CopySVG}
                    />
                  </StyledPopover>

                  <StyledPopover
                    overlayClassName="copy-paste-popover"
                    content={
                      <p>
                        Paste invoice and credit note info to this line item
                      </p>
                    }
                  >
                    <Icon
                      onClick={() => {
                        if (copyInvoiceInfo) {
                          onPasteInvoiceDetails(record.key, copyInvoiceInfo);
                        }
                      }}
                      component={PasteSVG}
                    />
                  </StyledPopover>
                </>
              )}
            {((!costBillingData.is_cost_locked &&
              !costBillingData.is_billing_locked) ||
              (!costBillingData.is_billing_locked &&
                costBillingData.is_cost_locked &&
                isCostLineItemBlank(currentLineItem[0]?.cost)) ||
              (!costBillingData.is_cost_locked &&
                costBillingData.is_billing_locked &&
                isBillingLineItemBlank(currentLineItem[0]?.billing))) && (
              <Icon
                onClick={() => handleRemoveLineItem(record.key)}
                component={DeleteSVG}
              />
            )}
          </div>
        ) : null;
      }
    }
  ];

  return (
    <StyledShipmentQuote>
      <h4>
        {`${detailAPI.origin && detailAPI.origin.country} to ${
          detailAPI.destination.country
        }`}
      </h4>
      <Section>
        <TableTitle>
          <SubTitle>Cost & Billing Details</SubTitle>
          <ColumnDropdown />
        </TableTitle>
        <Table
          totalCount={dataSource.length}
          dataSource={dataSource}
          className="primary"
          showScroll={false}
          showPagination={false}
          columns={columns.filter(
            (column) =>
              tableColumns.includes(column.key) || column.key === 'action'
          )}
        />
        {errors?.lineItems && (
          <Message type="error">{errors?.lineItems}</Message>
        )}
        <Row type="flex" justify="space-between" style={{ marginTop: '20px' }}>
          <Col span={10} xxl={{ span: 6 }}>
            {(!costBillingData.is_cost_locked ||
              !costBillingData.is_billing_locked) && (
              <LineItemButtom type="secondary" onClick={handleAddLineItems}>
                Add line item
              </LineItemButtom>
            )}
            {totalRates.exchangeRates && (
              <>
                {(costBillingData?.invoice_currency === 'USD' ||
                  detailAPI.quote_status !== 'BILLED') && (
                  <ExchangeRates
                    target="USD"
                    rates={totalRates.exchangeRates}
                  />
                )}
                {costBillingData?.invoice_currency !== 'USD' &&
                  detailAPI.quote_status === 'BILLED' && (
                    <>
                      <ExchangeRates
                        target="USD"
                        rates={
                          costBillingData?.cost_exchange_rates
                            ? new Map(
                                Object.keys(
                                  costBillingData?.cost_exchange_rates
                                ).reduce((val, curr) => {
                                  return [
                                    ...val,
                                    [
                                      curr,
                                      costBillingData?.cost_exchange_rates[curr]
                                    ]
                                  ];
                                }, [])
                              )
                            : []
                        }
                      />
                      <ExchangeRates
                        showLabel={false}
                        target={costBillingData?.invoice_currency}
                        rates={
                          costBillingData?.billing_exchange_rates
                            ? new Map(
                                Object.keys(
                                  costBillingData?.billing_exchange_rates
                                ).reduce((val, curr) => {
                                  return [
                                    ...val,
                                    [
                                      curr,
                                      costBillingData?.billing_exchange_rates[
                                        curr
                                      ]
                                    ]
                                  ];
                                }, [])
                              )
                            : []
                        }
                      />
                    </>
                  )}
              </>
            )}
          </Col>
          <Col
            span={13}
            xxl={{ span: 18 }}
            style={{ marginTop: 15, textAlign: 'right' }}
          >
            <TotalBillingCost
              costBillingLocked={
                costBillingData?.is_cost_locked &&
                costBillingData?.is_billing_locked
              }
              totalRates={totalRates}
              invoiceCurrency={
                detailAPI.quote_status === 'BILLED'
                  ? costBillingData?.invoice_currency
                  : 'USD'
              }
            />
          </Col>
        </Row>
      </Section>
      <Section last>
        <SubTitle>Notes</SubTitle>
        <Row gutter={[24, 16]}>
          <Col span={12} xxl={{ span: 6 }}>
            <InfoTitle>Operator Remarks</InfoTitle>
            <InfoValue>
              <TextArea
                value={costBillingData?.operator_remark}
                placeholder="Type here..."
                onChange={(e) =>
                  handleInfoChange('operator_remark', e.target.value)
                }
              />
              {errors?.billingRemark && (
                <Message type="error">{errors?.billingRemark}</Message>
              )}
            </InfoValue>
          </Col>
          <Col span={12} xxl={{ span: 6 }}>
            <InfoTitle>Finance Remarks</InfoTitle>
            <InfoValue>
              <TextArea
                value={costBillingData?.finance_remark}
                placeholder="Type here..."
                onChange={(e) =>
                  handleInfoChange('finance_remark', e.target.value)
                }
              />
              {errors?.operator_remark && (
                <Message type="error">{errors?.operator_remark}</Message>
              )}
            </InfoValue>
          </Col>
        </Row>
      </Section>
      <ActionButtonContainer>
        <Row type="flex" justify="space-between">
          <StyledButton type="secondary" onClick={handleCancel}>
            Cancel
          </StyledButton>
          {(!costBillingData?.is_cost_locked ||
            !costBillingData?.is_billing_locked) && (
            <StyledButton
              type="secondary"
              style={{ marginLeft: 'auto' }}
              loading={submitBtn === 'save_as_draft' && saving}
              onClick={() => {
                setSubmitBtn('save_as_draft');
                handleSave();
              }}
            >
              Save as Draft
            </StyledButton>
          )}
          {costBillingData?.is_cost_locked &&
            costBillingData?.is_billing_locked && (
              <StyledButton
                type="secondary"
                style={{ marginLeft: 'auto' }}
                loading={submitBtn === 'save' && saving}
                onClick={() => {
                  setSubmitBtn('save');
                  handleSave(true, true);
                }}
              >
                Save
              </StyledButton>
            )}
          {!costBillingData?.is_cost_locked && (
            <StyledButton
              style={{ marginLeft: '8px' }}
              loading={submitBtn === 'lock_cost' && saving}
              onClick={() => {
                setSubmitBtn('lock_cost');
                handleLockCostBilling('cost');
              }}
            >
              Lock Cost
            </StyledButton>
          )}
          {!costBillingData?.is_billing_locked && (
            <StyledButton
              style={{ marginLeft: '8px' }}
              loading={submitBtn === 'lock_billing' && saving}
              onClick={() => {
                setSubmitBtn('lock_billing');
                handleLockCostBilling('billing');
              }}
            >
              Ready To Bill
            </StyledButton>
          )}
        </Row>
      </ActionButtonContainer>
    </StyledShipmentQuote>
  );
};

export default CostBillingDetailsEdit;
