import { message } from 'antd';
import axios from 'axios';
import request, { axiosRequest } from '../utils/request';
import {
  removeEmpty,
  queryString,
  formatToISOString,
  removeNonEnglishCharacters
} from '../utils/formatString';

const updateQuoteDetail = (id, quote) => {
  const url = `/shipments/${id}`;
  return axiosRequest.patch(url, quote);
};

const updateQuoteRequestDetail = (id, quote) => {
  const url = `/quote/${id}`;
  return axiosRequest.patch(url, quote);
};

const getQuoteDetail = async (id) => {
  const url = `/shipments/${id}`;
  const response = await request().get(url);
  return response?.data?.data;
};

const getRequestQuotesDetail = async (id) => {
  const url = `/quote/quote-requests/${id}`;
  const response = await request().get(url);
  return response?.data?.data;
};
const getQuoteShipmentLog = async (id) => {
  const url = `/quote/${id}/shipment-log`;
  const response = await request().get(url);
  return response?.data?.data;
};

const getQuoteCharges = async (id) => {
  const url = `/quote/${id}/ops-charges`;
  const response = await request().get(url);
  return response?.data?.data;
};

const getQuoteMessage = (id) => {
  const url = `/quote/${id}/messages`;
  return axiosRequest.get(url);
};

const getQuoteLookups = () => {
  const url = `/quote/request-form-values`;
  return axiosRequest.get(url);
};

const acceptDocument = async (id) => {
  const url = `/quote/${id}/accept`;
  const response = await request().post(url, {});
  return response;
};

const approveDocument = async (id) => {
  const url = `/quote/document/${id}/approve`;
  const response = await request().post(url, {});
  return response;
};

const changeQuoteStatus = async (id, payload) => {
  const url = `/quote/${id}/action`;
  const response = await request().post(url, payload);
  return response;
};
const changeQuoteConfirmStatus = async (id, payload) => {
  const url = `/quote/confirm-shipment/${id}`;
  const response = await request().post(url, payload);
  return response;
};
const removeDocument = async (id) => {
  const url = `/quote/document/${id}/remove`;
  const response = await request().post(url, {});
  if (response.status === 200) {
    message.success('Document is deleted');
  } else {
    message.error('Document removal failed');
  }
  return response;
};

const documentUpload = async ({ data, id, documentStatus, importAwb }) => {
  const signedRequest = { files: [] };
  const formData = {};
  const uploadRequest = { files: [] };

  Object.keys(data).forEach((key) => {
    formData[key] = data[key];
    signedRequest.files.push(key);
  });
  const signedUrls = await request().post(
    `/quote/${id}/sign-documents`,
    signedRequest
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const file of signedRequest.files) {
    const url = signedUrls.data.data[file];
    const options = {
      headers: {
        'Content-Type': formData[file].type
      }
    };
    // eslint-disable-next-line no-await-in-loop
    await axios.put(url.pre_signed_url, formData[file], options);
    uploadRequest.files.push({
      field_name: file,
      documentStatus,
      key: url.key,
      file_name: removeNonEnglishCharacters(formData[file].name),
      mawb_no: importAwb
    });
  }

  const response = await request().post(
    `quote/v2/${id}/document-upload`,
    uploadRequest
  );

  if (response.status === 200) {
    message.success('Document successfully updated');
  } else {
    message.error('Document upload failed');
  }
  return response;
};

const saveQuoteLineItems = ({ data, id }) => {
  const url = `/quote/${id}/charges`;
  return axiosRequest.patch(url, data);
};
const updateQuoteStatus = ({
  id,
  status,
  reason = '',
  updateDate = null,
  userTimeZoneOffset = 0,
  otherInformation = {}
}) => {
  const url = `/quote/${id}/action`;
  return axiosRequest.post(url, {
    action: status,
    notes: reason,
    updateDate,
    userTimeZoneOffset,
    otherInformation
  });
};
const updateDocumentsVisibility = (data) => {
  const url = `/quote/update-document-visibility`;
  const filteredData = data.filter((doc) => !!doc.documentId);
  return axiosRequest.post(url, filteredData);
};

const getQuoteRequests = async ({
  quoteId = '',
  createdAt = [],
  approveRejectedStatus = '',
  merchantName = '',
  origin = '',
  destination = '',
  shipmentMode = '',
  createdBy = '',
  creatorEmail = '',
  quoteStatus = '',
  winLossReason = '',
  shipmentQuoteId = '',
  networkPartnerCost = '',
  totalQuotePrice = '',
  page = 1,
  pageSize = 10
}) => {
  const params = removeEmpty({
    quoteId,
    createdAt: createdAt?.map(formatToISOString) || [],
    approveRejectedStatus,
    merchantName,
    origin,
    destination,
    shipmentMode,
    createdBy,
    creatorEmail,
    quoteStatus,
    winLossReason,
    shipmentQuoteId,
    networkPartnerCost,
    totalQuotePrice,
    page,
    records: pageSize
  });

  params.networkPartnerCost = JSON.stringify(params.networkPartnerCost);
  params.totalQuotePrice = JSON.stringify(params.totalQuotePrice);

  const queryParams = queryString(params);
  const url = `/quote/quote-requests?${queryParams}`;
  const response = await axiosRequest.get(url, params).exec();
  const { quotes, total } = response.data.data;
  return {
    quotes: quotes.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const getQuoteRequestFilters = async () => {
  const url = '/quote/filters';
  const response = await request().get(url);
  return response?.data?.data;
};

export const createQuoteRequest = async (quoteRequest, dataFile) => {
  // save quote details and get tracking id
  const resp = await request().post(`quote/create-quote-request`, quoteRequest);
  const tracking_id = resp?.data?.data.tracking_id;
  // upload documents
  const signedRequest = { files: [] };
  const formData = {};
  const uploadDocRequest = {
    tracking_id,
    files: []
  };
  const {
    packingListFile,
    commercialInvoiceFile,
    quotationForm,
    MSDSFile
  } = dataFile;
  if (packingListFile) {
    formData.PACKING_LIST = packingListFile;
    signedRequest.files.push('PACKING_LIST');
  }

  if (commercialInvoiceFile) {
    formData.COMMERCIAL_INVOICE = commercialInvoiceFile;
    signedRequest.files.push('COMMERCIAL_INVOICE');
  }

  if (quotationForm) {
    formData.QUOTATION_FORM = quotationForm;
    signedRequest.files.push('QUOTATION_FORM');
  }

  if (MSDSFile) {
    formData['MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'] = MSDSFile;
    signedRequest.files.push('MATERIAL_SAFETY_DATA_SHEETS_(MSDS)');
  }

  if (packingListFile || commercialInvoiceFile || quotationForm || MSDSFile) {
    const signedUrls = await request().post(
      `/quote/${tracking_id}/sign-documents`,
      signedRequest
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const file of signedRequest.files) {
      const url = signedUrls.data.data[file];
      const options = {
        headers: {
          'Content-Type': formData[file].type
        }
      };
      // eslint-disable-next-line no-await-in-loop
      await axios.put(url.pre_signed_url, formData[file], options);
      uploadDocRequest.files.push({
        field_name: file,
        key: url.key,
        content_type: formData[file].type,
        file_name: removeNonEnglishCharacters(formData[file].name)
      });
    }
  }
  // save document on server and send email
  await request().post(
    `quote/${tracking_id}/quote-document-upload`,
    uploadDocRequest
  );
  return { tracking_id };
};

const changeQuoteRequestStatus = (id, payload) => {
  return new Promise((resolve) => {
    const url = `/quote/${id}/update-status`;
    request()
      .post(url, payload)
      .then((resp) => {
        resolve(resp);
      });
  });
};
export const quoteService = {
  getQuoteRequests,
  getQuoteRequestFilters,
  getQuoteDetail,
  getQuoteShipmentLog,
  getQuoteCharges,
  getQuoteMessage,
  acceptDocument,
  documentUpload,
  approveDocument,
  changeQuoteStatus,
  removeDocument,
  getQuoteLookups,
  updateQuoteDetail,
  updateQuoteRequestDetail,
  saveQuoteLineItems,
  updateQuoteStatus,
  changeQuoteConfirmStatus,
  updateDocumentsVisibility,
  getRequestQuotesDetail,
  createQuoteRequest,
  changeQuoteRequestStatus
};
