import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { message, Icon } from 'antd';
import _ from 'lodash';
import Modal from '../Modal';
import Select from '../Select';
import Button from '../Button';
import Message from '../Message';
import Input from '../Input';

import {
  getRequestQuotesDetail,
  updateQuoteWinLossStatus,
  getQuoteShipmentLog
} from '../../actions/quoteDetail';
import {
  TEXT_QUOTE_STATUS_ERROR,
  TEXT_QUOTE_STATUS_SUCCESS
} from '../../constants/message';
import {
  APPROVED,
  REJECTED,
  QUOTE_STATUS_MAPPING
} from '../../constants/status';

const { Option } = Select;

const StatusDropdown = styled(Select)`
  width: 300px;
  display: inline-block;
  margin-right: 10px;
`;
const TitleUpdateModal = styled.span`
  color: #050593;
  .anticon {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const StyledModalContent = styled.div`
  padding: 0 10px 0 10px;
`;
const StatusModalForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
`;
const reducer = (state, value) => ({ ...state, ...value });
const QuoteApproveRejectModal = ({
  isUpdateModalVisible,
  setIsUpdateModalVisible
}) => {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const quoteDetail = useSelector((state) => state?.quoteRequestDetails?.quote);
  const quoteId = useSelector(
    (state) => state?.quoteRequestDetails?.quote?.quote_id
  );
  const [updateStatusError, setUpdateStatusError] = useState({});
  const [state, setState] = useReducer(reducer, {});

  useEffect(() => {
    if (isUpdateModalVisible) {
      setState({
        quote_status: [APPROVED, REJECTED].includes(quoteDetail?.quote_status)
          ? quoteDetail?.quote_status
          : undefined,
        [APPROVED]: {
          win_loss_reason:
            quoteDetail?.quote_status !== '' ? quoteDetail?.win_loss_reason : ''
        },
        [REJECTED]: {
          win_loss_reason:
            quoteDetail?.quote_status !== '' ? quoteDetail?.win_loss_reason : ''
        }
      });
    }
    setUpdateStatusError({});
  }, [isUpdateModalVisible, quoteDetail]);

  const updateStatus = async (stateData) => {
    try {
      const data = {
        quote_status: stateData.quote_status,
        ...stateData[stateData.quote_status]
      };
      setUpdating(true);
      await dispatch(
        updateQuoteWinLossStatus({
          id: quoteId,
          data
        })
      );
      await dispatch(getRequestQuotesDetail(quoteId));
      await dispatch(getQuoteShipmentLog(quoteId));
      setUpdating(false);
      setIsUpdateModalVisible(false);
      message.success(TEXT_QUOTE_STATUS_SUCCESS);
    } catch (error) {
      const result = error?.response?.data?.result;
      setUpdating(false);
      message.error(result || TEXT_QUOTE_STATUS_ERROR);
      return null;
    }
    return null;
  };

  const ERROR_MESSAGES = {
    quote_status: 'Please select a valid status'
  };

  const validateState = () => {
    let isValid = true;
    const error = {};
    if (!state.quote_status) {
      isValid = false;
      error.quote_status = ERROR_MESSAGES.quote_status;
    }
    setUpdateStatusError(error);
    return isValid;
  };
  const onChange = (key, value) => {
    const updateStatusErrorObj = { ...updateStatusError };
    if (value) {
      _.set(updateStatusErrorObj, key, '');
    } else {
      _.set(updateStatusErrorObj, key, ERROR_MESSAGES[key]);
    }
    const stateObj = { ...state };
    if (key !== 'quote_status') {
      stateObj[state.quote_status][key] = value;
    } else {
      _.set(updateStatusErrorObj, 'win_loss_reason', '');
      stateObj[key] = value;
    }
    setUpdateStatusError(updateStatusErrorObj);
    setState(stateObj);
  };

  const updateStatusModal = () => {
    if (validateState()) {
      updateStatus(state);
    }
  };

  return (
    <Modal
      title={
        <TitleUpdateModal>
          <Icon type="info-circle" theme="filled" />
          Approve or reject quote?
        </TitleUpdateModal>
      }
      visible={isUpdateModalVisible}
      closable={false}
      width="680px"
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => {
            setIsUpdateModalVisible(false);
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" loading={updating} onClick={updateStatusModal}>
          Confirm
        </Button>
      ]}
    >
      <StyledModalContent>
        <p style={{ marginBottom: 15 }}>
          If the quote is approved, you may create shipment from it.
        </p>
        <StatusModalForm>
          <div>
            <p>Quote Approved/Rejected</p>
            <StatusDropdown
              placeholder="Select..."
              style={{ width: 300 }}
              value={_.get(state, 'quote_status', undefined)}
              allowClear
              onChange={(value) => {
                onChange('quote_status', value);
              }}
            >
              <Option key={APPROVED} value="APPROVED">
                {QUOTE_STATUS_MAPPING[APPROVED]}
              </Option>
              <Option key={REJECTED} value="REJECTED">
                {QUOTE_STATUS_MAPPING[REJECTED]}
              </Option>
            </StatusDropdown>
            <Message type="error">
              {_.get(updateStatusError, 'quote_status', '')}
            </Message>
          </div>
          {state.quote_status && [REJECTED].includes(state.quote_status) && (
            <div>
              <p>Reason for Rejection (Optional)</p>
              <Input
                value={_.get(
                  state,
                  [state.quote_status, 'win_loss_reason'],
                  ''
                )}
                placeholder="Type here"
                style={{ width: 300 }}
                onChange={(event) => {
                  const val = event.target.value;
                  onChange('win_loss_reason', val);
                }}
              />
              <Message type="error">
                {_.get(updateStatusError, 'win_loss_reason', '')}
              </Message>
            </div>
          )}
        </StatusModalForm>
      </StyledModalContent>
    </Modal>
  );
};

export default QuoteApproveRejectModal;
