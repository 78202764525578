import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { set } from 'lodash';
import Message from '../Message';
import NumberInput from '../NumberInput';
import FloatInput from '../FloatInput';
import Input from '../Input';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 1px #cccccc;
  .ant-input-number {
    width: 100%;
  }
  .delete-dimension {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    transition: none;
  }
  .delete-dimension:hover,
  .delete-dimension:focus {
    border-color: #050593;
    color: #050593;
  }
  .ant-row {
    margin-bottom: 10px;
    .ant-col {
      margin-bottom: 10px;
    }
  }
`;

const HawbInput = ({
  id,
  value,
  label,
  showDelete = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {}
}) => {
  const [hawbState, setHawbState] = useState(value);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema = Yup.object().shape({
    hawbNo: Yup.string()
      .nullable()
      .required('Please enter HAWB No.'),
    grossWeight: Yup.string()
      .nullable()
      .required('Please enter Gross Weight'),
    chargeableWeight: Yup.string()
      .nullable()
      .required('Please enter Chargeable Weight'),
    totalUnit: Yup.string()
      .nullable()
      .required('Please enter No. of Units')
  });

  const validate = () => {
    validationSchema
      .validate(hawbState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          set(ers, er.path, er.message);
          return ers;
        }, {});

        if (Object.keys(errs).length > 0) {
          setErrors(errs);
        } else {
          setErrors(null);
        }
      });
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }
    onChange(hawbState, errors);
  }, [hawbState, errors]);

  const handleChange = (name) => (e) => {
    dirty.current = true;
    switch (name) {
      case 'hawbNo':
        setHawbState({ ...hawbState, hawbNo: e.target.value });
        break;
      case 'grossWeight':
        setHawbState({ ...hawbState, grossWeight: e.target.value });
        break;
      case 'chargeableWeight':
        setHawbState({ ...hawbState, chargeableWeight: e.target.value });
        break;
      case 'totalUnit':
        setHawbState({ ...hawbState, totalUnit: e });
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur(hawbState, errors);
  };

  return (
    <Container id={id}>
      <h5>
        {label}
        {showDelete && (
          <Button
            className="delete-dimension"
            icon="delete"
            onClick={onDelete}
          />
        )}
      </h5>
      <Row gutter={20}>
        <Col span={12} xxl={{ span: 6 }}>
          <p>HAWB No.</p>
          <Input
            value={hawbState?.hawbNo}
            onChange={handleChange('hawbNo')}
            onBlur={handleBlur}
          />
          {errors?.hawbNo && dirty.current && (
            <Message type="error">{errors?.hawbNo}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Gross Weight (KG)</p>
          <FloatInput
            value={hawbState?.grossWeight}
            onChange={handleChange('grossWeight')}
            onBlur={handleBlur}
          />
          {errors?.grossWeight && dirty.current && (
            <Message type="error">{errors?.grossWeight}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Chargeable Weight (KG)</p>
          <FloatInput
            value={hawbState?.chargeableWeight}
            onChange={handleChange('chargeableWeight')}
            onBlur={handleBlur}
          />
          {errors?.chargeableWeight && dirty.current && (
            <Message type="error">{errors?.chargeableWeight}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>No. of Units</p>
          <NumberInput
            value={hawbState?.totalUnit}
            onChange={handleChange('totalUnit')}
            onBlur={handleBlur}
          />
          {errors?.totalUnit && dirty.current && (
            <Message type="error">{errors?.totalUnit}</Message>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HawbInput;
