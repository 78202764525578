import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { set } from 'lodash';
import moment from 'moment';
import Message from '../Message';
import NumberInput from '../NumberInput';
import Input from '../Input';
import SearchInput from '../SearchInput';
import { AIRPORT_VALUES } from '../../constants/ports';
import DatePicker from '../DatePicker';

const Container = styled.div`
  padding-top: 20px;
  border-top: solid 2px #cccccc;
  .ant-input-number {
    width: 100%;
  }
  .delete-dimension {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    transition: none;
  }
  .delete-dimension:hover,
  .delete-dimension:focus {
    border-color: #050593;
    color: #050593;
  }
  .ant-row {
    margin-bottom: 10px;
    .ant-col {
      margin-bottom: 10px;
    }
  }
`;

const MawbInput = ({
  id,
  mawb,
  hawbs,
  label,
  showDelete = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {}
}) => {
  const [mawbState, setMawbState] = useState(mawb);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema = Yup.object().shape({
    mawbNo: Yup.string()
      .nullable()
      .required('Please enter MAWB No.'),
    originAirport: Yup.string()
      .nullable()
      .required('Please enter Origin Airport'),
    destinationAirport: Yup.string()
      .nullable()
      .required('Please enter Destination Airport'),
    etd: Yup.string()
      .nullable()
      .required('Please enter Estimated Time of Departure (ETD)'),
    eta: Yup.string()
      .nullable()
      .required('Please enter Estimated Time of Arrival (ETA)'),
    flightNo: Yup.string()
      .nullable()
      .required('Please enter Flight Number'),
    grossWeight: Yup.string()
      .nullable()
      .required('Please enter Gross Weight'),
    chargeableWeight: Yup.string()
      .nullable()
      .required('Please enter Chargeable Weight'),
    totalUnit: Yup.string()
      .nullable()
      .required('Please enter No. of Units')
  });

  const validate = () => {
    validationSchema
      .validate(mawbState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          set(ers, er.path, er.message);
          return ers;
        }, {});

        if (disabled) {
          delete errs.grossWeight;
          delete errs.chargeableWeight;
          delete errs.totalUnit;
        }

        if (Object.keys(errs).length > 0) {
          setErrors(errs);
        } else {
          setErrors(null);
        }
      });
  };

  const calculateTotalUnit = () => {
    let totalUnit = 0;

    hawbs.forEach((hawb) => {
      totalUnit += hawb?.totalUnit ? Number(hawb?.totalUnit) : 0;
    });

    return totalUnit;
  };

  const calculateGrossWeight = () => {
    let grossWeight = 0;

    hawbs.forEach((hawb) => {
      grossWeight += hawb?.grossWeight ? Number(hawb?.grossWeight) : 0;
    });

    return grossWeight;
  };

  const calculateChargeableWeight = () => {
    let chargeableWeight = 0;

    hawbs.forEach((hawb) => {
      chargeableWeight += hawb?.chargeableWeight
        ? Number(hawb?.chargeableWeight)
        : 0;
    });

    return chargeableWeight;
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    setMawbState({ ...mawbState, hawbs });

    if (hawbs.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [hawbs]);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }

    onChange(mawbState, errors);
  }, [mawbState, errors]);

  const handleChange = (name) => (e, dateString) => {
    dirty.current = true;
    switch (name) {
      case 'mawbNo':
        setMawbState({ ...mawbState, mawbNo: e.target.value });
        break;
      case 'originAirport':
        setMawbState({ ...mawbState, originAirport: e });
        break;
      case 'destinationAirport':
        setMawbState({ ...mawbState, destinationAirport: e });
        break;
      case 'etd':
        setMawbState({ ...mawbState, etd: dateString });
        break;
      case 'eta':
        setMawbState({ ...mawbState, eta: dateString });
        break;
      case 'flightNo':
        setMawbState({ ...mawbState, flightNo: e.target.value });
        break;
      case 'grossWeight':
        setMawbState({ ...mawbState, grossWeight: e });
        break;
      case 'chargeableWeight':
        setMawbState({ ...mawbState, chargeableWeight: e });
        break;
      case 'totalUnit':
        setMawbState({ ...mawbState, totalUnit: e });
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur(mawbState, errors);
  };

  const disabledEtdDate = (date, eta) => {
    if (!eta) {
      return false;
    }
    return date.isAfter(eta, 'day');
  };

  const disabledEtaDate = (date, etd) => {
    if (!etd) {
      return false;
    }
    return date.isBefore(etd, 'day');
  };

  return (
    <Container id={id}>
      <h5>
        {label}
        {showDelete && (
          <Button
            className="delete-dimension"
            icon="delete"
            onClick={onDelete}
          />
        )}
      </h5>
      <Row gutter={20}>
        <Col span={12} xxl={{ span: 6 }}>
          <p>MAWB No.</p>
          <Input
            value={mawbState?.mawbNo}
            onChange={handleChange('mawbNo')}
            onBlur={handleBlur}
          />
          {errors?.mawbNo && dirty.current && (
            <Message type="error">{errors?.mawbNo}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Origin Airport</p>
          <SearchInput
            searchMode
            placeholder="Select"
            items={AIRPORT_VALUES}
            value={mawbState?.originAirport}
            onChange={handleChange('originAirport')}
            onBlur={handleBlur}
          />
          {errors?.originAirport && dirty.current && (
            <Message type="error">{errors?.originAirport}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Destination Airport</p>
          <SearchInput
            searchMode
            placeholder="Select"
            items={AIRPORT_VALUES}
            value={mawbState?.destinationAirport}
            onChange={handleChange('destinationAirport')}
            onBlur={handleBlur}
          />
          {errors?.destinationAirport && dirty.current && (
            <Message type="error">{errors?.destinationAirport}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Estimated Time of Departure (ETD)</p>
          <DatePicker
            value={mawbState?.etd ? moment(mawbState?.etd) : undefined}
            disabledDate={(date) => disabledEtdDate(date, mawbState?.eta)}
            onChange={handleChange('etd')}
          />
          {errors?.etd && dirty.current && (
            <Message type="error">{errors?.etd}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Estimated Time of Arrival (ETA)</p>
          <DatePicker
            value={mawbState?.eta ? moment(mawbState?.eta) : undefined}
            disabledDate={(date) => disabledEtaDate(date, mawbState?.etd)}
            onChange={handleChange('eta')}
          />
          {errors?.eta && dirty.current && (
            <Message type="error">{errors?.eta}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Flight Number</p>
          <Input
            value={mawbState?.flightNo}
            onChange={handleChange('flightNo')}
            onBlur={handleBlur}
          />
          {errors?.flightNo && dirty.current && (
            <Message type="error">{errors?.flightNo}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Gross Weight (KG)</p>
          <NumberInput
            value={disabled ? calculateGrossWeight() : mawbState?.grossWeight}
            onChange={handleChange('grossWeight')}
            onBlur={handleBlur}
            disabled={disabled}
            precision={3}
          />
          {errors?.grossWeight && dirty.current && (
            <Message type="error">{errors?.grossWeight}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>Chargeable Weight (KG)</p>
          <NumberInput
            value={
              disabled
                ? calculateChargeableWeight()
                : mawbState?.chargeableWeight
            }
            onChange={handleChange('chargeableWeight')}
            onBlur={handleBlur}
            disabled={disabled}
            precision={3}
          />
          {errors?.chargeableWeight && dirty.current && (
            <Message type="error">{errors?.chargeableWeight}</Message>
          )}
        </Col>
        <Col span={12} xxl={{ span: 6 }}>
          <p>No. of Units</p>
          <NumberInput
            value={disabled ? calculateTotalUnit() : mawbState?.totalUnit}
            onChange={handleChange('totalUnit')}
            onBlur={handleBlur}
            disabled={disabled}
          />
          {errors?.totalUnit && dirty.current && (
            <Message type="error">{errors?.totalUnit}</Message>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MawbInput;
