import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';

import {
  ShipmentHistory,
  Card,
  QuoteHeaderTitle,
  RequestQuoteDetailTabs
} from '../components';
import {
  unloadRequestQuoteDetails,
  getRequestQuotesDetail
} from '../actions/quoteDetail';

const StyledQuotesDetail = styled.div`
  // max-width: 1200px;
  margin: 0 40px;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;

const QuotesDetails = (props) => {
  const { match = {} } = props;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const quoteId = match?.params?.quoteId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getRequestQuotesDetail(quoteId));
    return () => {
      dispatch(unloadRequestQuoteDetails());
    };
  }, [dispatch, quoteId]);

  const acceptQuote = async () => {};

  const quoteStatus = useSelector(
    (state) => state?.quoteRequestDetails?.quote?.quote_status
  );

  return (
    <StyledQuotesDetail>
      <Row gutter={10}>
        <Col style={{ padding: 0 }}>
          <StyledCard>
            {!loading && (
              <QuoteHeaderTitle
                acceptQuote={acceptQuote}
                quoteStatus={quoteStatus}
              />
            )}
            <RequestQuoteDetailTabs
              quoteId={quoteId}
              quoteStatus={quoteStatus}
              onLoading={(isLoading) => {
                setLoading(isLoading);
              }}
            />
          </StyledCard>
          <ShipmentHistory quoteId={quoteId} heading="Quote" />
        </Col>
      </Row>
    </StyledQuotesDetail>
  );
};
export default withRouter(QuotesDetails);
