import { routerMiddleware, connectRouter } from 'connected-react-router';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as History from 'history';
import {
  persistStore
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER
} from 'redux-persist';
import rootReducer from './reducers';

export const history = History.createBrowserHistory();

const preloadedState = {};
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  }),
  routerMiddleware(history)
];

export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    ...rootReducer
  },
  middleware,
  preloadedState
});

export const persistor = persistStore(store);
