import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';
import { message } from 'antd';
import Message from '../Message';
import Modal from '../Modal';
import Button from '../Button';
import SearchInput from '../SearchInput';
import { shipmentService } from '../../services';
import {
  TEXT_ASSIGN_OPERATOR_SUCCESS,
  TEXT_ASSIGN_OPERATOR_ERROR
} from '../../constants/message';
import { getQuoteDetail } from '../../actions/quoteDetail';

const StyledModalContent = styled.div`
  padding: 0 10px 10px 10px;
`;

const StyledModal = styled(Modal)`
  width: 360px !important;
`;
const AssignOperatorModal = ({
  isVisible,
  setIsVisible,
  trackingNumber,
  onAssign = () => {}
}) => {
  const dispatch = useDispatch();
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getOperators = async () => {
      const ops = await shipmentService.getOperators();
      setOperators(
        ops.data.map((op) => ({
          label: `${op.name} (${op.email})`,
          value: op.id
        }))
      );
    };
    getOperators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignOperator = async () => {
    if (operator) {
      try {
        setLoading(true);
        setError(null);
        await shipmentService.assignOperator(operator, trackingNumber);
        await dispatch(getQuoteDetail(trackingNumber));
        setLoading(false);
        message.success(TEXT_ASSIGN_OPERATOR_SUCCESS);
        onAssign();
      } catch (err) {
        message.error(TEXT_ASSIGN_OPERATOR_ERROR);
      }
    } else {
      setError('Please choose an operator');
    }
  };

  return (
    <StyledModal
      title="Assign an Operator"
      visible={isVisible}
      closable={false}
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => {
            setIsVisible(false);
            setError(null);
            setOperator(undefined);
            setLoading(false);
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" onClick={assignOperator} loading={loading}>
          Confirm
        </Button>
      ]}
    >
      <StyledModalContent>
        <p>Operator-in-charge</p>
        <SearchInput
          value={operator}
          items={operators}
          disabled={loading}
          onChange={(operatorId) => setOperator(operatorId)}
        />
        {error && <Message type="error">{error}</Message>}
      </StyledModalContent>
    </StyledModal>
  );
};

export default AssignOperatorModal;
