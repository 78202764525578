import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import DatePicker from '../DatePicker';
import Message from '../Message';

const FormDatePicker = ({
  name,
  validate,
  onChange = () => {},
  disabledDate = () => false,
  ...restProps
}) => {
  const { validateField } = useFormikContext();
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, values, setFieldError },
        meta
      }) => (
        <>
          <DatePicker
            value={value ? moment(value) : undefined}
            disabledDate={(date) => disabledDate(date, values)}
            onChange={async (date, dateString) => {
              if (
                name === 'estimatedDepartureTime' ||
                name === 'estimatedArrivalTime'
              ) {
                await setFieldValue(name, dateString);
              } else {
                await setFieldValue(name, date);
              }
              // workaround: https://github.com/jaredpalmer/formik/issues/529
              await setFieldValue(name, dateString);
              // await setFieldTouched(name, true);
              validateField(name);
              onChange(date, dateString, { values, setFieldError });
            }}
            {...restProps}
          />
          {meta.error && <Message type="error">{meta.error}</Message>}
        </>
      )}
    </Field>
  );
};

export default FormDatePicker;
