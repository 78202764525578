import _ from 'lodash';
import { AIRPORTS, SEAPORTS } from '../constants/ports';
import { ALL_COUNTRIES as COUNTRIES } from '../constants/countries';

const getPortsList = (shipmentMode, country) => {
  if (country) {
    let ports = [];
    let portsList = [];
    const countryCode = COUNTRIES.find((obj) => obj.value === country)
      ?.countryCode;
    switch (shipmentMode) {
      case 'Sea':
        ports = SEAPORTS.filter(
          (seaport) => seaport.unlocs[0].substr(0, 2) === countryCode
        );
        ports = [
          ...new Set(ports.map((port) => `${port.name}-${port.unlocs[0]}`))
        ];
        portsList = ports.map((name) => ({
          label: name,
          value: name.split('-').length > 0 ? name.split('-')[0] : name
        }));
        portsList = portsList.filter(
          (port) => port.label !== '' || port.value !== ''
        );
        return portsList || [];
      case 'Air':
        ports = AIRPORTS.filter(
          (airport) => airport.countryCode === countryCode
        );
        ports = [
          ...new Set(
            ports.map((port) => `${port.airportName}-${port.airportCode}`)
          )
        ];
        portsList = ports.map((name) => ({
          label: name,
          value: name.split('-').length > 0 ? name.split('-')[0] : name
        }));
        portsList = portsList.filter(
          (port) => port.label !== '' || port.value !== ''
        );
        portsList = _.uniqBy(portsList, 'value');
        return portsList || [];
      default:
        return [];
    }
  } else {
    return [];
  }
};

export default getPortsList;
