import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';

const StyledMessageIcon = styled(Icon)`
  color: #050593;
`;

const MessageIcon = ({ status, ...props }) => {
  return <StyledMessageIcon {...props} />;
};

export default MessageIcon;
