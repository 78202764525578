import { createAction } from '@reduxjs/toolkit';
import { quoteService } from '../services/quotes';

export const fetchQuotes = createAction('quotes/FETCH_DATA');
export const fetchQuotesLoading = createAction('quotes/FETCH_DATA_LOADING');

export const fetchChoices = createAction('quotesChoices/FETCH_DATA');
export const fetchChoicesLoading = createAction(
  'quotesChoices/FETCH_DATA_LOADING'
);

export const setPageFilter = createAction('quotesFilter/SET_PAGE');
export const setPageSizeFilter = createAction('quotesFilter/SET_PAGE_SIZE');
export const setQuoteFilters = createAction('quotesFilter/SET_FILTERS');
export const resetFilters = createAction('quotesFilter/RESET_FILTERS');

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading());
  quoteService.getQuoteFilters().then((data) => {
    dispatch(fetchChoices(data));
  });
};

export const getQuotes = (params = {}) => async (dispatch) => {
  dispatch(fetchQuotesLoading());
  quoteService.getQuotes(params).then((data) => {
    dispatch(fetchQuotes(data));
  });
};
