import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ImgLogoReverse from '../assets/images/logo-sider.png';
import { getProfile } from '../auth0';

const StyledDrawer = styled(Drawer)`
  img {
    width: 100px;
    margin: 5px auto 40px auto;
    display: block;
  }
  .ant-drawer-body {
    padding: 0 22px;
  }
  .section {
    h2 {
      font-size: 20px;
      font-family: AvenirLTStdHeavy;
      margin-bottom: 15px;
      line-height: 1;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 40px;
    }
    ul li a {
      font-size: 16px;
      font-family: AvenirLTStdBook;
      color: #050593;
    }
  }
`;

const SideDrawer = ({ visible, onClose }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    getProfile().then((profile) => {
      const roles = profile['https://b2b.janio.asia/roles'] || [];
      setIsSuperAdmin(roles.includes('ops/superadmin'));
    });
  }, []);

  return (
    <StyledDrawer
      title=""
      placement="left"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={300}
    >
      <img alt="logo" src={ImgLogoReverse} />

      <div className="section">
        <h2>MANAGE SHIPMENTS</h2>
        <ul>
          <li>
            <Link to="/shipments" onClick={onClose}>
              View Shipments
            </Link>
          </li>
          <li style={{ marginTop: 5 }}>
            <Link to="/faqs" onClick={onClose}>
              Glossary/FAQ
            </Link>
          </li>
        </ul>
        <h2>MANAGE QUOTES</h2>
        <ul>
          <li>
            <Link to="/quotes" onClick={onClose}>
              View Quotes
            </Link>
          </li>
          <li hidden={!isSuperAdmin}>
            <Link to="/users" onClick={onClose}>
              Create/Block Users
            </Link>
          </li>
        </ul>
        <h2>OTHERS</h2>
        <ul>
          <li>
            <Link to="/shopee" onClick={onClose}>
              Shopee Integration
            </Link>
          </li>
        </ul>
      </div>
    </StyledDrawer>
  );
};

export default SideDrawer;
