import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import styled from '@emotion/styled';
import { message, Icon } from 'antd';
import { ALL_COUNTRIES as COUNTRIES } from '../../constants/countries';
import getPortsList from '../../helpers/portList';
// import { geocodeByAddress } from 'react-places-autocomplete';
import { locationService, quoteService } from '../../services';
import {
  TEXT_ADDRESS_INVALID,
  TEXT_UPDATE_ADDRESS_SUCCESS
} from '../../constants/message';

import { formatShipmentModeValue } from '../../utils/formatString';

import { updateQuoteAddressView } from '../../actions/quoteDetail';

import {
  FormInput,
  FormDatePicker,
  FormSearchInput,
  FormMultiSelect,
  FormLocationSearchInput,
  FormFullLoad,
  FormPackageDimension,
  FormFlightInformation
} from '../Forms';

import FormAddress from '../Forms/FormAddress';

const { FormTextArea } = FormInput;

const updateLocation = async (trackingId, location, point = 'origin') => {
  try {
    const addressLocation = await locationService
      .addLocation({
        country: location.country,
        city: location.city,
        state: location.state,
        postal_code: location?.postal_code?.trim(),
        street_address: location?.address?.trim(),
        location_name: location.location_name,
        poc_name: location.poc_name,
        poc_number: location?.poc_number?.trim(),
        poc_email: location?.poc_email?.trim(),
        google_location_id: null
      })
      .exec();
    await quoteService
      .updateQuoteDetail(trackingId, {
        [point]: {
          address_id: addressLocation.data.data.location_id
        }
      })
      .exec();
    message.success(TEXT_UPDATE_ADDRESS_SUCCESS);
    return addressLocation;
  } catch (error) {
    const result = error?.response?.data?.result;
    message.error(result || TEXT_ADDRESS_INVALID);
    return null;
  }
};

const ShipmentDetailsForm = ({
  initialValues,
  validationSchema,
  lookups = {},
  onSubmit,
  children
}) => {
  // TODO: instead of getting these from here, take from initialValues
  const quote = useSelector((state) => state?.quoteDetail?.quote);
  const dispatch = useDispatch();
  const originPortList = getPortsList(
    quote?.shipment_mode,
    quote?.origin?.country
  );
  const destinationPortList = getPortsList(
    quote?.shipment_mode,
    quote?.destination?.country
  );
  const [transhipmentPortList, setTranshipmentPortList] = useState(
    getPortsList(quote?.shipment_mode, quote?.transhipment_country)
  );
  const updateTranshipmentPortList = () => async (value, setFieldValue) => {
    const portList = getPortsList(quote?.shipment_mode, value);
    setFieldValue('transhipmentPort', undefined);
    setTranshipmentPortList(portList.length < 1 ? undefined : portList);
  };
  const updateLocationFields = (source) => async (values, setFieldValue) => {
    const location = await updateLocation(
      quote.quote_id,
      {
        ...values,
        poc_name: values?.poc_name?.trim(),
        poc_number: values?.poc_number?.trim(),
        address: values?.address?.trim(),
        postal_code: values?.postal_code?.trim(),
        location_name: values?.location_name?.trim(),
        poc_email: values?.poc_email
      },
      source
    );
    if (location) {
      setFieldValue(`${source}.pocName`, values?.poc_name?.trim());
      setFieldValue(`${source}.pocNumber`, values?.poc_number?.trim());
      setFieldValue(`${source}.pocEmail`, values?.poc_email?.trim());
      setFieldValue(
        `${source}.address`,
        [
          values.address?.trim(),
          values.city,
          values.state,
          values.postal_code?.trim()
        ]
          .filter(Boolean)
          .join(', ')
      );
      await dispatch(
        updateQuoteAddressView({
          address: source,
          details: {
            poc_name: values?.poc_name?.trim(),
            poc_number: values?.poc_number?.trim(),
            poc_email: values?.poc_email?.trim(),
            address: values?.address?.trim(),
            postal_code: values?.postal_code?.trim(),
            country: values.country,
            state: values.state,
            location_name: values?.location_name?.trim()
          }
        })
      );
    }
  };

  const merchant = {
    title: 'Merchant Information',
    data: [
      {
        label: 'Merchant Name',
        value: quote?.merchant_name
      }
    ]
  };
  const yourShipment = {
    title: 'Your Shipment',
    data: [
      {
        label: 'Shipment Name',
        editable: {
          name: 'yourShipment.shipmentName',
          type: 'FormInput'
        }
      },
      {
        label: 'Quote ID',
        editable: {
          name: 'yourShipment.quoteID',
          type: 'FormInput'
        }
      }
    ]
  };
  const shipmentMode = {
    title: 'Shipment Mode',
    data: [
      {
        label: 'Freight Used',
        value: quote?.shipment_mode
      }
    ]
  };
  const getFirstMileTxt = () => {
    let firstMileTxt = '';
    if (quote?.origin?.is_using_first_mile === 1) {
      firstMileTxt = 'Pickup';
    } else if (quote?.origin?.is_using_first_mile === 0) {
      firstMileTxt = 'Dropoff';
    } else {
      firstMileTxt = 'No first mile';
    }
    return firstMileTxt;
  };
  const origin = {
    title: 'Origin/Shipper',
    data: [
      {
        label: 'First-mile',
        value: getFirstMileTxt()
      },
      {
        label: 'Country',
        value: quote?.origin?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          quote?.origin?.is_using_first_mile ? 'Address' : 'Dropoff Address',
        editable: {
          name: 'origin.address',
          type: 'FormAddress',
          location: quote?.origin,
          onSubmit: updateLocationFields('origin')
        }
      },
      {
        label: 'Origin Airport/Port (Optional)',
        value: 'Yes',
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          name: 'origin.port',
          type:
            quote?.shipment_mode === 'Land' ? 'FormInput' : 'FormSearchInput',
          items: originPortList,
          allowClear: true
        }
      },
      {
        label: 'Pickup Date',
        formatLabel: () =>
          quote?.origin?.is_using_first_mile ? 'Pickup Date' : 'Dropoff Date',
        editable: {
          name: 'origin.pickupDate',
          type: 'FormDatePicker',
          disabledDate: (startDate, values) => {
            if (!values.destination.pickupDate) {
              return false;
            }
            return startDate.isAfter(values.destination.pickupDate, 'day');
          }
        }
      },
      {
        label: `Shipper's Name`,
        value: quote?.shipper_name,
        editable: {
          name: 'shipperName',
          type: 'FormInput'
        }
      },
      {
        label: `PoC's Name (Optional)`,
        editable: {
          name: 'origin.pocName',
          type: 'FormAddress',
          location: quote?.origin,
          onSubmit: updateLocationFields('origin')
        }
      },
      {
        label: `PoC's Number (Optional)`,
        editable: {
          name: 'origin.pocNumber',
          type: 'FormAddress',
          location: quote?.origin,
          onSubmit: updateLocationFields('origin')
        }
      },
      {
        label: `PoC's Email (Optional)`,
        editable: {
          name: 'origin.pocEmail',
          type: 'FormAddress',
          location: quote?.origin,
          onSubmit: updateLocationFields('origin')
        }
      }
    ]
  };
  origin.data =
    quote?.origin?.is_using_first_mile === 2
      ? origin.data.filter((obj) => obj.label !== 'Address')
      : origin.data;
  const midMile = {
    title: 'Mid-mile',
    data: [
      {
        label: 'Freight-forwarding',
        value: quote?.is_using_mid_mile,
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          type: 'FormSearchInput',
          name: 'midMile',
          items: [
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]
        }
      }
    ]
  };

  const destination = {
    title: 'Destination/Consignee',
    data: [
      {
        label: 'Last-mile',
        value: quote?.destination?.is_using_last_mile,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Country',
        value: quote?.destination?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          quote?.destination?.is_using_last_mile ? 'Address' : 'Pickup Address',
        editable: {
          name: 'destination.address',
          type: 'FormAddress',
          location: quote?.destination,
          onSubmit: updateLocationFields('destination')
        }
      },
      {
        label: 'Destination Airport/Port (Optional)',
        value: 'Yes',
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          name: 'destination.port',
          type:
            quote?.shipment_mode === 'Land' ? 'FormInput' : 'FormSearchInput',
          items: destinationPortList,
          allowClear: true
        }
      },
      {
        label: 'Delivery Date',
        formatValue: (value) => moment(value).format('DD MMM YYYY'),
        editable: {
          name: 'destination.pickupDate',
          type: 'FormDatePicker',
          disabledDate: (endDate, values) => {
            if (!values.origin.pickupDate) {
              return false;
            }
            return endDate.isBefore(values.origin.pickupDate, 'day');
          }
        }
      },
      {
        label: `Consignee's Name`,
        value: quote?.consignee_name,
        editable: {
          name: 'consigneeName',
          type: 'FormInput'
        }
      },
      {
        label: `PoC's Name (Optional)`,
        editable: {
          name: 'destination.pocName',
          type: 'FormAddress',
          location: quote?.destination,
          onSubmit: updateLocationFields('destination')
        }
      },
      {
        label: `PoC's Number (Optional)`,
        editable: {
          name: 'destination.pocNumber',
          type: 'FormAddress',
          location: quote?.destination,
          onSubmit: updateLocationFields('destination')
        }
      },
      {
        label: `PoC's Email (Optional)`,
        editable: {
          name: 'destination.pocEmail',
          type: 'FormAddress',
          location: quote?.destination,
          onSubmit: updateLocationFields('destination')
        }
      }
    ]
  };

  const information = {
    title: 'Shipping Information',
    data: [
      {
        label: 'Incoterm (Optional)',
        editable: {
          type: 'FormSearchInput',
          name: 'shippingInfo.incotermId',
          allowClear: true,
          items: lookups?.incoterms?.map((type) => ({
            label: type.value,
            value: type.key
          }))
        }
      },
      {
        label: 'Transhipment Country (Optional)',
        editable: {
          type: 'FormSearchInput',
          name: 'transhipmentCountry',
          allowClear: true,
          items: COUNTRIES,
          onChange: updateTranshipmentPortList()
        }
      },
      {
        label: 'Transhipment Airport/Port (Optional)',
        value: 'Yes',
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          name: 'transhipmentPort',
          allowClear: true,
          loading: false,
          type:
            quote?.shipment_mode === 'Land' ? 'FormInput' : 'FormSearchInput',
          items: transhipmentPortList
        }
      },
      {
        label: 'Transhipment MAWB (Optional)',
        editable: {
          type: 'FormInput',
          name: 'shippingInfo.transhipmentMawb'
        }
      }
    ]
  };

  const getVehicleInfo = (shipment_mode) => {
    switch (shipment_mode) {
      case 'Sea':
        return {
          title: 'Vessel Information',
          data: [
            {
              label: 'OOBL',
              editable: {
                type: 'FormInput',
                name: 'shippingInfo.importAwb'
              }
            },
            {
              label: 'HBL',
              editable: {
                type: 'FormInput',
                name: 'shippingInfo.exportAwb'
              }
            },
            {
              label: 'Estimated Time of Departure (ETD)',
              formatValue: (value) => moment(value).format('DD MMM YYYY'),
              editable: {
                name: 'estimatedDepartureTime',
                type: 'FormDatePicker',
                disabledDate: (endDate, values) => {
                  if (!values.estimatedArrivalTime) {
                    return false;
                  }
                  return endDate.isAfter(values.estimatedArrivalTime, 'day');
                }
              }
            },
            {
              label: 'Estimated Time of Arrival (ETA)',
              formatValue: (value) => moment(value).format('DD MMM YYYY'),
              editable: {
                name: 'estimatedArrivalTime',
                type: 'FormDatePicker',
                disabledDate: (endDate, values) => {
                  if (!values.estimatedDepartureTime) {
                    return false;
                  }
                  return endDate.isBefore(values.estimatedDepartureTime, 'day');
                }
              }
            },
            {
              label: 'Vessel Number',
              value: quote?.vehicle_number,
              editable: {
                name: 'vehicleNumber',
                type: 'FormInput'
              }
            }
          ]
        };
      default:
        return {
          title: 'Truck Information',
          data: [
            {
              label: 'D/O Number',
              editable: {
                type: 'FormInput',
                name: 'shippingInfo.importAwb'
              }
            },
            {
              label: 'Estimated Time of Departure (ETD)',
              formatValue: (value) => moment(value).format('DD MMM YYYY'),
              editable: {
                name: 'estimatedDepartureTime',
                type: 'FormDatePicker',
                disabledDate: (endDate, values) => {
                  if (!values.estimatedArrivalTime) {
                    return false;
                  }
                  return endDate.isAfter(values.estimatedArrivalTime, 'day');
                }
              }
            },
            {
              label: 'Estimated Time of Arrival (ETA)',
              formatValue: (value) => moment(value).format('DD MMM YYYY'),
              editable: {
                name: 'estimatedArrivalTime',
                type: 'FormDatePicker',
                disabledDate: (endDate, values) => {
                  if (!values.estimatedDepartureTime) {
                    return false;
                  }
                  return endDate.isBefore(values.estimatedDepartureTime, 'day');
                }
              }
            },
            {
              label: 'Truck Number',
              value: quote?.vehicle_number,
              editable: {
                name: 'vehicleNumber',
                type: 'FormInput'
              }
            }
          ]
        };
    }
  };

  const vehicleInformation = getVehicleInfo(quote?.shipment_mode);

  const flightInformation = {
    title: 'Edit Flight Information',
    description:
      'You may edit flight information, create up to 3 MAWBs, create multiple HAWBs (optional), and delete existing here.',
    background: '#05059312',
    collapsable: true,
    data: [
      {
        label: '',
        value: quote?.mawbs?.map((mawb) => {
          return {
            mawbNo: mawb.mawb_no,
            originAirport: mawb.origin_airport,
            destinationAirport: mawb.destination_airport,
            etd: mawb.estimated_departure_time,
            eta: mawb.estimated_arrival_time,
            flightNo: mawb.flight_number,
            grossWeight: mawb.gross_weight,
            chargeableWeight: mawb.chargeable_weight,
            totalUnit: mawb.total_unit,
            hawbs: mawb.hawbs?.map((hawb) => ({
              hawbNo: hawb.hawb_no,
              grossWeight: hawb.gross_weight,
              chargeableWeight: hawb.chargeable_weight,
              totalUnit: hawb.total_unit
            }))
          };
        }),
        editable: {
          type: 'FormFlightInformation',
          name: 'flightInformation'
        }
      }
    ]
  };

  const getLoadType = () => {
    switch (quote?.shipment_mode) {
      case 'Sea':
        return {
          label: 'Container',
          load: quote?.shipment_load_type
            ? 'Full container load (FCL)'
            : 'Less than container load (LCL)'
        };

      case 'Land':
        return {
          label: 'Truck',
          load: quote?.shipment_load_type
            ? 'Full truck load (FTL)'
            : 'Less than truck load (LTL)'
        };
      default:
        return null;
    }
  };

  const load = {
    title: 'Edit Container',
    description:
      'You may edit container details, add a new set of container load, and delete existing sets here.',
    background: '#1FCECA12',
    collapsable: true,
    data: [
      {
        value: getLoadType()?.load,
        editable: {
          type: 'FormFullLoad',
          name: 'shipmentLoad',
          modeOfShipment: quote?.shipment_mode,
          load: quote?.shipment_load_type,
          loads: quote?.loads.map((loadItem) => ({
            pId: loadItem.id,
            amount: loadItem.units,
            type: loadItem.type_id,
            seals: loadItem.containers_seals
          })),
          loadTypes: {
            containers: lookups?.container_types,
            trucks: lookups?.truck_types
          }
        }
      }
    ]
  };

  const agreedPrice = {
    title: 'Shipping Price',
    data: [
      {
        label: 'Has Agreed Price',
        value: quote?.agreed_price,
        formatValue: (value) => {
          if (value === null) {
            return 'None';
          }
          return value ? 'Yes' : 'No';
        },
        editable: {
          type: 'FormSearchInput',
          name: 'shippingPrice.hasAgreedPrice',
          items: [
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]
        }
      },
      {
        label: 'Agreed Price',
        value: quote?.shipment_price,
        formatValue: (value) => {
          if (value === null) {
            return 'None';
          }
          return value;
        },
        editable: {
          name: `shippingPrice.agreedPrice`,
          type: 'FormInput'
        }
      }
    ]
  };
  // TODO: make old data work with new UI, remove this if data is ever migrated
  const getMetricType = (shipment, item) => {
    if (shipment.quote_shipment_dimension_type) {
      return [
        shipment.quote_shipment_dimension_type,
        shipment.quote_shipment_dimension_type
      ];
    }
    return [item.weight_type, item.volume_type];
  };

  // everything is in quote?.packages
  const packages = {
    title: 'Edit Shipment Dimensions',
    description:
      'You may edit shipment dimension details, add a new set of product, and delete existing here.',
    background: '#05059312',
    collapsable: true,
    data: [
      {
        label: '',
        value: quote?.packages?.map((packageItem) => {
          const [weightType, volumeType] = getMetricType(quote, packageItem);
          return {
            pId: packageItem.id,
            amount: packageItem.units || 1,
            type: packageItem.type_id,
            dimensions: {
              height: packageItem.height,
              isCm: packageItem.length_uom === 'cm',
              length: packageItem.length,
              width: packageItem.width,
              amount: packageItem.total_volume
            },
            weight: {
              amount: weightType
                ? packageItem.weight
                : packageItem.total_weight,
              isKg: packageItem.weight_uom === 'kg'
            },
            weightType,
            volumeType,
            chargeableWeight: packageItem.chargeable_weight
          };
        }), // what value?
        editable: {
          type: 'FormPackageDimension',
          isEditForm: true,
          name: 'shipmentPackages',
          modeOfShipment: quote?.shipment_mode,
          packageTypes: lookups?.package_types,
          finalChargeableWeight: quote?.reconciled_weight,
          finalChargeableWeightUnit: quote?.reconciled_weight_unit
        }
      }
    ]
  };

  const dangerousGoods = {
    contains_hazardous_goods: 'Hazardous Materials',
    contains_battery: 'Batteries',
    other: 'Other'
  };

  const otherDetails = {
    title: 'Other Details',
    data: [
      {
        label: 'Dangerous Goods',
        editable: {
          type: 'FormMultiSelect',
          name: 'otherDetails.dangerousGoods',
          items: Object.entries(dangerousGoods).map(([key, value]) => ({
            label: value,
            value: key
          }))
        }
      },
      {
        label: `Shipment Name`,
        editable: {
          name: 'otherDetails.shipmentName',
          type: 'FormInput'
        }
      },
      {
        label: 'Shipment Notes/Remarks',
        editable: {
          name: 'otherDetails.description',
          type: 'FormTextArea'
        }
      }
    ]
  };

  const customBrokerageServices = {
    title: 'Custom Brokerage Services',
    data: [
      {
        label: 'Custom Clearance',
        value: quote?.is_using_custom_clearance,
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          type: 'FormSearchInput',
          name: 'customBrokerageServices.customsClearance',
          items: [
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]
        }
      },
      {
        label: 'Exporter of Record',
        value: quote?.is_eor_present,
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          type: 'FormSearchInput',
          name: 'customBrokerageServices.exporterOfRecord',
          items: [
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]
        }
      },
      {
        label: 'Importer of Record',
        value: quote?.is_ior_present,
        formatValue: (value) => (value ? 'Yes' : 'No'),
        editable: {
          type: 'FormSearchInput',
          name: 'customBrokerageServices.importerOfRecord',
          items: [
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]
        }
      }
    ]
  };

  const total = {
    title: 'Total',
    data: [
      {
        label: 'Units By Package Type',
        value: quote?.package_type_units,
        formatValue: (value) => value || 'None'
      },
      {
        label: 'Volume',
        value: quote?.total_volume,
        formatValue: (value) => {
          if (value) {
            const text = formatShipmentModeValue(quote?.shipment_mode, value);
            return `${text} CBM`;
          }
          return 'None';
        }
      },
      {
        label: 'Actual Weight',
        value: quote?.total_actual_weight,
        formatValue: (value) => {
          if (value) {
            return `${Math.ceil(parseFloat(value))} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Volumetric Weight',
        value: quote?.total_volumetric_weight,
        formatValue: (value) => {
          if (value) {
            const text = formatShipmentModeValue(quote?.shipment_mode, value);
            return `${text} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Total Chargeable Weight',
        value: formatShipmentModeValue(
          quote?.shipment_mode,
          quote?.total_weight
        ),
        formatValue: (value) => {
          if (value) {
            if (quote?.total_weight_unit) {
              return `${value} ${quote?.total_weight_unit}`;
            }
            return `${value} ${quote?.shipment_mode === 'Sea' ? 'CBM' : 'KG'}`;
          }
          return 'None';
        }
      }
    ]
    // formatData: (data) => data.filter((totalWeight) => totalWeight?.value)
  };

  const notes = {
    title: 'Operator Notes',
    data: [
      {
        editable: {
          name: 'notes',
          type: 'FormTextArea'
        }
      }
    ]
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, isValid }) =>
        children({
          schema: {
            merchant,
            yourShipment,
            shipmentMode,
            origin,
            midMile,
            destination,
            vehicleInformation,
            flightInformation,
            information,
            load,
            agreedPrice,
            packages,
            otherDetails,
            customBrokerageServices,
            total,
            notes
          },
          loadType: getLoadType(),
          handleSubmit,
          values,
          isValid
        })
      }
    </Formik>
  );
};

const FormEditableInput = ({ value, editable, formatValue, collapsed }) => {
  const getFormattedValue = () => {
    return (formatValue ? formatValue(value) : value) || 'None';
  };
  // second argument `meta` to get errors
  if (editable) {
    const { name, type, ...props } = editable;
    // TODO: maybe there is a better way to do this
    switch (editable.type) {
      case 'FormInput':
        return <FormInput name={name} />;
      case 'FormTextArea':
        return <FormTextArea name={name} />;
      case 'FormDatePicker':
        return <FormDatePicker {...props} name={name} />;
      case 'FormSearchInput':
        return <FormSearchInput {...props} name={name} loading={false} />;
      case 'FormMultiSelect':
        return <FormMultiSelect {...props} name={name} />;
      case 'FormAddress':
        return <FormAddress {...props} name={name} />;
      case 'FormLocationSearchInput':
        return <FormLocationSearchInput {...props} name={name} />;
      case 'FormFullLoad':
        return (
          <FormFullLoad
            isEditForm
            {...props}
            name={name}
            collapsed={collapsed}
          />
        );
      case 'FormPackageDimension':
        return (
          <FormPackageDimension {...props} name={name} collapsed={collapsed} />
        );
      case 'FormFlightInformation':
        return (
          <FormFlightInformation {...props} name={name} collapsed={collapsed} />
        );
      default:
        return <em style={{ color: '#1fceca' }}>{getFormattedValue()}</em>;
    }
  }

  return <span className="value">{getFormattedValue()}</span>;
};

const StyledShipmentDetailFormRow = styled.div`
  background: ${(props) => props.background || 'transparent'};
  border-bottom: ${(props) => (props.background ? '0' : '1px solid #ccc')};
  margin: ${(props) =>
    props.background ? '-20px -40px 20px -40px' : '0 0 20px 0'};

  padding: ${(props) => (props.background ? '20px 40px 0 40px' : '0')};

  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
    cursor: ${(props) => (props.collapsable ? 'pointer' : 'default')};
  }

  & > p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & > ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    margin-left: -10px;
    margin-right: -10px;
    & > li {
      width: ${(props) => props.width || '50%'};
      flex-grow: ${(props) => props.grow || '1'};
      padding: 0 10px;
      padding-bottom: 20px;
    }
    & > li em {
      font-family: AvenirLTStdHeavy;
      font-size: 14px;
      color: #707070;
      display: block;
      font-style: normal;
      margin-bottom: 5px;
    }
    & > li .editable-control {
      margin-bottom: ${(props) => (props.background ? '0' : '20px')};
    }
    & > li.editForm em {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #000;
      display: block;
      font-style: normal;
      margin-bottom: 8px;
    }
    & > li .editable-control > span {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #000000;
    }
  }
`;

export const ShipmentDetailsFormRow = ({ schema, width, grow, children }) => {
  const { collapsable } = schema;
  const [data, setData] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const getInitialData = () => {
      if (!schema) return [];
      if (schema.formatData) {
        return schema.formatData(schema.data);
      }
      return schema.data;
    };
    setData(getInitialData());
  }, [schema, schema.data]);

  return (
    <StyledShipmentDetailFormRow
      className="shipment-detail-row"
      width={width}
      grow={grow}
      background={schema.background}
      collapsable={collapsable}
    >
      {schema.title && (
        <h5
          aria-hidden="true"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {schema.title}{' '}
          {collapsable &&
            (collapsed ? <Icon type="down" /> : <Icon type="up" />)}
        </h5>
      )}
      {(collapsable ? !collapsed : true) && schema.description && (
        <p>{schema.description}</p>
      )}
      {data && (
        <ul>
          {data.map(({ label, value, editable, formatLabel, formatValue }) => (
            <li
              key={`${label}${value}`}
              className={editable ? 'editForm' : ''}
              style={label === 'Shipment Notes/Remarks' ? { width: '50%' } : {}}
            >
              {label && (
                <em className="label">
                  {formatLabel ? formatLabel(label) : label}
                </em>
              )}
              <div className="editable-control">
                <FormEditableInput
                  editable={editable}
                  value={value}
                  formatValue={formatValue}
                  collapsed={collapsed}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
      {children && <p>{children}</p>}
    </StyledShipmentDetailFormRow>
  );
};

export default ShipmentDetailsForm;
