import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import { adminService } from '../../services';

const { Option } = Select;

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;
`;
const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const Roles = [
  'merchant/internal',
  'merchant/user',
  'ops/admin',
  'ops/finance',
  'ops/user'
];

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);

  const createUser = async () => {
    try {
      setLoading(true);
      await adminService.createNewUser({
        user: { name, email, password },
        roles
      });
      message.success('User created successfully');
      setName('');
      setEmail('');
      setPassword('');
      setRoles([]);
    } catch (err) {
      const errMessage =
        err?.response?.data?.data?.message ||
        'Error Occured while creating user';
      message.error(errMessage);
    }
    setLoading(false);
  };
  return (
    <StyledContainer>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col span={12}>
          <p>Name</p>
          <Input
            placeholder="Type name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <p>E-mail</p>
          <Input
            placeholder="Type E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <p>Password</p>
          <Input
            placeholder="Type Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <p>Roles</p>
          <Select
            placeholder="Roles"
            mode="multiple"
            value={roles}
            showSearch
            onChange={(value) => {
              setRoles(value);
            }}
          >
            {Roles.map((role) => (
              <Option key={`${role}`} value={role}>
                {role}
              </Option>
            ))}
          </Select>
        </Col>
      </StyledRow>
      <Col>
        <Button
          onClick={createUser}
          loading={loading}
          disabled={!name || !email || !password || !roles.length}
        >
          Submit
        </Button>
      </Col>
    </StyledContainer>
  );
};

export default CreateUser;
