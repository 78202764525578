import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import { adminService } from '../../services';

const { Option } = Select;

const StyledTitle = styled.p`
  font-family: AvenirLTStdHeavy;
  padding-top: 5px;
`;
const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  color: #000000;
`;
const StyledRow = styled(Row)`
  margin: 20px 0;
`;
const MerchantMapping = () => {
  const [b2cMerchants, setB2cMerchants] = useState([]);
  const [b2bSelectedMerchant, setB2bSelectedMerchant] = useState();
  const [currentlyMapped, setCurrentlyMapped] = useState('-');
  const [b2cSelectedMerchant, setB2cSelectedMerchant] = useState();
  const [b2bMerchants, setB2bMerchants] = useState([]);
  const [authToken, setAuthToken] = useState();

  const fetchMerchantData = async () => {
    const data = await adminService.fetchMerchantData(authToken);
    setB2bMerchants(data.b2bMerchants);
    setB2cMerchants(data.b2cMerchants);
  };
  const handleMapAgentID = async () => {
    const resp = await adminService.mapMerchantAgentID(
      b2bSelectedMerchant,
      b2cSelectedMerchant
    );
    if (resp.code === 200) {
      message.success('Mapped Agent ID successfully');
    } else {
      message.error('Error Occured in Mapping');
    }
  };
  const getCurrentlyMappedAgent = (value) => {
    const selectedMerchant = b2bMerchants.find(
      (agentDetails) => agentDetails.merchantID === value
    );
    const mappedMerchant = b2cMerchants.find(
      (merchantDetails) =>
        merchantDetails.merchantID === selectedMerchant.agentID
    );
    if (mappedMerchant) setCurrentlyMapped(mappedMerchant.merchantName);
  };
  return (
    <StyledContainer>
      <StyledRow>
        <Col span={6}>
          <StyledTitle>B2C Authorization Token:</StyledTitle>
        </Col>
        <Col span={6}>
          <Input onChange={(e) => setAuthToken(e.target.value)} />
        </Col>
        <Col span={6}>
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => fetchMerchantData()}
          >
            Fetch Data
          </Button>
        </Col>
      </StyledRow>
      <StyledRow align="middle" gutter={[16, 24]}>
        <Col>
          <p>B2B Merchant</p>
          <Select
            placeholder="B2B Merchants"
            showSearch
            style={{ width: '300px' }}
            onChange={(value) => {
              getCurrentlyMappedAgent(value);
              setB2bSelectedMerchant(value);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {b2bMerchants.map((item) => (
              <Option
                key={item && item.merchantID}
                value={item && item.merchantID}
              >
                {item.merchantName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <p>Currently Mapped To</p>
          <StyledTitle>{currentlyMapped}</StyledTitle>
        </Col>
        <Col>
          <p>New B2C Merchants</p>
          <Select
            placeholder="B2C Merchants"
            showSearch
            style={{ width: '300px' }}
            onChange={(value, e) =>
              setB2cSelectedMerchant({
                agentID: value,
                agentName: e.props.children
              })
            }
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {b2cMerchants.map((item) => (
              <Option key={item.merchantID} value={item.merchantID}>
                {item.merchantName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button onClick={handleMapAgentID}>Map</Button>
        </Col>
      </StyledRow>
    </StyledContainer>
  );
};

export default MerchantMapping;
