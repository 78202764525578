import { createReducer } from '@reduxjs/toolkit';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchShipments,
  fetchShipmentsLoading,
  fetchChoices,
  fetchChoicesLoading,
  setPageFilter,
  setPageSizeFilter,
  setShipmentFilters,
  setShipmentRangeFilters,
  resetFilters,
  cancelFilters,
  applyFilters,
  setColumns
} from '../actions/shipmentList';

const initialState = {
  shipments: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  columns: {
    general: [
      'trackingNumber',
      'merchantName',
      'status',
      'creatorEmail',
      'shipmentName'
    ],
    shippingInfo: ['shipmentMode', 'packageTypeUnits'],
    billingInfo: [
      'readyToBill',
      'readyToBillDate',
      'totalAmount',
      'totalUSDAmount',
      'grossProfitMargin',
      'costBillComparison',
      'billingInvoiceNumber',
      'billingCreditNoteNumber',
      'costBillingLock'
    ],
    costInfo: ['costAmount'],
    weightVolume: [
      'volume',
      'actualWeight',
      'volumetricWeight',
      'chargeableWeight',
      'reconciliatedWeight'
    ],
    operator: ['operatorInCharge'],
    country: ['origin', 'destination'],
    port: ['originPort', 'destinationPort', 'transhipmentPort'],
    date: ['createdAt'],
    airwayBill: ['importAwb', 'exportAwb']
  },
  filtered: false,
  filters: {
    status: 'All',
    readyToBill: 'All',
    page: 1,
    records: '20',
    trackingNumber: null,
    merchantName: 'All',
    shipmentMode: 'All',
    origin: 'All',
    originPort: 'All',
    destinationPort: 'All',
    transhipmentPort: 'All',
    billingEntity: 'All',
    createdBy: 'All',
    creatorEmail: 'All',
    importAwb: null,
    billingInvoiceNumber: null,
    billingCreditNoteNumber: null,
    costBillingLock: null,
    exportAwb: null,
    transhipmentCountry: 'All',
    destination: 'All',
    operator: 'All',
    confirmedShipmentDate: [],
    estimatedDeliveryDate: [],
    actualDeliveryDate: [],
    readyToBillDate: [],
    createdAt: [],
    lastStatusUpdateDate: [],
    actualWeight: { min: '', max: '' },
    volumetricWeight: { min: '', max: '' },
    costAmount: { min: '', max: '' },
    chargeableWeight: { min: '', max: '', unit: 'All' },
    reconciliatedWeight: { min: '', max: '', unit: 'All' }
  },
  tempFilters: {
    status: 'All',
    readyToBill: 'All',
    page: 1,
    records: '20',
    trackingNumber: null,
    merchantName: 'All',
    shipmentMode: 'All',
    origin: 'All',
    originPort: 'All',
    destinationPort: 'All',
    transhipmentPort: 'All',
    billingEntity: 'All',
    createdBy: 'All',
    creatorEmail: 'All',
    importAwb: null,
    billingInvoiceNumber: null,
    billingCreditNoteNumber: null,
    costBillingLock: null,
    exportAwb: null,
    transhipmentCountry: 'All',
    destination: 'All',
    operator: 'All',
    confirmedShipmentDate: [],
    estimatedDeliveryDate: [],
    actualDeliveryDate: [],
    readyToBillDate: [],
    createdAt: [],
    lastStatusUpdateDate: [],
    actualWeight: { min: '', max: '' },
    volumetricWeight: { min: '', max: '' },
    costAmount: { min: '', max: '' },
    chargeableWeight: { min: '', max: '', unit: 'All' },
    reconciliatedWeight: { min: '', max: '', unit: 'All' }
  },
  choices: {
    loading: false,
    statuses: [],
    merchants: [],
    user_names: [],
    user_emails: [],
    originPorts: [],
    destinationPorts: [],
    transhipmentPorts: []
  }
};

const shipmentList = createReducer(initialState, {
  [fetchShipments](state, action) {
    const { shipments, total } = action.payload;
    state.shipments = {
      data: shipments,
      total,
      loading: false
    };
  },
  [fetchShipmentsLoading](state) {
    state.shipments.loading = true;
  },
  [fetchChoices](state, action) {
    const {
      merchants,
      users,
      statuses,
      operators,
      operatorBranchCodes,
      originPorts,
      destinationPorts,
      transhipmentPorts
    } = action.payload;
    state.choices = {
      loading: false,
      merchants: [
        {
          value: 'All',
          label: 'All'
        },
        ...merchants.map((item) => ({
          value: item.merchantId,
          label: item.merchantName
        }))
      ],
      originPorts: [
        {
          value: 'All',
          label: 'All'
        },
        ...originPorts
      ],
      destinationPorts: [
        {
          value: 'All',
          label: 'All'
        },
        ...destinationPorts
      ],
      transhipmentPorts: [
        {
          value: 'All',
          label: 'All'
        },
        ...transhipmentPorts
      ],
      user_names: [...new Set(users?.map((user) => user.name))].sort(),
      user_emails: [...new Set(users?.map((user) => user.email))].sort(),
      statuses: [
        {
          value: 'All',
          label: 'All'
        },
        ...statuses.map((item) => ({
          value: item.statusValue,
          label: item.statusName
        }))
      ],
      operators: [
        {
          value: 'All',
          label: 'All'
        },
        ...operators.map((item) => ({
          value: item.id,
          label: `${item.name} (${item.email})`
        }))
      ],
      operatorBranchCodes: [
        {
          value: 'All',
          label: 'All'
        },
        ...operatorBranchCodes.map((item) => ({
          value: item.value,
          label: item.name
        }))
      ]
    };
  },
  [fetchChoicesLoading](state) {
    state.choices.loading = true;
  },
  [setPageFilter](state, action) {
    state.filters.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.filters.page = 1;
    state.filters.records = action.payload;
  },
  [setShipmentFilters](state, action) {
    state.tempFilters = {
      ...state.tempFilters,
      ...action.payload
    };
  },
  [setShipmentRangeFilters](state, action) {
    state.tempFilters[action.payload.field][action.payload.key] =
      action.payload.value;
  },
  [resetFilters](state, action) {
    if (action.payload) {
      state.filters = {
        ...state.tempFilters,
        // ...initialState.filters,
        [action.payload.key]: initialState.filters[action.payload.key],
        records: state.filters.records
      };
    } else {
      state.filters = {
        ...state.tempFilters,
        ...initialState.filters,
        records: state.filters.records
      };
    }
    state.filtered = false;
  },
  [cancelFilters](state) {
    state.tempFilters = state.filters;
  },
  [applyFilters](state) {
    state.filters = state.tempFilters;
    state.filtered = !(
      state.filters.status === 'All' &&
      state.filters.readyToBill === 'All' &&
      !state.filters.trackingNumber &&
      !state.filters.quoteRequestID &&
      state.filters.merchantName === 'All' &&
      state.filters.shipmentMode === 'All' &&
      state.filters.origin === 'All' &&
      state.filters.originPort === 'All' &&
      state.filters.destinationPort === 'All' &&
      state.filters.transhipmentPort === 'All' &&
      state.filters.billingEntity === 'All' &&
      !state.filters.importAwb &&
      !state.filters.billingInvoiceNumber &&
      !state.filters.billingCreditNoteNumber &&
      !state.filters.costBillingLock &&
      !state.filters.exportAwb &&
      state.filters.transhipmentCountry === 'All' &&
      state.filters.destination === 'All' &&
      state.filters.operator === 'All' &&
      state.filters.operatorBranchCode === 'All' &&
      !state.filters.confirmedShipmentDate.length &&
      !state.filters.estimatedDeliveryDate.length &&
      !state.filters.createdAt.length &&
      !state.filters.lastStatusUpdateDate.length &&
      !state.filters.actualWeight &&
      !state.filters.volumetricWeight &&
      !state.filters.costAmount &&
      !state.filters.chargeableWeight &&
      !state.filters.reconciliatedWeight &&
      !state.filters.readyToBillDate.length &&
      !state.filters.actualDeliveryDate.length
    );
  },
  [setColumns](state, action) {
    const { columns, type } = action.payload;
    state.columns[type] = columns;
  }
});

const transformFilters = createTransform(
  (inboundState) => {
    // persist
    return { records: inboundState.records };
  },
  (outboundState) => {
    // hydrate
    return { ...initialState.filters, records: outboundState.records };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['filters'] }
);

export default persistReducer(
  {
    key: 'shipmentList',
    storage,
    whitelist: ['filters', 'columns'],
    transforms: [transformFilters]
  },
  shipmentList
);
