import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  ConfigProvider,
  Table as AntTable,
  Skeleton as AntSekeleton
} from 'antd';
import { Resizable } from 'react-resizable';

const ResizeableTitle = ({ onResize, width, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const StyledTable = styled(AntTable)`
  div.ant-table {
    border: none;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    .ant-table-row td {
      border: none;
      color: #000;
    }
    .ant-table-row:hover td {
      background: unset;
    }
  }
  .ant-table-body {
    overflow-x: auto !important;
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  .ant-table-placeholder {
    background-color: #f7f4ee;
    border-top: 1px solid #d9c8a9;
    border-bottom: 1px solid #d9c8a9 !important;
    color: #000000;
    padding: 10px;
    z-index: 2;
    height: 40px;
  }
  .ant-table-placeholder span.link {
    text-decoration: underline;
    color: #050593;
    cursor: pointer;
  }
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }
  /* TODO: temporary workaround, table should not add this class */
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: none;
  }
  &.primary div.ant-table {
    .ant-table-thead tr th:first-of-type,
    .ant-table-row td:first-of-type {
      padding-left: 24px !important;
    }

    .ant-table-row:nth-last-of-type(1) td {
      border-bottom: 1px solid #8084c1;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #8084c1;
    }
    .ant-table-row:nth-of-type(odd) td {
      background-color: #5c35ac0d;
    }
    td.tableSeparator {
      border-right: 1px solid rgba(5, 5, 147, 0.5) !important;
    }
    th.column-spacing,
    td.column-spacing {
      padding-left: 24px !important;
    }
  }
  &.compact div.ant-table {
    .ant-table-thead tr th:first-of-type,
    .ant-table-row td:first-of-type {
      padding-left: 16px !important;
    }
  }
  &.secondary div.ant-table {
    .ant-table-thead tr th:first-of-type,
    .ant-table-row td:first-of-type {
      padding-left: 24px !important;
    }

    .ant-table-row:nth-last-of-type(1) td {
      border-bottom: 1px solid #d9c8a9;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #d9c8a9;
    }
    .ant-table-row:nth-of-type(odd) td {
      background-color: #f7f4ee;
    }
  }
  &.tertiary div.ant-table {
    .ant-table-row td {
      border-bottom: 1px solid #f5f5f5;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #1fceca;
    }
  }
  li.ant-pagination-item-active {
    a {
      color: #050593;
    }
    border-color: #050593;
  }
  thead.ant-table-thead th {
    background-color: #ffffff;
    font-family: AvenirLTStdHeavy;
    color: #707070;
  }
  .ant-pagination.mini .ant-pagination-item {
    height: 32px;
    line-height: 30px;
    min-width: 32px;
  }
  .ant-pagination-item {
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding: 2px 0 5px 0;
  }
  .ant-pagination-item a {
    color: #050593;
  }
  .ant-pagination-item-active {
    background: #050593;
    color: #fff;
  }
  li.ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-item-link svg {
    width: 12px;
    height: 12px;
    fill: #050593;
  }
  .ant-pagination-item-ellipsis {
    font-size: 7px;
    color: #050593 !important;
    padding-top: 5px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    height: 32px;
    width: 32px;
    border-color: #050593;
    margin-top: -3px;
    text-align: center;
  }
  .ant-pagination-options-quick-jumper {
    font-size: 16px;
    font-family: AvenirLTStdBook;
    height: 32px;
    line-height: 32px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000;
    border-color: #000;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #000;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9;
  }
  .ant-checkbox-checked::after {
    border-color: #000;
  }
  .ant-spin-nested-loading {
    text-align: center;
  }
  .ant-table-pagination {
    float: none;
    display: inline-block;
  }
`;

const TableSkeleton = ({ active = true }) => {
  return (
    <AntSekeleton
      active={active}
      title={{ width: '100%' }}
      paragraph={{
        rows: 5,
        width: ['100%', '100%', '100%', '100%', '100%']
      }}
    />
  );
};

const Table = ({
  type = 'primary',
  totalCount,
  currentPage,
  pageSize = 10,
  // filtered = false,
  showScroll = true,
  showPagination = true,
  onChange,
  empty,
  renderEmptyTable = false,
  columns,
  resizeable = false,
  ...props
}) => {
  const [components] = useState(() => {
    if (resizeable) {
      return {
        header: {
          cell: ResizeableTitle
        }
      };
    }
    return {};
  });

  const [tableColumns, setTableColumns] = useState(columns);
  const [scrollWidth, setScrollWidth] = useState(true);

  const handleResize = (index) => (e, { size }) => {
    setTableColumns((oldColumns) => {
      const nextColumns = [...oldColumns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };
      return nextColumns;
    });
  };

  useEffect(() => {
    const cols = resizeable
      ? columns.map((col, index) => ({
          ...col,
          onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index)
          })
        }))
      : columns;
    let scrollX = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const col of cols) {
      scrollX += parseInt(col.width || 0, 10);
    }
    setScrollWidth(scrollX);
    setTableColumns(cols);
  }, [columns, resizeable]);

  return (
    <>
      {(totalCount > 0 || renderEmptyTable) && (
        <ConfigProvider renderEmpty={() => empty}>
          <StyledTable
            components={components}
            className={type}
            columns={tableColumns}
            {...props}
            pagination={
              showPagination
                ? {
                    current: currentPage,
                    defaultCurrent: 1,
                    pageSize,
                    total: totalCount,
                    onChange
                    // showQuickJumper: true,
                    // locale: {
                    //   jump_to: 'Jump to page'
                    // }
                  }
                : false
            }
            scroll={
              showScroll
                ? { x: scrollWidth, y: 'calc(100vh - 235px)' }
                : { x: false, y: false }
            }
            size="small"
          />
        </ConfigProvider>
      )}
      {totalCount <= 0 && totalCount !== null && !renderEmptyTable && empty}
      {!renderEmptyTable && totalCount == null && <TableSkeleton />}
    </>
  );
};

export default Table;
