import React from 'react';
import styled from '@emotion/styled';

const QuoteTotal = styled.div`
  .label {
    font-size: 14px;
    color: #707070;
    font-family: AvenirLTStdHeavy;
  }
  .total {
    font-size: 19px;
    color: #000000;
    margin-bottom: 15px;
    padding-left: 15px;
    font-family: AvenirLTStdHeavy;
  }
  .warningMsg {
    color: #d5152f;
    font-size: 16px;
  }
  padding-rigth: 5px;
  margin-bottom: ${(props) => (props.last ? '0px' : '20px')};
`;

const formatTotalAmount = (amnt) => {
  const amount = amnt || 0;
  if (amount < 0) {
    return `-${new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
      .format(amount)
      .slice(2)}`;
  }
  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
    .format(amount)
    .slice(1)}`;
};

const TotalBillingCost = (props) => {
  const { totalRates, costBillingLocked = false, invoiceCurrency } = props;
  return (
    <>
      <QuoteTotal>
        <span className="label">Cost Total</span>
        <span className="total">
          {`USD `}
          {formatTotalAmount(totalRates.totalCost)}
        </span>
        {invoiceCurrency === 'USD' && (
          <p className="label">{`+ USD ${formatTotalAmount(
            totalRates.totalCostTax
          )} in Taxes`}</p>
        )}
      </QuoteTotal>
      <QuoteTotal>
        <span className="label">Bill Total</span>{' '}
        <span className="total">
          {`${invoiceCurrency || 'USD'} `}
          {formatTotalAmount(totalRates.totalBilling)}
        </span>
        {invoiceCurrency === 'USD' && (
          <p className="label">{`+ USD ${formatTotalAmount(
            totalRates.totalBillingTax
          )} in Taxes`}</p>
        )}
      </QuoteTotal>
      {invoiceCurrency === 'USD' && (
        <QuoteTotal last>
          <span className="label">Gross Profit</span>{' '}
          <span className="total">
            {`USD `}
            {formatTotalAmount(totalRates.grossProfit)}
            {` (${totalRates.grossProfitMargin || 0}%)`}
          </span>
          {!!costBillingLocked && totalRates.grossProfit < 0 && (
            <p className="warningMsg">{`Cost Total of USD ${formatTotalAmount(
              totalRates.totalCost
            )} has exceeded the Submitted Bill Total of 
            USD ${formatTotalAmount(totalRates.totalBilling)}`}</p>
          )}
        </QuoteTotal>
      )}
    </>
  );
};

export default TotalBillingCost;
