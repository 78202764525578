import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { formatShipmentModeValue } from '../../utils/formatString';
import {
  APPROVED,
  REJECTED,
  QUOTE_STATUS_MAPPING,
  WIN,
  LOSS
} from '../../constants/status';
import QuoteActionBar from './QuoteActionBar';

const TrackingNumber = styled.span`
  color: #050593 !important;
  text-decoration: underline;
`;

const StyledShipmentDetails = styled.div`
  .shipment-body {
    /* padding: 0px 20px; */
  }
  .shipment-body div:last-child {
    border-bottom: transparent;
  }
  button {
    margin-top: 15px;
    padding: 0px 41px;
  }
`;

const StyledShipmentDetailRow = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  li em {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  li span {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
  ul {
    line-height: 1;
    list-style-type: none;
    display: grid;
    grid-template-columns: ${(prop) => {
      if (prop.templateColumnType === 'twoCol') {
        return '33% 67%';
      }
      return (
        prop.templateColumns ||
        'repeat(auto-fit,minmax(calc(100% - 66%),1fr)) 33% 33%'
      );
    }};
    padding: 0;
    grid-gap: 15px;
    margin-right: 25px;
    @media (min-width: 1200px) {
      grid-template-columns: ${(prop) => {
        if (prop.templateColumnType === 'twoCol') {
          return '25% 75%';
        }
        return (
          prop.templateColumns ||
          'repeat(auto-fit,minmax(calc(100% - 75%),1fr)) 25% 25% 25%'
        );
      }};
    @media (min-width: 1920px) {
      grid-template-columns: ${(prop) => {
        if (prop.templateColumnType === 'twoCol') {
          return '16% 84%';
        }
        return prop.templateColumns || '16% 16% 16% 16% 16% 16%';
      }}
  }
`;

const ShipmentDetailRow = ({ type, schema, title, children }) => {
  const getData = () => {
    if (!schema) return [];
    if (schema.formatData) {
      return schema.formatData(schema.data);
    }
    return schema.data;
  };
  const [data, setData] = useState(getData());

  useEffect(() => {
    setData(getData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  return (
    <StyledShipmentDetailRow templateColumnType={type}>
      <h5>{schema?.title || title}</h5>
      {data && (
        <ul>
          {data.map(({ label, value, formatLabel, formatValue }) => (
            <li key={`${label}${value}`}>
              <em className="label">
                {formatLabel ? formatLabel(label) : label}
              </em>
              <span className="value">
                {formatValue ? formatValue(value) : value}
              </span>
            </li>
          ))}
        </ul>
      )}
      {children && <p>{children}</p>}
    </StyledShipmentDetailRow>
  );
};

const QuoteDetailsView = ({ onEdit }) => {
  const detailAPI = useSelector((state) => state?.quoteRequestDetails?.quote);
  const shipmentDetails = detailAPI?.shipment_details;
  const getQuoteStatus = (quote_status) => {
    let data = [];
    switch (quote_status) {
      case WIN:
        data = [
          {
            label: 'Approved/Rejected',
            value: 'Approved'
          },
          {
            label: 'Win/Loss',
            value: 'Win'
          },
          {
            label: 'Reason for the Win',
            value: detailAPI?.win_loss_reason || '-'
          },
          {
            label: 'Shipment Tracking Number',
            value: detailAPI?.shipment_quote_id,
            formatValue: (text) =>
              text ? (
                <Link to={`/shipments/${text}`}>
                  <TrackingNumber>{text}</TrackingNumber>
                </Link>
              ) : (
                '-'
              )
          }
        ];
        break;
      case LOSS:
        data = [
          {
            label: 'Approved/Rejected',
            value: 'Approved'
          },
          {
            label: 'Win/Loss',
            value: 'Loss'
          },
          {
            label: 'Reason for the Loss',
            value: detailAPI?.win_loss_reason || '-'
          },
          {
            label: 'Total Cost from NP',
            value: detailAPI?.network_partner_cost
              ? `${detailAPI?.np_currency} ${detailAPI?.network_partner_cost}`
              : '-'
          },
          {
            label: 'Total Quote Price',
            value: detailAPI?.total_quote_price
              ? `${detailAPI?.qp_currency} ${detailAPI?.total_quote_price}`
              : '-'
          }
        ];
        break;
      case REJECTED:
        data = [
          {
            label: 'Approved/Rejected',
            value: [APPROVED, REJECTED].includes(detailAPI?.quote_status)
              ? QUOTE_STATUS_MAPPING[(detailAPI?.quote_status)]
              : '-'
          },
          {
            label: 'Reason for Rejection',
            value: detailAPI?.win_loss_reason || '-'
          },
          {
            label: 'Win/Loss',
            value: '-'
          }
        ];
        break;
      default:
        data = [
          {
            label: 'Approved/Rejected',
            value: [APPROVED, REJECTED].includes(detailAPI?.quote_status)
              ? QUOTE_STATUS_MAPPING[(detailAPI?.quote_status)]
              : '-'
          },
          {
            label: 'Win/Loss',
            value: '-'
          }
        ];
    }
    return {
      title: 'Quote Status',
      data
    };
  };
  const quoteStatus = getQuoteStatus(detailAPI?.quote_status);
  const merchant = {
    title: 'Merchant Information',
    data: [
      {
        label: 'Merchant Name',
        value: detailAPI?.merchant_name || '-'
      }
    ]
  };
  const yourShipment = {
    title: 'Your Shipment',
    data: [
      {
        label: 'Shipment Name',
        value: detailAPI?.shipment_name || '-'
      },
      {
        label: 'Emails to Receive Quotation Updates',
        value: detailAPI?.merchant_emails?.join(', ') || '-'
      }
    ]
  };
  const shipmentMode = {
    title: 'Shipment Mode',
    data: [
      {
        label: 'Freight Used',
        value: detailAPI?.shipment_mode
      }
    ]
  };
  const getFirstMileTxt = () => {
    let firstMileTxt = '';
    if (detailAPI?.origin?.is_using_first_mile === 1) {
      firstMileTxt = 'Pickup';
    } else if (detailAPI?.origin?.is_using_first_mile === 0) {
      firstMileTxt = 'Dropoff';
    } else {
      firstMileTxt = 'No first mile';
    }
    return firstMileTxt;
  };

  const origin = {
    title: 'Origin/Shipper',
    data: [
      {
        label: 'First-mile',
        value: getFirstMileTxt()
      },
      {
        label: 'Country',
        value: detailAPI?.origin?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          detailAPI?.origin?.is_using_first_mile
            ? 'Address'
            : 'Dropoff Address',
        value:
          [
            detailAPI?.origin?.location_name,
            detailAPI?.origin?.address,
            detailAPI?.origin?.city,
            detailAPI?.origin?.state,
            detailAPI?.origin?.postal_code
          ]
            .filter(Boolean)
            .join(', ') || '-'
      },
      {
        label: 'Origin Airport/Port',
        value: detailAPI?.origin?.port || '-'
      },
      {
        label: 'Pickup Date',
        formatLabel: () =>
          detailAPI?.origin?.is_using_first_mile
            ? 'Pickup Date'
            : 'Dropoff Date',
        value: detailAPI?.origin?.pickup_date,
        formatValue: (value) => moment(value).format('DD MMM YYYY')
      },
      {
        label: "Shipper's Name",
        value: detailAPI?.shipper_name
      },
      {
        label: 'Cargo Readiness Date',
        value: detailAPI?.cargo_readiness_date,
        formatValue: (value) =>
          value ? moment(value).format('DD MMM YYYY') : '-'
      },
      {
        label: 'POC Name',
        value: detailAPI?.origin?.poc_name || '-'
      },
      {
        label: 'POC Number',
        value: detailAPI?.origin?.poc_number || '-'
      },
      {
        label: 'PoC Email',
        value: detailAPI?.origin?.poc_email || '-'
      }
    ]
  };

  if (detailAPI?.origin?.is_using_first_mile === 2) {
    origin.data = origin.data.filter((obj) => obj.label !== 'Address');
  }

  const midMile = {
    title: 'Mid-mile',
    data: [
      {
        label: 'Freight-forwarding',
        value: detailAPI?.is_using_mid_mile,
        formatValue: (value) => (value ? 'Yes' : 'No')
      }
    ]
  };

  const destination = {
    title: 'Destination/Consignee',
    data: [
      {
        label: 'Last-mile',
        value: detailAPI?.destination?.is_using_last_mile,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Country',
        value: detailAPI?.destination?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          detailAPI?.destination?.is_using_last_mile
            ? 'Address'
            : 'Pickup Address',
        value:
          [
            detailAPI?.destination?.location_name,
            detailAPI?.destination?.address,
            detailAPI?.destination?.city,
            detailAPI?.destination?.state,
            detailAPI?.destination?.postal_code
          ]
            .filter(Boolean)
            .join(', ') || '-'
      },
      {
        label: 'Destination Airport/Port',
        value: detailAPI?.destination?.port || '-'
      },
      {
        label: 'Delivery Date',
        value: detailAPI?.destination?.pickup_date,
        formatValue: (value) => `${moment(value).format('DD MMM YYYY')} (Est.)`
      },
      {
        label: "Consignee's Name",
        value: detailAPI?.consignee_name || '-'
      },
      {
        label: 'POC Name',
        value: detailAPI?.destination?.poc_name || '-'
      },
      {
        label: 'POC Number',
        value: detailAPI?.destination?.poc_number || '-'
      },
      {
        label: 'PoC Email',
        value: detailAPI?.destination?.poc_email || '-'
      }
    ]
  };

  const information = {
    title: 'Shipping Information',
    data: [
      {
        label: 'Incoterm',
        value: detailAPI?.incoterm,
        formatValue: (value) => value || 'None'
      },
      {
        label: 'HS Code',
        value: detailAPI?.hs_code || '-'
      }
    ]
  };

  const getLoadType = () => {
    switch (detailAPI?.shipment_mode) {
      case 'Sea':
        return {
          label: 'Container',
          load: detailAPI?.shipment_load_type
            ? 'Full container load (FCL)'
            : 'Less than container load (LCL)'
        };

      case 'Land':
        return {
          label: 'Truck',
          load: detailAPI?.shipment_load_type
            ? 'Full truck load (FTL)'
            : 'Less than truck load (LTL)'
        };
      default:
        return null;
    }
  };

  const load = {
    title: getLoadType()?.label,
    data: [
      {
        label: `${getLoadType()?.label} Load`,
        value: getLoadType()?.load
      }
    ]
  };
  const getLoadDetails = () => {
    const loadDetails = [];
    let index = 1;
    const title = getLoadType()?.label;
    for (let i = 0; i < (detailAPI?.loads?.length || 0); i += 1) {
      for (
        let j = 0;
        j < (detailAPI?.loads[i]?.containers_seals?.length || 0);
        j += 1
      ) {
        const containerType =
          detailAPI?.loads[i]?.type !== null
            ? `(${detailAPI?.loads[i]?.type})`
            : '';
        loadDetails.push({
          title: `${title} ${index} ${containerType}`,
          data: [
            {
              label: `${title} No.`,
              value:
                detailAPI?.loads[i]?.containers_seals[j]?.containerNo || '-'
            },
            {
              label: `Seal No.`,
              value: detailAPI?.loads[i]?.containers_seals[j]?.sealNo || '-'
            }
          ]
        });
        index += 1;
      }
    }
    return loadDetails;
  };
  if (detailAPI?.shipment_load_type) {
    load.data = [
      ...load.data,
      {
        label: `No. of ${getLoadType()?.label}s`,
        value: detailAPI?.load_type_units
      }
    ];
  }

  const agreedPrice = {
    title: 'Shipping Price',
    data: [
      {
        label: 'Has Agreed Price',
        value: detailAPI?.agreed_price,
        formatValue: (value) => {
          if (value === null) {
            return 'None';
          }
          return value ? 'Yes' : 'No';
        }
      },
      {
        label: 'Proposed Price, Reasons & Conditions',
        value: detailAPI?.shipment_price,
        formatValue: (value) => value || 'None'
      }
    ]
  };
  const packages = detailAPI?.packages?.map((item, index) => {
    const getWeightValue = (weightType, packageItem) => {
      if (weightType) {
        return packageItem.weight
          ? `${packageItem.weight} ${packageItem.weight_uom.toUpperCase()}`
          : 'None';
      }
      return packageItem.total_weight
        ? `${packageItem.total_weight} ${packageItem.weight_uom.toUpperCase()}`
        : 'None';
    };
    const getVolumeValue = (volumeType, packageItem) => {
      if (
        volumeType &&
        packageItem.length &&
        packageItem.width &&
        packageItem.height
      ) {
        return `${packageItem.length} X ${packageItem.width} X ${
          packageItem.height
        } ${packageItem?.length_uom?.toUpperCase()}`;
      }
      return packageItem.total_volume
        ? `${packageItem.total_volume} CBM`
        : 'None';
    };
    return {
      title: `Set ${index + 1}`,
      data: [
        {
          label: 'Type',
          value: item.type
        },
        {
          label: 'No. of units',
          value: item.units
        },
        {
          label: 'Chargeable Weight',
          value: item.chargeable_weight
            ? `${item.chargeable_weight} KG`
            : 'None'
        },
        {
          label: item.weight_type ? 'Weight (Per Unit)' : 'Weight (Total)',
          value: getWeightValue(item.weight_type, item)
        },
        {
          label: item.volume_type ? 'Volume (Per Unit)' : 'Volume (Total)',
          value: getVolumeValue(item.volume_type, item)
        }
      ]
    };
  });

  const dangerousGoods = {
    'Hazardous Goods': shipmentDetails?.contains_hazardous_goods,
    Batteries: shipmentDetails?.contains_battery,
    Others: shipmentDetails?.other
  };

  const otherDetails = {
    title: 'Other Details',
    data: [
      {
        label: 'Dangerous Goods',
        value: Object.keys(dangerousGoods).filter(
          (goods) => dangerousGoods[goods]
        ),
        formatValue: (value) => value.join(', ') || 'None'
      },
      {
        label: 'Shipment Notes/Remarks',
        value: shipmentDetails?.description,
        formatValue: (value) => value || 'None'
      }
    ]
  };

  const customBrokerageServices = {
    title: 'Custom Brokerage Services',
    data: [
      {
        label: 'Custom Clearance',
        value: detailAPI?.is_using_custom_clearance,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Exporter of Record',
        value: detailAPI?.is_eor_present,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Importer of Record',
        value: detailAPI?.is_ior_present,
        formatValue: (value) => (value ? 'Yes' : 'No')
      }
    ]
  };

  const total = {
    title: 'Total',
    data: [
      {
        label: 'Units By Package Type',
        value: detailAPI?.package_type_units,
        formatValue: (value) => value || 'None'
      },
      {
        label: 'Volume/Measurement (m3)',
        value: detailAPI?.total_volume,
        formatValue: (value) => {
          if (value) {
            const text = formatShipmentModeValue(
              detailAPI?.shipment_mode,
              value
            );
            return `${text} CBM`;
          }
          return 'None';
        }
      },
      {
        label: 'Gross Weight',
        value: detailAPI?.total_actual_weight,
        formatValue: (value) => {
          if (value) {
            return `${Math.ceil(parseFloat(value))} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Volumetric Weight',
        value: detailAPI?.total_volumetric_weight,
        formatValue: (value) => {
          if (value && detailAPI?.shipment_mode !== 'Sea') {
            const text = formatShipmentModeValue(
              detailAPI?.shipment_mode,
              value
            );
            return `${text} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Total Chargeable Weight',
        value: formatShipmentModeValue(
          detailAPI?.shipment_mode,
          detailAPI?.total_weight
        ),
        formatValue: (value) => {
          if (value) {
            if (detailAPI?.total_weight_unit) {
              return `${value} ${detailAPI?.total_weight_unit}`;
            }
            return `${value} ${
              detailAPI?.shipment_mode === 'Sea' ? 'CBM' : 'KG'
            }`;
          }
          return 'None';
        }
      }
    ]
  };

  return (
    <StyledShipmentDetails id="shipment-details">
      <QuoteActionBar
        quote_status={detailAPI?.quote_status}
        quote_id={detailAPI?.quote_id}
        shipment_quote_id={detailAPI?.shipment_quote_id}
      />
      <div className="shipment-body">
        <ShipmentDetailRow schema={quoteStatus} />
        <ShipmentDetailRow schema={merchant} />
        <ShipmentDetailRow schema={yourShipment} type="twoCol" />
        <ShipmentDetailRow schema={shipmentMode} />
        <ShipmentDetailRow schema={origin} />
        <ShipmentDetailRow schema={midMile} />
        <ShipmentDetailRow schema={destination} />
        <ShipmentDetailRow schema={information} />
        <ShipmentDetailRow schema={agreedPrice} />
        {getLoadType() && detailAPI?.shipment_load_type !== null && (
          <ShipmentDetailRow schema={load} />
        )}
        {getLoadDetails().map((loadDetails) => (
          <ShipmentDetailRow key={loadDetails.title} schema={loadDetails} />
        ))}
        {packages.map((item) => (
          <ShipmentDetailRow key={item.title} schema={item} />
        ))}
        <ShipmentDetailRow schema={total} />
        <ShipmentDetailRow schema={otherDetails} type="twoCol" />
        <ShipmentDetailRow schema={customBrokerageServices} />
      </div>
      {![WIN, LOSS].includes(detailAPI?.quote_status) && (
        <Button style={{ width: '200px' }} onClick={onEdit}>
          Edit
        </Button>
      )}
    </StyledShipmentDetails>
  );
};

export default QuoteDetailsView;
