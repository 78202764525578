import React, { useState, useRef } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styled from '@emotion/styled';
import { cx } from 'emotion';
import countryList from 'country-list';
import Input from './Input';
import { TEXT_NO_RESULT } from '../constants/message';

const SuggestionItem = styled.div`
  padding: 5px 12px;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400px;
  color: #000;
  background: transparent;
  cursor: pointer;
  &.suggestion-item--active {
    background: #f2f2f9;
  }
`;

const DropdownContainer = styled.div`
  border-radius: 4px;
  &.autocomplete-dropdown-active {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 0;
  }
`;

const StyledSearchInput = styled.div`
  position: relative;
  input {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000;
    padding: 6px 11px 3px 11px;
  }
`;

const LocationSearchInput = ({
  country = '',
  value: inputValue = '',
  types = [],
  placeholder,
  onChange = () => {},
  onBlur = () => {},
  onFocusInput,
  freeText = true
}) => {
  const [value, setValue] = useState(() => inputValue);
  const triggerChange = useRef(true);
  const active = useRef(false);
  const valid = useRef(false);
  const node = useRef(null);
  const inputRef = useRef();

  const searchOptions = {
    types
  };

  if (country) {
    searchOptions.componentRestrictions = {
      country: countryList.getData().find((item) => item.name === country)?.code
    };
  }

  const handleChange = (val) => {
    setValue(val);
    onChange(val);
  };

  return (
    <PlacesAutocomplete
      ref={node}
      value={value}
      onChange={(val) => {
        if (triggerChange.current) {
          handleChange(val);
        }
      }}
      onSelect={(_desc, id) => {
        const selected = node.current.state.suggestions.find(
          (suggestion) => suggestion.placeId === id
        );
        valid.current = true;
        handleChange(selected?.formattedSuggestion?.mainText);
        active.current = false;
        inputRef.current.blur();
      }}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const {
          onBlur: onInputBlur,
          onKeyDown,
          ...inputProps
        } = getInputProps();
        const suggestionsFiltered = suggestions;
        return (
          <StyledSearchInput>
            <Input
              ref={inputRef}
              placeholder={placeholder}
              {...inputProps}
              // "off" does not work on Chrome
              autoComplete="disabled"
              onBlur={(e) => {
                active.current = false;
                onInputBlur(e);
                onBlur(e);
                if (!freeText && !valid.current) {
                  handleChange('');
                }
                valid.current = false;
              }}
              onClick={() => {
                active.current = true;
              }}
              onFocus={onFocusInput}
              onKeyDown={(e) => {
                if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) {
                  triggerChange.current = false;
                } else {
                  triggerChange.current = true;
                }
                active.current = true;
                onKeyDown(e);
              }}
            />
            {value && loading && active.current && (
              <DropdownContainer className="autocomplete-dropdown-container autocomplete-dropdown-active">
                <SuggestionItem>
                  <span>Loading...</span>
                </SuggestionItem>
              </DropdownContainer>
            )}
            {value &&
              !loading &&
              active.current &&
              suggestionsFiltered.length === 0 && (
                <DropdownContainer className="autocomplete-dropdown-container autocomplete-dropdown-active">
                  <SuggestionItem>
                    <span>{TEXT_NO_RESULT}</span>
                  </SuggestionItem>
                </DropdownContainer>
              )}
            {value && !loading && active.current && (
              <DropdownContainer
                className={cx('autocomplete-dropdown-container', {
                  'autocomplete-dropdown-active': suggestions.length
                })}
              >
                {suggestionsFiltered.map((suggestion) => {
                  const mainText =
                    suggestion.formattedSuggestion &&
                    suggestion.formattedSuggestion.mainText;
                  const itemProps = getSuggestionItemProps(suggestion, {
                    className: suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item'
                  });
                  return (
                    <SuggestionItem aria-hidden {...itemProps}>
                      <span>{mainText}</span>
                    </SuggestionItem>
                  );
                })}
              </DropdownContainer>
            )}
          </StyledSearchInput>
        );
      }}
    </PlacesAutocomplete>
  );
};
export default LocationSearchInput;
