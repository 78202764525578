import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import styled from '@emotion/styled';

const StyledCheckBox = styled(AntCheckbox)`
  .ant-checkbox-wrapper {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    min-width: 105px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #050593;
    border-color: #050593;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #050593;
  }

  .ant-checkbox-disabled :not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: #fff;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #8282c9;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
`;

const StyledCheckBoxGroup = styled(AntCheckbox.Group)`
  .ant-checkbox-wrapper {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    min-width: 105px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #050593;
    border-color: #050593;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #050593;
  }

  .ant-checkbox-disabled :not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: #fff;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #8282c9;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
`;

const Checkbox = ({ children, ...rest }) => {
  return <StyledCheckBox {...rest}>{children}</StyledCheckBox>;
};

const CheckboxGroup = ({ children, ...rest }) => {
  return <StyledCheckBoxGroup {...rest}>{children}</StyledCheckBoxGroup>;
};

Checkbox.Group = CheckboxGroup;

export default Checkbox;
