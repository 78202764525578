import React from 'react';
import styled from '@emotion/styled';
import { Dropdown as AntDropdown, Icon } from 'antd';

const StyledDropdown = styled(AntDropdown)`
  border-radius: 4px;
  border: solid 1px #050593;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  padding: 0 15px;
  outline: none;
  span {
    margin-right: 8px;
  }

  &.primary {
    color: #fff;
    background: #050593;
  }

  &.secondary {
    color: #050593;
    background: #fff;
  }
`;

const Dropdown = ({
  children,
  label,
  className,
  type = 'primary',
  ...props
}) => {
  return (
    <StyledDropdown
      className={type}
      overlay={children}
      trigger={['click']}
      {...props}
    >
      <button type="button">
        <span>{label}</span>
        <Icon type="down" />
      </button>
    </StyledDropdown>
  );
};

export default Dropdown;
