import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from './Button';
import Modal from './Modal';

import { FormInput, FormLocationSearchInput } from './Forms';

const StyledLocationInputFormRow = styled.div``;

export const LocationInputFormRow = ({ schema, title, children }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getInitialData = () => {
      if (!schema) return [];
      if (schema.formatData) {
        return schema.formatData(schema.data);
      }
      return schema.data;
    };
    setData(getInitialData());
  }, [schema, schema.data]);

  return (
    <StyledLocationInputFormRow className="sample-row">
      <h5>{schema?.title || title}</h5>
      {data && (
        <ul>
          {data.map(({ label, value, editable, formatValue }) => (
            <li key="labelvalue">
              {label && <em className="label">{label}</em>}
              <div className="editable-control">
                <FormEditableInput
                  editable={editable}
                  value={value}
                  formatValue={formatValue}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
      {children && <p>{children}</p>}
    </StyledLocationInputFormRow>
  );
};

const LocationInputForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  children
}) => {
  const address = {
    title: 'Edit Address',
    data: [
      {
        label: 'Country',
        value: initialValues?.country
      },
      {
        label: 'Location Name',
        value: initialValues?.location_name,
        editable: {
          name: 'location_name',
          type: 'FormInput'
        }
      },
      {
        label: 'City',
        value: initialValues.city,
        editable: {
          type: 'FormLocationSearchInput',
          name: 'city',
          country: initialValues?.country,
          types: ['(cities)'],
          freeText: false
        }
      },
      {
        label: 'State',
        value: initialValues.state,
        country: initialValues?.country,
        editable: {
          type: 'FormLocationSearchInput',
          name: 'state',
          country: initialValues?.country,
          types: ['(regions)'],
          freeText: false
        }
      },
      {
        label: 'Postal Code',
        value: initialValues.postal_code,
        editable: {
          name: 'postal_code',
          type: 'FormInput'
        }
      },
      {
        label: 'Address',
        value: initialValues.address,
        editable: {
          type: 'FormLocationSearchInput',
          name: 'address',
          country: initialValues?.country
        }
      },
      {
        label: `PoC's Name (Optional)`,
        value: initialValues.pocName,
        editable: {
          name: 'poc_name',
          type: 'FormInput'
        }
      },
      {
        label: `PoC's Number (Optional)`,
        value: initialValues.pocNumber,
        editable: {
          name: 'poc_number',
          type: 'FormInput'
        }
      },
      {
        label: `PoC's Email (Optional)`,
        value: initialValues.pocEmail,
        editable: {
          name: 'poc_email',
          type: 'FormInput'
        }
      }
    ]
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit, resetForm }) =>
        children({
          schema: {
            address
          },
          handleSubmit,
          resetForm
        })
      }
    </Formik>
  );
};

const FormEditableInput = ({ value, editable, formatValue }) => {
  const getFormattedValue = () => {
    return (formatValue ? formatValue(value) : value) || 'None';
  };

  if (editable) {
    const { name, type, ...props } = editable;
    switch (editable.type) {
      case 'FormInput':
        return <FormInput name={name} timeout={100} />;
      case 'FormLocationSearchInput':
        return <FormLocationSearchInput {...props} name={name} />;
      default:
        return <em style={{ color: '#1fceca' }}>{getFormattedValue()}</em>;
    }
  }

  return <span className="value">{getFormattedValue()}</span>;
};

const StyledLocationInput = styled.div`
  line-height: 1;
  ul.location-input-form {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    & > li {
      width: ${(props) => props.width || '50%'};
      flex-grow: ${(props) => props.grow || '1'};
      padding: 0 10px;
    }
    & > li:last-of-type {
      flex-grow: 0;
    }
    & > li em {
      font-family: AvenirLTStdHeavy;
      font-size: 14px;
      color: #707070;
      display: block;
      font-style: normal;
      margin-bottom: 5px;
    }
    & > li .editable-control {
      margin-bottom: 20px;
    }
    & > li .editable-control > span {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #000000;
    }
  }
`;

const LocationInput = ({
  location,
  title,
  children,
  submitText,
  onSubmit = async () => {}
}) => {
  // const [initialValues, setInitialValues] = useState(() => location);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const locationInputFormSchema = Yup.object().shape({
    location_name: Yup.mixed()
      .nullable()
      .required('Please enter Location Name'),
    city: Yup.string()
      .nullable()
      .required('Please enter City'),
    state: Yup.string()
      .nullable()
      .required('Please enter State'),
    postal_code: Yup.mixed()
      .nullable()
      .test('postalCode', 'Please enter valid characters', (value) => {
        if (!value) return true;
        return /^[0-9A-Z\- ]+$/.test(value);
      }),
    address: Yup.mixed()
      .nullable()
      .required('Please enter Address')
      .test(
        'max',
        'You have exceeded the maximum 250 characters length limit',
        (value) => {
          return (value?.length || 0) <= 250;
        }
      ),
    poc_name: Yup.mixed()
      .nullable()
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          if (value) {
            return (value?.length || 0) <= 50;
          }
          return true;
        }
      ),
    poc_number: Yup.mixed()
      .nullable()
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          if (value) {
            return (value?.length || 0) <= 50;
          }
          return true;
        }
      )
      .test('phoneNumber', 'Please enter valid characters', (value) => {
        if (value) {
          return /^\+?[0-9\- ]+$/.test(value);
        }
        return true;
      }),
    poc_email: Yup.mixed()
      .nullable()
      .test('email', 'Please enter a valid email address', (value) => {
        if (value) {
          return /^[^@]+@[^.]+\..+$/.test(value);
        }
        return true;
      })
  });

  const showModal = () => {
    setVisible(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    setVisible(false);
  };

  const handleCancel = (resetForm) => () => {
    resetForm();
    setVisible(false);
  };

  return (
    <LocationInputForm
      initialValues={location}
      validationSchema={locationInputFormSchema}
      onSubmit={handleSubmit}
    >
      {({ schema: { address }, handleSubmit: formSubmit, resetForm }) => (
        <StyledLocationInput>
          {children && children({ showModal, setVisible })}
          <Modal
            title={title || 'Edit Address'}
            visible={visible}
            onCancel={handleCancel(resetForm)}
            closable={false}
            footer={null}
            maskClosable={!loading}
          >
            {address.data && (
              <ul className="location-input-form">
                {address.data.map(({ label, value, editable, formatValue }) => (
                  <li key={`${label}${value}`}>
                    {label && <em className="label">{label}</em>}
                    <div className="editable-control">
                      <FormEditableInput
                        editable={editable}
                        value={value}
                        formatValue={formatValue}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div className="ant-modal-footer">
              <Button
                key="back"
                type="secondary"
                onClick={handleCancel(resetForm)}
              >
                Cancel
              </Button>
              <Button key="submit" loading={loading} onClick={formSubmit}>
                {submitText || 'Edit'}
              </Button>
            </div>
          </Modal>
        </StyledLocationInput>
      )}
    </LocationInputForm>
  );
};

export default LocationInput;
