import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Icon, message } from 'antd';
import moment from 'moment';
import { quoteService } from '../../services';
import { getNewStateFromCurrent } from '../../fsm';
import Button from '../Button';
import SelectTags from '../SelectTags';
import Input from '../Input';
import Table from '../Table';
import {
  getQuoteCharges,
  addLineItems,
  removeLineItem,
  updateLineItem,
  updateQuoteStatus
} from '../../actions/quoteDetail';
import {
  TEXT_QUOTE_LINE_ITEM_ERROR,
  TEXT_QUOTE_LINE_ITEM_SUCCESS
} from '../../constants/message';

const StyledShipmentQuote = styled.div`
  font-family: AvenirLTStdBook;
`;
const StyledTableContainer = styled.div`
  border: 1px dashed #050593;
  padding: 10px;
  margin-bottom: 15px;
`;
const QuoteValidity = styled.span`
  font-size: 16px;
  font-family: AvenirLTStdBookOblique;
  padding-left: 12px;
  color: #050593;
  width: 370px;
`;
const QuoteTotal = styled.span`
  font-size: 19px;
  color: #000000;
  font-family: AvenirLTStdHeavy;
  padding-right: 12px;
`;
const LineItemButtom = styled(Button)`
  margin: 10px 0 10px 14px;
`;
const { Option } = SelectTags;
const feeName = [
  'First mile',
  'Freight Handling',
  'Customs Brokerage',
  'Last mile',
  'Handling'
];
const StyledFeeNameDropdown = styled(SelectTags)`
  width: 160px;
`;
const StyledCommentsDropdown = styled(SelectTags)`
  width: 110px;
`;
const comments = ['Flat fee', 'Per Unit'];

const StyledButton = styled(Button)`
  margin: 0 0 0 8px;
`;

// TODO: implement loading state and fetch data
const ShipmentQuoteDetailsEdit = ({ quoteId, onCancel }) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState();
  useEffect(() => {
    dispatch(getQuoteCharges(quoteId));
  }, [dispatch, quoteId]);
  const quoteStatus = useSelector(
    (state) => state?.quoteDetail?.quote?.quote_status
  );
  const handleAddLineItems = () => {
    dispatch(addLineItems());
  };
  const handleRemoveLineItem = (key) => {
    dispatch(removeLineItem(key));
  };
  const handleLineItemChange = (index, value, type) => {
    dispatch(updateLineItem({ index, value, type }));
  };
  const invoiceAPI = useSelector(
    (state) => state?.quoteDetail?.charges?.line_items || []
  );
  const dataSource = invoiceAPI.map((item, index) => ({
    key: index,
    fee_name: item.fee_name,
    comment: item.comment,
    weight: item.weight,
    unit_price: `${item.unit_price}`,
    total_amount: `${item.total_amount}`
  }));
  const columns = [
    {
      title: 'Fee name',
      dataIndex: 'fee_name',
      key: 'fee_name',
      render: (text, record, index) => (
        <StyledFeeNameDropdown
          mode="tags"
          value={text}
          onChange={(value) => handleLineItemChange(index, value, 'fee_name')}
        >
          {feeName.map((name, feeNameIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Option key={feeNameIndex} value={name}>
              {name}
            </Option>
          ))}
        </StyledFeeNameDropdown>
      )
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (text, record, index) => (
        <StyledCommentsDropdown
          mode="mutiple"
          value={text}
          onChange={(value) => handleLineItemChange(index, value, 'comment')}
        >
          {comments.map((comment, commentIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Option key={commentIndex} value={comment}>
              {comment}
            </Option>
          ))}
        </StyledCommentsDropdown>
      )
    },
    {
      title: 'No. of units',
      dataIndex: 'weight',
      key: 'weight',
      render: (text, record, index) => (
        <Input
          type="number"
          value={text}
          min={0}
          onChange={(e) =>
            handleLineItemChange(index, e.target.value, 'weight')
          }
        />
      )
    },
    {
      title: 'Unit Price (USD)',
      dataIndex: 'unit_price',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          type="number"
          value={text}
          min={0}
          onChange={(e) =>
            handleLineItemChange(index, e.target.value, 'unit_price')
          }
        />
      )
    },
    {
      title: 'Total (USD)',
      dataIndex: 'total_amount',
      key: 'total_amount'
    },
    {
      key: 'action',
      render: (text, record, index) =>
        dataSource.length > 1 ? (
          <Icon type="delete" onClick={() => handleRemoveLineItem(index)} />
        ) : null
    }
  ];
  const getTotalPrice = () => {
    let total = 0;
    dataSource.map((item) => {
      total += item.weight * item.unit_price;
      return 0;
    });

    return total;
  };
  const handleSaveQuote = async () => {
    try {
      setSaving(true);
      await quoteService
        .saveQuoteLineItems({ data: dataSource, id: quoteId })
        .exec();
      await dispatch(
        updateQuoteStatus({
          id: quoteId,
          status: getNewStateFromCurrent(quoteStatus, 'SAVE')
        })
      );
      setSaving(false);
      message.success(TEXT_QUOTE_LINE_ITEM_SUCCESS);
      onCancel();
    } catch (error) {
      const result = error?.response?.data?.result;
      setSaving(false);
      message.error(result || TEXT_QUOTE_LINE_ITEM_ERROR);
      return null;
    }
    return null;
  };
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const dataShippingLog = useSelector((state) => ({
    expiringAt: state?.quoteDetail?.charges?.expiring_at,
    totalInvoiceAmount: state?.quoteDetail?.charges?.total_invoice_amount
  }));
  return (
    <StyledShipmentQuote id="shipment-quote">
      <h4>
        {`${detailAPI.origin && detailAPI.origin.country} to ${
          detailAPI.destination.country
        }`}
      </h4>
      <StyledTableContainer>
        <Table
          totalCount={dataSource.length}
          dataSource={dataSource}
          type="tertiary"
          showScroll={false}
          showPagination={false}
          columns={columns}
        />
        <LineItemButtom type="secondary" onClick={handleAddLineItems}>
          Add line item
        </LineItemButtom>
        <Row type="flex" justify="space-between">
          <QuoteValidity>
            This quote is valid until{' '}
            {moment(dataShippingLog && dataShippingLog.expiringAt).format(
              'DD MMM YYYY'
            )}{' '}
            and does not include taxes and duties
          </QuoteValidity>
          <QuoteTotal>
            Total USD{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            })
              .format(getTotalPrice())
              .slice(1)}
          </QuoteTotal>
        </Row>
      </StyledTableContainer>
      <div>
        <Row type="flex" justify="end">
          <Col>
            <StyledButton type="secondary" onClick={onCancel}>
              Cancel
            </StyledButton>
            <StyledButton loading={saving} onClick={handleSaveQuote}>
              Save
            </StyledButton>
          </Col>
        </Row>
      </div>
    </StyledShipmentQuote>
  );
};

export default ShipmentQuoteDetailsEdit;
