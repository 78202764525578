import React, { useState } from 'react';
import styled from '@emotion/styled';
import Select from './Select';
import { invalidCharacter } from '../utils/formatString';

const StyledSelect = styled(Select)`
  .ant-select-selection--multiple {
    padding: 0;
    height: 20px;
    overflow: hidden;
    .ant-select-selection__rendered {
      font-size: 16px;
    }
  }
  .ant-select-selection__rendered > ul {
    white-space: nowrap;
  }
  .ant-select-selection--multiple .ant-select-search--inline {
    position: absolute;
    display: inline;
  }
`;
const ErrorMsg = styled.p`
  color: #cf4242;
  font-size: 16px;
`;

const SelectTags = (props) => {
  const { children, onChange, value, ...rest } = props;
  const styles = props.styles || {};
  const [selectValue, setSelectValue] = useState(value);
  const [showError, setShowError] = useState(false);
  const handleOnChange = (val) => {
    const newValue = val[val.length - 1];
    if (invalidCharacter(newValue)) {
      setShowError(true);
    } else {
      setShowError(false);
      setSelectValue(val[val.length - 1]);
      onChange(val[val.length - 1]);
    }
  };
  return (
    <>
      <StyledSelect
        style={styles}
        getInputElement={() => <input maxLength={50} />}
        value={selectValue}
        onChange={handleOnChange}
        {...rest}
      >
        {children}
      </StyledSelect>
      {showError && <ErrorMsg>Please enter valid characters</ErrorMsg>}
    </>
  );
};

SelectTags.Option = Select.Option;
SelectTags.OptGroup = Select.OptGroup;

export default SelectTags;
