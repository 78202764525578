import { createAction } from '@reduxjs/toolkit';
import { quoteService } from '../services/quotes';

export const fetchQuotes = createAction('quoteRequests/FETCH_DATA');
export const fetchQuotesLoading = createAction(
  'quoteRequests/FETCH_DATA_LOADING'
);

export const fetchChoices = createAction('quoteRequestChoices/FETCH_DATA');
export const fetchChoicesLoading = createAction(
  'quoteRequestChoices/FETCH_DATA_LOADING'
);

export const setPageFilter = createAction('quoteRequestFilter/SET_PAGE');
export const setPageSizeFilter = createAction(
  'quoteRequestFilter/SET_PAGE_SIZE'
);

export const applyFilters = createAction('quoteRequestFilter/APPLY_FILTERS');
export const cancelFilters = createAction('quoteRequestFilter/CANCEL_FILTERS');
export const resetFilters = createAction('quoteRequestFilter/RESET_FILTERS');
export const setQuoteFilters = createAction('quoteRequestFilter/SET_FILTERS');
export const setQuoteRangeFilters = createAction(
  'quoteRequest/SET_RANGE_FILTERS'
);

export const getQuotes = (params = {}) => async (dispatch) => {
  dispatch(fetchQuotesLoading());
  quoteService.getQuoteRequests(params).then((data) => {
    dispatch(fetchQuotes(data));
  });
};

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading());
  const choicesData = await quoteService.getQuoteRequestFilters();
  dispatch(fetchChoices({ ...choicesData }));
};
