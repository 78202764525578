import airportCountries from './airportCountries.json';
import seaports from './seaPorts.json';

const AIRPORTS = airportCountries;

const AIRPORT_NAMES = [
  ...new Set(AIRPORTS.map((airport) => airport.airportName))
];

// eslint-disable-next-line array-callback-return, consistent-return
const AIRPORT_VALUES = AIRPORT_NAMES.map((airportName) => {
  if (airportName) {
    return {
      label: airportName,
      value: airportName
    };
  }
});

const SEAPORTS = Object.values(seaports);

const SHOPEE_DESTINATION_AIRPORTS = [
  {
    key: '0',
    value: 'CEK',
    label: 'Soekarno-Hatta International Airport ID Jakarta'
  },
  {
    key: '1',
    value: 'CEB',
    label: 'Mactan-Cebu International Airport PH Lapu-Lapu'
  },
  {
    key: '2',
    value: 'MNL',
    label: 'Ninoy Aquino International Airport / Villamor Air Base PH Manila'
  },
  {
    key: '3',
    value: 'SIN',
    label: 'Singapore Changi Airport SG Singapore'
  }
];

export {
  AIRPORTS,
  AIRPORT_NAMES,
  AIRPORT_VALUES,
  SEAPORTS,
  SHOPEE_DESTINATION_AIRPORTS
};
