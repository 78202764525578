import React, { useState } from 'react';
import CostBillingDetailsView from './CostBillingDetailsView';
import CostBillingDetailsEdit from './CostBillingDetailsEdit';

const CostBillingDetails = ({ quoteId }) => {
  const [isEditing, setEditing] = useState(false);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <CostBillingDetailsEdit quoteId={quoteId} onCancel={onCancel} />
      ) : (
        <CostBillingDetailsView quoteId={quoteId} onEdit={onEdit} />
      )}
    </>
  );
};

export default CostBillingDetails;
