/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col, Skeleton as AntSekeleton } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  Table,
  Card,
  ActionLink,
  Button,
  QuoteColumnFilter
} from '../components';
import {
  getQuotes,
  getChoices,
  setPageFilter,
  setQuoteFilters,
  cancelFilters,
  resetFilters
} from '../actions/quoteRequestsList';
import FilterSVG from '../assets/icons/filter.svg';
import FilterSelectedSVG from '../assets/icons/filterSelected.svg';

const filterIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSVG}
  />
);
const filterSelectedIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSelectedSVG}
  />
);

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 30px 40px;
  .ant-row {
    margin-bottom: 20px;
  }
  .requestQuoteBtn {
    width: 200px;
    height: 36px;
  }
  .reset-filter-btn {
    height: 36px;
  }
`;

const QuoteRequests = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState();
  const filtered = useSelector((state) => state.quoteRequestList.filtered);
  const currentPage = useSelector((state) => state.quoteRequestList?.page);
  const pageSize = useSelector((state) => state.quoteRequestList?.records);
  const { data, total: totalCount, loading } = useSelector(
    (state) => state.quoteRequestList.quotes
  );

  const handleFilter = (value) => {
    dispatch(setQuoteFilters({ page: 1 }));
    setFilters(value);
  };

  const handleResetFilter = () => {
    dispatch(resetFilters());
    handleFilter({});
    dispatch(cancelFilters());
  };

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) dispatch(cancelFilters());
  };

  useEffect(() => {
    const recordFilters = { page: currentPage, pageSize, ...filters };
    dispatch(getQuotes(recordFilters));
    dispatch(getChoices());
  }, [dispatch, currentPage, pageSize, filters]);

  const columns = [
    {
      title: 'Quote Number',
      dataIndex: 'quoteId',
      key: 'quoteId',
      width: 180,
      ellipsis: true,
      render: (text) => <ActionLink to={`/quotes/${text}`}>{text}</ActionLink>,
      fixed: 'left',
      filterIcon: filters && filters.quoteId ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="quoteId"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.createdAt?.length ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="createdAt"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Approved/Rejected',
      dataIndex: 'approveRejectedStatus',
      key: 'approveRejectedStatus',
      render: (text) => (text ? `${_.startCase(_.toLower(text))}` : '-'),
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.approveRejectedStatus
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="approveRejectedStatus"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 170,
      ellipsis: true,
      filterIcon:
        filters && filters.merchantName ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="merchantName"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      width: 130,
      ellipsis: true,
      filterIcon: filters && filters.origin ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="origin"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.destination ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="destination"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Mode of Shipment',
      dataIndex: 'shipmentMode',
      key: 'shipmentMode',
      width: 190,
      ellipsis: true,
      filterIcon:
        filters && filters.shipmentMode ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="shipmentMode"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.createdBy ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="createdBy"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Creator Email',
      dataIndex: 'creatorEmail',
      key: 'creatorEmail',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.creatorEmail ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="creatorEmail"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Quote Status',
      dataIndex: 'quoteStatus',
      key: 'quoteStatus',
      render: (text) => (text ? `${_.startCase(_.toLower(text))}` : '-'),
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.quoteStatus ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="quoteStatus"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Reason',
      dataIndex: 'winLossReason',
      key: 'winLossReason',
      render: (text) => text || '-',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.winLossReason ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="winLossReason"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Shipment Tracking Number',
      dataIndex: 'shipmentQuoteId',
      key: 'shipmentQuoteId',
      render: (text) => text || '-',
      width: 260,
      ellipsis: true,
      filterIcon:
        filters && filters.shipmentQuoteId ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          filterName="shipmentQuoteId"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Cost from NP',
      dataIndex: 'networkPartnerCost',
      key: 'networkPartnerCost',
      render: (text, record) =>
        record.networkPartnerCost
          ? `${record.npCurrency} ${record.networkPartnerCost}`
          : '-',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.networkPartnerCost &&
        (filters.networkPartnerCost.min || filters.networkPartnerCost.max)
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          isMinMaxFilter
          filterName="networkPartnerCost"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total Quote Price',
      dataIndex: 'totalQuotePrice',
      key: 'totalQuotePrice',
      render: (text, record) =>
        record.totalQuotePrice
          ? `${record.qpCurrency} ${record.totalQuotePrice}`
          : '-',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.totalQuotePrice &&
        (filters.totalQuotePrice.min || filters.totalQuotePrice.max)
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <QuoteColumnFilter
          confirm={confirm}
          isMinMaxFilter
          filterName="totalQuotePrice"
          onFilter={handleFilter}
        />
      )
    }
  ];

  return (
    <div>
      <StyledCard>
        <Row type="flex" sx={{ mb: '20px' }} gutter={16}>
          <Col>
            <Link to="/quotes/create-quote">
              <Button type="primary" className="requestQuoteBtn">
                Request Quote
              </Button>
            </Link>
          </Col>

          <Col sx={{ ml: 'auto' }}>
            <Button
              type="danger"
              onClick={handleResetFilter}
              className="reset-filter-btn"
              style={{ display: filtered ? 'block' : 'none' }}
            >
              Reset Filters
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            resizeable
            renderEmptyTable
            loading={loading}
            type="primary"
            columns={columns}
            dataSource={data}
            pageSize={parseInt(pageSize, 10)}
            currentPage={currentPage}
            onChange={(page) => dispatch(setPageFilter(page))}
            totalCount={totalCount}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No quote is found.</span>&nbsp;
                    {filtered === true && (
                      <span
                        className="link"
                        role="button"
                        tabIndex={0}
                        onKeyDown={handleResetFilter}
                        onClick={handleResetFilter}
                      >
                        Reset all filters
                      </span>
                    )}
                  </div>
                )}

                {loading && (
                  <AntSekeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default QuoteRequests;
