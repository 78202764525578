import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Skeleton, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import { ShipmentDetails } from './ShipmentDetails';
import { CostBillingDetails } from './CostBilling';
import { ShipmentQuoteDetails } from './ShipmentQuoteDetails';
import ShipmentDocuments from './ShipmentDocuments';
import ShipmentTrackingStatus from './ShipmentTrackingStatus';

const { TabPane } = Tabs;

const StyledQuoteDetailsTabs = styled.div`
  .ant-tabs {
    overflow: visible !important;
  }
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    @media (min-width: 1600px) {
      padding: 10px 32px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
  }
`;

let StateTabs = {
  QUOTE_REQUESTED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  QUOTE_DRAFT_CREATED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE'],
    activeTab: 'SHIPMENT_QUOTE'
  },
  QUOTE_REREQUESTED_DRAFT: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE'],
    activeTab: 'SHIPMENT_QUOTE'
  },
  QUOTE_CREATED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE'],
    activeTab: 'SHIPMENT_QUOTE'
  },
  QUOTE_ACCEPTED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DOCUMENT'
  },
  SHIPMENT_CREATED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DOCUMENT'
  },
  QUOTE_REQUEST_UNSUCCESSFUL: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_QUOTE'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  DOCUMENT_UPLOADED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DOCUMENT'
  },
  SHIPMENT_CONFIRMED: {
    tabs: [
      'SHIPMENT_DETAILS',
      'SHIPMENT_DOCUMENT',
      'TRACKING_STATUS',
      'SHIPMENT_COST_BILLING'
    ],
    activeTab: 'SHIPMENT_COST_BILLING'
  },
  CANCELLED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT', 'SHIPMENT_COST_BILLING'],
    activeTab: 'SHIPMENT_COST_BILLING'
  }
};

StateTabs = {
  ...StateTabs,
  SCHEDULED_FOR_PICKUP: StateTabs.SHIPMENT_CONFIRMED,
  SCHEDULED_FOR_DROPOFF: StateTabs.SHIPMENT_CONFIRMED,
  RESCHEDULED_FOR_PICKUP: StateTabs.SHIPMENT_CONFIRMED,
  RESCHEDULED_FOR_DROPOFF: StateTabs.SHIPMENT_CONFIRMED,
  REACHED_ORIGIN_WAREHOUSE: StateTabs.SHIPMENT_CONFIRMED,
  RECEIVED_BY_AIRLINES: StateTabs.SHIPMENT_CONFIRMED,
  FLIGHT_RESCHEDULED: StateTabs.SHIPMENT_CONFIRMED,
  CONTAINER_LOADING: StateTabs.SHIPMENT_CONFIRMED,
  PENDING_CUSTOM_CLEARANCE: StateTabs.SHIPMENT_CONFIRMED,
  OUT_FOR_DELIVERY: StateTabs.SHIPMENT_CONFIRMED,
  DELIVERED: StateTabs.SHIPMENT_CONFIRMED,
  RESCHEDULED_FOR_DELIVERY: StateTabs.SHIPMENT_CONFIRMED,
  DELIVERY_FAILED: StateTabs.SHIPMENT_CONFIRMED,
  SHIPMENT_PICKEDUP: StateTabs.SHIPMENT_CONFIRMED,
  CARGO_DELIVERED_TO_AIRLINES_TERMINAL: StateTabs.SHIPMENT_CONFIRMED,
  CARGO_ONBOARD_VESSEL: StateTabs.SHIPMENT_CONFIRMED,
  ON_SAIL: StateTabs.SHIPMENT_CONFIRMED,
  PARTIAL_UPLIFT_TO_DESTINATION: StateTabs.SHIPMENT_CONFIRMED,
  FREIGHT_UPLIFT_COMPLETED: StateTabs.SHIPMENT_CONFIRMED,
  OFFLOADED_BY_AIRLINES: StateTabs.SHIPMENT_CONFIRMED,
  IN_TRANSHIPMENT_COUNTRY: StateTabs.SHIPMENT_CONFIRMED,
  CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY: StateTabs.SHIPMENT_CONFIRMED,
  DELIVERED_WITHOUT_POD: StateTabs.SHIPMENT_CONFIRMED,
  BILLED: StateTabs.SHIPMENT_CONFIRMED,
  PARTIALLY_DELIVERED: StateTabs.SHIPMENT_CONFIRMED,
  VESSEL_DELAYED: StateTabs.SHIPMENT_CONFIRMED,
  SPACE_BOOKING_IN_PROGRESS: StateTabs.SHIPMENT_CONFIRMED
};

const TabComponent = ({ component: Component, ...props }) => {
  return <Component {...props} />;
};

const QuoteDetailsTabs = ({ quoteStatus, quoteId, onLoading = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const [tabKey, setTabKey] = useState(Date.now());
  const [currentTab, setCurrentTab] = useState(() => {
    return StateTabs[quoteStatus]?.activeTab || 'SHIPMENT_DETAILS';
  });

  useEffect(() => {
    if (quoteStatus) {
      setCurrentTab(StateTabs[quoteStatus]?.activeTab || 'SHIPMENT_DETAILS');
    }
  }, [quoteStatus]);

  const tabList = {
    SHIPMENT_DETAILS: {
      title: 'Details',
      component: ShipmentDetails
    },
    SHIPMENT_QUOTE: {
      title: 'Quote',
      component: ShipmentQuoteDetails
    },
    SHIPMENT_DOCUMENT: {
      title: 'Documents',
      component: ShipmentDocuments
    },
    TRACKING_STATUS: {
      title: 'Tracking',
      component: ShipmentTrackingStatus
    },
    SHIPMENT_COST_BILLING: {
      title: 'Cost & Billing',
      component: CostBillingDetails
    }
  };

  const quoteExpired = quoteStatus === 'QUOTE_EXPIRED';

  // TODO: make this more generic so that any tab can inform parent that it is still loading
  const charges = useSelector(
    (state) => state?.quoteDetail?.charges?.total_invoice_amount
  );
  const chargesLoaded = StateTabs[quoteStatus]?.tabs?.includes('SHIPMENT_QUOTE')
    ? charges !== undefined
    : true;

  useEffect(() => {
    if (quoteStatus && chargesLoaded) {
      setLoading(false);
      onLoading(false);
    } else {
      setLoading(true);
      onLoading(true);
    }
  }, [quoteStatus, onLoading, chargesLoaded]);

  useEffect(() => {
    if (currentTab === 'SHIPMENT_DETAILS' || currentTab === 'TRACKING_STATUS') {
      // hack
      setTimeout(() => {
        setTabKey(`${Date.now()}`);
      }, 500);
    }
  }, [currentTab]);

  return (
    <StyledQuoteDetailsTabs>
      {quoteStatus && !quoteExpired && (
        <Tabs
          key={tabKey}
          activeKey={currentTab}
          onTabClick={(key) => setCurrentTab(key)}
          style={{ display: loading ? 'none' : 'block' }}
          animated={false}
        >
          {StateTabs[quoteStatus]?.tabs.map((tab) => (
            <TabPane tab={tabList[tab].title} key={tab} forceRender>
              <TabComponent
                component={tabList[tab].component}
                quoteId={quoteId}
              />
            </TabPane>
          ))}
        </Tabs>
      )}
      {loading && <Skeleton title active paragraph={{ rows: 5 }} />}
    </StyledQuoteDetailsTabs>
  );
};

export default QuoteDetailsTabs;
