import { createReducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import randomstring from 'randomstring';
import {
  fetchCostBillingDetails,
  updateCostBillingDetails,
  addLineItems,
  removeLineItem,
  updateLineItem,
  cancelLineItems,
  saveLineItems,
  loadCostBilling,
  updateInvoiceDetails,
  setColumns
} from '../actions/costBillingDetail';

const initialState = {
  loading: false,
  costBillingData: {
    cost_dirty: false,
    quote_line_items: [],
    temp_quote_line_items: []
  },
  columns: {
    cost: [
      'network_partner',
      'np_invoice_number',
      'cost_description',
      'cost_awb',
      'cost_fee_type',
      'cost_units',
      'cost_currency',
      'cost_unit_price'
    ],
    billing: [
      'billing_country',
      'xero_client_id',
      'comment',
      'description',
      'billing_awb',
      'weight',
      'billing_currency',
      'billing_unit_price'
    ],
    financeTeam: [
      'invoice_number',
      'invoice_link',
      'credit_note_no',
      'credit_note_link'
    ]
  }
};

const mergeCostBillingLineItems = (
  costLineItems = [],
  billingLineItems = [],
  billingDetails
) => {
  costLineItems = costLineItems.map((cost) => ({
    key: cost.sqlId,
    fee_name: cost.fee_name || '',
    cost: {
      ...cost,
      fee_name: cost.fee_name || '',
      comment: cost.comment || '',
      units: Number(cost.units) || 0,
      unit_price: Number(cost.unit_price) || 0,
      total_amount: Number(cost.total_amount) || 0
    },
    billing: {
      fee_name: cost.fee_name,
      weight: 0,
      unit_price: 0,
      total_amount: 0,
      currency: undefined
    }
  }));
  let costBillingLineItems = billingLineItems.reduce(
    (lineItems, billing) => {
      let found = false;
      const billingInfo = {
        ...billing,
        key: billing.sqlId,
        currency: billing.currency || billingDetails?.invoiceCurrency,
        fee_name: billing.fee_name || '',
        comment: billing.comment || '',
        weight: Number(billing.weight) || 0,
        unit_price: Number(billing.unit_price) || 0,
        total_amount: Number(billing.total_amount) || 0
      };

      if (billingDetails?.billingCountry) {
        billingInfo.xero_client_name = billingDetails?.xeroClientName;
        billingInfo.xero_client_id = billingDetails?.xeroClientId;
        billingInfo.billing_country = billingDetails?.billingCountry;
        billingInfo.bill_invoice_number = billingDetails?.invoiceNumber;
        billingInfo.invoice_link = billingDetails?.invoiceLink;
        billingInfo.credit_note_no = billingDetails?.creditNoteNumber;
        billingInfo.credit_note_link = billingDetails?.creditNoteLink;
      }
      for (let i = 0; i < lineItems.length; i += 1) {
        if (
          lineItems[i].fee_name === billingInfo.fee_name &&
          !lineItems[i].billing?.currency
        ) {
          lineItems[i].billing = billingInfo;
          found = true;
          break;
        }
      }
      if (!found) {
        lineItems.push({
          key: billingInfo.sqlId,
          fee_name: billingInfo.fee_name,
          cost: {
            fee_name: billingInfo.fee_name,
            units: 0,
            unit_price: 0,
            total_amount: 0,
            currency: undefined
          },
          billing: billingInfo
        });
      }
      return lineItems;
    },
    [...costLineItems]
  );

  costBillingLineItems = costBillingLineItems.sort((a, b) => {
    return a.fee_name.toLowerCase() > b.fee_name.toLowerCase() ? 1 : -1;
  });
  return costBillingLineItems;
};

const costBillingDetails = createReducer(initialState, {
  [loadCostBilling](state) {
    state.loading = true;
  },
  [fetchCostBillingDetails](state, action) {
    const { cost, billing } = action.payload;
    let operator_remark = cost?.costs?.operatorRemark || '';
    let finance_remark = cost?.costs?.financeRemark || '';
    if (billing?.billings?.billingRemark) {
      operator_remark = `Cost Remarks: ${operator_remark}`;
      operator_remark = operator_remark
        ? `${operator_remark}\nBilling Remarks: ${billing?.billings?.billingRemark}`
        : billing?.billings?.billingRemark;
    }
    if (billing?.billings?.operatorRemark) {
      operator_remark = operator_remark
        ? `${operator_remark}\nOther Remarks: ${billing?.billings?.operatorRemark}`
        : billing?.billings?.operatorRemark;
    }
    if (billing?.billings?.financeRemark) {
      finance_remark = `Cost Remarks: ${finance_remark}`;
      finance_remark = finance_remark
        ? `${finance_remark}\nBilling Remarks: ${billing?.billings?.financeRemark}`
        : billing?.billings?.financeRemark;
    }

    state.costBillingData = {
      ...state.costBillingData,
      billing_exchange_rates: billing?.billings?.exchangeRates
        ? JSON.parse(billing?.billings?.exchangeRates)
        : null,
      cost_exchange_rates: cost?.costs?.exchangeRates
        ? JSON.parse(cost?.costs?.exchangeRates)
        : null,
      invoice_currency: billing?.billings?.invoiceCurrency,
      billing_total_amount: billing?.billings?.totalAmount,
      cost_total_amount: cost?.costs?.totalAmount,
      operator_remark,
      finance_remark,
      is_cost_locked: !!cost?.costs?.costLocked,
      is_billing_locked: !!billing?.billings?.readyToBill
    };
    const lineItems = mergeCostBillingLineItems(
      cost?.quote_line_items,
      billing?.quote_line_items,
      billing?.billings
    );
    state.costBillingData.quote_line_items = lineItems;
    if (state.costBillingData.quote_line_items?.length < 1) {
      state.costBillingData.temp_quote_line_items = [
        {
          key: randomstring.generate(),
          fee_name: '',
          cost: {
            units: 0,
            unit_price: 0,
            total_amount: 0,
            currency: undefined
          },
          billing: {
            weight: 0,
            unit_price: 0,
            total_amount: 0,
            currency: undefined
          }
        }
      ];
    } else {
      state.costBillingData.temp_quote_line_items = lineItems;
    }
    state.loading = false;
  },
  [updateCostBillingDetails](state, action) {
    const { type, value } = action.payload;
    const { costBillingData } = state;
    costBillingData[type] = value;
    state.costBillingData = { ...costBillingData };
  },
  [addLineItems](state) {
    state.costBillingData.temp_quote_line_items = [
      ...state.costBillingData.temp_quote_line_items,
      {
        key: randomstring.generate(),
        fee_name: '',
        cost: {
          units: 0,
          unit_price: 0,
          total_amount: 0,
          currency: undefined
        },
        billing: {
          weight: 0,
          unit_price: 0,
          total_amount: 0,
          currency: undefined
        }
      }
    ];
  },
  [removeLineItem](state, action) {
    state.costBillingData.temp_quote_line_items = state.costBillingData.temp_quote_line_items.filter(
      (data) => data.key !== action.payload
    );
  },
  [updateLineItem](state, action) {
    const { type, key, name, value } = action.payload;
    if (state.costBillingData.temp_quote_line_items?.length > 0) {
      const index = state.costBillingData.temp_quote_line_items.findIndex(
        (data) => data.key === key
      );
      if (name === 'fee_name') {
        state.costBillingData.temp_quote_line_items[
          index
        ].cost.fee_name = value;
        state.costBillingData.temp_quote_line_items[
          index
        ].billing.fee_name = value;
        state.costBillingData.temp_quote_line_items[index].fee_name = value;
      } else if (name === 'xeroClient') {
        state.costBillingData.temp_quote_line_items[
          index
        ].billing.xero_client_name = value.text;
        state.costBillingData.temp_quote_line_items[
          index
        ].billing.xero_client_id = value.value;
      } else {
        state.costBillingData.temp_quote_line_items[index][type][name] = value;
        const quoteLineItem =
          state.costBillingData.temp_quote_line_items[index][type];
        const units =
          type === 'cost' ? quoteLineItem.units : quoteLineItem.weight;
        state.costBillingData.temp_quote_line_items[index][type].total_amount =
          quoteLineItem.unit_price && units
            ? Math.round(quoteLineItem.unit_price * units * 100) / 100
            : 0;
      }
    }
  },
  [updateInvoiceDetails](state, action) {
    const { key, value } = action.payload;
    if (state.costBillingData.temp_quote_line_items?.length > 0) {
      const index = state.costBillingData.temp_quote_line_items.findIndex(
        (data) => data.key === key
      );
      state.costBillingData.temp_quote_line_items[
        index
      ].billing.bill_invoice_number = value?.bill_invoice_number;
      state.costBillingData.temp_quote_line_items[index].billing.invoice_link =
        value?.invoice_link;
      state.costBillingData.temp_quote_line_items[
        index
      ].billing.credit_note_no = value?.credit_note_no;
      state.costBillingData.temp_quote_line_items[
        index
      ].billing.credit_note_link = value?.credit_note_link;
    }
  },
  [cancelLineItems](state) {
    state.costBillingData.temp_quote_line_items =
      state.costBillingData.quote_line_items;
  },
  [saveLineItems](state) {
    state.costBillingData.quote_line_items =
      state.costBillingData.temp_quote_line_items;
  },
  [setColumns](state, action) {
    const { columns, type } = action.payload;
    state.columns[type] = columns;
  }
});

export default persistReducer(
  {
    key: 'costBillingDetails',
    storage,
    whitelist: ['columns']
  },
  costBillingDetails
);
