import request, { axiosRequest } from '../utils/request';

const getCostDetails = async (id) => {
  const url = `/cost/${id}`;
  const response = await request().get(url);
  return response.data.data;
};
const saveCostInfo = ({ data, id }) => {
  const url = `/cost/${id}`;
  return axiosRequest.post(url, data);
};

const getExchangeRates = async () => {
  if (localStorage.getItem('rates')) {
    return JSON.parse(localStorage.getItem('rates'));
  }
  const url = '/billing/rates';
  const response = await request().get(url);
  localStorage.setItem('rates', JSON.stringify(response.data));
  return response.data;
};

export const costService = {
  getCostDetails,
  saveCostInfo,
  getExchangeRates
};
