const OP_CODES = [
  { key: '0', value: 'F339', label: '优先carton维度传输----出口报关完成' },
  { key: '1', value: 'F360', label: '优先carton维度传输----航班已抵达机场' },
  { key: '2', value: 'F399', label: '优先carton维度传输----进口清关完成' },
  { key: '3', value: 'F419', label: '优先carton维度传输----货物已交接给尾程' },
  { key: '4', value: 'F300', label: '优先carton维度传输----尾程接收货物' },
  { key: '5', value: 'F341', label: '优先carton维度传输----航班已出发机场' }
];

export { OP_CODES };
