// TODO: maybe use a i18n library react-i18next
export const TEXT_NO_RESULT = 'There are no results.';
export const TEXT_WRONG_FORMAT_EMAIL =
  'Please enter email address in this format: yourname@example.com';
export const TEXT_MISS_EMAIL = 'Please enter email address.';
export const TEXT_MISS_PASSWORD = 'Please enter password.';
export const TEXT_NOT_MATCH = 'Your username and/or password do not match.';
export const TEXT_MISSING_FIELD = 'Please enter';
export const TEXT_LIMIT_50 =
  'You have exceeded the maximum 50 characters length limit.';
export const TEXT_LIMIT_250 =
  'You have exceeded the maximum 250 characters length limit.';
export const TEXT_VALID = 'Please enter valid characters.';
export const TEXT_ONLY_NUMBER = 'Please enter positive numbers.';
export const TEXT_MAX_2_DECIMAL =
  'Please do not enter more than 2 decimal places.';
export const TEXT_MIN_VOLUME = 'Minimum is 0.1cbm.';
export const TEXT_MAX_VOLUME = 'Maximum is 43cbm.';
export const TEXT_MIN_WEIGHT_KG = 'Minimum is 0.1 kg.';
export const TEXT_MAX_WEIGHT_KG = 'Maximum is 34000 kg.';
export const TEXT_MIN_WEIGHT_LB = 'Minimum is 0.1 lb.';
export const TEXT_MAX_WEIGHT_LB = 'Maximum is 75000 lb.';
export const TEXT_DOCUMENT_UPLOADED = 'Document(s) has been uploaded.';
export const TEXT_REQUEST_FAILED = 'request failed.';
export const TEXT_ADD_ADDRESS_SUCCESS = 'A new address is added.';
export const TEXT_UPDATE_ADDRESS_SUCCESS =
  'Address has been succesfully updated';
export const TEXT_UPDATE_SHIPMENT_SUCCESS =
  'Shipment details has been successfully updated';
export const TEXT_UPDATE_QUOTE_SUCCESS =
  'Quote details has been successfully updated';
export const TEXT_UPDATE_SHIPMENT_ERROR =
  'An error occured while updating the shipment details';
export const TEXT_UPDATE_QUOTE_ERROR =
  'An error occured while updating the quote details';
export const TEXT_ADDRESS_INVALID = 'Please enter valid address.';
export const TEXT_NOTICE_ADDRESS =
  'Currently there is no drop-off address in this country. Please continue with the quote request and we will assist you.';
export const TEXT_NOTICE_ADDRESS2 =
  'Currently there is no pickup address in this country. Please continue with the quote request and we will assist you.';
export const TEXT_MISS_COUNTRY =
  'No address in this country. Please add a new one.';
export const TEXT_SELECT_ORI_COUNTRY = 'Please select Origin Country.';
export const TEXT_SELECT_DES_COUNTRY = 'Please select Destination Country.';
export const TEXT_ERR_DATE1 =
  'Estimated Pickup Date must be before Target Delivery Date.';
export const TEXT_ERR_DATE2 =
  'Estimated Dropoff Date must be before Target Delivery Date.';
export const TEXT_MISS_EST_PICKUP_DATE = 'Please select Estimated Pickup Date.';
export const TEXT_MISS_EST_DROPOFF_DATE =
  'Please select Estimated Dropoff Date.';
export const TEXT_MISS_TARGET_DELIVERY_DATE =
  'Please select Target Delivery Date.';
export const TEXT_POSITIVE_NUMB = 'Please enter positive numbers.';
export const TEXT_DECIMAL_PLACES =
  'Please do not enter more than 2 decimal places.';
export const TEXT_MIN_VALUE = 'Minimum is';
export const TEXT_MAX_VALUE = 'Maximum is';
export const TEXT_LOADING = 'Loading...';
export const TEXT_MIN_UNITS = 'Minimum is 1.';
export const TEXT_MAX_UNITS = 'Maximum is 999.';
export const TEXT_QUOTE_LINE_ITEM_ERROR =
  'An error occured while updating the quote';
export const TEXT_QUOTE_LINE_ITEM_SUCCESS =
  'Quote details has been successfully updated';
export const TEXT_QUOTE_SEND_SUCCESS = 'Quote Sent Successfully';
export const TEXT_QUOTE_STATUS_ERROR =
  'An error occured while updating the quote';
export const TEXT_QUOTE_STATUS_SUCCESS = 'Quote Status Updated';
export const TEXT_BILLING_SAVE_SUCCESS =
  'Cost & Billing Information is Updated';
export const TEXT_COST_SAVE_SUCCESS = 'Cost Information is Updated';
export const TEXT_BILLING_SAVE_ERROR =
  'An error occured while updating the cost & billing information';
export const TEXT_COST_SAVE_ERROR =
  'An error occured while updating the cost information';
export const TEXT_SHIPMENT_STATUS_SUCCESS =
  'Shipment Status Updated Succesfully';
export const TEXT_SHIPMENT_STATUS_ERROR =
  'An error occured while updating the shipment';
export const TEXT_ASSIGN_OPERATOR_SUCCESS = 'Operator assigned successfully';
export const TEXT_ASSIGN_OPERATOR_ERROR =
  'An error occurred while assigning an operator';
export const TEXT_BILLING_UNLOCK_SUCCESS = 'Billing information is unlocked';
export const TEXT_COST_UNLOCK_SUCCESS = 'Cost information is unlocked';
export const TEXT_BILLING_UNLOCK_ERROR =
  'An error occured while unlock billing information';
export const TEXT_COST_UNLOCK_ERROR =
  'An error occured while unlock cost information';
export const TEXT_SEARCH_MODE = 'Please type in to search...';
