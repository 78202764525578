import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Col, message, Icon } from 'antd';
import { titleCase } from 'title-case';

import Modal from './Modal';
import Button from './Button';
import Table from './Table';
import Radio from './Radio';
import { handleAbbrevCase } from '../utils/formatString';
import { quoteService } from '../services/quotes';
import { updateDocumentVisibility } from '../actions/quoteDetail';

const Title = styled.span`
  color: #050593;
`;
const StyledModalContent = styled(Col)`
  padding: 0 0 15px 15px;
`;
const BoldText = styled.span`
  font-weight: 600;
`;
const UnderLineText = styled.span`
  text-decoration: underline;
`;
const PrivateWarning = styled.p`
  margin: 10px;
  padding: 10px;
  border: 1px solid #050593;
  background: #0505930d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  .anticon-exclamation-circle {
    padding-right: 10px;
  }
`;
const PublicWarning = styled.p`
  margin: 10px;
  padding: 10px;
  background: #fbe7e9 0% 0% no-repeat padding-box;
  border: 1px solid #d5152f;
  border-radius: 4px;
  .anticon-exclamation-circle {
    padding-right: 10px;
  }
`;
const WarningMessage = ({ isPrivate = false }) =>
  isPrivate ? (
    <PrivateWarning>
      <Icon type="exclamation-circle" />
      If you set a document to <BoldText>Private</BoldText>, the merchant of
      this shipment will <UnderLineText>not</UnderLineText> be able to see it.
    </PrivateWarning>
  ) : (
    <PublicWarning>
      <Icon type="exclamation-circle" />
      If you set a document to <BoldText>Public</BoldText>, the merchant of this
      shipment will be able to see it.
    </PublicWarning>
  );
const getDocuments = (documents, defaultDocs, type) => {
  const dataSource = documents.map((doc) => {
    if (defaultDocs.includes(doc.document_type)) {
      return {
        documentId: doc.document_id,
        documentName: doc.document_type,
        status: type,
        disabled: true
      };
    }
    return {
      documentId: doc.document_id,
      documentName: doc.document_type,
      status: type,
      disabled: false
    };
  });
  return dataSource;
};
const getDataSourceDocuments = (
  publicDocuments,
  privateDocuments,
  defaultPublicDocuments
) => {
  const defaultDocs = defaultPublicDocuments.map((doc) => doc.document_type);
  const publicDataSource = getDocuments(publicDocuments, defaultDocs, 'public');
  const privateDataSource = getDocuments(
    privateDocuments,
    defaultDocs,
    'private'
  );
  return [...publicDataSource, ...privateDataSource];
};

const EditDocumentTypeModal = ({
  isModalVisible,
  setIsModalVisible,
  defaultPublicDocuments,
  publicDocuments,
  privateDocuments
}) => {
  const dispatch = useDispatch();
  const [showPublicWarn, setShowPublicWarn] = useState(false);
  const [showPrivateWarn, setShowPrivateWarn] = useState(false);
  const defaultDataSource = getDataSourceDocuments(
    publicDocuments,
    privateDocuments,
    defaultPublicDocuments
  );
  const [dataSource, setDataSource] = useState(defaultDataSource);
  useEffect(() => {
    setDataSource(
      getDataSourceDocuments(
        publicDocuments,
        privateDocuments,
        defaultPublicDocuments
      )
    );
    setShowPublicWarn(false);
    setShowPrivateWarn(false);
  }, [privateDocuments, publicDocuments, defaultPublicDocuments]);
  const updateDocuments = () => {
    quoteService
      .updateDocumentsVisibility(dataSource)
      .exec()
      .then((data) => {
        if (data.data.code === 200) {
          message.success('Updated Successfully');
          setIsModalVisible(false);
          dispatch(updateDocumentVisibility(dataSource));
        } else {
          message.error('Error Occured');
        }
      });
  };
  const showWarnings = (newDataSource) => {
    const diff = newDataSource.filter((obj) => {
      return !defaultDataSource.some((obj2) => {
        return obj.documentId === obj2.documentId && obj.status === obj2.status;
      });
    });
    let showPrivate = false;
    let showPublic = false;
    diff.forEach((data) => {
      if (data.status === 'private') showPrivate = true;
      else showPublic = true;
    });
    setShowPrivateWarn(showPrivate);
    setShowPublicWarn(showPublic);
  };
  const columns = [
    {
      title: 'Documents',
      dataIndex: 'documentName',
      key: 'documentName',
      render: (documentName) =>
        handleAbbrevCase(
          titleCase(
            documentName.toLowerCase().replace(new RegExp('_', 'g'), ' ')
          )
        ),
      width: 160
    },
    {
      title: 'Public',
      dataIndex: 'status',
      key: 'publicStatus',
      render: (text, { status, disabled }, index) => {
        return (
          <Radio
            checked={status === 'public'}
            disabled={disabled}
            onChange={(e) => {
              const val =
                e.target.checked || dataSource[index].status === 'public'
                  ? 'public'
                  : 'private';
              const newDataSource = [...dataSource];
              newDataSource[index].status = val;
              setDataSource(newDataSource);
              showWarnings(newDataSource);
            }}
          />
        );
      },
      width: 70
    },
    {
      title: 'Private',
      dataIndex: 'status',
      render: (text, { status, disabled }, index) => {
        return (
          <Radio
            checked={status === 'private'}
            disabled={disabled}
            onChange={(e) => {
              const val =
                e.target.checked || dataSource[index].status === 'private'
                  ? 'private'
                  : 'public';
              const newDataSource = [...dataSource];
              newDataSource[index].status = val;
              setDataSource(newDataSource);
              showWarnings(newDataSource);
            }}
          />
        );
      },
      key: 'privateStatus',
      width: 70
    }
  ];
  return (
    <Modal
      title={<Title>Edit Documents</Title>}
      visible={isModalVisible}
      closable={false}
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => setIsModalVisible(false)}
        >
          Cancel
        </Button>,
        <Button key="submit" onClick={updateDocuments}>
          Confirm
        </Button>
      ]}
    >
      <StyledModalContent>
        <Table
          totalCount={dataSource.length}
          type="primary"
          showScroll={false}
          showPagination={false}
          dataSource={dataSource}
          columns={columns}
          empty={<span>None</span>}
          renderEmptyTable
        />
        {showPrivateWarn && <WarningMessage isPrivate />}
        {showPublicWarn && <WarningMessage />}
      </StyledModalContent>
    </Modal>
  );
};

export default EditDocumentTypeModal;
