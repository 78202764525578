import quoteDetail from './quoteDetail';
import quoteList from './quoteList';
import shipmentList from './shipmentList';
import costBillingDetails from './costBillingDetails';
import quoteRequestList from './quoteRequestList';
import quoteRequestDetails from './quoteRequestDetails';
import shipmentForm from './shipmentForm';

export default {
  quoteDetail,
  quoteList,
  shipmentList,
  costBillingDetails,
  quoteRequestList,
  quoteRequestDetails,
  shipmentForm
};
