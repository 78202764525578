import { createReducer } from '@reduxjs/toolkit';
import {
  fetchQuotes,
  fetchQuotesLoading,
  fetchChoices,
  fetchChoicesLoading,
  setPageFilter,
  setPageSizeFilter,
  setQuoteFilters,
  resetFilters
} from '../actions/quoteList';

const initialState = {
  quotes: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filters: {
    status: 'All',
    page: 1,
    records: '20',
    trackingNumber: null,
    merchantName: null,
    action: 'All',
    fromDate: null,
    toDate: null,
    origin: 'All',
    destination: 'All'
  },
  choices: {
    loading: false,
    merchants: [],
    statuses: [],
    actions: []
  }
};

export default createReducer(initialState, {
  [fetchQuotes](state, action) {
    const { quotes, total } = action.payload;
    state.quotes = {
      data: quotes,
      total,
      loading: false
    };
  },
  [fetchQuotesLoading](state) {
    state.quotes.loading = true;
  },
  [fetchChoices](state, action) {
    const { merchants, statuses, actions } = action.payload;
    state.choices = {
      loading: false,
      merchants: merchants.map((item) => ({
        value: item.merchantId,
        label: item.merchantName
      })),
      statuses: [
        {
          value: 'All',
          label: 'All'
        },
        ...statuses.map((item) => ({
          value: item.statusValue,
          label: item.statusName
        }))
      ],
      actions: [
        {
          value: 'All',
          label: 'All'
        },
        ...actions.map((item) => ({
          value: item.statusValue,
          label: item.statusName
        }))
      ]
    };
  },
  [fetchChoicesLoading](state) {
    state.choices.loading = true;
  },
  [setPageFilter](state, action) {
    state.filters.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.filters.page = 1;
    state.filters.records = action.payload;
  },
  [setQuoteFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
  }
});
