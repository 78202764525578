import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import { setColumns } from '../actions/shipmentList';

const FilterContainer = styled.div`
  user-select: none;
  width: 1075px;
  height: 520px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
  .ant-checkbox-group {
    margin-top: 8px;
    margin-bottom: 25px;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const ColumnDropdown = ({ label }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  const general = useSelector((state) => state.shipmentList.columns.general);
  const shippingInfo = useSelector(
    (state) => state.shipmentList.columns.shippingInfo
  );
  const billingInfo = useSelector(
    (state) => state.shipmentList.columns.billingInfo
  );
  const costInfo = useSelector((state) => state.shipmentList.columns.costInfo);
  const weightVolume = useSelector(
    (state) => state.shipmentList.columns.weightVolume
  );
  const operator = useSelector((state) => state.shipmentList.columns.operator);
  const country = useSelector((state) => state.shipmentList.columns.country);
  const port = useSelector((state) => state.shipmentList.columns.port);
  const date = useSelector((state) => state.shipmentList.columns.date);
  const airwayBill = useSelector(
    (state) => state.shipmentList.columns.airwayBill
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (!node.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Hide/Show Columns'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
        align={{ overflow: { adjustX: false, adjustY: false } }}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[5, 5]}>
            <Col span={6}>
              <header>General</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Tracking Number',
                    value: 'trackingNumber',
                    disabled: true
                  },
                  { label: 'Tracking Status', value: 'status' },
                  { label: 'Merchant Name', value: 'merchantName' },
                  { label: 'Created By', value: 'createdBy' },
                  { label: 'Creator Email', value: 'creatorEmail' },
                  { label: 'Shipment Name', value: 'shipmentName' },
                  { label: 'Quote ID', value: 'quoteRequestID' }
                ]}
                defaultValue={general}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'general', columns: values }));
                }}
              />
              <header>Operator</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Operator Incharge',
                    value: 'operatorInCharge'
                  },
                  {
                    label: 'Operator Branch Code',
                    value: 'operatorBranchCode'
                  }
                ]}
                defaultValue={operator}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'operator', columns: values }));
                }}
              />
              <header>Cost Information</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Total cost',
                    value: 'costAmount'
                  },
                  {
                    label: 'Network partners',
                    value: 'networkPartners'
                  }
                ]}
                defaultValue={costInfo}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'costInfo', columns: values }));
                }}
              />
            </Col>
            <Col span={6}>
              <header>Billing Information</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Ready to Bill',
                    value: 'readyToBill'
                  },
                  {
                    label: 'Ready to Bill Date',
                    value: 'readyToBillDate'
                  },
                  {
                    label: 'Bill Amount',
                    value: 'totalAmount'
                  },
                  {
                    label: 'Bill Amount (in USD)',
                    value: 'totalUSDAmount'
                  },
                  {
                    label: 'Janio Billing Branch',
                    value: 'billingEntity'
                  },
                  {
                    label: 'Cost > Bill Price',
                    value: 'costBillComparison'
                  },
                  {
                    label: 'Gross profit margin (%)',
                    value: 'grossProfitMargin'
                  },
                  {
                    label: 'Billing Invoice No.',
                    value: 'billingInvoiceNumber'
                  },
                  {
                    label: 'Credit Note No.',
                    value: 'billingCreditNoteNumber'
                  },
                  {
                    label: 'Cost & billing lock',
                    value: 'costBillingLock'
                  }
                ]}
                defaultValue={billingInfo}
                onChange={(values) => {
                  dispatch(
                    setColumns({ type: 'billingInfo', columns: values })
                  );
                }}
              />

              <header>Country</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Origin country',
                    value: 'origin'
                  },
                  {
                    label: 'Destination country',
                    value: 'destination'
                  },
                  {
                    label: 'Transhipment country',
                    value: 'transhipmentCountry'
                  }
                ]}
                defaultValue={country}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'country', columns: values }));
                }}
              />
            </Col>
            <Col span={6}>
              <header>Shipping Information</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Mode of shipment',
                    value: 'shipmentMode'
                  },
                  { label: 'Total no. of units', value: 'packageTypeUnits' },
                  { label: 'Container/truck type', value: 'shipmentLoadType' },
                  { label: 'No. of containers/trucks', value: 'loadTypeUnits' }
                ]}
                defaultValue={shippingInfo}
                onChange={(values) => {
                  dispatch(
                    setColumns({ type: 'shippingInfo', columns: values })
                  );
                }}
              />
              <header>Airport/Port</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Origin Airport/Port',
                    value: 'originPort'
                  },
                  {
                    label: 'Destination Airport/Port',
                    value: 'destinationPort'
                  },
                  {
                    label: 'Transhipment Airport/Port',
                    value: 'transhipmentPort'
                  }
                ]}
                defaultValue={port}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'port', columns: values }));
                }}
              />
              <header>Documents</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'MAWB, OOBL, D/O Number',
                    value: 'importAwb'
                  },
                  {
                    label: 'Transhipment MAWB',
                    value: 'transhipmentMawb'
                  }
                ]}
                defaultValue={airwayBill}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'airwayBill', columns: values }));
                }}
              />
            </Col>
            <Col span={6}>
              <header>Weight & Volume</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Total volume',
                    value: 'volume'
                  },
                  {
                    label: 'Total actual weight',
                    value: 'actualWeight'
                  },
                  {
                    label: 'Total volumetric weight',
                    value: 'volumetricWeight'
                  },
                  {
                    label: 'Total chargeable weight',
                    value: 'chargeableWeight'
                  }
                ]}
                defaultValue={weightVolume}
                onChange={(values) => {
                  dispatch(
                    setColumns({ type: 'weightVolume', columns: values })
                  );
                }}
              />
              <header>Date</header>
              <Checkbox.Group
                options={[
                  {
                    label: 'Shipment creation date',
                    value: 'createdAt'
                  },
                  {
                    label: 'Shipment confirmation date',
                    value: 'confirmedDate'
                  },
                  {
                    label: 'Estimated delivery date',
                    value: 'deliveryDate'
                  },
                  {
                    label: 'Status last updated date',
                    value: 'lastStatusUpdateDate'
                  },
                  {
                    label: 'Actual delivery date',
                    value: 'actualDeliveryDate'
                  }
                ]}
                defaultValue={date}
                onChange={(values) => {
                  dispatch(setColumns({ type: 'date', columns: values }));
                }}
              />
            </Col>
          </Row>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default ColumnDropdown;
