/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState, useReducer, useEffect } from 'react';
import { cx } from 'emotion';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { titleCase } from 'title-case';
import moment from 'moment';
import styled from '@emotion/styled';
import { Icon, Upload, Modal as AntModal, Row, Col, message } from 'antd';
import Button from './Button';
import DatePicker from './DatePicker';
import MultiSelect from './MultiSelect';
import Radio from './Radio';
import Modal from './Modal';
import Message from './Message';
import SearchInput from './SearchInput';
import { handleAbbrevCase, invalidCharacter } from '../utils/formatString';
import { shipmentService } from '../services';
import EditDocumentTypeModal from './EditDocumentTypeModal';

import {
  uploadDocument,
  confirmShipmentStatus,
  approveDocument,
  removeDocument,
  getQuoteDetail,
} from '../actions/quoteDetail';
import { isShipmentStatus, isQuoteFinal } from '../constants/status';

const { confirm } = AntModal;
// import fileDownload from '../utils/download';

const StyledShipmentDocuments = styled.div`
  .shipment-documents-body {
    padding: 0;
    border-bottom: 1px solid #050593;
    margin-bottom: 15px;
  }

  .documents-container {
    display: grid;
    grid-template-columns: 35% 35% 30%;
    @media (max-width: 1300px) {
      grid-template-columns: 30% 35% 35%;
    }
    padding: 15px 0;
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }
  .actionButtons {
    margin-left: auto;
  }
  .document {
    padding-left: 0px;
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-family: AvenirLTStdHeavy;
  }
  .document::first-letter {
    text-transform: uppercase;
  }
  .document.file-name {
    font-family: AvenirLTStdBookOblique;
    a {
      text-decoration: underline;
      color: #000;
      white-space: pre-line;
      word-break: break-all;
      line-height: 1.2;
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-decoration: underline;
      width: calc(100% - 25px);
      overflow: hidden;
    }
  }
  .upload-input {
    width: 100%;
    display: flex;
  }
  .upload-input-box {
    width: 275px;
    font-size: 16px;
    text-align: right;
    p {
      text-align: left;
      margin-top: 5px;
    }
  }
  .upload-input-upload-status {
    margin-left: 45px;
    p {
      text-align: left;
      margin-top: 5px;
    }
  }
  .upload-input-upload-button {
    position: relative;
    left: 0px;
    font-size: 16px;
    text-align: left;
    button {
      max-width: 200px;
      display: flex;
      i {
        align-self: center;
      }
      span {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
      }
    }
  }
  .upload-input-add-button {
    position: relative;
    font-size: 16px;
    margin-left: auto;
    right: 50px;
  }
  .upload-document-container {
    background: #f2f2f9;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 25px;
    position: relative;
    .upload-body {
      display: flex;
    }
  }
  .upload-document-container button.ant-btn-link {
    position: absolute;
    padding: 0;
    right: 10px;
    top: 20px;
    color: #000;
    font-size: 12px;
  }
  .confirm-shipment-button {
    margin-left: auto;
  }
  .upload-new-button {
    font-size: 16px;
  }
  .edit-type-button {
    margin-left: 15px;
  }
`;
const DatePickerRow = styled(Row)`
  padding-bottom: 20px;
  color: #000000;
  .ant-calendar-picker {
    width: 100%;
  }
`;

const ConfirmShipmentModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 25px;
  }
  .ant-modal-content {
    width: 680px !important;
  }
  .ant-col {
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
  }
  label {
    font-family: 'AvenirLTStdBook';
    font-size: 16px;
  }
`;

const ApproveButton = styled(Button)`
  min-width: 120px;
  margin-left: 10px;
  margin-right: 10px;
`;

const UploadFooter = styled.div`
  display: flex;
  margin-top: 20px;
`;
const DocumentNoneTitle = styled.p`
  font-family: AvenirLTStdHeavy;
  font-size: 16px;
`;
const DocumentTypeTitle = styled.p`
  font-family: AvenirLTStdHeavy;
  font-size: 20px;
  margin-bottom: 0px;
`;

const UploadDocumentsDialog = (props) => {
  const [docType, setDocType] = useState('');
  const [docStatus, setDocStatus] = useState(true);
  const [fileUploadRequest, setfileUploadRequest] = useState({});
  const [fileUploadError, setFileUploadError] = useState('');
  const [fileUploadDirty, setFileUploadDirty] = useState(false);
  const [fileName, setFileName] = useState('');

  const validationSchema = Yup.object().shape({
    docType: Yup.mixed()
      .nullable()
      .test('required', 'Please enter Document Name', (value) => value)
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          return (value?.length || 0) <= 50;
        }
      )
      .test('docType', 'Please enter valid characters', (value) => {
        return !invalidCharacter(value);
      }),
  });

  const validate = () => {
    validationSchema
      .validate({ docType })
      .then(() => {
        setFileUploadError(null);
      })
      .catch((err) => {
        setFileUploadError(err.message);
      });
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docType, fileName]);

  const validateFileUploadRequest = () => {
    if (docType) {
      if (fileUploadRequest[docType]) {
        return fileUploadRequest;
      }
      if (fileUploadRequest['']) {
        const request = {
          [docType
            .trim()
            .toUpperCase()
            .replace(new RegExp(' ', 'g'), '_')]: fileUploadRequest[''],
        };
        setfileUploadRequest(request);
        return request;
      }
    }
    return null;
  };

  return (
    <div className="upload-document-container">
      <div className="upload-header">
        <Button
          onClick={() => {
            props.closeButton(false);
          }}
          type="link"
          icon="close"
          size="large"
        />
        <div className="upload-instructions">
          <h6>Upload new document</h6>
        </div>
      </div>
      <div className="upload-body">
        <div className="upload-input-box">
          <p>Document Name</p>
          <MultiSelect
            mode="combobox"
            showSearch="true"
            placeholder="Type or select document name"
            onBlur={() => {
              setFileUploadDirty(true);
            }}
            onChange={(value) => {
              setDocType(value);
            }}
            items={[
              'Master Airway Bill',
              'House Airway Bill',
              'Proof of Delivery',
            ]}
          />
          <Message type="error">{fileUploadDirty && fileUploadError}</Message>
        </div>
        <div className="upload-input-upload-status">
          <p>Document Status</p>
          <Radio.Group
            value={docStatus}
            onChange={(e) => setDocStatus(e.target.value)}
          >
            <Radio value>Private</Radio>
            <Radio value={false}>Public</Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="upload-input">
        <div className="upload-input-upload-button">
          <Upload
            className="wrapper-upload"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
            showUploadList={false}
            beforeUpload={(file) => {
              const request = {};
              // TODO: needs to come up with a better stratergy for this one to make it more complex to create new doc types.
              request[''] = file;
              setFileName(file.name);
              setfileUploadRequest(request);
              return false;
            }}
          >
            <Button type="secondary" icon={fileName ? 'close' : 'upload'} ghost>
              {fileName || 'Upload'}
            </Button>
          </Upload>
        </div>
        <div className="upload-input-add-button">
          <Button
            onClick={() => {
              if (docType === 'Master Airway Bill' && !props.mawbNo) {
                message.error('Please update MAWB in shipment details');
              } else {
                const request = validateFileUploadRequest();
                if (request && !fileUploadError) {
                  props.handleUpload(request, props.quoteId, docStatus);
                  setFileName('');
                  setFileUploadDirty(false);
                }
              }
            }}
            loading={props.uploading}
            type="secondary"
            disabled={fileUploadError || !fileName}
            ghost
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

const Document = (props) => {
  const dispatch = useDispatch();
  const [isApproved, setisApproved] = useState(
    props.docValues.document_is_document_approved
      ? props.docValues.document_is_document_approved
      : false
  );

  const confirmDocRemovalModal = () => {
    confirm({
      icon: (
        <Icon
          twoToneColor="#D5152F"
          theme="twoTone"
          type="exclamation-circle"
        />
      ),
      okText: 'Yes',
      cancelText: 'No',
      title: 'Are you sure you want delete document entry?',
      content:
        'The entire document entry on this row, including its name and file, will be deleted.',
      onOk() {
        dispatch(removeDocument(props.docValues.document_id));
      },
      onCancel() {},
    });
  };

  // TODO: make this fetch from the backend
  const nonRemovableFields = {
    COMMERCIAL_INVOICE: 1,
    PACKING_LIST: 1,
    QUOTE: 1,
    'IMPORTER_OF_RECORD_(IOR)': 1,
    'EXPORTER_OF_RECORD_(EOR)': 1,
    'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)': 1,
  };

  return (
    <div className="documents-container">
      <div className="document">
        <b>
          {props.docValues.document_type === 'QUOTE'
            ? 'Customer Quotation'
            : handleAbbrevCase(
                titleCase(
                  props.docValues.document_type
                    .toLowerCase()
                    .replace(new RegExp('_', 'g'), ' ')
                )
              )}
        </b>
      </div>
      <div className="document file-name">
        <Icon type="file" />{' '}
        {props.isAwaiting ? (
          <span>None</span>
        ) : (
          <a
            title={props.docValues.document_title}
            href={props.docValues.document_url}
            download={props.docValues.document_title}
          >
            {props.docValues.document_title}
          </a>
        )}
      </div>
      <div className="document actionButtons">
        <Upload
          className="wrapper-upload"
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
          showUploadList={false}
          beforeUpload={(file) => {
            const request = {};
            request[props.docValues.document_type] = file;
            props.handleUpload(request, props.quoteId);
            return false;
          }}
        >
          <Button type="link" icon="upload" disabled={props.isCostLocked} />
        </Upload>
        {!isShipmentStatus(props?.status) && (
          <ApproveButton
            disabled={!props.docValues.document_id}
            onClick={() => {
              setisApproved(!isApproved);
              dispatch(
                approveDocument(props.docValues.document_id, props.quoteId)
              );
            }}
            type={isApproved ? 'primary' : 'secondary'}
          >
            {isApproved ? (
              <React.Fragment>
                <Icon theme="filled" type="check-circle" />
                &nbsp; Verified
              </React.Fragment>
            ) : (
              'Verify'
            )}
          </ApproveButton>
        )}
        <div style={{ width: 30, display: 'inline-block' }}>
          {!nonRemovableFields[props.docValues.document_type] && (
            <Button
              icon="delete"
              type="link"
              disabled={props.isCostLocked}
              onClick={() => {
                confirmDocRemovalModal();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// TODO: implement loading state and fetch data
const ShipmentDocuments = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadDialog, setuploadDialog] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isEditDocTypeModalVisible, setIsEditDocTypeModalVisible] = useState(
    false
  );
  const [submitting, setSubmitting] = useState(false);
  const [operators, setOperators] = useState([]);
  // TODO: for now this should work as we are only validating 2 fields
  const [error, setError] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {}
  );
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const costBillingData = useSelector(
    (state) => state?.costBillingDetails?.costBillingData
  );
  const getFromToDate = () => {
    const fromDate = detailAPI?.origin?.pickup_date
      ? moment(detailAPI?.origin?.pickup_date)
      : null;

    const toDate = detailAPI?.destination?.pickup_date
      ? moment(detailAPI?.destination?.pickup_date)
      : null;
    return [fromDate, toDate];
  };
  const [pickupDate, setPickupDate] = useState(() => {
    const [from, to] = getFromToDate();
    return from > to || from.diff(moment(), 'days') < 0 ? null : from;
  });
  const [deliveryDate, setDeliveryDate] = useState(() => {
    const [from, to] = getFromToDate();
    return to < from || to.diff(moment(), 'days') < 0 ? null : to;
  });
  const [operator, setOperator] = useState(detailAPI?.operator_id);
  let defaultPublicDocuments = [
    {
      document_type: 'PACKING_LIST',
    },
    {
      document_type: 'COMMERCIAL_INVOICE',
    },
    {
      document_type: 'QUOTE',
    },
  ];
  // if (detailAPI.is_eor_present || isQuoteFinal(detailAPI?.quote_status)) {
  if (detailAPI.is_eor_present) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'EXPORTER_OF_RECORD_(EOR)' },
    ];
  }
  // if (detailAPI.is_ior_present || isQuoteFinal(detailAPI?.quote_status)) {
  if (detailAPI.is_ior_present) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'IMPORTER_OF_RECORD_(IOR)' },
    ];
  }
  if (
    detailAPI.shipment_details.contains_battery ||
    detailAPI.shipment_details.contains_hazardous_goods ||
    detailAPI.shipment_details.other
  ) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)' },
    ];
  }

  const getPublicDocuments = () => {
    // get the default document list, with values coming from API if present
    const defPublicDocuments = defaultPublicDocuments.map((document) => {
      const docs = detailAPI?.documents?.publicDocuments?.find((doc) => {
        return doc.document_type === document.document_type;
      });
      if (docs) {
        return docs;
      }
      return document;
    });

    // If status is DOCUMENTS_UPLOAD check if IOR/EOR exist else remove it
    // if (isQuoteFinal(detailAPI?.quote_status)) {
    //   defPublicDocuments = defPublicDocuments.filter((doc) => {
    //     if (
    //       ['EXPORTER_OF_RECORD_(EOR)', 'IMPORTER_OF_RECORD_(IOR)'].includes(
    //         doc.document_type
    //       )
    //     ) {
    //       return doc.document_id;
    //     }
    //     return true;
    //   });
    // }

    // get other documents that are not on the default list
    const otherDocuments = detailAPI?.documents?.publicDocuments?.filter(
      (document) => {
        return ![
          'PACKING_LIST',
          'COMMERCIAL_INVOICE',
          'QUOTE',
          'EXPORTER_OF_RECORD_(EOR)',
          'IMPORTER_OF_RECORD_(IOR)',
          'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)',
        ].includes(document.document_type);
      }
    );
    // merge default documents and other documents
    return [...defPublicDocuments, ...otherDocuments];
  };

  const publicDocuments =
    detailAPI?.documents?.publicDocuments?.length > 0
      ? getPublicDocuments()
      : defaultPublicDocuments;
  const privateDocuments = detailAPI?.documents?.privateDocuments || [];
  const isRequiredDocumentsApproved = () => {
    const requiredDocuments = publicDocuments.filter((document) => {
      return [
        'PACKING_LIST',
        'COMMERCIAL_INVOICE',
        'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)',
      ].includes(document.document_type);
    });
    return requiredDocuments.every(
      (doc) => doc.document_is_document_approved === true
    );
  };
  const dispatch = useDispatch();

  const handlePickupDateChange = (value) => {
    if (!value) {
      setError({
        pickupDate: `Please select ${
          detailAPI?.origin?.is_using_first_mile
            ? 'Pickup Date'
            : 'Dropoff Date'
        }`,
      });
    } else {
      setError({ pickupDate: '' });
    }
    setPickupDate(value);
  };
  const handleDeliveryDateChange = (value) => {
    if (!value) {
      setError({
        deliveryDate: `Please select Estimated Delivery Date`,
      });
    } else {
      setError({ deliveryDate: '' });
    }
    setDeliveryDate(value);
  };
  const handleOperatorChange = (value) => {
    if (!value) {
      setError({
        operator: `Please choose an operator`,
      });
    } else {
      setError({ operator: '' });
    }
    setOperator(value);
  };
  const handleUpload = async (file, quoteId, docStatus) => {
    setUploading(true);
    await dispatch(
      uploadDocument({
        data: file,
        documentStatus: docStatus,
        // status: detailAPI?.quote_status,
        importAwb: detailAPI?.import_awb,
        id: quoteId
      })
    );
    setUploading(false);
    setuploadDialog(false);
  };

  const confirmShipment = async () => {
    if (pickupDate && deliveryDate && operator) {
      setError({ pickupDate: '', deliveryDate: '' });
      setSubmitting(true);
      await dispatch(
        confirmShipmentStatus({
          action: 'SHIPMENT_CONFIRMED',
          id: detailAPI?.quote_id,
          pickupDate,
          deliveryDate,
          operator,
        })
      );
      dispatch(getQuoteDetail(detailAPI?.quote_id));
      setSubmitting(false);
      setIsConfirmModalVisible(false);
    }
    if (!pickupDate) {
      setError({
        pickupDate: `Please select ${
          detailAPI?.origin?.is_using_first_mile
            ? 'Pickup Date'
            : 'Dropoff Date'
        }`,
      });
    }
    if (!deliveryDate) {
      setError({
        deliveryDate: `Please select Estimated Delivery Date`,
      });
    }
    if (!operator) {
      setError({
        operator: `Please choose an operator`,
      });
    }
  };

  const disabledStartDate = (startDate) => {
    if (!deliveryDate) {
      return false;
    }
    return startDate.isAfter(deliveryDate, 'day');
  };

  const disabledEndDate = (endDate) => {
    if (!pickupDate) {
      return false;
    }
    return endDate.isBefore(pickupDate, 'day');
  };

  useEffect(() => {
    const getOperators = async () => {
      const ops = await shipmentService.getOperators();
      setOperators(
        ops.data.map((op) => ({
          label: `${op.name} (${op.email})`,
          value: op.id,
        }))
      );
    };
    getOperators();
  }, []);

  return (
    <StyledShipmentDocuments
      id="shipment-documents"
      className={cx({
        confirmed: isShipmentStatus(detailAPI?.quote_status),
      })}
    >
      <div className="shipment-documents-body">
        <DocumentTypeTitle>Public Documents</DocumentTypeTitle>
        <div>
          {publicDocuments.map((document) => (
            <Document
              key={document.document_id}
              handleUpload={handleUpload}
              docValues={document}
              isAwaiting={!document.document_id}
              quoteId={detailAPI?.quote_id}
              status={detailAPI?.quote_status}
              isCostLocked={costBillingData.is_cost_locked}
            />
          ))}
        </div>
      </div>
      <div className="shipment-documents-body">
        <DocumentTypeTitle>Private Documents</DocumentTypeTitle>
        <div>
          {privateDocuments.length > 0 ? (
            privateDocuments.map((document) => (
              <Document
                key={document.document_id}
                handleUpload={handleUpload}
                docValues={document}
                isAwaiting={!document.document_id}
                quoteId={detailAPI?.quote_id}
                status={detailAPI?.quote_status}
              />
            ))
          ) : (
            <DocumentNoneTitle>None</DocumentNoneTitle>
          )}
        </div>
      </div>
      {uploadDialog && (
        <div className="shipment-documents-body">
          <UploadDocumentsDialog
            quoteId={detailAPI?.quote_id}
            handleUpload={handleUpload}
            closeButton={setuploadDialog}
            uploading={uploading}
            mawbNo={detailAPI?.import_awb}
          />
        </div>
      )}
      <UploadFooter>
        {!uploadDialog &&
          (isQuoteFinal(detailAPI?.quote_status) ||
            detailAPI?.quote_status === 'SHIPMENT_CREATED') && (
            <div>
              <Button
                onClick={() => {
                  setuploadDialog(true);
                }}
                type="secondary"
                className="upload-new-button"
                disabled={costBillingData.is_cost_locked}
              >
                Upload
              </Button>
              <Button
                onClick={() => {
                  setIsEditDocTypeModalVisible(true);
                }}
                type="secondary"
                className="edit-type-button"
                disabled={costBillingData.is_cost_locked}
              >
                Edit
              </Button>
            </div>
          )}
        {!isShipmentStatus(detailAPI?.quote_status) && (
          <Button
            disabled={
              !isRequiredDocumentsApproved() ||
              !(
                detailAPI?.documents.privateDocuments.length > 0 ||
                detailAPI?.documents.publicDocuments.length > 0
              )
            }
            onClick={() => {
              setIsConfirmModalVisible(true);
            }}
            className="confirm-shipment-button"
          >
            Confirm Shipment
          </Button>
        )}
      </UploadFooter>
      <ConfirmShipmentModal
        title="Please confirm the following shipment information"
        visible={isConfirmModalVisible}
        closable={false}
        footer={[
          <Button
            key="back"
            type="secondary"
            onClick={() => setIsConfirmModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={() => confirmShipment()}
            loading={submitting}
          >
            Confirm
          </Button>,
        ]}
      >
        <DatePickerRow type="flex" justify="space-between">
          <Col>
            <label htmlFor="pickup-date">
              {detailAPI?.origin?.is_using_first_mile
                ? 'Pickup Date'
                : 'Dropoff Date'}
            </label>
            <DatePicker
              id="pickup-date"
              value={pickupDate}
              onChange={handlePickupDateChange}
              disabledDate={disabledStartDate}
            />
            {error.pickupDate && (
              <Message type="error">{error.pickupDate}</Message>
            )}
          </Col>
          <Col>
            <label htmlFor="delivery-date">Estimated Delivery Date</label>
            <DatePicker
              value={deliveryDate}
              id="delivery-date"
              onChange={handleDeliveryDateChange}
              disabledDate={disabledEndDate}
            />
            {error.deliveryDate && (
              <Message type="error">{error.deliveryDate}</Message>
            )}
          </Col>
          <Col>
            <label htmlFor="operator-in-charge">Operator-in-charge</label>
            <SearchInput
              value={operator}
              id="operator-in-charge"
              items={operators}
              disabled={submitting}
              onChange={handleOperatorChange}
            />
            {error.operator && <Message type="error">{error.operator}</Message>}
          </Col>
        </DatePickerRow>
      </ConfirmShipmentModal>
      <EditDocumentTypeModal
        defaultPublicDocuments={defaultPublicDocuments}
        publicDocuments={publicDocuments}
        privateDocuments={privateDocuments}
        isModalVisible={isEditDocTypeModalVisible}
        setIsModalVisible={setIsEditDocTypeModalVisible}
      />
    </StyledShipmentDocuments>
  );
};

export default ShipmentDocuments;
