import React from 'react';
import { Switch as AntSwitch } from 'antd';
import styled from '@emotion/styled';

const StyledInput = styled(AntSwitch)`
  &.ant-switch-checked {
    background-color: #050593;
  }
`;

const Switch = (props) => {
  return <StyledInput {...props} />;
};

export default Switch;
