import React, { useEffect, useState } from 'react';
import { Row, Col, message, Divider } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import DatePicker from '../DatePicker';
import Message from '../Message';
import SearchInput from '../SearchInput';
import { shopeeService } from '../../services/shopee';
import { removeEmpty } from '../../utils/formatString';
import { SHOPEE_DESTINATION_AIRPORTS } from '../../constants/ports';
import ErrorMessage from './ErrorMessage';

const { Option } = Select;

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;

  input:disabled {
    color: #000000;
    background-color: #ffffff;
  }
`;

const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const initialState = {
  actionType: 0,
  originRegion: '',
  destinationRegion: '',
  carrierName: '',
  pcs: '',
  packageWeight: '',
  volumetricWeight: '',
  destinationLocation: undefined,
  eta: '',
  etd: '',
  cartonList: []
};

const CartonLoading = ({ mawbNo, data = [] }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visibleError, setVisibleError] = useState(false);
  const [errorOriginRegion, setErrorOriginRegion] = useState(false);
  const [errorDestinationRegion, setErrorDestinationRegion] = useState(false);

  const [
    {
      actionType,
      originRegion,
      destinationRegion,
      carrierName,
      pcs,
      packageWeight,
      volumetricWeight,
      destinationLocation,
      eta,
      etd,
      cartonList
    },
    setState
  ] = useState(initialState);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleValueChange = (name) => (value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearchInputChange = (field) => (value, option) => {
    setState((prevState) => ({ ...prevState, [field]: option?.props?.value }));
  };

  const handleDatePickerChange = (name) => (value, dateString) => {
    setState((prevState) => ({ ...prevState, [name]: dateString }));
  };

  const handleCarrierName = (dest) => {
    switch (dest) {
      case 'SG':
        return 'Taurus One PTE LTD';
      case 'PH':
        return 'Link Asia Forwarding Solutions Corporation';
      case 'ID':
        return 'PT. Dwitama Global Persada';
      default:
        return '';
    }
  };

  const handleLmCarrierName = (code) => {
    switch (code) {
      case 'PV01':
      case 'PV02':
        return 'J&T';
      case 'PV03':
      case 'PV04':
        return '2GO';
      case 'PV05':
      case 'PV06':
        return 'SPX';
      case 'PV07':
      case 'PV08':
        return 'Entrego';
      default:
        return '';
    }
  };

  const submit = () => {
    const key = `carton-loading-${Date.now()}`;
    message.loading({ content: 'Processing...', duration: 0, key });
    setLoading(true);

    const emptyObj = removeEmpty({
      mawbNo,
      originRegion,
      destinationRegion,
      carrierName,
      pcs,
      packageWeight,
      volumetricWeight,
      destinationLocation,
      eta,
      etd,
      cartonList: cartonList.length ? cartonList : null
    });

    if (Object.keys(emptyObj).length !== 11) {
      setLoading(false);
      message.error({
        content: 'Please fill in all the details and attach an attachment',
        key
      });
      return;
    }

    if (errorOriginRegion || errorDestinationRegion) {
      setLoading(false);
      message.error({
        content:
          'Different values are found in the Origin and Destination regions.',
        key
      });
      return;
    }

    const body = {
      mawb_no: mawbNo,
      action_type: actionType,
      origin_region: originRegion,
      destination_region: destinationRegion,
      cc_carrier_name: carrierName,
      pcs,
      package_weight: packageWeight,
      vol_weight: volumetricWeight,
      chargable_weight: packageWeight,
      transport_type: 1,
      ori_location: 'SZX',
      dest_location: destinationLocation,
      eta,
      etd,
      carton_list: cartonList
    };

    shopeeService
      .cartonLoading(body)
      .then(() => {
        setLoading(false);
        message.success({ content: 'Success!', key });
      })
      .catch((err) => {
        message.destroy();
        setLoading(false);

        const errMessage =
          err?.message ||
          'Failed to process. Please reach out Janio Technical Team.';

        setVisibleError(true);
        setErrorMessage(errMessage);
      });
  };

  useEffect(() => {
    if (!data.length) {
      return;
    }

    if (
      !data.every(
        (val, i, arr) => val['Sender Country'] === arr[0]['Sender Country']
      )
    ) {
      setErrorOriginRegion(true);
    } else {
      setErrorOriginRegion(false);
    }

    if (
      !data.every(
        (val, i, arr) => val['Destination Code'] === arr[0]['Destination Code']
      )
    ) {
      setErrorDestinationRegion(true);
    } else {
      setErrorDestinationRegion(false);
    }

    const cartonItems = [];
    data.forEach((val) => {
      if (!cartonItems.some((i) => i.carton_no === val['Carton No'])) {
        cartonItems.push({
          carton_no: val['Carton No'],
          carton_packer_code: '101',
          fl_carrier_name: 'Janio',
          lm_carrier_name: handleLmCarrierName(val['Service Code']),
          service_code: val['Service Code'],
          goods_type: val.Channel === '[DG]' ? 1 : 0
        });
      }
    });

    setState((prevState) => ({
      ...prevState,
      originRegion: data[0]['Sender Country'],
      destinationRegion: data[0]['Destination Code'],
      carrierName: handleCarrierName(data[0]['Destination Code']),
      volumetricWeight: cartonItems.length * 20.0475,
      pcs: data.length,
      cartonList: cartonItems
    }));
  }, [data]);

  return (
    <StyledContainer>
      <StyledRow gutter={[16, 24]}>
        <Col span={12}>
          <p>Action Type</p>
          <Select
            placeholder="Action type"
            value={actionType}
            onChange={handleValueChange('actionType')}
          >
            <Option key="0" value={0}>
              Create
            </Option>
            <Option key="1" value={1}>
              Update
            </Option>
          </Select>
        </Col>

        <Col span={12}>
          <p>Origin Region</p>
          <Input disabled value={originRegion} />
          {errorOriginRegion && (
            <Message type="error">
              All values in this field are not the same
            </Message>
          )}
        </Col>

        <Col span={12}>
          <p>Destination Location</p>
          <SearchInput
            id="destination-location"
            allowClear
            placeholder="Type or select..."
            items={SHOPEE_DESTINATION_AIRPORTS}
            value={destinationLocation}
            onChange={handleSearchInputChange('destinationLocation')}
          />
        </Col>

        <Col span={12}>
          <p>Destination Region</p>
          <Input disabled value={destinationRegion} />
          {errorDestinationRegion && (
            <Message type="error">
              All values in this field are not the same
            </Message>
          )}
        </Col>

        <Col span={12}>
          <p>Package Weight (KG)</p>
          <Input
            type="number"
            name="packageWeight"
            placeholder="Type package weight"
            value={packageWeight}
            onChange={handleTextChange}
          />
        </Col>

        <Col span={12}>
          <p>Volumetric Weight (KG)</p>
          <Input disabled value={volumetricWeight} />
        </Col>

        <Col span={12}>
          <p>Estimated Time of Arrival</p>
          <DatePicker showTime onChange={handleDatePickerChange('eta')} />
        </Col>

        <Col span={12}>
          <p>Carrier Name</p>
          <Input disabled value={carrierName} />
        </Col>

        <Col span={12}>
          <p>Estimated Time of Departure</p>
          <DatePicker showTime onChange={handleDatePickerChange('etd')} />
        </Col>

        <Col span={12}>
          <p>Pieces</p>
          <Input disabled value={pcs} />
        </Col>
      </StyledRow>

      <Divider />

      <Col>
        <Button onClick={submit} loading={loading}>
          Submit
        </Button>
      </Col>

      <ErrorMessage
        key={new Date()}
        message={errorMessage}
        visible={visibleError}
        setVisible={setVisibleError}
      />
    </StyledContainer>
  );
};

export default CartonLoading;
