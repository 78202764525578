import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import UnitInput from './UnitInput';
import FloatInput from './FloatInput';
import SearchInput from './SearchInput';

const StyledDimensionsInput = styled.div`
  margin-left: 1px;
  .ant-input-group > div .ant-select,
  .ant-input-group > div .ant-select-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;
  }
  .ant-input-group > div {
    width: calc(40% + 1px);
    margin-left: -1px;
  }
  .ant-input-group > input {
    width: ${(props) => (props.type ? 'calc(20% + 1px)' : 'calc(60% + 1px)')};
    margin-left: -1px;
  }
`;

const DimensionsInput = ({
  unit = 'CM',
  type = false,
  value: inputValue = {
    length: null,
    width: null,
    height: null,
    volume: null
  },
  onChange: handleChange = () => {},
  onBlur: handleBlur = () => {}
}) => {
  const [dimensions, setDimensions] = useState(inputValue);
  const [dimensionsUnit, setDimensionsUnit] = useState(unit);
  const [metricType, setMetricType] = useState(type);

  useEffect(() => {
    setDimensionsUnit(unit);
  }, [unit]);

  return (
    <StyledDimensionsInput type={type}>
      <UnitInput
        hide={!type}
        unit={dimensionsUnit.toUpperCase()}
        units={['CM', 'IN']}
        value={inputValue}
        onChange={(selectedUnit) => {
          setDimensionsUnit(selectedUnit.toLowerCase());
          handleChange({
            unit: selectedUnit.toLowerCase(),
            type: metricType,
            ...dimensions
          });
        }}
      >
        {({ value, onChange }) => (
          <span className="ant-input-group ant-input-group-compact">
            <SearchInput
              value={type ? 'unit' : 'total'}
              items={[
                { label: 'Total Volume', value: 'total' },
                { label: 'Unit Volume', value: 'unit' }
              ]}
              onChange={(selectedType) => {
                setMetricType(selectedType === 'unit');
                const dim = {
                  unit: dimensionsUnit,
                  type: selectedType === 'unit'
                };

                if (selectedType) {
                  dim.volume = null;
                } else {
                  dim.length = null;
                  dim.width = null;
                  dim.height = null;
                }
                handleChange(dim);
              }}
            />
            {type ? (
              <>
                <FloatInput
                  placeholder="L"
                  value={value.length}
                  onChange={(e) => {
                    const length = {
                      ...value,
                      length: e.target.value
                    };
                    setDimensions(length);
                    handleChange({
                      unit: dimensionsUnit,
                      type: metricType,
                      ...length
                    });
                    onChange(length);
                  }}
                  onBlur={handleBlur}
                />
                <FloatInput
                  placeholder="W"
                  value={value.width}
                  onChange={(e) => {
                    const width = {
                      ...value,
                      width: e.target.value
                    };
                    setDimensions(width);
                    handleChange({
                      unit: dimensionsUnit,
                      type: metricType,
                      ...width
                    });
                    onChange(width);
                  }}
                  onBlur={handleBlur}
                />
                <FloatInput
                  placeholder="H"
                  value={value.height}
                  onChange={(e) => {
                    const height = {
                      ...value,
                      height: e.target.value
                    };
                    setDimensions(height);
                    handleChange({
                      unit: dimensionsUnit,
                      type: metricType,
                      ...height
                    });
                    onChange(height);
                  }}
                  onBlur={handleBlur}
                />
              </>
            ) : (
              <FloatInput
                placeholder="Total volume in this package"
                value={value.volume}
                onChange={(e) => {
                  setDimensions({ volume: e.target.value });
                  handleChange({
                    unit: dimensionsUnit,
                    type: metricType,
                    volume: e.target.value
                  });
                }}
                onBlur={handleBlur}
              />
            )}
          </span>
        )}
      </UnitInput>
    </StyledDimensionsInput>
  );
};

export default DimensionsInput;
