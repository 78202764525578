import React, { useState } from 'react';
import QuoteDetailsView from './QuoteDetailsView';
import QuoteDetailsEdit from './QuoteDetailsEdit';

const QuoteDetails = () => {
  const [isEditing, setEditing] = useState(false);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setEditing(false);
  };

  const onSave = () => {
    setEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <QuoteDetailsEdit onCancel={onCancel} onSave={onSave} />
      ) : (
        <QuoteDetailsView onEdit={onEdit} />
      )}
    </>
  );
};

export default QuoteDetails;
