import request from '../utils/request';

const handleError = (res) => {
  // Shopee will send success retcode 0
  if (res.result === 0) {
    return;
  }

  throw new Error(res.data);
};

const cartonOperation = async (data) => {
  const url = `/webhooks/shopee/first_leg/repack_carton/lh_janio`;
  const response = await request().post(url, data);
  handleError(response.data);
};

const cartonLoading = async (data) => {
  const url = `/webhooks/shopee/first_leg/loading_carton/lh_janio`;
  const response = await request().post(url, data);
  handleError(response.data);
};

const trackingReceive = async (cartonNos, data) => {
  const url = `/webhooks/shopee/first_leg/tracking_carton/lh_janio`;

  // eslint-disable-next-line no-restricted-syntax
  for (const cartonNo of cartonNos) {
    // eslint-disable-next-line no-param-reassign
    data.carton_no = cartonNo;
    // eslint-disable-next-line no-await-in-loop
    const response = await request().post(url, data);
    handleError(response.data);
  }
};

export const shopeeService = {
  cartonOperation,
  cartonLoading,
  trackingReceive
};
