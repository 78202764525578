import React, { useState } from 'react';
import ShipmentQuoteDetailsView from './ShipmentQuoteDetailsView';
import ShipmentQuoteDetailsEdit from './ShipmentQuoteDetailsEdit';

const ShipmentQuoteDetails = ({ quoteId }) => {
  const [isEditing, setEditing] = useState(false);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <ShipmentQuoteDetailsEdit quoteId={quoteId} onCancel={onCancel} />
      ) : (
        <ShipmentQuoteDetailsView quoteId={quoteId} onEdit={onEdit} />
      )}
    </>
  );
};

export default ShipmentQuoteDetails;
