import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { message } from 'antd';
import moment from 'moment';
import * as Yup from 'yup';
import Button from '../Button';
import QuoteDetailsForm, { QuoteDetailsFormRow } from './QuoteDetailsForm';
import {
  getQuoteLookups,
  getRequestQuotesDetail,
  getQuoteShipmentLog
} from '../../actions/quoteDetail';
import { quoteService } from '../../services';
import {
  TEXT_UPDATE_QUOTE_SUCCESS,
  TEXT_UPDATE_QUOTE_ERROR
} from '../../constants/message';
import { initValidators } from '../../helpers/validation';
import { invalidCharacter } from '../../utils/formatString';
import { APPROVED, REJECTED, WIN, LOSS } from '../../constants/status';

const getMetricType = (shipment, item) => {
  if (shipment.quote_shipment_dimension_type) {
    return [
      shipment.quote_shipment_dimension_type,
      shipment.quote_shipment_dimension_type
    ];
  }
  return [item.weight_type, item.volume_type];
};

const StyledShipmentDetails = styled.div`
  .shipment-body {
    .ant-calendar-picker {
      width: 100%;
    }
    line-height: 1;
  }
  .shipment-body .shipment-detail-row:last-child {
    border-bottom: 1px solid #050593;
  }
  .shipment-controls {
    display: flex;
    justify-content: space-between;
    button {
      padding: 0px 41px;
      margin-left: 10px;
    }
    button:first-of-type {
      margin-left: auto;
    }
  }
`;

const ShipmentDetailsEdit = ({ onCancel, onSave }) => {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const quote = useSelector((state) => state?.quoteRequestDetails?.quote);
  const lookups = useSelector((state) => state?.quoteDetail?.lookups);
  const shipmentDetails = quote?.shipment_details;
  useEffect(() => {
    dispatch(getQuoteLookups());
  }, [dispatch]);

  const dangerousGoods = {
    contains_hazardous_goods: 'Hazardous Materials',
    contains_battery: 'Batteries',
    other: 'Other'
  };

  initValidators();

  const shipmentFormSchema = Yup.object().shape({
    origin: Yup.object().shape({
      port: Yup.string()
        .nullable()
        .test(
          'length',
          'You have exceeded the maximum 50 characters length limit',
          (val) => ((val && val.length) || 0) < 50
        ),
      pickupDate: Yup.string()
        .nullable()
        .required(
          `Please select ${
            quote?.origin?.is_using_first_mile ? 'Pickup Date' : 'Dropoff Date'
          }`
        ),
      cargoReadinessDate: Yup.string().nullable(),
      address: Yup.string()
        .nullable()
        .test(
          'address',
          `Please enter ${
            quote?.origin?.is_using_first_mile ? 'Address' : 'Dropoff Address'
          }`,
          (value) => {
            if (quote?.origin?.is_using_first_mile !== 2) {
              return value !== undefined;
            }
            return true;
          }
        )
    }),
    destination: Yup.object().shape({
      port: Yup.string()
        .nullable()
        .test(
          'length',
          'You have exceeded the maximum 50 characters length limit',
          (val) => ((val && val.length) || 0) < 50
        ),
      pickupDate: Yup.string()
        .nullable()
        .required(`Please select Estimated Delivery Date`),
      address: Yup.string()
        .nullable()
        .required(
          `Please enter ${
            quote?.destination?.is_using_first_mile
              ? 'Address'
              : 'Pickup Address'
          }`
        )
    }),
    shipperName: Yup.mixed()
      .nullable()
      .required(`Please enter Shipper's Name`),
    consigneeName: Yup.mixed()
      .nullable()
      .required(`Please enter Consignee's Name`),
    internal: Yup.object().shape({
      shipmentLoad: Yup.mixed().test('internal', 'error', (value) => {
        return !value;
      }),
      shipmentPackages: Yup.mixed().test('internal', 'error', (value) => {
        return !value;
      })
    }),
    yourShipment: Yup.object().shape({
      shipmentName: Yup.string()
        .nullable()
        .test(
          'max',
          'You have exceeded the maximum 200 characters length limit',
          (value) => {
            return (value?.length || 0) <= 200;
          }
        ),
      merchantEmails: Yup.mixed()
        .nullable()
        .test('email', 'Please enter a valid emails', (value) => {
          if (value && value.length) {
            const invalidEmails = value.filter((email) => {
              return !/^[^@]+@[^.]+\..+$/.test(email);
            });
            return !invalidEmails.length;
          }
          return true;
        })
    }),
    otherDetails: Yup.object().shape({
      description: Yup.string()
        .nullable()
        .test(
          'max',
          'You have exceeded the maximum 2000 characters length limit',
          (value) => {
            return (value?.length || 0) <= 2000;
          }
        )
        .test('description', 'Please enter valid characters', (value) => {
          return !invalidCharacter(value);
        })
    })
  });
  const getApproveRejectStatus = (quoteStatus) => {
    let status = '';
    switch (quoteStatus) {
      case WIN:
      case LOSS:
        status = 'APPROVED';
        break;
      case APPROVED:
      case REJECTED:
        status = quoteStatus;
        break;
      default:
        status = '';
    }
    return status;
  };

  const initialValues = {
    quoteStatus: {
      quoteStatus: getApproveRejectStatus(quote?.quote_status),
      rejectionReason: quote?.win_loss_reason
    },
    origin: {
      pickupDate: moment(quote?.origin?.pickup_date),
      port: quote?.origin?.port,
      address: [
        quote?.origin?.address,
        quote?.origin?.city,
        quote?.origin?.state,
        quote?.origin?.postal_code
      ]
        .filter(Boolean)
        .join(', '),
      pocName: quote?.origin?.poc_name,
      pocNumber: quote?.origin?.poc_number,
      pocEmail: quote?.origin?.poc_email,
      cargoReadinessDate: quote?.origin?.cargo_readiness_date
        ? moment(quote?.origin?.cargo_readiness_date)
        : null
    },
    destination: {
      pickupDate: moment(quote?.destination?.pickup_date),
      port: quote?.destination?.port,
      address: [
        quote?.destination?.address,
        quote?.destination?.city,
        quote?.destination?.state,
        quote?.destination?.postal_code
      ]
        .filter(Boolean)
        .join(', '),
      pocName: quote?.destination?.poc_name,
      pocNumber: quote?.destination?.poc_number,
      pocEmail: quote?.destination?.poc_email
    },
    shipperName: quote?.shipper_name,
    hsCode: quote?.hs_code,
    consigneeName: quote?.consignee_name,
    shipmentLoad: quote?.shipment_load_type ? 'Full' : 'Less',
    midMile: quote?.is_using_mid_mile ? 'Yes' : 'No',
    shippingInfo: {
      incotermId: quote?.incoterm_id
    },
    shippingPrice: {
      hasAgreedPrice: quote?.agreed_price ? 'Yes' : 'No',
      agreedPrice: quote?.shipment_price
    },
    shipmentPackages: quote?.packages?.map((packageItem) => {
      const [weightType, volumeType] = getMetricType(quote, packageItem);
      return {
        pId: packageItem.id,
        amount: packageItem.units || 1,
        type: packageItem.type_id,
        dimensions: {
          height: packageItem.height,
          isCm: packageItem.length_uom === 'cm',
          length: packageItem.length,
          width: packageItem.width,
          amount: packageItem.total_volume
        },
        weight: {
          amount: weightType ? packageItem.weight : packageItem.total_weight,
          isKg: packageItem.weight_uom === 'kg'
        },
        weightType,
        volumeType,
        chargeableWeight: packageItem.chargeable_weight
      };
    }),
    otherDetails: {
      dangerousGoods: Object.keys(dangerousGoods).filter(
        (goods) => shipmentDetails[goods]
      ),
      description: shipmentDetails?.description
    },
    yourShipment: {
      shipmentName: quote?.shipment_name,
      merchantEmails: quote?.merchant_emails
    },
    customBrokerageServices: {
      customsClearance: quote?.is_using_custom_clearance ? 'Yes' : 'No',
      exporterOfRecord: quote?.is_eor_present ? 'Yes' : 'No',
      importerOfRecord: quote?.is_ior_present ? 'Yes' : 'No'
    }
  };

  return (
    <StyledShipmentDetails id="quote-details">
      <QuoteDetailsForm
        initialValues={initialValues}
        lookups={lookups}
        validationSchema={shipmentFormSchema}
        onSubmit={(values) => {
          const quoteDetail = {
            quote_status: values?.quoteStatus?.quoteStatus,
            win_loss_reason: values?.quoteStatus?.rejectionReason,
            shipping_incoterm_id: values?.shippingInfo?.incotermId,
            origin: {
              estimated_pickup_dt: values?.origin?.pickupDate,
              port: values?.origin?.port ? values?.origin?.port : '',
              cargo_readiness_date: values?.origin?.cargoReadinessDate
            },
            destination: {
              estimated_delivery_dt: values?.destination?.pickupDate,
              port: values?.destination?.port ? values?.destination?.port : ''
            },
            shipper_name: values?.shipperName,
            merchant_emails: values?.yourShipment?.merchantEmails,
            shipping_hs_code: values?.hsCode,
            consignee_name: values?.consigneeName,
            shipment_name: values?.yourShipment?.shipmentName,
            shipment_details: {
              description: values?.otherDetails?.description?.trim()
            },
            shipment_load: values?.shipmentLoad?.fullLessLoad === 'Full',
            shipment_loads: values?.shipmentLoad?.loadItems
              ? values?.shipmentLoad?.loadItems?.map((loadItem) => {
                  return {
                    pId: loadItem.pId,
                    type_id:
                      values?.shipmentLoad?.fullLessLoad === 'Full'
                        ? loadItem.type
                        : null,
                    units: loadItem.amount,
                    containers_seals: loadItem.seals
                  };
                })
              : [],
            shipments_packages: {
              packages: values?.shipmentPackages.map((packageItem) => {
                return {
                  type_id: packageItem.type,
                  units: packageItem.amount,
                  weight_per_unit: packageItem.weightType
                    ? packageItem?.weight?.amount
                    : null,
                  weight_uom: packageItem?.weight?.isKg ? 'kg' : 'lb',
                  dimensions: {
                    length: packageItem?.dimensions?.length || null,
                    width: packageItem?.dimensions?.width || null,
                    height: packageItem?.dimensions?.height || null,
                    length_uom: packageItem?.dimensions?.isCm ? 'cm' : 'in'
                  },
                  cbm: packageItem.volumeType
                    ? null
                    : packageItem?.dimensions?.amount,
                  total_weight: packageItem.weightType
                    ? null
                    : packageItem?.weight?.amount,
                  weight_type: packageItem.weightType,
                  volume_type: packageItem.volumeType,
                  chargeable_weight: packageItem.chargeableWeight
                };
              })
            },
            is_using_mid_mile: values?.midMile === 'Yes',
            is_using_custom_clearance:
              values?.customBrokerageServices?.customsClearance === 'Yes',
            is_eor_present:
              values?.customBrokerageServices?.exporterOfRecord === 'Yes',
            is_ior_present:
              values?.customBrokerageServices?.importerOfRecord === 'Yes',
            has_agreed_price: values?.shippingPrice?.hasAgreedPrice === 'Yes',
            shipment_price: values?.shippingPrice?.agreedPrice
          };

          if (
            values?.otherDetails?.dangerousGoods &&
            values?.otherDetails?.dangerousGoods?.length > 0
          ) {
            values.otherDetails.dangerousGoods.forEach((key) => {
              quoteDetail.shipment_details = {
                ...quoteDetail.shipment_details,
                [key]: 1
              };
            });
          } else {
            quoteDetail.shipment_details.contains_no_special_goods = 1;
          }
          setSaving(true);
          quoteService
            .updateQuoteRequestDetail(quote?.quote_id, quoteDetail)
            .exec()
            .then(async () => {
              await dispatch(getRequestQuotesDetail(quote.quote_id));
              await dispatch(getQuoteShipmentLog(quote.quote_id));

              setSaving(false);
              message.success(TEXT_UPDATE_QUOTE_SUCCESS);
              onSave();
            })
            .catch(() => {
              setSaving(false);
              message.error(TEXT_UPDATE_QUOTE_ERROR);
            });
        }}
      >
        {({ isValid, handleSubmit, loadType, schema }) => (
          <>
            <div className="shipment-body">
              <QuoteDetailsFormRow schema={schema.quoteStatus} grow="0" />
              <QuoteDetailsFormRow schema={schema.merchant} grow="0" />
              <QuoteDetailsFormRow schema={schema.yourShipment} grow="0" />
              <QuoteDetailsFormRow schema={schema.shipmentMode} grow="0" />
              <QuoteDetailsFormRow grow="0" schema={schema.origin} />
              <QuoteDetailsFormRow grow="0" schema={schema.midMile} />
              <QuoteDetailsFormRow grow="0" schema={schema.destination} />
              <QuoteDetailsFormRow grow="0" schema={schema.information} />
              <QuoteDetailsFormRow grow="0" schema={schema.agreedPrice} />
              {loadType && quote?.shipment_load_type !== null && (
                <QuoteDetailsFormRow schema={schema.load} />
              )}
              <QuoteDetailsFormRow schema={schema.packages} />
              <QuoteDetailsFormRow
                style={{ width: '75%' }}
                grow="0"
                schema={schema.otherDetails}
              />
              <QuoteDetailsFormRow
                style={{ width: '75%' }}
                grow="0"
                schema={schema.customBrokerageServices}
              />
            </div>
            <div className="shipment-controls">
              <div>
                <Button
                  style={{ width: '200px' }}
                  onClick={onCancel}
                  type="secondary"
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  style={{ width: '200px' }}
                  disabled={!isValid}
                  loading={saving}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </QuoteDetailsForm>
    </StyledShipmentDetails>
  );
};

export default ShipmentDetailsEdit;
