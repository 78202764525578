import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { Row, Col, Checkbox, Popover, Icon, message } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import randomstring from 'randomstring';
import { FormSection, Radio, Button } from '../components';
import {
  FormSearchInput,
  FormRadioGroup,
  FormInput,
  FormPackageDimension,
  FormCheckboxGroup,
  FormMultiSelect
} from '../components/Forms';
import { merchantService } from '../services/merchants';
import { packageService } from '../services/packages';
import { quoteService } from '../services/quotes';
import FormPickupDeliveryDetails from '../components/Forms/FormPickupDeliveryDetails';
import FormFullLoad from '../components/Forms/FormFullLoad';
import { toTitleCase } from '../utils/formatString';
import { usePrevious } from '../helpers/hooks';
import QuoteDocuments from '../components/QuoteRequestDocuments';

// TODO: this form can be further optimized by reducing re-renders where possible

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;

const StyledCreateShipment = styled.div`
  padding-bottom: 10px;
  .create-shipment {
    width: 100%;
  }
  .shipmentBtns {
    max-width: 975px;
    margin: 5px auto 25px auto !important;
  }
`;

const StyledFormPanel = styled.div`
  .divider {
    position: relative;
    margin-top: 10px;
    height: 1px;
  }
  & > .ant-row {
    margin: 30px 0;
    .ant-row:first-of-type {
      margin-top: 0;
    }
    .ant-row:last-of-type {
      margin-bottom: 0;
    }
    .ant-row {
      margin: 30px 0;
    }
  }
  .dimension-type {
    margin-bottom: 10px;
  }
`;

const FormPanel = ({ children }) => (
  <StyledFormPanel>
    <div className="divider" />
    <Row>{children}</Row>
  </StyledFormPanel>
);

const FormActivator = ({
  errors,
  values,
  setFieldValue,
  children,
  validateForm = () => {},
  onLoadChange = () => {}
}) => {
  const [activeForms, setActiveForms] = useState([0]);
  const [lastIndex, setLastIndex] = useState(false);
  const prevModeOfShipment = usePrevious(values.modeOfShipment);
  useEffect(() => {
    const list = [
      ['merchantName'],
      [
        'shipmentName',
        'modeOfShipment',
        'merchantEmails',
        'firstMile',
        'internal.origin',
        'midMile',
        'lastMile',
        'internal.destination',
        'incoterm',
        'shipperName',
        'consigneeName',
        'agreedPrice',
        'agreedPriceText'
      ],
      [
        'load',
        'internal.loads',
        'internal.dimensions',
        'dangerousGoods',
        'shipmentDescription'
      ],
      ['customsClearance', 'hasEor', 'hasIor', 'relevantDocuments']
    ];
    let active = [0];
    for (let item = 0; item < list.length; item += 1) {
      let error = false;
      for (let prop = 0; prop < list[item].length; prop += 1) {
        if (
          values.modeOfShipment !== 'Air' &&
          list[item][prop] === 'internal.loads' &&
          values.load === false
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (get(errors, list[item][prop])) {
          error = true;
          break;
        }
      }
      if (error) {
        break;
      }
      if (item < list.length) {
        active = [...active, item + 1];
      }
    }
    // const active = [0, 1, 2, 3];
    setActiveForms(active);
    // setLastIndex(true);
    setLastIndex(list.length === active.length - 1);
  }, [errors, values.load, values.modeOfShipment]);

  useEffect(() => {
    if (
      values?.dangerousGoods?.includes('no_special_goods') &&
      values?.dangerousGoods?.length > 1
    ) {
      setFieldValue('dangerousGoods', ['no_special_goods']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dangerousGoods]);

  useEffect(() => {
    if (values?.load === false) {
      setFieldValue('loads', [
        {
          pId: randomstring.generate(),
          amount: null,
          type: undefined
        }
      ]);
    }
    onLoadChange(values.load, validateForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.load]);

  useEffect(() => {
    if (values.modeOfShipment === 'Air') {
      setFieldValue('load', undefined);
    }
    if (
      prevModeOfShipment !== undefined &&
      values.modeOfShipment !== prevModeOfShipment
    ) {
      setFieldValue('loads', [
        {
          pId: randomstring.generate(),
          amount: null,
          type: undefined
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modeOfShipment]);

  return children({ active: activeForms, lastIndex });
};

const getLoadType = (modeOfShipment, titleCase = false) => {
  let label = '';
  if (modeOfShipment === 'Sea') {
    label = 'container';
  }
  if (modeOfShipment === 'Land') {
    label = 'truck';
  }
  if (titleCase) {
    return toTitleCase(label);
  }
  return label;
};

const CreateQuote = (props) => {
  const defaultPublicDocuments = [
    { document_type: 'PACKING_LIST' },
    { document_type: 'COMMERCIAL_INVOICE' },
    { document_type: 'QUOTATION_FORM' }
  ];
  const { match = { params: {} } } = props;
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [fullLoad, setFullLoad] = useState();
  const [loadTypes, setLoadTypes] = useState({
    containers: [],
    trucks: []
  });
  const [publicDocuments, setPublicDocuments] = useState(
    defaultPublicDocuments
  );
  const [incoterms, setIncoterms] = useState([]);
  const [formValues] = useState({
    merchantName: undefined,
    merchantEmails: [],
    modeOfShipment: match?.params?.trackingId ? undefined : 'Air',
    firstMile: 1,
    origin: {
      country: undefined,
      port: '',
      address: undefined,
      date: undefined
    },
    shipper_name: undefined,
    consignee_name: undefined,
    midMile: true,
    lastMile: true,
    destination: {
      country: undefined,
      port: '',
      address: undefined,
      date: undefined
    },
    incoterm: undefined,
    agreedPriceText: '',
    dimensions: [
      {
        pId: randomstring.generate(),
        amount: 1,
        name: '',
        type: undefined,
        dimensions: {
          height: null,
          isCm: true,
          length: null,
          width: null
        },
        weight: {
          amount: null,
          isKg: true
        },
        weightType: false,
        volumeType: true,
        chargeableWeight: null
      }
    ],
    loads: [
      {
        pId: randomstring.generate(),
        amount: null,
        type: undefined
      }
    ],
    shipmentName: '',
    shipmentDescription: '',
    relevantDocuments: ''
  });

  const onLoadChange = async (load, validateForm) => {
    await setFullLoad(load);
    validateForm();
  };

  const getFormSectionNumber = (name) => {
    const list = [
      'merchant',
      'shipmentMode',
      'shipmentDetails',
      'customs',
      'documents'
    ];
    return list.findIndex((item) => item === name) + 1;
  };

  useEffect(() => {
    const fetchMerchants = merchantService.getMerchants();
    fetchMerchants.exec().then((res) => {
      setMerchants(
        res?.data?.data?.map((merchant) => ({
          label: merchant.name,
          value: merchant.merchant_id
        }))
      );
    });
    return () => fetchMerchants.cancel();
  }, []);

  useEffect(() => {
    setLoadingPackage(true);
    const fetchLookupValues = packageService.getPackageTypes();
    fetchLookupValues.exec().then((res) => {
      setPackageTypes(res.data.data.package_types);
      setIncoterms(res.data.data.incoterms);
      setLoadTypes({
        containers: res.data.data.container_types,
        trucks: res.data.data.truck_types
      });
      setLoadingPackage(false);
    });
    return () => fetchLookupValues.cancel();
  }, []);

  const [dataFile, setDataFile] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {}
  );
  const getValidationSchema = useCallback(
    () =>
      Yup.object().shape({
        merchantName: Yup.mixed()
          .nullable()
          .required('Please enter Merchant Name'),
        merchantEmails: Yup.mixed()
          .nullable()
          .test('email', 'Please enter a valid emails', (value) => {
            if (value && value.length) {
              const invalidEmails = value.filter((email) => {
                return !/^[^@]+@[^.]+\..+$/.test(email);
              });
              return !invalidEmails.length;
            }
            return true;
          }),
        shipperName: Yup.mixed()
          .nullable()
          .required(`Please enter Shipper's Name`),
        consigneeName: Yup.mixed()
          .nullable()
          .required(`Please enter Consignee's Name`),
        internal: Yup.object().shape({
          destination: Yup.mixed().test('internal', 'error', (value) => !value),
          loads: Yup.mixed().test('internal', 'error', (value) => {
            if (fullLoad) {
              return !value;
            }
            return true;
          }),
          dimensions: Yup.mixed().test('internal', 'error', (value) => !value),
          origin: Yup.mixed().test('internal', 'error', (value) => !value)
        }),
        load: Yup.mixed().test(
          'load',
          'error',
          // eslint-disable-next-line func-names
          function(value) {
            const { parent } = this;
            if (parent.modeOfShipment !== 'Air') {
              return value !== undefined;
            }
            return true;
          }
        ),
        dangerousGoods: Yup.array()
          .nullable()
          .required('Please select at least one'),
        shipmentName: Yup.mixed()
          .nullable()
          .test(
            'max',
            'You have exceeded the maximum 200 characters length limit',
            (value) => {
              return (value?.length || 0) <= 200;
            }
          ),
        shipmentDescription: Yup.mixed()
          .nullable()
          .test(
            'max',
            'You have exceeded the maximum 2000 characters length limit',
            (value) => {
              return (value?.length || 0) <= 2000;
            }
          ),
        customsClearance: Yup.mixed()
          .nullable()
          .required(),
        hasEor: Yup.mixed()
          .nullable()
          .required(),
        hasIor: Yup.mixed()
          .nullable()
          .required(),
        agreedPrice: Yup.mixed()
          .nullable()
          .required(),
        agreedPriceText: Yup.mixed().test(
          'agreedPrice',
          'Please enter Proposed Price, Reasons & Conditions',
          // eslint-disable-next-line func-names
          function(value) {
            const { parent } = this;
            if (parent.agreedPrice) {
              return value;
            }
            return true;
          }
        ),
        documents: Yup.mixed()
          .nullable()
          .test(
            'documents',
            'MSDS Document is required',
            // eslint-disable-next-line func-names
            function(value) {
              const { parent } = this;
              if (
                parent.dangerousGoods &&
                parent.dangerousGoods.length > 0 &&
                parent.dangerousGoods.indexOf('no_special_goods') === -1
              ) {
                return value;
              }
              return true;
            }
          )
      }),
    [fullLoad]
  );

  const documentsMap = {
    'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)': 'Material Safety Data Sheets (MSDS)'
  };

  return (
    <StyledCreateShipment>
      <Formik
        enableReinitialize
        validationSchema={getValidationSchema()}
        initialValues={formValues}
        onSubmit={(values) => {
          const quote_request = {
            merchant_id: values?.merchantName,
            merchant_emails: values?.merchantEmails,
            origin: {
              is_first_mile_purchase: values?.firstMile,
              address_id: values?.origin?.address,
              port: values?.origin?.port,
              estimated_dpd_dt: values?.origin?.date?.format('YYYY-MM-DD'),
              country: values?.origin?.country,
              cargo_readiness_date: values?.origin?.cargoReadinessDate?.format(
                'YYYY-MM-DD'
              )
            },
            shipper_name: values?.shipperName,
            consignee_name: values?.consigneeName,
            is_using_mid_mile: values?.midMile,
            destination: {
              is_last_mile_purchase: values?.lastMile,
              address_id: values?.destination?.address,
              port: values?.destination?.port,
              estimated_delivery_dt: values?.destination?.date?.format(
                'YYYY-MM-DD'
              ),
              country: values?.destination?.country
            },
            shipment_mode: values?.modeOfShipment,
            shipping_incoterm_id: values?.incoterm,
            shipping_hs_code: values?.hsCode,
            has_agreed_price: values?.agreedPrice || null,
            shipment_price: values?.agreedPriceText || null,
            shipment_load: values?.load,
            shipment_loads: values?.load
              ? values?.loads?.map((loadItem) => {
                  return {
                    type_id: loadItem.type,
                    units: loadItem.amount
                  };
                })
              : [],
            shipments_packages: {
              packages: values?.dimensions?.map((packageItem) => {
                return {
                  type_id: packageItem.type,
                  units: packageItem.amount,
                  weight_per_unit: packageItem.weightType
                    ? packageItem?.weight?.amount
                    : null,
                  weight_uom: packageItem?.weight?.isKg ? 'kg' : 'lb',
                  dimensions: {
                    length: packageItem?.dimensions?.length || null,
                    width: packageItem?.dimensions?.width || null,
                    height: packageItem?.dimensions?.height || null,
                    length_uom: packageItem?.dimensions?.isCm ? 'cm' : 'in'
                  },
                  cbm: packageItem.volumeType
                    ? null
                    : packageItem?.dimensions?.amount,
                  total_weight: packageItem.weightType
                    ? null
                    : packageItem?.weight?.amount,
                  weight_type: packageItem.weightType,
                  volume_type: packageItem.volumeType,
                  chargeable_weight: packageItem.chargeableWeight
                };
              })
            },
            shipment_name: values?.shipmentName,
            shipment_details: {
              description: values?.shipmentDescription,
              contains_battery: values?.dangerousGoods?.includes(
                'contains_battery'
              ),
              contains_hazardous_goods: values?.dangerousGoods?.includes(
                'contains_hazardous_goods'
              ),
              other: values?.dangerousGoods?.includes('other'),
              contains_no_special_goods: values?.dangerousGoods?.includes(
                'no_special_goods'
              )
            },
            is_eor_present: values?.hasEor,
            is_ior_present: values?.hasIor,
            is_using_custom_clearance: values?.customsClearance
          };
          setSaving(true);
          quoteService
            .createQuoteRequest(quote_request, dataFile)
            .then(async (resp) => {
              history.push(`/quotes/${resp?.tracking_id}`);
            })
            .catch((err) => {
              message.error(
                err?.response?.data?.result ||
                  'Quote request failed. Please check the request.'
              );
            })
            .finally(() => {
              setSaving(false);
            });
        }}
      >
        {({
          values,
          isValid,
          handleSubmit,
          errors,
          setFieldValue,
          validateForm
        }) => (
          <FormActivator
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            onLoadChange={onLoadChange}
            validateForm={validateForm}
          >
            {({ active }) => (
              <>
                <FormSection
                  bkColor="#5C35AC"
                  number={getFormSectionNumber('merchant')}
                  title="Which merchant is creating shipment?"
                  locked={
                    !active.includes(getFormSectionNumber('merchant') - 1)
                  }
                  deps={[values.merchantName, merchants]}
                >
                  <FormPanel>
                    <h4>Merchant Information</h4>
                    <Col className="input-wrapper">
                      <p>Merchant Name</p>
                      <FormSearchInput
                        name="merchantName"
                        placeholder="Select..."
                        items={merchants}
                      />
                    </Col>
                  </FormPanel>
                </FormSection>
                <FormSection
                  bkColor="#5C35AC"
                  number={getFormSectionNumber('shipmentMode')}
                  title="How do you want your shipment to be delivered?"
                  locked={
                    !active.includes(getFormSectionNumber('shipmentMode') - 1)
                  }
                >
                  <FormPanel>
                    <h4>Your Shipment</h4>
                    <Row>
                      <Col className="input-wrapper">
                        <p>Shipment Name (Optional)</p>
                        <FormInput
                          placeholder="Type here"
                          name="shipmentName"
                        />
                      </Col>
                      <Col span={15}>
                        <p>Emails to Receive Quotation Updates (Optional)</p>
                        <FormMultiSelect
                          name="merchantEmails"
                          maxTagCount={1}
                          notFoundContent=""
                          mode="tags"
                          placeholder="Type in emails that will receive updates for this quotation"
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <h4>Mode of Shipment</h4>
                    <Col>
                      <p>How do you want your shipment to be delivered?</p>
                      <FormRadioGroup name="modeOfShipment">
                        <Radio value="Air">Air</Radio>
                        <Radio value="Sea">Sea</Radio>
                        <Radio value="Land">Land</Radio>
                      </FormRadioGroup>
                    </Col>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Origin/Shipper</h4>
                      <Col>
                        <p>Which first-mile service are you using?</p>
                        <FormRadioGroup name="firstMile">
                          <Radio value={1}>Pickup</Radio>
                          <Radio value={0}>Dropoff</Radio>
                          <Radio value={2}>No first mile</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <FormPickupDeliveryDetails
                      name="origin"
                      otherDate={values?.destination?.date}
                      source="origin"
                      service={values?.firstMile}
                      shipmentType={values?.modeOfShipment}
                      isInternal
                      setFieldValue={setFieldValue}
                      isQuote
                    />
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Mid-mile</h4>
                      <Col>
                        <p>
                          Do you require the mid-mile freight-forwarding
                          service?
                        </p>
                        <FormRadioGroup name="midMile">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Destination/Consignee</h4>
                      <Col>
                        <p>Do you require last-mile delivery?</p>
                        <FormRadioGroup name="lastMile">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <FormPickupDeliveryDetails
                      name="destination"
                      otherDate={values?.origin?.date}
                      source="destination"
                      service={values?.lastMile}
                      isInternal
                      shipmentType={values?.modeOfShipment}
                    />
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipping Information</h4>
                      <Col className="input-wrapper">
                        <p>
                          Incoterm (Optional)
                          <StyledPopover
                            title={<h4>Incoterm</h4>}
                            content={
                              <p>
                                Incoterms are an internationally recognised set
                                of instructions used in the global
                                transportation of goods. They define the
                                division of responsibility between the Shipper
                                (usually the supplier/consignor) and the
                                Consignee (usually the buyer). The terms dictate
                                which party is responsible for the risks, costs
                                and liabilities associated with the shipment at
                                each stage of the shipping process
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormSearchInput
                          allowClear
                          name="incoterm"
                          placeholder="Select..."
                          items={incoterms.map((type) => ({
                            label: type.value,
                            value: type.key
                          }))}
                        />
                      </Col>
                      <Col className="input-wrapper">
                        <p>HS Code (Optional)</p>
                        <FormInput placeholder="Type here" name="hsCode" />
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipment Price</h4>
                      <Col>
                        <p>
                          Does this shipment already have a price that is agreed
                          upon?
                        </p>
                        <FormRadioGroup name="agreedPrice">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Proposed Price, Reasons & Conditions</p>
                        <FormInput.FormTextArea
                          name="agreedPriceText"
                          placeholder="Type here"
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <FormSection
                  bkColor="#5C35AC"
                  number={getFormSectionNumber('shipmentDetails')}
                  title="What is your shipment?"
                  locked={
                    !active.includes(
                      getFormSectionNumber('shipmentDetails') - 1
                    )
                  }
                >
                  {values?.modeOfShipment && values?.modeOfShipment !== 'Air' && (
                    <FormPanel>
                      <Row>
                        <h4>
                          {getLoadType(values?.modeOfShipment, true)} Load
                        </h4>
                        <Col>
                          <p>
                            What is the {getLoadType(values?.modeOfShipment)}{' '}
                            load of your shipment?{' '}
                            <StyledPopover
                              content={
                                values?.modeOfShipment === 'Sea' ? (
                                  <>
                                    <p>
                                      <strong>FCL</strong>: Full container load
                                      is an ocean shipment in which the cargo
                                      occupies a full container (of any size).
                                    </p>
                                    <p>
                                      <strong>LCL</strong>: Less than Container
                                      Load is an ocean shipment not requiring
                                      full capacity of an ocean container and is
                                      usually less than 20 CBM (cubic meters)
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      <strong>FTL</strong>: Full truck load is
                                      the transport of goods that fill up a full
                                      truck, or a partial load shipment
                                      occupying an entire truck
                                    </p>
                                    <p>
                                      <strong>LTL</strong>: Less than truck load
                                      is the transport of goods which occupies
                                      partial capacity of a truck
                                    </p>
                                  </>
                                )
                              }
                            >
                              <Icon
                                className="question-circle"
                                type="question-circle"
                              />
                            </StyledPopover>
                          </p>
                          <FormRadioGroup name="load">
                            <Radio value>
                              Full{' '}
                              {getLoadType(
                                values?.modeOfShipment,
                                true
                              ).toLowerCase()}{' '}
                              load
                            </Radio>
                            <Radio value={false} style={{ marginLeft: 50 }}>
                              Less than{' '}
                              {getLoadType(
                                values?.modeOfShipment,
                                true
                              ).toLowerCase()}{' '}
                              load
                            </Radio>
                          </FormRadioGroup>
                          {values?.load && (
                            <FormFullLoad
                              loading={loadingPackage}
                              loadTypes={loadTypes}
                              name="loads"
                              modeOfShipment={values?.modeOfShipment}
                            />
                          )}
                        </Col>
                      </Row>
                    </FormPanel>
                  )}
                  <FormPanel>
                    <Row>
                      <h4>Shipment Dimension</h4>
                      <Col>
                        <p>
                          Add weight, chargeable weight and other information
                          for your shipment. If you have the information, please
                          enter shipment dimensions by unit volume and add a new
                          set of package when dimensions between
                          packages/cargoes in the shipment differ.
                        </p>
                        <FormPackageDimension
                          name="dimensions"
                          loading={loadingPackage}
                          modeOfShipment={values?.modeOfShipment}
                          packageTypes={packageTypes}
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Shipment Details</h4>
                      <Col>
                        <p>
                          Does your shipment contain any of the following goods?
                        </p>
                        <FormCheckboxGroup
                          name="dangerousGoods"
                          showErrorMessage
                          onChange={(value) => {
                            if (
                              value.length > 0 &&
                              value.indexOf('no_special_goods') === -1
                            ) {
                              setPublicDocuments([
                                ...defaultPublicDocuments,
                                {
                                  document_type:
                                    'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'
                                }
                              ]);
                            } else {
                              setPublicDocuments(defaultPublicDocuments);
                            }
                          }}
                        >
                          <Row>
                            <Col span={10}>
                              <Checkbox value="contains_battery">
                                Batteries/Powerbanks
                                <Popover
                                  title={<h4>Batteries</h4>}
                                  content={
                                    <p>
                                      Please select if you are shipping
                                      batteries. A valid Material Safety Data
                                      Sheet will be required for the battery
                                      type.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={14}>
                              <Checkbox value="contains_hazardous_goods">
                                Hazardous Materials
                                <Popover
                                  title={<h4>Hazardous Materials</h4>}
                                  content={
                                    <p>
                                      Please select if you are shipping
                                      hazardous material/dangerous goods. A
                                      valid Material Safety Data Sheet will be
                                      required for these items.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={10}>
                              <Checkbox value="other">
                                Other (powders, creams, liquids)
                                <Popover
                                  title={
                                    <h4>Other (powders, liquids, creams)</h4>
                                  }
                                  content={
                                    <p>
                                      Material Safety Data Sheets will be
                                      required for these commodities to certify
                                      that they are not classified as dangerous
                                      goods.
                                    </p>
                                  }
                                >
                                  <Icon
                                    className="question-circle"
                                    type="question-circle"
                                  />
                                </Popover>
                              </Checkbox>
                            </Col>
                            <Col span={14}>
                              <Checkbox value="no_special_goods">
                                No, my shipment does not contain any of the
                                goods above
                              </Checkbox>
                            </Col>
                          </Row>
                        </FormCheckboxGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Shipment Notes/Remarks (Optional)</p>
                        <FormInput.FormTextArea
                          name="shipmentDescription"
                          placeholder="Type here"
                        />
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <FormSection
                  bkColor="#5C35AC"
                  number={getFormSectionNumber('customs')}
                  title="How will the shipments clear customs?"
                  locked={!active.includes(getFormSectionNumber('customs') - 1)}
                >
                  <FormPanel>
                    <Row>
                      <h4>Customs Clearance</h4>
                      <Col>
                        <p>
                          Will you be using Janio&apos;s customs clearance
                          service?
                        </p>
                        <FormRadioGroup name="customsClearance">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                  <FormPanel>
                    <Row>
                      <h4>Customs Records</h4>
                      <Col>
                        <p>
                          Do you have an exporter of record (EOR) for the origin
                          country?
                          <StyledPopover
                            title={<h4>EOR (Importer Of Record)</h4>}
                            content={
                              <p>
                                An Exporter of Record is the legal exporting
                                entity for goods exiting a country.
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormRadioGroup name="hasEor">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          Do you have an importer of record (IOR) for the
                          destination country?
                          <StyledPopover
                            title={<h4>IOR (Importer Of Record)</h4>}
                            content={
                              <p>
                                Is a person or a company responsible for
                                arranging everything legal related in an import
                                operation on the country of importing.
                              </p>
                            }
                          >
                            <Icon
                              className="question-circle"
                              type="question-circle"
                            />
                          </StyledPopover>
                        </p>
                        <FormRadioGroup name="hasIor">
                          <Radio value>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </FormRadioGroup>
                      </Col>
                    </Row>
                  </FormPanel>
                </FormSection>
                <FormSection
                  bkColor="#5C35AC"
                  number={getFormSectionNumber('documents')}
                  title="Shipment Documents"
                  locked={
                    !active.includes(getFormSectionNumber('documents') - 1)
                  }
                >
                  <FormPanel>
                    <QuoteDocuments
                      setDataFile={(data) => {
                        setDataFile(data);
                        if (
                          values.dangerousGoods &&
                          values.dangerousGoods.length > 0 &&
                          values.dangerousGoods.indexOf('no_special_goods') ===
                            -1
                        ) {
                          if (data && data.MSDSFile) {
                            setFieldValue('documents', 'MSDS document');
                          }
                        }
                      }}
                      dataFile={dataFile}
                      dangerousGoods={
                        values.dangerousGoods &&
                        values.dangerousGoods.length > 0 &&
                        values.dangerousGoods.indexOf('no_special_goods') === -1
                      }
                      publicDocuments={publicDocuments}
                      documentsMap={documentsMap}
                    />
                  </FormPanel>
                </FormSection>
                Î
                <Row className="shipmentBtns" gutter={16}>
                  <Col span={24}>
                    <Button
                      loading={saving}
                      disabled={!isValid}
                      size="large"
                      className="create-shipment"
                      onClick={handleSubmit}
                    >
                      Request Quote
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </FormActivator>
        )}
      </Formik>
    </StyledCreateShipment>
  );
};

export default withRouter(CreateQuote);
