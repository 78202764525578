import React, { useEffect, useState } from 'react';
import { Row, Col, message, Divider } from 'antd';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Select from '../Select';
import { shopeeService } from '../../services/shopee';
import ErrorMessage from './ErrorMessage';

const { Option } = Select;

const StyledContainer = styled.div`
  font-family: AvenirLTStdBook;
  pagging: 30px 0px;
  font-size: 16px;
  color: #000000;

  input:disabled {
    color: #000000;
    background-color: #ffffff;
  }
`;

const StyledRow = styled(Row)`
  margin: 20px 0;
`;

const initialState = {
  cartonNo: undefined,
  packageWeight: undefined,
  actionType: 1
};

const CartonOperation = ({ data = [] }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visibleError, setVisibleError] = useState(false);

  const [{ cartonNo, packageWeight, actionType }, setState] = useState(
    initialState
  );

  const handleValueChange = (name) => (value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submit = () => {
    const key = `carton-operation-${Date.now()}`;
    message.loading({ content: 'Processing...', duration: 0, key });
    setLoading(true);

    if (!data.length) {
      setLoading(false);
      message.error({ content: 'Please attach an attachment', key });
      return;
    }

    const trackingNos = [];
    data.forEach((val) => {
      trackingNos.push({
        sls_tn: val['Shopee Tracking'],
        action_type: actionType
      });
    });

    const body = {
      carton_no: cartonNo,
      carton_packer_code: '101',
      package_weight: packageWeight,
      vol_weight: 20.0475,
      charged_weight: packageWeight,
      tracking_no_list: trackingNos
    };

    shopeeService
      .cartonOperation(body)
      .then(() => {
        setLoading(false);
        message.success({ content: 'Success!', key });
      })
      .catch((err) => {
        message.destroy();
        setLoading(false);

        const errMessage =
          err?.message ||
          'Failed to process. Please reach out Janio Technical Team.';

        setVisibleError(true);
        setErrorMessage(errMessage);
      });
  };

  useEffect(() => {
    if (!data.length) {
      return;
    }

    let totalPackageWeight = 0;
    data.forEach((val) => {
      totalPackageWeight += Number(val['Parcel Weight(KG)']);
    });

    setState((prevState) => ({
      ...prevState,
      cartonNo: data[0]['Carton No'],
      packageWeight: totalPackageWeight
    }));
  }, [data]);

  return (
    <StyledContainer>
      <StyledRow gutter={[16, 24]}>
        <Col span={12}>
          <p>Action Type</p>
          <Select
            placeholder="Action type"
            value={actionType}
            onChange={handleValueChange('actionType')}
          >
            <Option key="1" value={1}>
              Bind
            </Option>
            <Option key="2" value={2}>
              Unbind
            </Option>
          </Select>
        </Col>

        <Col span={12}>
          <p>Carton No</p>
          <Input disabled value={cartonNo} />
        </Col>

        <Col span={12} offset={12}>
          <p>Package Weight</p>
          <Input disabled value={packageWeight} />
        </Col>
      </StyledRow>

      <Divider />

      <Col>
        <Button onClick={submit} loading={loading}>
          Submit
        </Button>
      </Col>

      <ErrorMessage
        key={new Date()}
        message={errorMessage}
        visible={visibleError}
        setVisible={setVisibleError}
      />
    </StyledContainer>
  );
};

export default CartonOperation;
