import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { QUOTE_REQUEST, APPROVED, REJECTED, WIN } from '../../constants/status';
import QuoteWinLossModal from './QuoteWinLossModal';
import QuoteApproveRejectModal from './QuoteApproveRejectModal';

const StyledShippingInfoMessage = styled.div`
  background-color: #1fceca33;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-family: AvenirLTStdBook;
  color: #000;
  margin-bottom: 15px;
  svg {
    margin-right: 10px;
    color: #000;
  }
  .check svg {
    color: #52c41a;
  }
  a {
    color: #050593;
  }
`;

const ShippingInfoMessage = ({ children, info = true }) => {
  return (
    <StyledShippingInfoMessage>
      {info ? (
        <Icon type="info-circle" theme="outlined" />
      ) : (
        <Icon type="check-circle" theme="filled" className="check" />
      )}
      {children}
    </StyledShippingInfoMessage>
  );
};

const getQuoteActionBar = (
  quoteStatus,
  setIsApproveModalVisible,
  setIsUpdateModalVisible,
  quote_id,
  shipment_quote_id
) => {
  switch (quoteStatus) {
    case QUOTE_REQUEST:
      return (
        <ShippingInfoMessage>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setIsApproveModalVisible(true);
            }}
          >
            Approve or reject quote.
          </a>
        </ShippingInfoMessage>
      );
    case APPROVED:
      return (
        <ShippingInfoMessage>
          Quote is approved. Waiting for merchant’s response.&nbsp;
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setIsUpdateModalVisible(true);
            }}
          >
            Set quote to win or loss.
          </a>
        </ShippingInfoMessage>
      );
    case REJECTED:
      return <ShippingInfoMessage>Quote is rejected.</ShippingInfoMessage>;
    case WIN:
      if (shipment_quote_id) {
        return null;
      }
      return (
        <ShippingInfoMessage>
          Quote won.&nbsp;
          <a href={`/shipments/create-shipment/${quote_id}`}>
            Create shipment based on this quote.
          </a>
        </ShippingInfoMessage>
      );
    default:
      return <ShippingInfoMessage>Quote lost.</ShippingInfoMessage>;
  }
};

const QuoteActionBar = ({ quote_status, quote_id, shipment_quote_id }) => {
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  return (
    <>
      {getQuoteActionBar(
        quote_status,
        setIsApproveModalVisible,
        setIsUpdateModalVisible,
        quote_id,
        shipment_quote_id
      )}
      <QuoteWinLossModal
        isUpdateModalVisible={isUpdateModalVisible}
        setIsUpdateModalVisible={setIsUpdateModalVisible}
      />
      <QuoteApproveRejectModal
        isUpdateModalVisible={isApproveModalVisible}
        setIsUpdateModalVisible={setIsApproveModalVisible}
      />
    </>
  );
};

export default QuoteActionBar;
