import React, { forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import styled from '@emotion/styled';
import { useDebounce } from '../helpers/hooks';

const StyledInput = styled(AntInput)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  /* height: 35px; */
  color: #000;
  /* Font alignment adjustment for AvenirLTStdBook */
  padding: 6px 11px 3px 11px;
  &:hover {
    border-color: #050593;
  }
  &:focus {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
`;

const Input = (props, ref) => {
  const {
    onChange = () => {},
    value: propValue,
    timeout = 300,
    ...rest
  } = props;
  const [value, handleOnChange] = useDebounce(propValue, onChange, timeout);

  return (
    <StyledInput {...rest} onChange={handleOnChange} ref={ref} value={value} />
  );
};

export default forwardRef(Input);
