import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon, Row, Col } from 'antd';
import TextArea from '../TextArea';
import Modal from '../Modal';
import Button from '../Button';
import Message from '../Message';
import { invalidCharacter } from '../../utils/formatString';

const StyledButton = styled(Button)`
  margin: 0 0 0 8px;
`;
const StyledIcon = styled(Icon)`
  padding: 0 10px 0 0;
`;
const StyledModalContent = styled.div`
  padding: 0 0 15px 15px;
`;
const TitleReject = styled.span`
  color: #d5152f;
`;
const titleConfirm = (
  <>
    <StyledIcon type="info-circle" theme="filled" />
    Are you sure you want to send this quote?
  </>
);
const ShipmentQuoteActions = ({
  handleOnSendClick,
  handleOnRejectClick,
  handleOnEditClick,
  buttonSchema,
  quoteStatus
}) => {
  const [isSendModalVisible, setIsSendModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionReasonError, setRejectionReasonError] = useState('');
  const handleSend = async () => {
    await handleOnSendClick();
    setRejectionReasonError('');
    setIsSendModalVisible(false);
  };
  const handleReject = async () => {
    if (
      rejectionReason &&
      !invalidCharacter(rejectionReason) &&
      rejectionReason.length <= 250
    ) {
      await handleOnRejectClick(rejectionReason);
      setRejectionReasonError('');
      setIsRejectModalVisible(false);
    } else if (rejectionReason && invalidCharacter(rejectionReason)) {
      setRejectionReasonError('Please enter valid characters');
    } else if (rejectionReason && rejectionReason.length > 250) {
      setRejectionReasonError(
        'You have exceeded the maximum 250 characters length limit.'
      );
    } else {
      setRejectionReasonError('Please enter Reason for Rejection.');
    }
  };
  const handleSetRejectReason = (e) => {
    setRejectionReason(e.target.value);
  };
  return (
    <>
      <Modal
        title={titleConfirm}
        visible={isSendModalVisible}
        closable={false}
        footer={[
          <Button
            key="back"
            type="secondary"
            onClick={() => setIsSendModalVisible(false)}
          >
            No
          </Button>,
          <Button key="submit" onClick={handleSend}>
            Yes
          </Button>
        ]}
      >
        <StyledModalContent>
          <p>Your merchant may choose to accept this quote.</p>
        </StyledModalContent>
      </Modal>

      <Modal
        title={<TitleReject>Reject Quote Request</TitleReject>}
        visible={isRejectModalVisible}
        closable={false}
        footer={[
          <Button
            key="back"
            type="secondary"
            onClick={() => setIsRejectModalVisible(false)}
          >
            No
          </Button>,
          <Button key="submit" onClick={handleReject}>
            Yes
          </Button>
        ]}
      >
        <StyledModalContent>
          <p>Reason for rejection</p>
          <TextArea placeholder="Type Here" onChange={handleSetRejectReason} />
          <Message type="error">{rejectionReasonError}</Message>
        </StyledModalContent>
      </Modal>
      <div>
        <Row type="flex" justify="space-between">
          <Col span={12}>
            {buttonSchema.EDIT_QUOTE_BUTTON.includes(quoteStatus) && (
              <Button type="secondary" onClick={handleOnEditClick}>
                Edit
              </Button>
            )}
          </Col>
          <Col>
            {buttonSchema.REJECT_QUOTE_BUTTON.includes(quoteStatus) && (
              <StyledButton
                type="secondary"
                onClick={() => setIsRejectModalVisible(true)}
              >
                Reject
              </StyledButton>
            )}
            {buttonSchema.SEND_QUOTE_BUTTON.includes(quoteStatus) && (
              <StyledButton onClick={() => setIsSendModalVisible(true)}>
                Send
              </StyledButton>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ShipmentQuoteActions;
