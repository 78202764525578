const INVOICE_CURRENCIES = [
  {
    label: 'IDR (Indonesian Rupiah)',
    value: 'IDR'
  },
  {
    label: 'JPY (Japanese Yen)',
    value: 'JPY'
  },
  {
    label: 'MYR (Malaysian Ringgit)',
    value: 'MYR'
  },
  {
    label: 'SGD (Singapore Dollar)',
    value: 'SGD'
  },
  {
    label: 'USD (United States Dollar)',
    value: 'USD'
  },
  {
    label: 'AUD (Australian Dollar)',
    value: 'AUD'
  },
  {
    label: 'CNY (Chinese Yuan)',
    value: 'CNY'
  },
  {
    label: 'PHP (Philippine Peso)',
    value: 'PHP'
  },
  {
    label: 'GBP (British Pound Sterling)',
    value: 'GBP'
  },
  {
    label: 'KRW (South Korean Won)',
    value: 'KRW'
  },
  {
    label: 'TWD (New Taiwan Dollar)',
    value: 'TWD'
  },
  {
    label: 'HKD (Hong Kong Dollar)',
    value: 'HKD'
  },
  {
    label: 'VND (Viet-namese Dong)',
    value: 'VND'
  },
  {
    label: 'THB (Thai baht)',
    value: 'THB'
  }
];

export default INVOICE_CURRENCIES;
