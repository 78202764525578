import React, { useState, useRef, useEffect } from 'react';
import Select from './Select';
import { TEXT_NO_RESULT, TEXT_SEARCH_MODE } from '../constants/message';

const { Option } = Select;

const SearchInput = ({
  items,
  placeholder,
  value: outValue,
  allowClear = false,
  showSearch = true,
  loading = false,
  disabled = false,
  searchMode = false,
  onChange = () => {},
  onBlur = () => {},
  styles = {}
}) => {
  const [value, setValue] = useState(outValue || null);
  const [data, setData] = useState(items || []);
  const node = useRef();

  const handleSearch = (search = '') => {
    if (search) {
      setData(
        items?.filter((item) =>
          item?.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else if (searchMode) {
      setData([]);
    } else {
      setData(items);
    }
  };

  useEffect(() => {
    setValue(outValue);
    if (searchMode) {
      setData([]);
    } else if (items?.length > 0) {
      setData(items);
    } else {
      setData([]);
    }
  }, [items, outValue, searchMode]);

  return (
    <div ref={node}>
      <Select
        style={styles}
        disabled={disabled}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        // loading={loading && items?.length ? items.length < 1 : false}
        loading={loading}
        value={value}
        optionFilterProp="children"
        onSearch={handleSearch}
        onChange={(_value, option) => onChange(_value, option)}
        onBlur={onBlur}
        notFoundContent={searchMode ? TEXT_SEARCH_MODE : TEXT_NO_RESULT}
        getPopupContainer={() => node.current}
      >
        {data.map((item) => (
          <Option
            key={(item && item.value) || item}
            value={(item && item.value) || item}
          >
            {(item && item.label) || item}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchInput;
