import countryList from 'country-list';

const COUNTRIES_LIST = countryList
  .getData()
  .map((_item) => ({ value: _item.name, label: _item.name }));

const COUNTRIES = [
  {
    value: 'All',
    label: 'All'
  },
  ...countryList
    .getData()
    .map((_item) => ({ label: _item.name, value: _item.name }))
];
const COUNTRIES_WITH_BLANK = [
  {
    value: '-',
    label: '-'
  },
  ...countryList
    .getData()
    .map((_item) => ({ label: _item.name, value: _item.name }))
];

const ALL_COUNTRIES = countryList.getData().map((_item) => ({
  label: _item.name,
  value: _item.name,
  countryCode: _item.code
}));

const BILLING_COUNTRIES = [
  'INDONESIA',
  'SINGAPORE',
  'TAIWAN',
  'HONGKONG',
  'MALAYSIA',
  'CHINA'
];
export default COUNTRIES_LIST;
export { ALL_COUNTRIES, COUNTRIES, COUNTRIES_WITH_BLANK, BILLING_COUNTRIES };
