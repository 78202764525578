import { createReducer } from '@reduxjs/toolkit';
import {
  fetchQuoteDetail,
  updateQuoteAddressView,
  fetchQuoteShipmentLog,
  fetchQuoteCharges,
  changeQuoteStatus,
  updateDocument,
  unloadQuoteDetails,
  fetchQuoteLookups,
  addLineItems,
  removeLineItem,
  updateLineItem,
  changeDocumentStatus,
  removeDocumentFromList,
  updateDocumentVisibility
} from '../actions/quoteDetail';

const initialState = {
  quote: null,
  shipping_log: [],
  charges: {},
  lookups: {}
};

export default createReducer(initialState, {
  [fetchQuoteDetail](state, action) {
    state.quote = action.payload.quote;
  },
  [updateQuoteAddressView](state, action) {
    state.quote[action.payload.address] = {
      ...state.quote[action.payload.address],
      ...action.payload.details
    };
  },
  [fetchQuoteShipmentLog](state, action) {
    state.shipping_log = action.payload.shipping_log;
  },
  [fetchQuoteCharges](state, action) {
    state.charges = action.payload;
  },
  [changeQuoteStatus](state, action) {
    state.quote.quote_status = action.payload.status;
    if (action.payload.updateDate) {
      state.quote.shipment_status_updated_at = action.payload.updateDate;
    }
  },
  [changeDocumentStatus](state, action) {
    // TODO : Optimized the code
    const publicDocuments = state.quote.documents.publicDocuments.map((doc) => {
      return doc.document_id === action.payload
        ? {
            ...doc,
            document_is_document_approved: !doc.document_is_document_approved
          }
        : doc;
    });
    const privateDocuments = state.quote.documents.privateDocuments.map(
      (doc) => {
        return doc.document_id === action.payload
          ? {
              ...doc,
              document_is_document_approved: !doc.document_is_document_approved
            }
          : doc;
      }
    );
    state.quote = {
      ...state.quote,
      documents: { publicDocuments, privateDocuments }
    };
  },
  [removeDocumentFromList](state, action) {
    const publicDocuments = state.quote.documents.publicDocuments.filter(
      (doc) => {
        return doc.document_id !== action.payload;
      }
    );
    const privateDocuments = state.quote.documents.privateDocuments.filter(
      (doc) => {
        return doc.document_id !== action.payload;
      }
    );
    state.quote = {
      ...state.quote,
      documents: { publicDocuments, privateDocuments }
    };
  },
  [fetchQuoteLookups](state, action) {
    state.lookups = action.payload;
  },
  [updateDocument](state, action) {
    const docTypes = {};
    const publicDocuments = state.quote.documents.publicDocuments.map((doc) => {
      docTypes[doc.document_type] = 1;
      return doc.document_type === action.payload[0].document_type
        ? {
            ...doc,
            document_url: action.payload[0].document_url,
            document_title: action.payload[0].document_title
          }
        : doc;
    });
    const privateDocuments = state.quote.documents.privateDocuments.map(
      (doc) => {
        docTypes[doc.document_type] = 1;
        return doc.document_type === action.payload[0].document_type
          ? {
              ...doc,
              document_url: action.payload[0].document_url,
              document_title: action.payload[0].document_title
            }
          : doc;
      }
    );
    if (!docTypes[action.payload[0].document_type]) {
      if (action.payload[0].document_is_private) {
        privateDocuments.push(action.payload[0]);
      } else {
        publicDocuments.push(action.payload[0]);
      }
    }

    state.quote = {
      ...state.quote,
      documents: { publicDocuments, privateDocuments }
    };
  },
  [updateDocumentVisibility](state, action) {
    const allDocuments = {};
    state.quote.documents.publicDocuments.forEach((doc) => {
      if (doc?.document_id) {
        allDocuments[doc.document_id] = doc;
      }
    });
    state.quote.documents.privateDocuments.forEach((doc) => {
      allDocuments[doc.document_id] = doc;
    });
    const privateDocuments = [];
    const publicDocuments = [];
    action.payload.forEach((docData) => {
      if (docData.status === 'public') {
        if (docData.documentId)
          publicDocuments.push(allDocuments[docData.documentId]);
      } else {
        privateDocuments.push(allDocuments[docData.documentId]);
      }
    });
    state.quote = {
      ...state.quote,
      documents: { publicDocuments, privateDocuments }
    };
  },
  [unloadQuoteDetails]() {
    return initialState;
  },
  [addLineItems](state) {
    state.charges.line_items = [
      ...state.charges.line_items,
      {
        key: state.charges.line_items.length,
        // fee_name: null,
        // comment: null,
        weight: 0,
        unit_price: 0,
        total_amount: 0
      }
    ];
  },
  [removeLineItem](state, action) {
    state.charges.line_items.splice(action.payload, 1);
  },
  [updateLineItem](state, action) {
    state.charges.line_items[action.payload.index][action.payload.type] =
      action.payload.value;
    state.charges.line_items[action.payload.index].total_amount =
      state.charges.line_items[action.payload.index].unit_price *
      state.charges.line_items[action.payload.index].weight;
  }
});
