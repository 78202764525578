import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';

const itemRender = (route, params, routes, paths) => {
  const path = paths.reduce((a, b) => {
    return a.length > b.length ? a : b;
  }, '');
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${path}`}>{route.breadcrumbName}</Link>
  );
};

const StyledBreadcrumb = styled(AntBreadcrumb)`
  &.ant-breadcrumb {
    background: transparent;
    padding: 12px 40px;
    line-height: 14.3px;
    max-height: 40px;
    height: 40px;
    & span .ant-breadcrumb-link {
      font-family: AvenirLTStdBook;
      font-size: 14px;
      a {
        color: #050593;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      color: #000000;
    }

    & > span:last-child .ant-breadcrumb-link {
      text-decoration: none;
      span {
        font-family: AvenirLTStdBook;
        color: #000000;
      }
    }
  }
`;

const Breadcrumb = () => {
  const history = useHistory();
  const { pathname } = history.location;

  const [routes, setRoutes] = useState([
    {
      path: '/',
      breadcrumbName: 'Overview'
    }
  ]);

  useEffect(() => {
    const routesConfig = {
      '': {
        name: 'Overview',
        path: '/'
      },
      shipments: {
        name: 'View Shipments',
        path: '/shipments'
      },
      faqs: {
        name: 'Glossary/FAQ',
        path: '/faqs'
      },
      quotes: {
        name: 'Quotes',
        path: '/quotes'
      },
      admin: {
        name: 'Administrator',
        path: '/admin'
      },
      users: {
        name: 'Manage Users',
        path: '/users'
      },
      'create-shipment': {
        name: 'Create Shipment',
        path: '/shipments/create-shipment'
      },
      'create-quote': {
        name: 'Request Quote',
        path: '/quotes/create-quote'
      },
      shopee: {
        name: 'Shopee Integration',
        path: '/shopee'
      }
    };
    const Current = [];
    const arrRoute = pathname.split('/');
    arrRoute.shift();
    arrRoute.forEach((item) => {
      if (Object.keys(routesConfig).includes(item)) {
        Current.push({
          path: routesConfig[item].path,
          breadcrumbName: routesConfig[item].name
        });
      } else {
        Current.push({
          breadcrumbName: item
        });
      }
    });
    setRoutes(Current);
  }, [pathname]);

  return (
    <StyledBreadcrumb itemRender={itemRender} separator=">" routes={routes} />
  );
};

export default Breadcrumb;
