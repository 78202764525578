import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Popover, Icon, Input as AntInput } from 'antd';
import moment from 'moment';
import Input from './Input';
import DatePicker from './DatePicker';
import Button from './Button';
import SearchInput from './SearchInput';
import { COUNTRIES } from '../constants/countries';
import {
  setQuoteFilters,
  setQuoteRangeFilters,
  resetFilters,
  applyFilters
} from '../actions/quoteRequestsList';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  top: 225px;
  left: 184px;
  width: 280px;
  height: 138px;
  z-index: 999;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 20px #00000029;
  opacity: 1;
  &.range-filter {
    width: 330px !important;
  }
  &.filter-container {
    background: #fff;
    padding: 15px;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      text-align: left;
      .anticon {
        cursor: pointer;
      }
    }
    .ant-select-dropdown-menu {
      text-align: left;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 60px;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      color: #bbbbbb;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 0 12px 0 !important;
  }
`;

const QuoteFilter = ({
  onFilter = () => {},
  filterName,
  isMinMaxFilter = false,
  confirm = () => {}
}) => {
  const dispatch = useDispatch();
  const { user_names, user_emails, loading } = useSelector(
    (state) => state.quoteRequestList.choices
  );
  const quoteId = useSelector(
    (state) => state.quoteRequestList.tempFilters.quoteId
  );
  const approveRejectedStatus = useSelector(
    (state) => state.quoteRequestList.tempFilters.approveRejectedStatus
  );
  const merchantName = useSelector(
    (state) => state.quoteRequestList.tempFilters.merchantName
  );
  const origin = useSelector(
    (state) => state.quoteRequestList.tempFilters.origin
  );
  const destination = useSelector(
    (state) => state.quoteRequestList.tempFilters.destination
  );
  const shipmentMode = useSelector(
    (state) => state.quoteRequestList.tempFilters.shipmentMode
  );
  const createdBy = useSelector(
    (state) => state.quoteRequestList.tempFilters.createdBy
  );
  const creatorEmail = useSelector(
    (state) => state.quoteRequestList.tempFilters.creatorEmail
  );
  const quoteStatus = useSelector(
    (state) => state.quoteRequestList.tempFilters.quoteStatus
  );
  const winLossReason = useSelector(
    (state) => state.quoteRequestList.tempFilters.winLossReason
  );
  const shipmentQuoteId = useSelector(
    (state) => state.quoteRequestList.tempFilters.shipmentQuoteId
  );
  const networkPartnerCost = useSelector(
    (state) => state.quoteRequestList.tempFilters.networkPartnerCost
  );
  const totalQuotePrice = useSelector(
    (state) => state.quoteRequestList.tempFilters.totalQuotePrice
  );
  const createdAt = useSelector((state) =>
    state.quoteRequestList.tempFilters.createdAt
      ? state.quoteRequestList.tempFilters.createdAt?.map((d) => moment(d)) ||
        []
      : []
  );

  const handleReset = () => {
    dispatch(resetFilters({ key: filterName }));
    const filters = {
      quoteId,
      createdAt,
      winLossReason,
      shipmentQuoteId,
      merchantName,
      origin: origin === 'All' ? null : origin,
      destination: destination === 'All' ? null : destination,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      quoteStatus: quoteStatus === 'All' ? null : quoteStatus,
      approveRejectedStatus:
        approveRejectedStatus === 'All' ? null : approveRejectedStatus,
      networkPartnerCost:
        networkPartnerCost && (networkPartnerCost.min || networkPartnerCost.max)
          ? networkPartnerCost
          : null,
      totalQuotePrice:
        totalQuotePrice && (totalQuotePrice.min || totalQuotePrice.max)
          ? totalQuotePrice
          : null,
      [filterName]:
        [
          'quoteId',
          'createdAt',
          'winLossReason',
          'shipmentQuoteId',
          'merchantName',
          'origin',
          'destination',
          'shipmentMode',
          'createdBy',
          'creatorEmail',
          'quoteStatus',
          'approveRejectedStatus',
          'networkPartnerCost',
          'totalQuotePrice'
        ].indexOf(filterName) === -1
          ? ''
          : null
    };
    onFilter(filters);
    confirm();
  };

  const handleFilter = () => {
    onFilter({
      quoteId,
      createdAt,
      winLossReason,
      shipmentQuoteId,
      merchantName: merchantName === 'All' ? null : merchantName,
      origin: origin === 'All' ? null : origin,
      destination: destination === 'All' ? null : destination,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      quoteStatus: quoteStatus === 'All' ? null : quoteStatus,
      approveRejectedStatus:
        approveRejectedStatus === 'All' ? null : approveRejectedStatus,
      networkPartnerCost:
        networkPartnerCost && (networkPartnerCost.min || networkPartnerCost.max)
          ? networkPartnerCost
          : null,
      totalQuotePrice:
        totalQuotePrice && (totalQuotePrice.min || totalQuotePrice.max)
          ? totalQuotePrice
          : null
    });
    dispatch(applyFilters());
    confirm();
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setQuoteFilters({ [field]: option?.props?.value }));
  };

  const handleDatePickerChange = (field) => (value) => {
    dispatch(
      setQuoteFilters({
        [field]: [
          value[0]?.startOf('day').toISOString(),
          value[1]?.endOf('day').toISOString()
        ]
      })
    );
  };

  const handleTrackerChange = (field) => (e) => {
    dispatch(setQuoteFilters({ [field]: e.target.value }));
  };

  const handleRangeChange = (field, key) => (e) => {
    dispatch(setQuoteRangeFilters({ field, key, value: e.target.value }));
  };

  return (
    <FilterContainer
      className={
        isMinMaxFilter ? 'filter-container range-filter' : 'filter-container'
      }
    >
      {filterName === 'quoteId' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-number">
              Filter quote number{' '}
              <Popover
                placement="topLeft"
                arrowPointAtCenter
                overlayClassName="tooltip"
                content={
                  <div className="tooltip-content">
                    The quote number is a unique ID referring to a quote
                  </div>
                }
                title="Quote Number"
              >
                <Icon type="question-circle" />
              </Popover>
            </label>
            <Input
              id="quote-number"
              placeholder="Type..."
              value={quoteId}
              onChange={handleTrackerChange('quoteId')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'createdAt' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdAt">Filter quote creation date</label>
            <RangePicker
              id="createdAt"
              value={createdAt}
              onChange={handleDatePickerChange('createdAt')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'approveRejectedStatus' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="approveRejectedStatus">
              Filter approved/rejected status
            </label>
            <SearchInput
              placeholder="Type or select..."
              id="approveRejectedStatus"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Approved', value: 'Approved' },
                { label: 'Rejected', value: 'Rejected' }
              ]}
              loading={loading}
              value={approveRejectedStatus}
              onChange={handleSearchInputChange('approveRejectedStatus')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'merchantName' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="merchant-name">Filter merchant name</label>
            <Input
              id="merchant-name"
              placeholder="Type..."
              value={merchantName}
              onChange={handleTrackerChange('merchantName')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'origin' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="origin-country">Filter origin country</label>
            <SearchInput
              id="origin-country"
              items={COUNTRIES}
              value={origin}
              valueKey="value"
              onChange={handleSearchInputChange('origin')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'destination' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="destination">Filter destination country</label>
            <SearchInput
              id="destination"
              items={COUNTRIES}
              value={destination}
              valueKey="value"
              onChange={handleSearchInputChange('destination')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'shipmentMode' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-shipment-mode">Filter shipment mode</label>
            <SearchInput
              id="quote-shipment-mode"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Air', value: 'Air' },
                { label: 'Sea', value: 'Sea' },
                { label: 'Land', value: 'Land' }
              ]}
              loading={loading}
              value={shipmentMode}
              onChange={handleSearchInputChange('shipmentMode')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'createdBy' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdBy">Filter created by</label>
            <SearchInput
              id="createdBy"
              items={['All', ...user_names].map((name) => ({
                label: name,
                value: name
              }))}
              value={createdBy}
              valueKey="value"
              onChange={handleSearchInputChange('createdBy')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'creatorEmail' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="creatorEmail">Filter creator email</label>
            <SearchInput
              id="creatorEmail"
              items={['All', ...user_emails].map((email) => ({
                label: email,
                value: email
              }))}
              value={creatorEmail}
              valueKey="value"
              onChange={handleSearchInputChange('creatorEmail')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'quoteStatus' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-status">Filter quote status</label>
            <SearchInput
              id="quote-status"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Win', value: 'Win' },
                { label: 'Loss', value: 'Loss' }
              ]}
              loading={loading}
              value={quoteStatus}
              onChange={handleSearchInputChange('quoteStatus')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'winLossReason' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-reason">Filter quote reason </label>
            <Input
              id="quote-reason"
              placeholder="Type..."
              value={winLossReason}
              onChange={handleTrackerChange('winLossReason')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'shipmentQuoteId' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="tracking-number">
              Filter tracking number{' '}
              <Popover
                placement="topLeft"
                arrowPointAtCenter
                overlayClassName="tooltip"
                content={
                  <div className="tooltip-content">
                    The tracking number is a unique ID referring to a quote or
                    shipment
                  </div>
                }
                title="Tracking Number"
              >
                <Icon type="question-circle" />
              </Popover>
            </label>
            <Input
              id="tracking-number"
              placeholder="Type..."
              value={shipmentQuoteId}
              onChange={handleTrackerChange('shipmentQuoteId')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'networkPartnerCost' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="networkPartnerCost">
              Filter total cost from NP
            </label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: 136,
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={networkPartnerCost.min}
                onChange={handleRangeChange('networkPartnerCost', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 136,
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={networkPartnerCost.max}
                onChange={handleRangeChange('networkPartnerCost', 'max')}
              />
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'totalQuotePrice' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="totalQuotePrice">Filter total quote price</label>
            <AntInput.Group compact>
              <Input
                style={{
                  width: 136,
                  textAlign: 'center'
                }}
                placeholder="Min"
                value={totalQuotePrice.min}
                onChange={handleRangeChange('totalQuotePrice', 'min')}
              />
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  backgroundColor: 'white'
                }}
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right"
                style={{
                  width: 136,
                  textAlign: 'center',
                  borderLeft: 0
                }}
                placeholder="Max"
                value={totalQuotePrice.max}
                onChange={handleRangeChange('totalQuotePrice', 'max')}
              />
            </AntInput.Group>
          </Col>
        </Row>
      )}
      <div className="actions">
        <Button
          type="link"
          size="small"
          className="reset-filter"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="secondary" onClick={handleFilter}>
          Filter
        </Button>
      </div>
    </FilterContainer>
  );
};

export default QuoteFilter;
