import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Popover, Icon } from 'antd';
import moment from 'moment';
import Dropdown from './Dropdown';
import Input from './Input';
import DatePicker from './DatePicker';
import Button from './Button';
import SearchInput from './SearchInput';
import { COUNTRIES } from '../constants/countries';
import {
  setShipmentFilters,
  resetFilters,
  cancelFilters,
  applyFilters
} from '../actions/shipmentList';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  user-select: none;
  width: 860px;
  min-height: 350px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
      margin-bottom: 12px;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 0 12px 0 !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const ShipmentFilterDropdown = ({ onFilter = () => {} }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();
  const { merchants, statuses, loading, operators } = useSelector(
    (state) => state.shipmentList.choices
  );

  const status = useSelector((state) => state.shipmentList.tempFilters.status);
  const readyToBill = useSelector(
    (state) => state.shipmentList.tempFilters.readyToBill
  );
  const trackingNumber = useSelector(
    (state) => state.shipmentList.tempFilters.trackingNumber
  );
  const quoteRequestID = useSelector(
    (state) => state.shipmentList.tempFilters.quoteRequestID
  );
  const merchantName = useSelector(
    (state) => state.shipmentList.tempFilters.merchantName
  );
  const shipmentMode = useSelector(
    (state) => state.shipmentList.tempFilters.shipmentMode
  );
  const operator = useSelector(
    (state) => state.shipmentList.tempFilters.operator
  );
  const confirmedShipmentDate = useSelector((state) =>
    state.shipmentList.tempFilters.confirmedShipmentDate
      ? state.shipmentList.tempFilters.confirmedShipmentDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const estimatedDeliveryDate = useSelector((state) =>
    state.shipmentList.tempFilters.estimatedDeliveryDate
      ? state.shipmentList.tempFilters.estimatedDeliveryDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const actualDeliveryDate = useSelector((state) =>
    state.shipmentList.tempFilters.actualDeliveryDate
      ? state.shipmentList.tempFilters.actualDeliveryDate?.map((d) =>
          moment(d)
        ) || []
      : []
  );
  const origin = useSelector((state) => state.shipmentList.tempFilters.origin);
  const destination = useSelector(
    (state) => state.shipmentList.tempFilters.destination
  );
  const transhipmentCountry = useSelector(
    (state) => state.shipmentList.tempFilters.transhipmentCountry
  );

  const filtered = useSelector((state) => state.shipmentList.filtered);

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    dispatch(cancelFilters());
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };
  const handleFilter = () => {
    onFilter({
      status: status === 'All' ? null : status,
      readyToBill: readyToBill === 'All' ? null : readyToBill,
      trackingNumber,
      quoteRequestID,
      merchantName: merchantName === 'All' ? null : merchantName,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      estimatedDeliveryDate,
      confirmedShipmentDate,
      actualDeliveryDate,
      origin: origin === 'All' ? null : origin,
      destination: destination === 'All' ? null : destination,
      transhipmentCountry:
        transhipmentCountry === 'All' ? null : transhipmentCountry,
      operator: operator === 'All' ? null : operator
    });
    dispatch(applyFilters());
    setVisible(false);
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setShipmentFilters({ [field]: option?.props?.value }));
  };

  const handleDatePickerChange = (field) => (value) => {
    dispatch(
      setShipmentFilters({
        [field]: [
          value[0]?.startOf('day').toISOString(),
          value[1]?.endOf('day').toISOString()
        ]
      })
    );
  };

  const handleTrackerChange = (e) => {
    dispatch(setShipmentFilters({ trackingNumber: e.target.value }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (!node.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!visible) {
      dispatch(cancelFilters());
    }
  }, [dispatch, visible]);

  const getButtonType = () => {
    if (filtered) {
      return 'primary';
    }
    return visible ? 'primary' : 'secondary';
  };

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={filtered ? 'Filtered*' : 'Filter'}
        onClick={handleDropdownClick}
        type={getButtonType()}
        getPopupContainer={() => node.current}
        align={{ overflow: { adjustX: false, adjustY: false } }}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[50, 16]}>
            <Col span={7} style={{ borderRight: '1px solid #eee' }}>
              <header>By Shipment</header>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="tracking-number">
                    Tracking Number{' '}
                    <Popover
                      placement="topLeft"
                      arrowPointAtCenter
                      overlayClassName="tooltip"
                      content={
                        <div className="tooltip-content">
                          The tracking number is a unique ID referring to a
                          quote or shipment
                        </div>
                      }
                      title="Tracking Number"
                      getPopupContainer={() => node.current}
                    >
                      <Icon type="question-circle" />
                    </Popover>
                  </label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={trackingNumber}
                    onChange={handleTrackerChange}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="merchant-name">Merchant Name</label>
                  <SearchInput
                    placeholder="Type or select..."
                    id="merchant-name"
                    items={merchants}
                    loading={loading}
                    value={merchantName}
                    onChange={handleSearchInputChange('merchantName')}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="quote-public-status">Tracking Status</label>
                  <SearchInput
                    id="quote-public-status"
                    items={statuses}
                    loading={loading}
                    value={status}
                    onChange={handleSearchInputChange('status')}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="ready-to-bill">Ready to Bill</label>
                  <SearchInput
                    id="ready-to-bill"
                    items={[
                      { label: 'All', value: 'All' },
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' }
                    ]}
                    value={readyToBill}
                    onChange={handleSearchInputChange('readyToBill')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <header>By Place &amp; Mode</header>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="origin-country">Origin Country</label>
                  <SearchInput
                    id="origin-country"
                    items={COUNTRIES}
                    value={origin}
                    valueKey="value"
                    onChange={handleSearchInputChange('origin')}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="transhipment-country">
                    Transhipment Country
                  </label>
                  <SearchInput
                    id="transhipment-country"
                    items={COUNTRIES}
                    value={transhipmentCountry}
                    valueKey="value"
                    onChange={handleSearchInputChange('transhipmentCountry')}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="destination-country">
                    Destination Country
                  </label>
                  <SearchInput
                    id="destination-country"
                    items={COUNTRIES}
                    value={destination}
                    valueKey="value"
                    onChange={handleSearchInputChange('destination')}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="quote-shipment-mode">Shipment Mode</label>
                  <SearchInput
                    id="quote-shipment-mode"
                    items={[
                      { label: 'All', value: 'All' },
                      { label: 'Air', value: 'Air' },
                      { label: 'Sea', value: 'Sea' },
                      { label: 'Land', value: 'Land' }
                    ]}
                    loading={loading}
                    value={shipmentMode}
                    onChange={handleSearchInputChange('shipmentMode')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={10} style={{ borderLeft: '1px solid #eee' }}>
              <header>By Date &amp; Operator</header>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="shipment-confirmed-date">
                    Shipment Confirmed Date
                  </label>
                  <RangePicker
                    id="shipment-confirmed-date"
                    value={confirmedShipmentDate}
                    onChange={handleDatePickerChange('confirmedShipmentDate')}
                    contained
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="estimated-delivery-date">
                    Estimated Delivery Date
                  </label>
                  <RangePicker
                    id="estimated-delivery-date"
                    value={estimatedDeliveryDate}
                    onChange={handleDatePickerChange('estimatedDeliveryDate')}
                    contained
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="actual-delivery-date">
                    Actual Delivery Date
                  </label>
                  <RangePicker
                    id="actual-delivery-date"
                    value={actualDeliveryDate}
                    onChange={handleDatePickerChange('actualDeliveryDate')}
                    contained
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="operator-in-charge">Operator-in-charge</label>
                  <SearchInput
                    id="operator-in-charge"
                    items={operators}
                    value={operator}
                    onChange={handleSearchInputChange('operator')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default ShipmentFilterDropdown;
